import { BitmapLoader } from './bitmap-loader'
import { DraggableTile } from './draggable-tile'
import { Point } from './point'

export class BitmapTile extends DraggableTile {
  public name: string = ''

  public async loadImage(src: string) {
    const bitmap = await BitmapLoader.load(src)

    var w = bitmap.image.width * this.scaleX
    var h = bitmap.image.height * this.scaleY

    const pos = new Point(this.origin.x - this.regX, this.origin.y - this.regY)
    // console.log(`loadImage ${this.scaleY}`)
    this.size = new Point(w, h)
    // this.regX = (this.size.x / 2);
    // this.regY = (this.size.y / 2);
    this.regX = 0 // This is likely a bug
    this.regY = 0
    this.x = pos.x + this.regX
    this.y = pos.y + this.regY
    this.origin = new Point(pos.x + this.regX, pos.y + this.regY)
    bitmap.x = -(this.size.x / 2)
    bitmap.y = -(this.size.y / 2)

    this.addChild(bitmap)
    this.stage?.update()
  }

  public loadFromBitmap(bitmap: createjs.Bitmap) {
    // var w = bitmap.image.width * this.scaleX
    // var h = bitmap.image.height * this.scaleY

    // const pos = new Point(this.origin.x - this.regX, this.origin.y - this.regY)
    // // console.log(`loadImage ${this.scaleY}`)
    // this.size = new Point(w, h)
    // // this.regX = (this.size.x / 2);
    // // this.regY = (this.size.y / 2);
    // this.regX = w / 2
    // this.regY = h / 2
    // this.x = pos.x + this.regX
    // this.y = pos.y + this.regY
    // this.origin = new Point(pos.x + this.regX, pos.y + this.regY)
    // bitmap.x = -(this.size.x / 2)
    // bitmap.y = -(this.size.y / 2)

    this.addChild(bitmap)
    this.setBounds(0, 0, bitmap.image.width, bitmap.image.height)
    this.stage?.update()
  }

  protected snapTo(x: number, y: number): Point | undefined {
    return new Point(x, y)
  }
}
