'use client'
import * as createjs from 'createjs-module'
import Randomize from '../utils/randomize'
import { Animator } from '../core/animator'
import { BitmapLoader } from '../core/bitmap-loader'
import { BitmapTile } from '../core/bitmap-tile'
import { Color } from '../core/color'
import { delay } from '../utils/delay'
import { directionFrom, LayoutFlex } from '../core/layout-flex'
import { DraggableTile } from '../core/draggable-tile'
import { GameBase, IGameConfig } from '../core/game-base'
import { GameSound } from '../resource/game-sound'
import { IBasicImageFactory } from '../core/image-factory'
import { LayoutPadding } from '../core/layout-padding'
import { LayoutPin, Pin } from '../core/layout-pin'
import { LetterTile } from '../core/letter-tile'
import { Point } from '../core/point'
import { Rect } from '../core/rect'
import { ResizableRect } from '../core/resizable-rect'

export class SortingGame extends GameBase {
  protected _imageFactory: IBasicImageFactory

  protected _label1: LetterTile
  protected _label2: LetterTile
  protected _background1: ResizableRect | undefined
  protected _background2: ResizableRect | undefined
  protected _bitmap1: createjs.Bitmap | undefined
  protected _bitmap2: createjs.Bitmap | undefined

  protected _answers: BitmapTile[] = []
  protected _setOne: string[] = []
  protected _setTwo: string[] = []

  protected _win: boolean = false

  constructor(config: IGameConfig) {
    super(config)

    this._imageFactory = config.imageFactory
    this._pageIndex = config.pageIndex % this.count()

    const [l1, l2] = this.questions()[this._pageIndex]
    this._label1 = this.createLabel(l1, Color.randomTextColor())
    this._label2 = this.createLabel(l2, Color.randomTextColor())

    GameSound.registerOne(
      'can you sort them',
      require('../../sounds/can you sort them.mp3')
    )
  }

  public layoutSize(landscape: boolean) {
    super.layoutSize(landscape)
    if (landscape) {
      return new Point(GameBase.LONGEST, GameBase.SHORTER)
    } else {
      return new Point(GameBase.SHORTER, GameBase.LONGEST)
    }
  }

  protected questions(): string[][] {
    return [[], []]
  }

  protected answers(index: number): string[][] {
    return [[], []]
  }

  public count() {
    return this.questions().length
  }

  async load() {
    await this.loadQuestion()
    await this.loadAnswers()

    if (this._bitmap1 && this._bitmap2) {
      this.addChild(this._bitmap1)
      this.addChild(this._bitmap2)
    }

    this.layout(this._landscape)
    this.stage?.update()

    Animator.fadeIn(this._background1!)
    Animator.fadeIn(this._background2!)
    Animator.fadeIn(this._label1)
    Animator.fadeIn(this._label2)

    if (this._bitmap1 && this._bitmap2) {
      Animator.fadeIn(this._bitmap1)
      Animator.fadeIn(this._bitmap2)
    }

    this._answers.forEach((f) => {
      Animator.fadeIn(f)
    })

    await delay(GameBase.INTROSOUNDDELAY)
    await this.playSound('can you sort them')
  }

  protected createLabel(text: string, color: string) {
    const labelSize = Point.xy(240, 60)
    const label = new LetterTile(
      labelSize,
      labelSize.plus(Point.xy(20, 0)),
      text,
      true,
      false
    )
    label.alpha = 0
    label.setBackground(color, 0)
    return label
  }

  protected async loadQuestion() {
    const size = Point.xy(400, 400)
    const b1 = new ResizableRect(size, 'white', 10)
    b1.regX = size.x / 2
    b1.regY = size.y / 2
    b1.name = 'b1'
    b1.alpha = 0
    this._background1 = b1
    this.addChild(b1)

    const b2 = new ResizableRect(size, 'white', 10)
    b2.regX = size.x / 2
    b2.regY = size.y / 2
    b2.name = 'b2'
    b2.alpha = 0
    this._background2 = b2
    this.addChild(b2)

    this.addChild(this._label1)
    this.addChild(this._label2)
  }

  protected async loadAnswers() {
    const [setOne, setTwo] = this.answers(this._pageIndex)
    const names = [...setOne, ...setTwo]
    for (let i = 0; i < names.length; ++i) {
      let b = await this._imageFactory.getImage(names[i])
      b = BitmapLoader.scale(b, 0.4)

      const tile = new BitmapTile(Point.ZERO, Point.ZERO, Point.ZERO)
      tile.name = names[i]
      tile.alpha = 0
      tile.onLifted = this.onLifted
      tile.onPlaced = this.onPlaced
      tile.loadFromBitmap(b)

      this._answers.push(tile)
    }

    Randomize.shuffle(this._answers)

    this.addChild(...this._answers)
  }

  public layout(landscape: boolean) {
    const layoutSize = this.layoutSize(landscape)

    const rect = new Rect(Point.ZERO, layoutSize).inset(0, -100)

    const padding = new LayoutPadding(50, 50)

    new LayoutFlex(
      directionFrom(landscape),
      rect,
      [this._background1!, padding, this._background2!],
      this._answers,
      30
    ).layoutFlex()

    LayoutPin.pin(this._label1!, Pin.bottom, this._background1!, Pin.top, 10)
    LayoutPin.pin(this._label2!, Pin.bottom, this._background2!, Pin.top, 10)

    this.stage?.update()
  }

  protected isSetOne(t: createjs.DisplayObject) {
    return this._setOne.findIndex((n) => n === t.name) !== -1
  }

  protected checkAnswer() {
    return this._answers.map((a) => !a.canDrag).reduce((p, i) => p && i)
  }

  protected onLifted = (t: DraggableTile) => {
    this.playSound(t.name)
  }

  protected onPlaced = (t: DraggableTile) => {
    const current = new Point(t.x, t.y)
    const isSetOne = this.isSetOne(t)
    let correct = false
    if (Point.from(this._background1!).isRoughlyEqual(current, 150)) {
      correct = isSetOne
    } else if (Point.from(this._background2!).isRoughlyEqual(current, 150)) {
      correct = !isSetOne
    } else {
      return undefined
    }

    if (correct) {
      this.playSound('success')
      Animator.win(t)
      t.canDrag = false
      if (this.checkAnswer()) {
        this.animateSummary()
      }
    } else {
      this.lose(t)
    }
    return current
  }

  protected async lose(t: DraggableTile) {
    Animator.lose(t)
    this.playSound('error')
    await delay(500)
    t.setPositionToOrigin()
    t.stage?.update()
  }

  protected async animateSummary() {
    if (this._win) {
      return
    }
    this._win = true

    await delay(1500)

    this.playSound('success')

    await Promise.all(
      this._answers.map(async (t) => {
        t.rotation = 0
        await Animator.win(t)
      })
    )

    await delay(GameBase.WINDELAY)
    this._callback()
  }
}
