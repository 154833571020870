export function isPortrait() {
  const ori = window.orientation
  if (ori === undefined) {
    return false
  }
  switch (ori) {
    case -90:
    case 90:
      return false
    default:
      return true
  }
}

export function isLandscape() {
  return !isPortrait()
}
