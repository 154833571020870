import { PaintingBaseImages } from './painting-base-images'

export class PaintingSeaAnimalImages extends PaintingBaseImages {
  public static PICTURES: { [id: string]: string } = {
    whale: require('../../images/paint-sea-animals/whale_outline.png'),
    frog: require('../../images/paint-sea-animals/frog_outline.png'),
    dolphin: require('../../images/paint-sea-animals/dolphin_outline.png'),
    'puffer fish': require('../../images/paint-sea-animals/puffer fish_outline.png'),
    shrimp: require('../../images/paint-sea-animals/shrimp_outline.png'),
    squid: require('../../images/paint-sea-animals/squid_outline.png'),
    turtle: require('../../images/paint-sea-animals/turtle_outline.png'),
    crab: require('../../images/paint-sea-animals/crab_outline.png'),
    jellyfish: require('../../images/paint-sea-animals/jellyfish_outline.png')
  }

  private static LAYERS_COUNT: { [id: string]: number } = {
    whale: 5,
    jellyfish: 16,
    frog: 12,
    crab: 15,
    dolphin: 10,
    'puffer fish': 8,
    shrimp: 10,
    squid: 9,
    turtle: 11
  }

  public static LAYERS: { [id: string]: string[] } = Object.fromEntries(
    Object.entries(PaintingSeaAnimalImages.LAYERS_COUNT).map((e) => {
      return [
        e[0],
        PaintingSeaAnimalImages.zeroToN(e[1], e[0], 'paint-sea-animals')
      ]
    })
  )

  protected pictures() {
    return PaintingSeaAnimalImages.PICTURES
  }

  protected allLayers() {
    return PaintingSeaAnimalImages.LAYERS
  }
}
