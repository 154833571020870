import Randomize from '../utils/randomize'
import { AdditionGame } from '../addition/addition-game'
import { delay } from '../utils/delay'
import { MathQuestion } from '../addition/math-question'
// 'use client'
import * as createjs from 'createjs-module'

export class SubtractionGame extends AdditionGame {
  // questions() {
  //   return [
  //     [2, 1],
  //     [3, 1],
  //     [4, 3],
  //     [4, 1],
  //     [3, 2],
  //     [5, 2],
  //     [6, 3],
  //     [6, 2],
  //     [8, 3],
  //     [8, 6],
  //     [9, 4],
  //     [9, 5]
  //   ]
  // }

  count() {
    return 12
  }

  question() {
    const m = Math.max(5, this._pageIndex % this.count())
    const n1 = Randomize.randomInt(1, m)
    let n2 = n1
    do {
      n2 = Randomize.randomInt(1, 12)
    } while (n2 === n1)
    return n1 > n2 ? [n1, n2] : [n2, n1]
  }

  answer() {
    return this._number1 - this._number2
  }

  protected async loadQuestion() {
    const size = this.tileSize()

    this._question = new MathQuestion(this._number1, this._number2, '-', size)
    this._question.alpha = 0
    this.addChild(this._question)
  }

  protected async playIntroSound() {
    await this.playNumberSound(this._number1)
    this.playSound('minus')
    await delay(1000)
    await this.playNumberSound(this._number2)
  }
}
