import * as createjs from 'createjs-module'
import { Animator } from '../core/animator'
import { BitmapLoader } from '../core/bitmap-loader'
import { ICodingWalkGameLevel } from './coding-walk-level'
import { Point } from '../core/point'

export enum CodeWalkCommand {
  walk,
  turnLeft,
  turnRight,
  jump,
  dig
}

enum CodeWalkDirection {
  up,
  left,
  down,
  right
}

export class CodingWalkBoard extends createjs.Container {
  private _player: createjs.DisplayObject | undefined
  private _targets: createjs.DisplayObject[] = []

  private _playerOriginal: Point = Point.ZERO
  private _playerOriginalDirection = CodeWalkDirection.left

  private _playerDirection = CodeWalkDirection.left

  private _level: ICodingWalkGameLevel
  private _dimensions: Point
  private _size: Point
  private _boardScale: number

  constructor(level: ICodingWalkGameLevel, size: Point) {
    super()
    this._level = level
    this._dimensions = level.dimension
    this._size = size
    this._boardScale = 3 / level.dimension.width()

    this.setBounds(0, 0, size.width(), size.height())
  }

  public get player() {
    return this._player
  }

  async load() {
    this._playerOriginal = this._level.player

    await this.loadBackground()
    await this.loadTarget(this._level.target)
    await this.loadPlayer(this._level.player)
    this.stage?.update()
  }

  async loadTiles(
    container: createjs.Container,
    src: string,
    locations?: Point[]
  ) {
    if (locations === undefined) {
      return
    }
    const w = this._size.width() / this._dimensions.width()
    const h = this._size.height() / this._dimensions.height()

    const imgWater = await BitmapLoader.load(src)
    imgWater.scaleX = this._boardScale
    imgWater.scaleY = this._boardScale

    locations.forEach((p) => {
      const i = imgWater.clone()
      i.x = p.x * w
      i.y = p.y * h
      container.addChild(i)
    })
  }

  async loadBackground() {
    const w = this._size.width() / this._dimensions.width()
    const h = this._size.height() / this._dimensions.height()

    const imgGlass = await BitmapLoader.load(
      require('../../images/coding/glass.png')
    )

    const s = this._boardScale
    imgGlass.scaleX = s
    imgGlass.scaleY = s

    const container = new createjs.Container()
    for (let r = 0; r < this._dimensions.r(); r++) {
      for (let c = 0; c < this._dimensions.c(); c++) {
        const i = imgGlass.clone()
        i.x = c * w
        i.y = r * h
        container.addChild(i)
      }
    }

    await this.loadTiles(
      container,
      require('../../images/coding/water.png'),
      this._level.water
    )

    await this.loadTiles(
      container,
      require('../../images/coding/hole.png'),
      this._level.hole
    )

    await this.loadTiles(
      container,
      require('../../images/coding/verticle-fence.png'),
      this._level.verticalFence
    )

    await this.loadTiles(
      container,
      require('../../images/coding/horizontal-fence.png'),
      this._level.horizontalFence
    )

    container.cache(0, 0, this._size.width(), this._size.height())
    const combinedBitmap = new createjs.Bitmap(container.cacheCanvas)
    this.addChildAt(combinedBitmap, 0)
  }

  async loadPlayer(p: Point) {
    var pos = this.positionAt(p.r(), p.c())
    const player = BitmapLoader.scale(
      await BitmapLoader.loadAndCenter(
        require('../../images/coding/rabbit.png')
      ),
      this._boardScale
    )
    player.x = pos.x
    player.y = pos.y
    player.regX = player.image.width / 2
    player.regY = player.image.height / 2
    player.name = 'rabbit'
    this.addChild(player)
    this._player = player
  }

  async loadTarget(targets: Point[]) {
    const bitmaps = await Promise.all(
      targets.map(async (p) => {
        const target = BitmapLoader.scale(
          await BitmapLoader.loadAndCenter(
            require('../../images/coding/carrot.png')
          ),
          this._boardScale
        )
        const pos = this.positionAt(p.r(), p.c())
        target.x = pos.x
        target.y = pos.y
        return target
      })
    )

    bitmaps.forEach((b) => {
      this.addChild(b)
      this._targets.push(b)
    })
  }

  public async execute(commands: CodeWalkCommand[]) {
    for (let i = 0; i < commands.length; ++i) {
      let playerPosition = this.playerPosition()

      const c = commands[i]
      switch (c) {
        case CodeWalkCommand.walk:
          const nextWalk = this.nextPositionFromDirection()
          if (this.isAtFence(nextWalk)) {
            return false
          }
          await this.walk()
          break
        case CodeWalkCommand.turnLeft:
          await this.turnLeft()
          break
        case CodeWalkCommand.turnRight:
          await this.turnRight()
          break
        case CodeWalkCommand.jump:
          const nextJump1 = this.nextPositionFromDirection()
          const nextJump2 = this.nextPositionFromDirection(2)
          if (this.isAtFence(nextJump1) || this.isAtFence(nextJump2)) {
            return false
          }
          await this.jump()
          break
        case CodeWalkCommand.dig:
          if (!this.isInHole(playerPosition)) {
            return false
          }
          await this.dig()
          break
      }

      playerPosition = this.playerPosition()
      if (this.isInWater(playerPosition)) {
        return false
      }

      if (this.isAtTarget(playerPosition)) {
        await this.collectTarget()
      }
    }

    const playerPosition = this.playerPosition()
    if (this.isAtTarget(playerPosition) && this.hasCollectedAllTargets()) {
      return true
    }

    return false
  }

  private nextPositionFromDirection(distance = 1) {
    const direction = this._playerDirection
    let p = this.playerPosition()
    switch (direction) {
      case CodeWalkDirection.left:
        p.x -= distance
        break
      case CodeWalkDirection.right:
        p.x += distance
        break
      case CodeWalkDirection.up:
        p.y -= distance
        break
      case CodeWalkDirection.down:
        p.y += distance
        break
    }
    return p
  }

  private async walk() {
    const p = this.nextPositionFromDirection()
    await Animator.moveTo(this._player!, this.positionAt(p.r(), p.c()))
  }

  private async jump() {
    const p = this.nextPositionFromDirection(2)
    await Animator.moveTo(this._player!, this.positionAt(p.r(), p.c()))
  }

  private async turnLeft() {
    switch (this._playerDirection) {
      case CodeWalkDirection.left:
        Animator.flipVertical(this._player!)
        this._playerDirection = CodeWalkDirection.down
        break
      case CodeWalkDirection.right:
        Animator.flipVertical(this._player!)
        this._playerDirection = CodeWalkDirection.up
        break
      case CodeWalkDirection.up:
        this._playerDirection = CodeWalkDirection.left
        break
      case CodeWalkDirection.down:
        this._playerDirection = CodeWalkDirection.right
        break
    }
    await Animator.rotate(this._player!, -90)
  }

  private async turnRight() {
    switch (this._playerDirection) {
      case CodeWalkDirection.left:
        this._playerDirection = CodeWalkDirection.up
        break
      case CodeWalkDirection.right:
        this._playerDirection = CodeWalkDirection.down
        break
      case CodeWalkDirection.up:
        Animator.flipVertical(this._player!)
        this._playerDirection = CodeWalkDirection.right
        break
      case CodeWalkDirection.down:
        Animator.flipVertical(this._player!)
        this._playerDirection = CodeWalkDirection.left
        break
    }
    await Animator.rotate(this._player!, 90)
  }

  private async dig() {
    const holes = this._level.hole ?? []
    const p = this.playerPosition()
    const index = holes.findIndex((w) => w.isRoughlyEqual(p, 0.1))
    if (index === -1) {
      return
    }

    const newPosition = holes[(index + 1) % holes.length]
    await Animator.fadeOut(this._player!)

    const positionAt = this.positionAt(newPosition.r(), newPosition.c())
    this.player!.x = positionAt.x
    this.player!.y = positionAt.y

    await Animator.fadeIn(this._player!)
  }

  private async collectTarget() {
    const targets = this._level.target ?? []
    const p = this.playerPosition()
    const index = targets.findIndex((w) => w.isRoughlyEqual(p, 0.1))
    if (index === -1) {
      return
    }

    await Animator.fadeOut(this._targets[index])
  }

  public reset() {
    const p = this.positionAt(
      this._playerOriginal.r(),
      this._playerOriginal.c()
    )
    this._player!.x = p.x
    this._player!.y = p.y
    this._player!.rotation = 0
    this._player!.scaleY = 1
    this._playerDirection = this._playerOriginalDirection

    this._targets.forEach((t) => (t.alpha = 1))
  }

  private playerPosition() {
    const x = this._player!.x
    const y = this._player!.y
    const w = this._size.width() / this._dimensions.width()
    const h = this._size.height() / this._dimensions.height()
    return Point.xy(Math.floor(x / w), Math.floor(y / h))
  }

  private targetPosition(i: number) {
    const x = this._targets[i].x
    const y = this._targets[i].y
    const w = this._size.width() / this._dimensions.width()
    const h = this._size.height() / this._dimensions.height()
    return Point.xy(Math.floor(x / w), Math.floor(y / h))
  }

  private positionAt(r: number, c: number) {
    const w = this._size.width() / this._dimensions.width()
    const h = this._size.height() / this._dimensions.height()
    return Point.xy(c * w, r * h).plus(Point.xy(w / 2, h / 2))
  }

  private hasCollectedAllTargets() {
    return this._targets.filter((t) => t.alpha !== 0).length === 0
  }

  private isAtTarget(p: Point) {
    const target = this._level.target ?? []
    return target.findIndex((w) => w.isRoughlyEqual(p, 0.1)) !== -1
  }

  private isInWater(p: Point) {
    const water = this._level.water ?? []
    return water.findIndex((w) => w.isRoughlyEqual(p, 0.1)) !== -1
  }

  private isAtFence(p: Point) {
    const fence = this._level.verticalFence ?? []
    const anotherFence = this._level.horizontalFence ?? []
    fence.push(...anotherFence)
    return fence.findIndex((w) => w.isRoughlyEqual(p, 0.1)) !== -1
  }

  private isInHole(p: Point) {
    const hole = this._level.hole ?? []
    return hole.findIndex((w) => w.isRoughlyEqual(p, 0.1)) !== -1
  }
}
