import { GameStorage } from './game-storage'

export interface IGameStats {
  id: string
  name: string
  level: number
  finishes: number
  countLevel: number
}

export interface IGameCompletion {
  name: string
  completions: number
}

export class GameStats {
  private static GAMESTATSTABLE = 'gameplaystats'

  private static async saveGameStats(obj: IGameStats) {
    await GameStorage.save(this.GAMESTATSTABLE, obj)
  }

  private static async getGameStatsById(
    id: string
  ): Promise<IGameStats | undefined> {
    return await GameStorage.getByKey(this.GAMESTATSTABLE, id)
  }

  private static getId(name: string, level: number) {
    const n = name.replace(/ /g, '-')
    return `${n}-${level}`
  }

  public static async increment(
    name: string,
    level: number,
    countLevel: number
  ) {
    const id = this.getId(name, level)
    let stats = await this.getGameStatsById(id)
    if (stats === undefined) {
      stats = {
        id,
        name,
        level,
        finishes: 1,
        countLevel
      }
    } else {
      stats = {
        ...stats,
        finishes: stats.finishes + 1,
        countLevel
      }
    }
    // console.log(`GameStats increment ${JSON.stringify(stats)}`)
    await this.saveGameStats(stats)
  }

  public static async getGameStats(): Promise<IGameStats[]> {
    return await GameStorage.get(this.GAMESTATSTABLE)
  }

  public static sortFn(a: IGameStats, b: IGameStats) {
    const n = a.name.localeCompare(b.name)
    if (n !== 0) {
      return n
    }
    return a.level - b.level
  }

  public static computeGameCompletion(stats: IGameStats[]): IGameCompletion[] {
    // const finishes = stats.filter((s) => s.level + 1 === s.countLevel)
    // return finishes.map<IGameCompletion>((f) => {
    //   return {
    //     name: f.name,
    //     completions: f.finishes
    //   }
    // })

    const names = Array.from(new Set<string>(stats.map((s) => s.name)))

    const completions: IGameCompletion[] = []
    for (let i = 0; i < names.length; ++i) {
      const name = names[i]
      const s = stats.filter((s) => s.name === name)
      if (s.length !== s[0].countLevel) {
        continue
      }

      completions.push({
        name,
        completions: s.map((f) => f.finishes).reduce((p, i) => Math.min(p, i))
      })
    }

    return completions
  }
}
