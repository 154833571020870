import { GameStats } from '../utils/game-stats'
import { GameStorage } from '../utils/game-storage'

export interface IStickerGameTile {
  name: string
  x: number
  y: number
  snap: boolean
  flip: boolean
  lock: boolean
}

export class StickerStorage {
  private static TABLENAME = 'stickerdata'
  public static async saveTiles(pagename: string, tiles: IStickerGameTile[]) {
    await GameStorage.save(StickerStorage.TABLENAME, {
      key: pagename,
      data: JSON.stringify(tiles)
    })
  }

  public static async loadTiles(pagename: string) {
    const obj: any = await GameStorage.getByKey(
      StickerStorage.TABLENAME,
      pagename
    )
    if (obj && obj.data) {
      const tiles = JSON.parse(obj.data) as IStickerGameTile[]
      return tiles
    }
    return []
  }

  // Computes the number of unlocked stickers
  // based on the total completion of all games
  // one completion yields one sticker
  public static async computeUnlockStickers() {
    const gameStats = await GameStats.getGameStats()
    const completions = GameStats.computeGameCompletion(gameStats)

    if (completions.length === 0) {
      return 0
    }

    const totalCompletions = completions
      .map((s) => s.completions)
      .reduce((p, i) => p + i)
    return totalCompletions
  }
}
