import Randomize from '../utils/randomize'
import { Point } from '../core/point'
import { SubtractionGame } from './subtraction-game'

export default class SubtractionIIGame extends SubtractionGame {
  count() {
    return 12
  }

  question() {
    const n1 = Randomize.randomInt(10, 99)
    let n2 = n1
    do {
      n2 = Randomize.randomInt(10, 99)
    } while (n2 === n1)
    return n1 > n2 ? [n1, n2] : [n2, n1]
  }

  protected tileSize() {
    return Point.xy(160, 160)
  }
}
