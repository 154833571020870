import * as React from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
} from "reactstrap";
import { useState } from "react";

export interface IFilterItem {
  text: string;
  active: boolean;
}

export interface IFilterProps {
  ages: IFilterItem[];
  subjects: IFilterItem[];
  count: number;
  onChange: (ages: IFilterItem[], subjects: IFilterItem[]) => void;
}

export default function Filter(props: IFilterProps) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const onItemChanged = (type: string, index: number) => {
    const ages = props.ages;
    const subjects = props.subjects;
    const items = type === "age" ? ages : subjects;
    items[index].active = !items[index].active;
    props.onChange(ages, subjects);
  };

  const onMouse = (e: React.MouseEvent) => {
    const t = e.target as HTMLElement;
    if (t.className.indexOf("dropdown") !== -1) {
    } else {
      toggle();
    }
  };

  const renderItem = (type: string, item: IFilterItem, index: number) => {
    const name = item.text;
    return (
      <DropdownItem onClick={(e) => onItemChanged(type, index)} key={index}>
        {item.active ? "\u2713 " : undefined}
        {name}
      </DropdownItem>
    );
  };

  return (
    <Dropdown
      className="text-end"
      size="sm"
      isOpen={dropdownOpen}
      toggle={onMouse}
    >
      <DropdownToggle color="primary" onClick={toggle} caret>
        Filter ({props.count})
      </DropdownToggle>
      <DropdownMenu align="right">
        <DropdownItem header>Age</DropdownItem>
        <Form>
          {props.ages.map((item, index) => renderItem("age", item, index))}
        </Form>
        <DropdownItem header>Subject</DropdownItem>
        <Form>
          {props.subjects.map((item, index) =>
            renderItem("subject", item, index)
          )}
        </Form>
      </DropdownMenu>
    </Dropdown>
  );
}
