'use client'
import * as createjs from 'createjs-module'
import { Point } from './point'

interface IResizableRect {
  color: string
  r?: number
  borderWidth?: number
  borderColor?: string
}

export class ResizableShapeDeprecated extends createjs.Shape {
  private _size: Point

  private _rectConfig: IResizableRect | undefined

  constructor(size: Point, config: IResizableRect) {
    super()
    this._size = size
    this._rectConfig = config

    this.setBounds(0, 0, size.width(), size.height())
    this.layout()
  }

  get size() {
    return this._size
  }

  set size(s: Point) {
    this._size = s
    this.setBounds(this.x, this.y, s.width(), s.height())
  }

  set origin(p: Point) {
    this.x = p.x
    this.y = p.y
  }

  public layout(size?: Point) {
    if (size) {
      this.size = size
    }
    this.graphics.clear()
    this.layoutRect()
  }

  private layoutRect() {
    const rectConfig = this._rectConfig
    if (rectConfig === undefined) {
      return
    }
    var w = this._size.x
    var h = this._size.y
    var x = 0
    var y = 0
    var r = rectConfig.r
    this.graphics
      .beginStroke(rectConfig.borderColor ?? rectConfig.color)
      .setStrokeStyle(rectConfig.borderWidth ?? 0)
      .beginFill(rectConfig.color)
      .drawRoundRect(x, y, w, h, r ?? 0)
  }
}
