'use client'
import * as createjs from 'createjs-module'

export class ShadowBitmapLoader {
  public static load(src: string, color: string): Promise<createjs.Shape> {
    return new Promise<createjs.Shape>((resolve) => {
      var image = new Image()
      image.src = src
      image.onload = (event) => {
        const image = event.target as HTMLImageElement

        const w = image.width
        const h = image.height

        const box = new createjs.Shape()
        box.graphics.beginFill(color)
        box.graphics.drawRect(0, 0, w, h)
        box.filters = [new createjs.AlphaMaskFilter(image)]
        box.cache(0, 0, w, h)

        resolve(box)
      }
    })
  }

  public static loadFromContainer(
    container: createjs.DisplayObject,
    color: string
  ): createjs.DisplayObject {
    const bounds = container.getBounds()
    const w = bounds.width
    const h = bounds.height
    container.cache(0, 0, w, h)

    const box = new createjs.Shape()
    box.graphics.beginFill(color)
    box.graphics.drawRect(0, 0, w, h)
    box.filters = [
      new createjs.AlphaMaskFilter(container.cacheCanvas as HTMLCanvasElement)
    ]
    box.cache(0, 0, w, h)
    box.setBounds(0, 0, w, h)
    box.scaleX = container.scaleX
    box.scaleY = container.scaleY

    // console.log(`loadFromContainer ${JSON.stringify(box.getBounds())}`)
    return box
  }

  public static loadFromContainerAndCenter(
    container: createjs.DisplayObject,
    color: string
  ): createjs.DisplayObject {
    const box = ShadowBitmapLoader.loadFromContainer(container, color)

    const bounds = container.getBounds()
    box.regX = bounds.width / 2
    box.regY = bounds.height / 2

    // console.log(`loadFromContainer ${JSON.stringify(box.getBounds())}`)
    return box
  }
}
