import GamePage from "./GamePage";
// import NotFoundPage from '../pages/NotFoundPage'
import React from "react";
import { GameFactory } from "../games/core/game-factory";
import { getGameInfoById } from "../games/utils/game-info";
import { IUserInfo, UserInfo } from "../games/utils/user-info";
import { Language } from "../games/resource/language";
import { useParams } from "react-router-dom";

export default function RouteIdToGamePage(match: any) {
  React.useEffect(() => {
    function handleAuthChanged(u: IUserInfo | undefined) {
      setAuth(UserInfo.checkValidUser());
    }
    const token = UserInfo.registerOnChanged(handleAuthChanged);
    return () => {
      UserInfo.unregisterOnChanged(token);
    };
  });

  const { id } = useParams();

  const [isAuth, setAuth] = React.useState(true);

  const gameId = id !== undefined ? id : "match-vehicle";
  const gameInfo = getGameInfoById(gameId);
  const isSticker = false;

  if (gameInfo === undefined) {
    return <div />;
  } else if (!isAuth && gameInfo.isLock) {
    console.log(gameInfo.isLock);
    return <div />;
  } else {
    return (
      <GamePage
        gameId={gameId}
        language={Language.en}
        gameFactory={new GameFactory(gameInfo.gameType)}
        imageFactory={new gameInfo.imageFactoryType()}
        soundFactory={new gameInfo.soundFactoryType()}
        excludeStats={isSticker}
        skipSticker={isSticker}
        skipIntro={window.location.href.startsWith("http://localhost")}
      />
    );
  }
}
