import { PaintingBaseImages } from './painting-base-images'

export class PaintingAnimalImages extends PaintingBaseImages {
  public static PICTURES: { [id: string]: string } = {
    chicken: require('../../images/paint-animals/chicken_outline.png'),
    bear: require('../../images/paint-animals/bear_outline.png'),

    parrot: require('../../images/paint-animals/parrot_outline.png'),
    deer: require('../../images/paint-animals/deer_outline.png'),

    koala: require('../../images/paint-animals/koala_outline.png'),
    monkey: require('../../images/paint-animals/monkey_outline.png'),
    bird: require('../../images/paint-animals/bird_outline.png'),

    lion: require('../../images/paint-animals/lion_outline.png'),
    fox: require('../../images/paint-animals/fox_outline.png'),
    rabbit: require('../../images/paint-animals/rabbit_outline.png')
  }

  private static LAYERS_COUNT: { [id: string]: number } = {
    chicken: 10,
    bear: 13,
    parrot: 13,
    deer: 19,
    koala: 19,
    monkey: 16,
    bird: 14,
    lion: 15,
    fox: 21,
    rabbit: 22
  }

  public static LAYERS: { [id: string]: string[] } = Object.fromEntries(
    Object.entries(PaintingAnimalImages.LAYERS_COUNT).map((e) => {
      return [e[0], PaintingAnimalImages.zeroToN(e[1], e[0], 'paint-animals')]
    })
  )

  protected pictures() {
    return PaintingAnimalImages.PICTURES
  }

  protected allLayers() {
    return PaintingAnimalImages.LAYERS
  }
}
