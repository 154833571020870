export enum Browser {
  Safari,
  Chrome,
  Edge,
  IE,
  Firefox,
  Opera,
  Unknown
}

export function checkBrowser() {
  const ua = navigator.userAgent.toLowerCase()
  if (ua.indexOf('msie') !== -1) {
    return Browser.IE
  } else if (ua.indexOf('firefox') !== -1) {
    return Browser.Firefox
  } else if (ua.indexOf('opera') !== -1) {
    return Browser.Opera
  } else if (ua.indexOf('chrome edg') !== -1) {
    return Browser.Edge
  } else if (ua.indexOf('chrome') !== -1) {
    return Browser.Chrome
  } else if (ua.indexOf('safari') !== -1) {
    return Browser.Safari
  }
  return Browser.Unknown
}

export function checkLine() {
  const ua = navigator.userAgent.toLowerCase()
  if (ua.indexOf(' line') !== -1) {
    return true
  }
  return false
}

export function alertUnsupportIE() {
  const browser = checkBrowser()
  if (browser === Browser.IE) {
    alert(
      'We are sorry. IE is not supported. Please use Microsoft Edge or Chrome.'
    )
    return true
  }
  return false
}
