import { VehicleImages } from '../resource/vehicle-images'
import { Animator } from '../core/animator'
import { Color } from '../core/color'
import { delay } from '../utils/delay'
import { GameBase, IGameConfig } from '../core/game-base'
import { GameSound } from '../resource/game-sound'
import { Point } from '../core/point'
import { TextLabelDeprecated } from '../core/text-label-deprecated'
import { WritingSounds } from '../resource/writing-sounds'
import { BitmapTile } from '../core/bitmap-tile'
import { MazeBoard } from './maze-board'
import { IMazeGameLevel, getMazeGameLevels } from './maze-level'
import { ResizableShapeDeprecated } from '../core/resizable-shape'
import { generateMaze } from './maze-gen'

export class MazeGame extends GameBase {
    protected static INKCONTAINER_SIZE = Point.xy(800, 800)

    protected _win: boolean = false

    private _background: ResizableShapeDeprecated
    private _level: IMazeGameLevel
    private _board: MazeBoard

    protected _bitmap: createjs.Bitmap | undefined
    protected _text: TextLabelDeprecated | undefined

    constructor(config: IGameConfig) {
        super(config)

        // const levels = getMazeGameLevels()
        // this._level = levels[config.pageIndex % levels.length]

        const n = config.pageIndex > 2 ? 8 : 6
        // const n = 8
        this._level = generateMaze(Point.s(n))

        this._background = new ResizableShapeDeprecated(
            this.layoutSize(this._landscape),
            {
                color: '#aaaaaa',
                r: 0,
                borderWidth: 0,
                borderColor: '#aaaaaa'
            }
        )
        this.addChild(this._background)

        this._board = new MazeBoard(this._level, Point.xy(800, 800))
        this._board._onWinCallback = this.onWinCallback
        this._board.x += 100

        this.addChild(this._board)
    }

    public count() {
        return 100
    }

    async load() {
        await this._board.load()
        this.stage?.update()
    }

    public layout(landscape: boolean) {
        const layoutSize = this.layoutSize(landscape)
        this._background.layout(layoutSize)
    }

    public onWinCallback = async () => {
        this.playSound('success')
        const player = this._board.player
        if (player) {
            if (player.scaleY < 0) {
                Animator.flipVertical(player, 100)
            }
            await Animator.rotateTo(player, -720, 500)
        }
        this._callback()
    }

}
