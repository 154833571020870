import { PaintingBaseImages } from './painting-base-images'

export class PaintingVehicleImages extends PaintingBaseImages {
  public static PICTURES: { [id: string]: string } = {
    car: require('../../images/paint-vehicles/car_outline.png'),
    truck: require('../../images/paint-vehicles/truck_outline.png'),
    van: require('../../images/paint-vehicles/van_outline.png'),
    boat: require('../../images/paint-vehicles/boat_outline.png'),
    helicopter: require('../../images/paint-vehicles/helicopter_outline.png'),
    'school bus': require('../../images/paint-vehicles/school bus_outline.png'),

    train: require('../../images/paint-vehicles/train_outline.png'),

    plane: require('../../images/paint-vehicles/plane_outline.png'),
    submarine: require('../../images/paint-vehicles/submarine_outline.png'),
    ambulance: require('../../images/paint-vehicles/ambulance_outline.png')
  }

  private static LAYERS_COUNT: { [id: string]: number } = {
    car: 11,
    truck: 12,
    van: 15,
    boat: 12,
    helicopter: 11,
    'school bus': 13,

    train: 14,

    plane: 13,
    submarine: 11,
    ambulance: 16
  }

  public static LAYERS: { [id: string]: string[] } = Object.fromEntries(
    Object.entries(PaintingVehicleImages.LAYERS_COUNT).map((e) => {
      return [e[0], PaintingVehicleImages.zeroToN(e[1], e[0], 'paint-vehicles')]
    })
  )

  // public static LAYERS: { [id: string]: string[] } = {
  //   train: [
  //     require('../../images/paint-vehicles/layers/train_0.png'),
  //     require('../../images/paint-vehicles/layers/train_1.png'),
  //     require('../../images/paint-vehicles/layers/train_2.png'),
  //     require('../../images/paint-vehicles/layers/train_3.png'),
  //     require('../../images/paint-vehicles/layers/train_4.png'),
  //     require('../../images/paint-vehicles/layers/train_5.png'),
  //     require('../../images/paint-vehicles/layers/train_6.png'),
  //     require('../../images/paint-vehicles/layers/train_7.png'),
  //     require('../../images/paint-vehicles/layers/train_8.png'),
  //     require('../../images/paint-vehicles/layers/train_9.png'),
  //     require('../../images/paint-vehicles/layers/train_10.png'),
  //     require('../../images/paint-vehicles/layers/train_11.png'),
  //     require('../../images/paint-vehicles/layers/train_12.png'),
  //     require('../../images/paint-vehicles/layers/train_13.png'),
  //     require('../../images/paint-vehicles/layers/train_14.png')
  //   ],
  //   truck: [
  //     require('../../images/paint-vehicles/layers/truck_0.png'),
  //     require('../../images/paint-vehicles/layers/truck_1.png'),
  //     require('../../images/paint-vehicles/layers/truck_2.png'),
  //     require('../../images/paint-vehicles/layers/truck_3.png'),
  //     require('../../images/paint-vehicles/layers/truck_4.png'),
  //     require('../../images/paint-vehicles/layers/truck_5.png'),
  //     require('../../images/paint-vehicles/layers/truck_6.png'),
  //     require('../../images/paint-vehicles/layers/truck_7.png'),
  //     require('../../images/paint-vehicles/layers/truck_8.png'),
  //     require('../../images/paint-vehicles/layers/truck_9.png'),
  //     require('../../images/paint-vehicles/layers/truck_10.png'),
  //     require('../../images/paint-vehicles/layers/truck_11.png'),
  //     require('../../images/paint-vehicles/layers/truck_12.png')
  //   ],
  //   'school bus': [
  //     require('../../images/paint-vehicles/layers/schoolbus_0.png'),
  //     require('../../images/paint-vehicles/layers/schoolbus_1.png'),
  //     require('../../images/paint-vehicles/layers/schoolbus_2.png'),
  //     require('../../images/paint-vehicles/layers/schoolbus_3.png'),
  //     require('../../images/paint-vehicles/layers/schoolbus_4.png'),
  //     require('../../images/paint-vehicles/layers/schoolbus_5.png'),
  //     require('../../images/paint-vehicles/layers/schoolbus_6.png'),
  //     require('../../images/paint-vehicles/layers/schoolbus_7.png'),
  //     require('../../images/paint-vehicles/layers/schoolbus_8.png'),
  //     require('../../images/paint-vehicles/layers/schoolbus_9.png'),
  //     require('../../images/paint-vehicles/layers/schoolbus_10.png'),
  //     require('../../images/paint-vehicles/layers/schoolbus_11.png'),
  //     require('../../images/paint-vehicles/layers/schoolbus_12.png'),
  //     require('../../images/paint-vehicles/layers/schoolbus_13.png')
  //   ],
  //   helicopter: [
  //     require('../../images/paint-vehicles/layers/helicopter_0.png'),
  //     require('../../images/paint-vehicles/layers/helicopter_1.png'),
  //     require('../../images/paint-vehicles/layers/helicopter_2.png'),
  //     require('../../images/paint-vehicles/layers/helicopter_3.png'),
  //     require('../../images/paint-vehicles/layers/helicopter_4.png'),
  //     require('../../images/paint-vehicles/layers/helicopter_5.png'),
  //     require('../../images/paint-vehicles/layers/helicopter_6.png'),
  //     require('../../images/paint-vehicles/layers/helicopter_7.png'),
  //     require('../../images/paint-vehicles/layers/helicopter_8.png'),
  //     require('../../images/paint-vehicles/layers/helicopter_9.png'),
  //     require('../../images/paint-vehicles/layers/helicopter_10.png'),
  //     require('../../images/paint-vehicles/layers/helicopter_11.png')
  //   ],
  //   car: [
  //     require('../../images/paint-vehicles/layers/car_0.png'),
  //     require('../../images/paint-vehicles/layers/car_1.png'),
  //     require('../../images/paint-vehicles/layers/car_2.png'),
  //     require('../../images/paint-vehicles/layers/car_3.png'),
  //     require('../../images/paint-vehicles/layers/car_4.png'),
  //     require('../../images/paint-vehicles/layers/car_5.png'),
  //     require('../../images/paint-vehicles/layers/car_6.png'),
  //     require('../../images/paint-vehicles/layers/car_7.png'),
  //     require('../../images/paint-vehicles/layers/car_8.png'),
  //     require('../../images/paint-vehicles/layers/car_9.png'),
  //     require('../../images/paint-vehicles/layers/car_10.png'),
  //     require('../../images/paint-vehicles/layers/car_11.png')
  //   ],
  //   van: [
  //     require('../../images/paint-vehicles/layers/van_0.png'),
  //     require('../../images/paint-vehicles/layers/van_1.png'),
  //     require('../../images/paint-vehicles/layers/van_2.png'),
  //     require('../../images/paint-vehicles/layers/van_3.png'),
  //     require('../../images/paint-vehicles/layers/van_4.png'),
  //     require('../../images/paint-vehicles/layers/van_5.png'),
  //     require('../../images/paint-vehicles/layers/van_6.png'),
  //     require('../../images/paint-vehicles/layers/van_7.png'),
  //     require('../../images/paint-vehicles/layers/van_8.png'),
  //     require('../../images/paint-vehicles/layers/van_9.png'),
  //     require('../../images/paint-vehicles/layers/van_10.png'),
  //     require('../../images/paint-vehicles/layers/van_11.png'),
  //     require('../../images/paint-vehicles/layers/van_12.png'),
  //     require('../../images/paint-vehicles/layers/van_13.png'),
  //     require('../../images/paint-vehicles/layers/van_14.png'),
  //     require('../../images/paint-vehicles/layers/van_15.png')
  //   ],
  //   ambulance: [
  //     require('../../images/paint-vehicles/layers/ambulance_0.png'),
  //     require('../../images/paint-vehicles/layers/ambulance_1.png'),
  //     require('../../images/paint-vehicles/layers/ambulance_2.png'),
  //     require('../../images/paint-vehicles/layers/ambulance_3.png'),
  //     require('../../images/paint-vehicles/layers/ambulance_4.png'),
  //     require('../../images/paint-vehicles/layers/ambulance_5.png'),
  //     require('../../images/paint-vehicles/layers/ambulance_6.png'),
  //     require('../../images/paint-vehicles/layers/ambulance_7.png'),
  //     require('../../images/paint-vehicles/layers/ambulance_8.png'),
  //     require('../../images/paint-vehicles/layers/ambulance_9.png'),
  //     require('../../images/paint-vehicles/layers/ambulance_10.png'),
  //     require('../../images/paint-vehicles/layers/ambulance_11.png'),
  //     require('../../images/paint-vehicles/layers/ambulance_12.png'),
  //     require('../../images/paint-vehicles/layers/ambulance_13.png'),
  //     require('../../images/paint-vehicles/layers/ambulance_14.png'),
  //     require('../../images/paint-vehicles/layers/ambulance_15.png'),
  //     require('../../images/paint-vehicles/layers/ambulance_16.png')
  //   ],
  //   boat: [
  //     require('../../images/paint-vehicles/layers/boat_0.png'),
  //     require('../../images/paint-vehicles/layers/boat_1.png'),
  //     require('../../images/paint-vehicles/layers/boat_2.png'),
  //     require('../../images/paint-vehicles/layers/boat_3.png'),
  //     require('../../images/paint-vehicles/layers/boat_4.png'),
  //     require('../../images/paint-vehicles/layers/boat_5.png'),
  //     require('../../images/paint-vehicles/layers/boat_6.png'),
  //     require('../../images/paint-vehicles/layers/boat_7.png'),
  //     require('../../images/paint-vehicles/layers/boat_8.png'),
  //     require('../../images/paint-vehicles/layers/boat_9.png'),
  //     require('../../images/paint-vehicles/layers/boat_10.png'),
  //     require('../../images/paint-vehicles/layers/boat_11.png'),
  //     require('../../images/paint-vehicles/layers/boat_12.png')
  //   ],
  //   plane: [
  //     require('../../images/paint-vehicles/layers/plane_0.png'),
  //     require('../../images/paint-vehicles/layers/plane_1.png'),
  //     require('../../images/paint-vehicles/layers/plane_2.png'),
  //     require('../../images/paint-vehicles/layers/plane_3.png'),
  //     require('../../images/paint-vehicles/layers/plane_4.png'),
  //     require('../../images/paint-vehicles/layers/plane_5.png'),
  //     require('../../images/paint-vehicles/layers/plane_6.png'),
  //     require('../../images/paint-vehicles/layers/plane_7.png'),
  //     require('../../images/paint-vehicles/layers/plane_8.png'),
  //     require('../../images/paint-vehicles/layers/plane_9.png'),
  //     require('../../images/paint-vehicles/layers/plane_10.png'),
  //     require('../../images/paint-vehicles/layers/plane_11.png'),
  //     require('../../images/paint-vehicles/layers/plane_12.png'),
  //     require('../../images/paint-vehicles/layers/plane_13.png')
  //   ],
  //   submarine: [
  //     require('../../images/paint-vehicles/layers/submarine_0.png'),
  //     require('../../images/paint-vehicles/layers/submarine_1.png'),
  //     require('../../images/paint-vehicles/layers/submarine_2.png'),
  //     require('../../images/paint-vehicles/layers/submarine_3.png'),
  //     require('../../images/paint-vehicles/layers/submarine_4.png'),
  //     require('../../images/paint-vehicles/layers/submarine_5.png'),
  //     require('../../images/paint-vehicles/layers/submarine_6.png'),
  //     require('../../images/paint-vehicles/layers/submarine_7.png'),
  //     require('../../images/paint-vehicles/layers/submarine_8.png'),
  //     require('../../images/paint-vehicles/layers/submarine_9.png'),
  //     require('../../images/paint-vehicles/layers/submarine_10.png'),
  //     require('../../images/paint-vehicles/layers/submarine_11.png')
  //   ]
  // }

  protected pictures() {
    return PaintingVehicleImages.PICTURES
  }

  protected allLayers() {
    return PaintingVehicleImages.LAYERS
  }
}
