import * as React from "react";
import AdditionIIGame from "../addition/addition-ii-game";
import SubtractionIIGame from "../subtraction/subtraction-ii-game";
import { AbcImages } from "../resource/abc-images";
import { AbcSounds } from "../resource/abc-sounds";
import { ActivityImages } from "../resource/activity-images";
import { ActivitySounds } from "../resource/activity-sounds";
import { AdditionGame } from "../addition/addition-game";
import { AnimalImages } from "../resource/animal-images";
import { AnimalSounds } from "../resource/animal-sounds";
import { CodingWalkGame } from "../coding-walk/coding-walk-game";
import { ColorImages } from "../resource/color-images";
import { ColorSounds } from "../resource/color-sounds";
import { ComparingGame } from "../comparing/comparing-game";
import { CountingGame } from "../counting/counting-game";
import { DinosaurImages } from "../resource/dinosaur-images";
import { DinosaurSounds } from "../resource/dinosaur-sounds";
import { FakeSoundFactory, ISoundFactory } from "../core/sound-factory";
import { FoodImages } from "../resource/food-images";
import { FoodSounds } from "../resource/food-sounds";
import { GameBase } from "../core/game-base";
import { IBasicImageFactory } from "../core/image-factory";
import { MatchActivityGame } from "../match/match-activity-game";
import { MatchAlphabetGame } from "../match/match-alphabet";
import { MatchColorGame } from "../match/match-color-game";
import { MatchFoodGame } from "../match/match-food-game";
import { MatchGame } from "../match/match-game";
import { MatchSeaAnimalGame } from "../match/match-sea-animal-game";
import { MatchVehicleGame } from "../match/match-vehicle-game";
import { MazeGame } from "../maze/maze-game";
import { NumberImages } from "../resource/number-images";
import { NumberSounds } from "../resource/number-sounds";
import { NumberWordImages } from "../resource/number-word-images";
import { NumberWordSounds } from "../resource/number-word-sounds";
import { PaintingAnimalImages } from "../resource/painting-animal-images";
import { PaintingAnimalSounds } from "../resource/painting-animal-sounds";
import { PaintingGame } from "../painting/painting-game";
import { PaintingSeaAnimalImages } from "../resource/painting-sea-animal-images";
import { PaintingSeaAnimalSounds } from "../resource/painting-sea-animal-sounds";
import { PaintingVehicleImages } from "../resource/painting-vehicle-images";
import { PaintingVehicleSounds } from "../resource/painting-vehicle-sounds";
import { PatternGame } from "../pattern/pattern-game";
import { PatternSounds } from "../resource/pattern-sounds";
import { SeaAnimalImages } from "../resource/sea-animal-images";
import { SeaAnimalSounds } from "../resource/sea-animal-sounds";
import { ShadowGame } from "../shadow/shadow-game";
import { SightwordActivityGame } from "../sightword/sightword-activity-game";
import { SightwordColorGame } from "../sightword/sightword-color-game";
import { SightwordGame } from "../sightword/sightword-game";
import { SkipCountingGame } from "../skip-counting/skip-counting-game";
import { SortingAnimalGame } from "../sorting/sorting-animal-game";
import { SortingAnimalIIGame } from "../sorting/sorting-animal-ii-game";
import { SortingAnimalImages } from "../sorting/sorting-animal-images";
import { SpellingColorGame } from "../spelling/spelling-color-game";
import { SpellingGame } from "../spelling/spelling-game";
import { StickerGame } from "../sticker/sticker-game";
import { StickerImages } from "../resource/sticker-images";
import { StickerSounds } from "../resource/sticker-sounds";
import { Strings } from "./game-strings";
import { SubtractionGame } from "../subtraction/subtraction-game";
import { VehicleImages } from "../resource/vehicle-images";
import { VehicleSounds } from "../resource/vehicle-sounds";
import { WritingAbcGame } from "../writing/writing-abc-game";
import { WritingNumberGame } from "../writing/writing-number-game";
import { WritingSmallAbcGame } from "../writing/writing-small-abc-game";

export interface IGameInfo {
  title: string;
  category: string[];
  lead: JSX.Element;
  img: string;
  link: string;
  gameType: typeof GameBase;
  imageFactoryType: new () => IBasicImageFactory;
  soundFactoryType: new () => ISoundFactory;
  isLock: boolean;
  isProd: boolean;
}

const s_partialGames = [
  {
    category: ["Language", "3 yrs"],
    img: require("../../images/match-abc-demo.gif"),
    link: "match-abc",
    gameType: MatchAlphabetGame,
    imageFactoryType: AbcImages,
    soundFactoryType: AbcSounds,
    isLock: false,
    isProd: true,
  },
  {
    category: ["Science", "2 yrs"],
    img: require("../../images/match-animal-rule.gif"),
    link: "match-animal",
    gameType: MatchGame,
    imageFactoryType: AnimalImages,
    soundFactoryType: AnimalSounds,
    isLock: false,
    isProd: true,
  },
  {
    category: ["Science", "2 yrs"],
    img: require("../../images/match-dinosaur-rule.gif"),
    link: "match-dinosaur",
    gameType: MatchGame,
    imageFactoryType: DinosaurImages,
    soundFactoryType: DinosaurSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Science", "2 yrs"],
    img: require("../../images/match-sea-animal-rule.gif"),
    link: "match-sea-animal",
    gameType: MatchSeaAnimalGame,
    imageFactoryType: SeaAnimalImages,
    soundFactoryType: SeaAnimalSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Art", "2 yrs"],
    img: require("../../images/match-color-rule.gif"),
    link: "match-color",
    gameType: MatchColorGame,
    imageFactoryType: ColorImages,
    soundFactoryType: ColorSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Social", "3 yrs"],
    img: require("../../images/match-vehicle-rule.gif"),
    link: "match-vehicle",
    gameType: MatchVehicleGame,
    imageFactoryType: VehicleImages,
    soundFactoryType: VehicleSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Science", "4 yrs"],
    img: require("../../images/match-food-rule.gif"),
    link: "match-food",
    gameType: MatchFoodGame,
    imageFactoryType: FoodImages,
    soundFactoryType: FoodSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Social", "4 yrs"],
    img: require("../../images/match-activity-rule.gif"),
    link: "match-activity",
    gameType: MatchActivityGame,
    imageFactoryType: ActivityImages,
    soundFactoryType: ActivitySounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Language", "3 yrs"],
    img: require("../../images/writing-abc-rule.gif"),
    link: "write-uppercase-letters",
    gameType: WritingAbcGame,
    imageFactoryType: AbcImages,
    soundFactoryType: AbcSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Language", "3 yrs"],
    img: require("../../images/writing-small-abc-rule.gif"),
    link: "write-lowercase-letters",
    gameType: WritingSmallAbcGame,
    imageFactoryType: AbcImages,
    soundFactoryType: AbcSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Math", "3 yrs"],
    img: require("../../images/write-number-demo.gif"),
    link: "write-number",
    gameType: WritingNumberGame,
    imageFactoryType: NumberImages,
    soundFactoryType: NumberSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Art", "2 yrs"],
    img: require("../../images/painting-sea-animal-rule.gif"),
    link: "painting-sea-animal",
    gameType: PaintingGame,
    imageFactoryType: PaintingSeaAnimalImages,
    soundFactoryType: PaintingSeaAnimalSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Art", "3 yrs"],
    img: require("../../images/painting-animal-rule.png"),
    link: "painting-animal",
    gameType: PaintingGame,
    imageFactoryType: PaintingAnimalImages,
    soundFactoryType: PaintingAnimalSounds,
    isLock: false,
    isProd: true,
  },
  {
    category: ["Art", "3 yrs"],
    img: require("../../images/painting-vehicle-rule.png"),
    link: "painting-vehicle",
    gameType: PaintingGame,
    imageFactoryType: PaintingVehicleImages,
    soundFactoryType: PaintingVehicleSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Science", "Language", "4 yrs"],
    img: require("../../images/sightword-animal-demo.gif"),
    link: "sightword-animal",
    gameType: SightwordGame,
    imageFactoryType: AnimalImages,
    soundFactoryType: AnimalSounds,
    isLock: false,
    isProd: true,
  },
  {
    category: ["Science", "Language", "4 yrs"],
    img: require("../../images/sightword-sea-animal-rule.png"),
    link: "sightword-sea-animal",
    gameType: SightwordGame,
    imageFactoryType: SeaAnimalImages,
    soundFactoryType: SeaAnimalSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Language", "4 yrs"],
    img: require("../../images/placeholder-rule.jpg"),
    link: "sightword-abc",
    gameType: SightwordGame,
    imageFactoryType: AbcImages,
    soundFactoryType: AbcSounds,
    isLock: true,
    isProd: false,
  },
  {
    category: ["Science", "Language", "5 yrs"],
    img: require("../../images/sightword-dinosaur-demo.png"),
    link: "sightword-dinosaur",
    gameType: SightwordGame,
    imageFactoryType: DinosaurImages,
    soundFactoryType: DinosaurSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Art", "Language", "3 yrs"],
    img: require("../../images/sightword-color-rule.png"),
    link: "sightword-color",
    gameType: SightwordColorGame,
    imageFactoryType: ColorImages,
    soundFactoryType: ColorSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Social", "Language", "4 yrs"],
    img: require("../../images/sightword-vehicle-rule.png"),
    link: "sightword-vehicle",
    gameType: SightwordGame,
    imageFactoryType: VehicleImages,
    soundFactoryType: VehicleSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Science", "Language", "5 yrs"],
    img: require("../../images/sightword-food-demo.png"),
    link: "sightword-food",
    gameType: SightwordGame,
    imageFactoryType: FoodImages,
    soundFactoryType: FoodSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Social", "Language", "5 yrs"],
    img: require("../../images/sightword-activity-demo.png"),
    link: "sightword-activity",
    gameType: SightwordActivityGame,
    imageFactoryType: ActivityImages,
    soundFactoryType: ActivitySounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Math", "Language", "4 yrs"],
    img: require("../../images/sightword-number-demo.png"),
    link: "sightword-number",
    gameType: SightwordGame,
    imageFactoryType: NumberWordImages,
    soundFactoryType: NumberWordSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Language", "5 yrs"],
    img: require("../../images/spelling-animal-demo.gif"),
    link: "spelling-animal",
    gameType: SpellingGame,
    imageFactoryType: AnimalImages,
    soundFactoryType: AnimalSounds,
    isLock: false,
    isProd: true,
  },
  {
    category: ["Language", "6 yrs"],
    img: require("../../images/spelling-sea-animal-demo.png"),
    link: "spelling-sea-animal",
    gameType: SpellingGame,
    imageFactoryType: SeaAnimalImages,
    soundFactoryType: SeaAnimalSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Language", "5 yrs"],
    img: require("../../images/spelling-color-demo.png"),
    link: "spelling-color",
    gameType: SpellingColorGame,
    imageFactoryType: ColorImages,
    soundFactoryType: ColorSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Social", "Language", "6 yrs"],
    img: require("../../images/spelling-vehicle-demo.png"),
    link: "spelling-vehicle",
    gameType: SpellingGame,
    imageFactoryType: VehicleImages,
    soundFactoryType: VehicleSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Science", "Language", "6 yrs"],
    img: require("../../images/spelling-food-demo.png"),
    link: "spelling-food",
    gameType: SpellingGame,
    imageFactoryType: FoodImages,
    soundFactoryType: FoodSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Social", "Language", "6 yrs"],
    img: require("../../images/spelling-activity-demo.png"),
    link: "spelling-activity",
    gameType: SpellingGame,
    imageFactoryType: ActivityImages,
    soundFactoryType: ActivitySounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Math", "Language", "5 yrs"],
    img: require("../../images/spelling-number-demo.png"),
    link: "spelling-number",
    gameType: SpellingGame,
    imageFactoryType: NumberWordImages,
    soundFactoryType: NumberWordSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Fun", "2 yrs"],
    img: require("../../images/shadow-animal-rule.gif"),
    link: "shadow-animal",
    gameType: ShadowGame,
    imageFactoryType: AnimalImages,
    soundFactoryType: AnimalSounds,
    isLock: false,
    isProd: true,
  },
  {
    category: ["Fun", "2 yrs"],
    img: require("../../images/shadow-sea-animal-rule.png"),
    link: "shadow-sea-animal",
    gameType: ShadowGame,
    imageFactoryType: SeaAnimalImages,
    soundFactoryType: SeaAnimalSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Fun", "3 yrs"],
    img: require("../../images/shadow-dinosaur-rule.png"),
    link: "shadow-dinosaur",
    gameType: ShadowGame,
    imageFactoryType: DinosaurImages,
    soundFactoryType: DinosaurSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Fun", "2 yrs"],
    img: require("../../images/shadow-vehicle-rule.png"),
    link: "shadow-vehicle",
    gameType: ShadowGame,
    imageFactoryType: VehicleImages,
    soundFactoryType: VehicleSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Fun", "2 yrs"],
    img: require("../../images/shadow-food-rule.png"),
    link: "shadow-food",
    gameType: ShadowGame,
    imageFactoryType: FoodImages,
    soundFactoryType: FoodSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Math", "2 yrs"],
    img: require("../../images/counting-dinosaur-rule.png"),
    link: "counting-dinosaur",
    gameType: CountingGame,
    imageFactoryType: DinosaurImages,
    soundFactoryType: NumberSounds,
    isLock: true,
    isProd: false,
  },
  {
    category: ["Math", "2 yrs"],
    img: require("../../images/counting-animal-rule.gif"),
    link: "counting-animal",
    gameType: CountingGame,
    imageFactoryType: AnimalImages,
    soundFactoryType: NumberSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Math", "3 yrs"],
    img: require("../../images/pattern-animal-rule.gif"),
    link: "pattern-animal",
    gameType: PatternGame,
    imageFactoryType: AnimalImages,
    soundFactoryType: PatternSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Math", "5 yrs"],
    img: require("../../images/addition-demo.png"),
    link: "addition",
    gameType: AdditionGame,
    imageFactoryType: AnimalImages,
    soundFactoryType: NumberSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Math", "5 yrs"],
    img: require("../../images/subtraction-demo.png"),
    link: "subtraction",
    gameType: SubtractionGame,
    imageFactoryType: SeaAnimalImages,
    soundFactoryType: NumberSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Math", "5 yrs"],
    img: require("../../images/comparing-demo.png"),
    link: "comparing",
    gameType: ComparingGame,
    imageFactoryType: SeaAnimalImages,
    soundFactoryType: NumberSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Math", "6 yrs"],
    img: require("../../images/addition-ii-demo.png"),
    link: "addition-ii",
    gameType: AdditionIIGame,
    imageFactoryType: DinosaurImages,
    soundFactoryType: NumberSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Math", "6 yrs"],
    img: require("../../images/subtraction-ii-demo.png"),
    link: "subtraction-ii",
    gameType: SubtractionIIGame,
    imageFactoryType: VehicleImages,
    soundFactoryType: NumberSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Math", "6 yrs"],
    img: require("../../images/skip-counting-demo.gif"),
    link: "skip-counting",
    gameType: SkipCountingGame,
    imageFactoryType: AnimalImages,
    soundFactoryType: NumberSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Math", "3 yrs"],
    img: require("../../images/sorting-animal-demo.gif"),
    link: "sorting-animal",
    gameType: SortingAnimalGame,
    imageFactoryType: AnimalImages,
    soundFactoryType: AnimalSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Math", "6 yrs"],
    img: require("../../images/sorting-animal-ii-demo.png"),
    link: "sorting-animal-ii",
    gameType: SortingAnimalIIGame,
    imageFactoryType: SortingAnimalImages,
    soundFactoryType: AnimalSounds,
    isLock: true,
    isProd: true,
  },
  {
    category: ["Technology", "6 yrs"],
    img: require("../../images/coding-rabbit-demo.gif"),
    link: "coding-rabbit",
    gameType: CodingWalkGame,
    imageFactoryType: AnimalImages,
    soundFactoryType: FakeSoundFactory,
    isLock: true,
    isProd: false,
  },
  {
    category: ["Technology", "3 yrs"],
    img: require("../../images/maze-game-demo.png"),
    link: "maze-game",
    gameType: MazeGame,
    imageFactoryType: VehicleImages,
    soundFactoryType: VehicleSounds,
    isLock: true,
    isProd: true,
  },

  // {
  //   category: ['Fun', '2 yrs'],
  //   img: require('../../images/sticker-demo.gif'),
  //   link: 'sticker',
  //   gameType: StickerGame,
  //   imageFactoryType: StickerImages,
  //   soundFactoryType: StickerSounds,
  //   isLock: true,
  //   isProd: true
  // }
];

function fillFullGameInfo(g: any) {
  return {
    ...g,
    lead: <span>{Strings.getDescriptionForLink(g.link)}</span>,
    title: Strings.getTitleForLink(g.link),
  };
}

export function getAllGames(): IGameInfo[] {
  const partial = s_partialGames;
  const games = partial.map((g) => {
    return fillFullGameInfo(g);
  });
  return games;
}

export function getProdGames(): IGameInfo[] {
  const games = getAllGames();
  return games.filter((g) => g.isProd);
}

export function getGameInfoById(id: string): IGameInfo | undefined {
  const info = s_partialGames.find((g) => g.link === id);
  if (info === undefined) {
    return undefined;
  }
  return fillFullGameInfo(info);
}
