'use client'
import * as createjs from 'createjs-module'
import { Browser, checkBrowser } from '../utils/browser'
import { Point } from '../core/point'
import { TextLabelDeprecated } from '../core/text-label-deprecated'

export default class InkContainer extends createjs.Container {
  public callback: () => void

  private _size: Point
  private _inkColor: string
  private _inkWidth: number
  private _letter: string
  private _checkPoints: { x: number; y: number }[]

  private _text: createjs.Text
  private _ink: createjs.Shape
  private _isInking: boolean = false
  private _previousInkingPoint: Point = Point.ZERO

  public displayTracingPoints = false

  public tracingMode = false
  public tracingPoints: Point[] = []

  constructor(
    size: Point,
    inkColor: string,
    inkWidth: number,
    letter: string,
    font: string,
    checkPoints: { x: number; y: number }[]
  ) {
    super()
    this.callback = () => {}
    this._size = size
    this._inkColor = inkColor
    this._inkWidth = inkWidth
    this._letter = letter
    this._checkPoints = checkPoints

    this.setBounds(0, 0, this._size.width(), this._size.height())

    this._text = TextLabelDeprecated.createTextNode(600)

    this._text.color = 'rgba(200, 200, 200, 0.7)'
    this._text.text = this._letter
    this._text.font = font

    const adjustedZero = this.hitTestAdjustment(Point.ZERO)
    this._text.x -= adjustedZero.x
    this._text.y -= adjustedZero.y

    this.addChild(this._text)

    this._ink = new createjs.Shape()
    this.addChild(this._ink)

    this.on('mousedown', (evt: any) => {
      this.handleMouseDownEvent(evt)
    })

    this.on('pressmove', (evt: any) => {
      this.handleMouseMoveEvent(evt)
    })

    this.on('pressup', (evt: any) => {
      this.handleMouseUpEvent(evt)
    })

    if (this.displayTracingPoints) {
      for (let i = 0; i < this._checkPoints.length; ++i) {
        const p = this._checkPoints[i]
        this._ink.graphics.beginFill('white').drawCircle(p.x, p.y, 5)
      }
    }
  }

  public resetInk() {
    this._ink.graphics.clear()
    this.stage?.update()
  }

  private hitTestAdjustment(p: Point) {
    let c = p.copy()
    const browser = checkBrowser()
    if (browser === Browser.Safari) {
      c.y += 45 // Safari text is not vertically centered
    }
    return c
  }

  private addInk(p: Point) {
    const ink = this._ink
    const text = this._text
    if (ink === undefined || text === undefined) {
      return
    }
    const local = Point.from(this.globalToLocal(p.x, p.y))
    const adjustedLocal = this.hitTestAdjustment(local)
    if (!text.hitTest(adjustedLocal.x, adjustedLocal.y)) {
      return
    }

    if (this.tracingMode) {
      const roundPoint = Point.xy(Math.round(local.x), Math.round(local.y))
      this.tracingPoints.push(roundPoint)
      console.log(roundPoint.toString())
      ink.graphics.beginFill('red').drawCircle(roundPoint.x, roundPoint.y, 5)
      return
    }

    if (this._previousInkingPoint.isRoughlyEqual(Point.ZERO, 1)) {
      this._previousInkingPoint = local
    } else if (this._previousInkingPoint.distanceXy(local) > 160) {
      this._previousInkingPoint = local
    } else if (this._previousInkingPoint.distanceXy(local) > 16) {
      ink.graphics
        .beginStroke(this._inkColor)
        .setStrokeStyle(this._inkWidth, 'round', 'round')
        .moveTo(this._previousInkingPoint.x, this._previousInkingPoint.y)
        .lineTo(local.x, local.y)
      ink.graphics.endStroke()
      this._previousInkingPoint = local
      this.stage?.update()
    }
  }

  private handleMouseDownEvent(evt: any) {
    this._isInking = true
    this._previousInkingPoint = Point.ZERO
    const p = Point.xy(evt.stageX, evt.stageY)
    this.addInk(p)
  }

  private handleMouseMoveEvent(evt: any) {
    if (this._isInking) {
      const p = Point.xy(evt.stageX, evt.stageY)
      this.addInk(p)
    }
  }

  private handleMouseUpEvent(_evt: any) {
    this._isInking = false
    if (this.checkAnswer()) {
      this.callback()
    }
    this.stage?.update()
  }

  private checkAnswer() {
    if (this.tracingMode) {
      return false
    }

    const layoutSize = this._size
    const w = layoutSize.width()
    const h = layoutSize.height()
    this.cache(-w / 2, -h / 2, w, h)
    const canvas = this.cacheCanvas as HTMLCanvasElement
    this.uncache()

    const ctx = canvas.getContext('2d')
    if (ctx === null) {
      return false
    }

    let pass = true
    for (let i = 0; i < this._checkPoints.length; ++i) {
      const p = this._checkPoints[i]
      var pixel = ctx.getImageData(p.x + w / 2, p.y + h / 2, 1, 1)
      var data = pixel.data
      // r=data[0], g=data[1], b=data[2], a=data[3]
      // this._ink?.graphics.beginFill('red').drawCircle(p.x, p.y, 5)
      if (
        data[0] >= 125 &&
        data[1] >= 125 &&
        data[2] >= 125 &&
        data[3] >= 125
      ) {
        pass = false
        break
      }
    }

    // console.log(`checkAnswer ${pass}`)

    return pass
  }
}
