import { Browser, checkBrowser } from '../utils/browser'
import { DraggableTile } from './draggable-tile'
import { Point } from './point'
import { TextLabelDeprecated } from './text-label-deprecated'

export class LetterTile extends DraggableTile {
  protected _text: string = ''
  protected _size: Point
  protected _areaSize: Point
  protected _includeBackground: boolean
  protected _textNode: createjs.Text
  protected _background: createjs.Shape

  constructor(
    size: Point,
    areaSize: Point,
    text: string = '',
    includeBackground: boolean = false,
    draggable: boolean = true,
    font: string = TextLabelDeprecated.DEFAULT_FONT
  ) {
    super(Point.ZERO, Point.ZERO, areaSize)
    this.canDrag = draggable
    this._size = size.copy()
    this._areaSize = areaSize.copy()
    this._text = text
    this._includeBackground = includeBackground

    this._background = new createjs.Shape()
    if (this._includeBackground) {
      this.addChild(this._background)
    }

    this._textNode = LetterTile.createLabel()
    this._textNode.text = text

    var fontSize = Math.floor(size.y / 1.2)
    var w = 0
    do {
      this._textNode.font = TextLabelDeprecated.font(fontSize, font)
      w = this._textNode.getMeasuredWidth()
      fontSize -= 10
    } while (w > size.x)
    this._textNode.x = this._areaSize.x / 2
    this._textNode.y = this._areaSize.y / 2

    const browser = checkBrowser()
    if (browser === Browser.Chrome || browser === Browser.Edge) {
      this._textNode.y += this._areaSize.y / 20
    }

    this.addChild(this._textNode)

    this.setBounds(0, 0, this._areaSize.x, this._areaSize.y)

    this.setBackground('blue')

    this.hitArea = this._background.clone()
    this.hitArea.alpha = 1

    this.regX = this._areaSize.x / 2
    this.regY = this._areaSize.y / 2

    this.x = 0
    this.y = 0
  }

  public static createLabel() {
    const textNode = new createjs.Text()
    textNode.color = 'white'
    textNode.textAlign = 'center'
    textNode.textBaseline = 'middle'
    return textNode
  }

  public static createFont(size: number, fontName?: string) {
    return TextLabelDeprecated.font(size, fontName)
  }

  public setText(text: string) {
    this._text = text
    this._textNode.text = text
  }

  public setTextColor(color: string) {
    this._textNode.color = color
  }

  public setBackground(
    color: string,
    strokeWidth: number = 8,
    strokeColor: string = 'white',
    shrinkToFit: boolean = false
  ) {
    var w = this._areaSize.x
    var h = this._areaSize.y
    var r = 0.2 * h

    if (shrinkToFit) {
      w = this._textNode.getMeasuredWidth() + (this._areaSize.w - this._size.w)
    }

    this._background.graphics.clear()

    const borderColor = color
    this._background.graphics
      .setStrokeStyle(strokeWidth)
      .beginStroke(strokeWidth === 0 ? 'transparent' : strokeColor)
      .beginFill(borderColor)
      .drawRoundRect((this._areaSize.w - w) / 2, 0, w, h, r)

    this._background.alpha = this._includeBackground ? 1 : 0

    this._background.regX = this._areaSize.x / 2
    this._background.regY = this._areaSize.y / 2
    this._background.x = this._areaSize.x / 2
    this._background.y = this._areaSize.y / 2
  }

  // public addCheckmark() {
  //   const check = new createjs.Text()
  //   check.color = 'white'
  //   check.font = TextLabel.font(this._fontSize * 0.24, this._font)
  //   check.text = '\u2713'
  //   check.textAlign = 'center'
  //   check.textBaseline = 'middle'
  //   check.x = this._size.x / 2
  //   check.y = this._size.y - this._size.y / 8
  //   this.addChild(check)
  //   this._checkmark = check
  // }
}
