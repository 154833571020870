import { ISoundFactory } from '../core/sound-factory'
import { Language } from './language'
import { GameSound } from './game-sound'
import { SoundEffects } from './sound-effect'

export class AbcSounds implements ISoundFactory {
  public static ALPHABETS: { [id: string]: string } = {
    A: require('../../sounds/abc/a.mp3'),
    B: require('../../sounds/abc/b.mp3'),
    C: require('../../sounds/abc/c.mp3'),
    D: require('../../sounds/abc/d.mp3'),
    E: require('../../sounds/abc/e.mp3'),
    F: require('../../sounds/abc/f.mp3'),
    G: require('../../sounds/abc/g.mp3'),
    H: require('../../sounds/abc/h.mp3'),
    I: require('../../sounds/abc/i.mp3'),
    J: require('../../sounds/abc/j.mp3'),
    K: require('../../sounds/abc/k.mp3'),
    L: require('../../sounds/abc/l.mp3'),
    M: require('../../sounds/abc/m.mp3'),
    N: require('../../sounds/abc/n.mp3'),
    O: require('../../sounds/abc/o.mp3'),
    P: require('../../sounds/abc/p.mp3'),
    Q: require('../../sounds/abc/q.mp3'),
    R: require('../../sounds/abc/r.mp3'),
    S: require('../../sounds/abc/s.mp3'),
    T: require('../../sounds/abc/t.mp3'),
    U: require('../../sounds/abc/u.mp3'),
    V: require('../../sounds/abc/v.mp3'),
    W: require('../../sounds/abc/w.mp3'),
    X: require('../../sounds/abc/x.mp3'),
    Y: require('../../sounds/abc/y.mp3'),
    Z: require('../../sounds/abc/z.mp3')
  }

  public static WORDS: { [id: string]: string } = {
    ant: require('../../sounds/ant.mp3'),
    bird: require('../../sounds/bird.mp3'),
    cat: require('../../sounds/cat.mp3'),
    dog: require('../../sounds/dog.mp3'),
    elephant: require('../../sounds/elephant.mp3'),
    fox: require('../../sounds/fox.mp3'),
    giraffe: require('../../sounds/giraffe.mp3'),
    horse: require('../../sounds/horse.mp3'),
    insect: require('../../sounds/insect.mp3'),
    jar: require('../../sounds/jar.mp3'),
    kangaroo: require('../../sounds/kangaroo.mp3'),
    lion: require('../../sounds/lion.mp3'),
    monkey: require('../../sounds/monkey.mp3'),
    nurse: require('../../sounds/nurse.mp3'),
    ox: require('../../sounds/ox.mp3'),
    pig: require('../../sounds/pig.mp3'),
    queen: require('../../sounds/queen.mp3'),
    rat: require('../../sounds/rat.mp3'),
    sheep: require('../../sounds/sheep.mp3'),
    tiger: require('../../sounds/tiger.mp3'),
    unicorn: require('../../sounds/unicorn.mp3'),
    violin: require('../../sounds/violin.mp3'),
    whale: require('../../sounds/whale.mp3'),
    'x-ray': require('../../sounds/x-ray.mp3'),
    yak: require('../../sounds/yak.mp3'),
    zebra: require('../../sounds/zebra.mp3')
  }

  public static MAP: { [id: string]: string } = {
    A: 'ant',
    B: 'bird',
    C: 'cat',
    D: 'dog',
    E: 'elephant',
    F: 'fox',
    G: 'giraffe',
    H: 'horse',
    I: 'insect',
    J: 'jar',
    K: 'kangaroo',
    L: 'lion',
    M: 'monkey',
    N: 'nurse',
    O: 'ox',
    P: 'pig',
    Q: 'queen',
    R: 'rat',
    S: 'sheep',
    T: 'tiger',
    U: 'unicorn',
    V: 'violin',
    W: 'whale',
    X: 'x-ray',
    Y: 'yak',
    Z: 'zebra'
  }

  public static map(alphabet: string) {
    return AbcSounds.MAP[alphabet]
  }

  /* ISoundFactory */
  public register(_language: Language) {
    GameSound.registerAll([
      AbcSounds.ALPHABETS,
      AbcSounds.WORDS,
      SoundEffects.FOR,
      SoundEffects.EFFECTS
    ])
  }
}
