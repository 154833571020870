'use client'
import * as createjs from 'createjs-module'
import { BitmapLoader } from '../core/bitmap-loader'
import { CodeWalkCommand } from './coding-walk-board'
import { CommandBlock } from './command-block'
import { Direction } from '../core/layout-flex'
import { LayoutFlexStack } from '../core/layout-flex-stack'
import { Point } from '../core/point'
import { Rect } from '../core/rect'
import { ResizableShapeDeprecated } from '../core/resizable-shape'
import { TextLabelDeprecated } from '../core/text-label-deprecated'

export class CodeAnswerBlock extends createjs.Container {
  public onRun: (commands: CodeWalkCommand[]) => void = (_c) => {}
  public onReset: () => void = () => {}

  private _background: ResizableShapeDeprecated
  private _commands: CodeWalkCommand[] = []
  private _text: createjs.Text

  private _run: createjs.Bitmap | undefined
  private _reset: createjs.Bitmap | undefined

  constructor(size: Point) {
    super()

    this._background = new ResizableShapeDeprecated(size, {
      color: 'whitesmoke',
      r: 10,
      borderWidth: 0,
      borderColor: 'whitesmoke'
    })
    this.addChild(this._background)

    this._text = new createjs.Text()
    this._text.x = 20
    this._text.y = 20
    this._text.text = ''
    this._text.font = TextLabelDeprecated.font(40)
    this.addChild(this._text)

    this.alpha = 0
    this.setBounds(0, 0, size.width(), size.height())
  }

  public get commands() {
    return this._commands
  }

  public async load() {
    this._run = await BitmapLoader.loadAndCenter(
      require('../../images/coding/run.svg')
    )
    this._run.on('mousedown', () => {
      this.onRun(this._commands)
    })
    this.addChild(this._run)

    this._reset = await BitmapLoader.loadAndCenter(
      require('../../images/coding/reset.svg')
    )
    this._reset.on('mousedown', () => {
      this._commands = []
      this._text.text = this.toCommandString()
      this.onReset()
    })
    this.addChild(this._reset)
  }

  public layout(rect: Rect, landscape: boolean) {
    this.alpha = 1

    this.x = rect.origin.x
    this.y = rect.origin.y
    this._background.size = rect.size
    this._background.layout()

    let imgHeight = this._reset!.image.height
    if (!landscape) {
      imgHeight = imgHeight * 2 + 30
    }
    new LayoutFlexStack(
      landscape ? Direction.horizontal : Direction.vertical,
      Rect.from(
        0,
        rect.size.height() - imgHeight,
        rect.size.width(),
        imgHeight
      ),
      [this._reset!, this._run!],
      20
    ).layout()
  }

  public addCommand(command: CodeWalkCommand) {
    this._commands.push(command)
    this._text.text = this.toCommandString()
  }

  private toCommandString() {
    let str = ''
    let count = 0
    for (let i = 0; i < this._commands.length; ++i) {
      const c = this._commands[i]
      count += 1

      if (i === this._commands.length - 1 || c !== this._commands[i + 1]) {
        if (count > 1) {
          str += `${CommandBlock.textFromCommand(c)} x ${count}\n`
        } else {
          str += `${CommandBlock.textFromCommand(c)}\n`
        }
        count = 0
      }
    }
    return str
  }
}
