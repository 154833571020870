import { ISoundFactory } from '../core/sound-factory'
import { Language } from './language'
import { GameSound } from './game-sound'
import { SoundEffects } from './sound-effect'

export class PatternSounds implements ISoundFactory {
  public static INTRO: { [id: string]: string } = {
    'build a pattern': require('../../sounds/build a pattern.mp3')
  }

  /* ISoundFactory */
  public register(_language: Language) {
    GameSound.registerAll([PatternSounds.INTRO, SoundEffects.EFFECTS])
  }
}
