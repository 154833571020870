import { ISoundFactory } from '../core/sound-factory'
import { Language } from './language'
import { GameSound } from './game-sound'
import { SoundEffects } from './sound-effect'

export class SeaAnimalSounds implements ISoundFactory {
  static SOUNDS: { [id: string]: string } = {
    clownfish: require('../../sounds/sea-animals/clownfish.mp3'),
    crab: require('../../sounds/sea-animals/crab.mp3'),
    dolphin: require('../../sounds/sea-animals/dolphin.mp3'),
    eel: require('../../sounds/sea-animals/eel.mp3'),
    fish: require('../../sounds/sea-animals/fish.mp3'),
    lionfish: require('../../sounds/sea-animals/lionfish.mp3'),
    octopus: require('../../sounds/sea-animals/octopus.mp3'),
    seahorse: require('../../sounds/sea-animals/seahorse.mp3'),
    shark: require('../../sounds/sea-animals/shark.mp3'),
    snail: require('../../sounds/sea-animals/snail.mp3'),
    squid: require('../../sounds/sea-animals/squid.mp3'),
    starfish: require('../../sounds/sea-animals/starfish.mp3'),
    stingray: require('../../sounds/sea-animals/stingray.mp3'),
    turtle: require('../../sounds/sea-animals/turtle.mp3'),
    whale: require('../../sounds/sea-animals/whale.mp3')
  }

  /* ISoundFactory */
  public register(_language: Language) {
    GameSound.registerAll([SeaAnimalSounds.SOUNDS, SoundEffects.EFFECTS])
  }
}
