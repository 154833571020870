'use client'
import * as createjs from 'createjs-module'
import Randomize from '../utils/randomize'
import { Animator } from '../core/animator'
import { BitmapLoader } from '../core/bitmap-loader'
import { Color } from '../core/color'
import { delay } from '../utils/delay'
import { Direction } from '../core/layout-flex'
import { GameSound } from '../resource/game-sound'
import { LayoutFlexStack } from '../core/layout-flex-stack'
import { LoadingLabel } from '../core/loading-label'
import { Point } from '../core/point'
import { Rect } from '../core/rect'
import { TextLabelDeprecated } from '../core/text-label-deprecated'

const soundResources: { [id: string]: string } = {
  'plane intro': require('../../sounds/intro-animation/plane.mp3'),
  'submarine intro': require('../../sounds/intro-animation/bubble.mp3'),
  'dog intro': require('../../sounds/intro-animation/barking.mp3'),
  'fish intro': require('../../sounds/intro-animation/bubble.mp3')
}

const imageResources: { [id: string]: string } = {
  'plane intro': require('../../images/intro-animation/plane.png'),
  'submarine intro': require('../../images/intro-animation/submarine.png'),
  'dog intro': require('../../images/intro-animation/dog.png'),
  'fish intro': require('../../images/intro-animation/clownfish.png')
}

export class IntroAnimation extends createjs.Container {
  protected _name: string
  protected _size: Point
  protected _landscape: boolean
  protected _resource: string
  protected _shake: number
  protected _bitmap: createjs.Bitmap | undefined
  protected _text: TextLabelDeprecated | undefined
  protected _container: createjs.Container | undefined
  protected _loadingText: LoadingLabel | undefined
  protected _isAnimatedOut = false

  constructor(name: string, size: Point, landscape: boolean) {
    super()
    this._name = name ?? 'Untitled'
    this._size = size
    this._landscape = landscape

    this._resource = Randomize.randomFromArray([
      'plane intro',
      'submarine intro',
      'dog intro',
      'fish intro'
    ])

    switch (this._resource) {
      case 'dog intro':
        this._shake = 15
        break
      case 'fish intro':
        this._shake = 3
        break
      case 'submarine intro':
        this._shake = 1
        break
      default:
        this._shake = 0
        break
    }

    GameSound.registerOne(this._resource, soundResources[this._resource])
  }

  public async load() {
    const center = this._size.divide(2)

    this._bitmap = await BitmapLoader.loadAndCenter(
      imageResources[this._resource]
    )

    const textSize = Point.xy(this._size.width() * 0.5, 80)
    const text = new TextLabelDeprecated(textSize, this._name, true)
    text.setBackground(Color.randomStrongTextColor())
    text.shrinkToFitWidth()
    text.measureText()
    text.regX = textSize.width() / 2
    text.regY = textSize.height() / 2
    text.setBounds(0, 0, textSize.width(), textSize.height())
    this._text = text

    const container = new createjs.Container()
    const containerSize = Point.xy(
      textSize.width() + this._bitmap.image.width + 40,
      200
    )
    container.addChild(this._bitmap)
    container.addChild(text)

    container.x = this._size.x + 400
    container.y = center.y
    container.regX = containerSize.width() / 2
    container.regY = containerSize.height() / 2
    container.setBounds(0, 0, containerSize.width(), containerSize.height())
    this._container = container
    this.addChild(container)

    new LayoutFlexStack(
      Direction.horizontal,
      new Rect(Point.ZERO, containerSize),
      [this._bitmap, text],
      40
    ).layout()
  }

  public async play() {
    const center = this._size.divide(2)

    await delay(1000)
    GameSound.play(this._resource)

    if (this._shake > 0) {
      Animator.shake(this._bitmap!, this._shake, 1500)
    }

    this._text!.shrinkToFitWidth()

    await Animator.moveTo(this._container!, center, 1500)
    await delay(1800)

    delay(1000).then(this.addLoadingText)
  }

  public async animateOut() {
    this._isAnimatedOut = true
    this._loadingText?.stop()
    await Animator.fadeOut(this._container!)
  }

  private addLoadingText = () => {
    if (this._isAnimatedOut) {
      return
    }

    const container = this._container!

    // Add loading label
    const text = new LoadingLabel(
      Point.xy(container.regX, container.regY + 120)
    )
    this._loadingText = text
    container.addChild(text)

    text.animate()
  }
}
