import LocalizedStrings from 'react-localization'
import { LanguageCookie } from '../games/utils/language-cookie'

export const UiStrings = new LocalizedStrings({
  en: {
    mainPageLead:
      "Welcome to Handtoy School where kids can play and learn at the same time. Meet many cute characters helping kids learn variety of skills while having fun. The games are designed to improve a child's fine motor skills for kids 2+ year-olds. Enjoy the total of {0} games. Play on any phone or computer right from your browser. Have a wonderful playtime and have fun!",
    playButton: 'Play',
    redeemCodeButton: 'Redeem Coupon',
    alreadyMember: 'Already a member?',
    redeemCodeAndRegister: 'Redeem Coupon Code and Register',
    noGames: 'No games for the selected filter',
    thankyouForMember: 'Thank you for being our member',
    name: 'Name',
    email: 'Email',
    memberSince: 'Member since',
    nextRenewDate: 'Next Membership renewal date',
    changeLanguage: 'Change webpage language',
    statslink: 'Game Play Statistics',
    stats: 'Statistics',
    showDetails: 'Show details',

    welcomeNewMember:
      'Welcome to handtoy family! Play our games on iPhone, iPad, Android, Mac, or PC right from your browser.',
    whatIsHandtoySchool: 'What is Handtoy School?',
    enterCode: 'Please enter coupon code',
    enterCodeDescription:
      "Handtoy gives out a coupon code to handtoy's customers. Contact us at",
    enterCodeLabel: 'Please enter coupon code (case sensitive)',
    registerWithFacebook: 'Register the account with your Facebook account',
    registerWithFacebookDescription:
      'Handtoy links our membership account with a facebook account. You can play handtoy games from any devices after login with the registered facebook account',
    checkingCode: 'checking coupon code',
    correctCode: 'Perfect! Your code is correct',
    wrongCode:
      'Sorry, we cannot find your code in the system. Please try again.',
    errorTryagain: 'Sorry, there was an error. Please try again.',
    errorLogin: 'Sorry, there was an error with the login. Please try again.',
    errorRegisterAlreadyMember: 'You are already a member. Please go to ',

    signup: 'Sign up',
    newToHandtoy: 'New to Handtoy?',
    signupPrice: 'Just for ฿99/yr',
    checkout: 'Checkout',
    successful: 'Successful',
    checkoutDescription:
      "For just ฿99, you have unlimited access to all Handtoy School's games for 1 Year on any devices after login with the registered facebook account.",
    checkoutCompleted: 'Your checkout has completed',
    processing: "Processing a payment. Please wait and don't refresh.",
    errorCompleteStep1: 'please complete step 1 first',

    renewMembership: 'Renew Membership',
    renewMembershipDetail: 'Renew your membership to {0}',
    renewMembershipSuccess: 'Your membership has been renewed. Please go to',
    membershipExpiringShort:
      'Your membership will be expired after {0}. To renew, go to',
    membershipExpiringDetails:
      'Your membership will be expired after {0}. Your children can enjoy playing all 40+ games by siging up for unlimited access on any devices just ฿99/yr.'
  },
  th: {
    mainPageLead:
      'ยินดีต้อนรับสู่ Handtoy School ขอเชิญชวนคุณหนูๆ มาลองเล่นเกมการศึกษาต่างๆมากมายกับทาง handtoy พบกับสัตว์ต่างๆน่ารักมากมาย พัฒนากล้ามเนื้อมือมัดเล็ก ฝึกเชาวน์ปัญญา ทาง handtoy มีเกมมานำเสนอให้คุณหนูๆเล่นถึง {0} เกม เล่นสนุกได้ทุกที่จากเบราว์เซอร์ของคุณไม่ว่าจะเป็นโทรศัพท์มือถือหรือคอมพิวเตอร์ เล่นสนุก ได้ความรู้กับทาง handtoy',
    playButton: 'เล่น',
    redeemCodeButton: 'แลกรหัส',
    alreadyMember: 'สำหรับท่านที่เป็นสมาชิกอยู่แล้ว',
    redeemCodeAndRegister: 'แลกรหัสและลงชื่อเป็นสมาชิก',
    noGames: 'ไม่มีเกมสำหรับ filter ที่เลือกอยู่',
    thankyouForMember: 'ขอบคุณที่เป็นสมาชิกกับเรา',
    name: 'ชื่อ',
    email: 'อีเมล์',
    memberSince: 'เป็นสมาชิกตั้งแต่',
    nextRenewDate: 'ต่ออายุสมาชิกวันที่',
    changeLanguage: 'เปลี่ยนภาษาของหน้าเว็บ',
    statslink: 'สถิติการเล่น',
    stats: 'สถิติการเล่น',
    showDetails: 'แสดงรายละเอียด',

    welcomeNewMember:
      'ยินดีต้อนรับสมาชิกใหม่! คุณสามารถเล่นเกมของ handtoy ได้บน iPhone, iPad, Android, Mac, หรือ PC จากเบราว์เซอร์ของคุณ',
    whatIsHandtoySchool: 'Handtoy School คืออะไร',
    enterCode: 'โปรดป้อนรหัส',
    enterCodeDescription:
      'ทาง handtoy แจกรหัสให้กับลูกค้าของเรา หรือคุณสามารถติดต่อเราผ่าน Line Admin ได้ที่',
    enterCodeLabel: 'โปรดป้อนรหัส (แยกแยะตัวพิมพ์ใหญ่-เล็ก)',
    registerWithFacebook: 'เชื่อมโยงรหัสกับบัญชี Facebook',
    registerWithFacebookDescription:
      'คุณสามารถเล่นเกม handtoy ได้ทุกที่ไม่ว่าจะเป็นโทรศัพท์มือถือหรือคอมพิวเตอร์ เพียงแค่ login ผ่านทาง facebook',
    checkingCode: 'กำลังตรวจสอบรหัส',
    correctCode: 'รหัสถูกต้อง ขอบคุณค่ะ',
    wrongCode: 'ขออภัยค่ะ เราไม่พบรหัสของท่าน โปรดลองใหม่อีกครั้่ง',
    errorTryagain: 'ขออภัยค่ะ เกิดข้อผิดพลาด ขอให้ลองใหม่อีกครั้ง',
    errorLogin: 'ขออภัยค่ะ เกิดข้อผิดพลาด ขอให้ลอง login ใหม่อีกครั้ง',
    errorRegisterAlreadyMember: 'คุณเป็นสมาชิกอยู่แล้วค่ะ ไปที่หน้า ',

    signup: 'สมัครสมาชิก',
    newToHandtoy: 'สำหรับท่านที่ยังไม่ได้เป็นสมาชิก',
    signupPrice: 'เพียง 99 บาท ต่อปี',
    checkout: 'Checkout',
    successful: 'สำเร็จ',
    checkoutDescription:
      'เพียง 99 บาท คุณสามารถเล่นเกมทุกเกมของ handtoy เป็นเวลา 1 ปี ได้ทุกที่ไม่ว่าจะเป็นโทรศัพท์มือถือหรือคอมพิวเตอร์ เพียงแค่ login ผ่านทาง facebook',
    checkoutCompleted: 'รายการ checkout ของคุณได้ทำการเรียบร้อยแล้ว',
    processing: 'กำลังดำเนินการ กรุณารอและอย่า refresh browser',
    errorCompleteStep1: 'โปรดทำตามขั้นตอนที่ 1 ก่อนนะคะ',

    renewMembership: 'ต่ออายุสมาชิก',
    renewMembershipDetail: 'ต่ออายุสมาชิกของคุณไปจนถึง {0}',
    renewMembershipSuccess: 'ต่ออายุสมาชิกเรียบร้อย ไปยังหน้า',
    membershipExpiringShort:
      'สมาชิก handtoy ของคุณกำลังจะหมดอายุหลังวันที่ {0} ต่ออายุได้ที่',
    membershipExpiringDetails:
      'สมาชิก handtoy ของคุณกำลังจะหมดอายุหลังวันที่ {0} กรุณาต่ออายุสมาชิกให้ลูกๆ หลานๆ เล่นเกมของเรากว่า 40+ เกมได้บนทุกอุปกรณ์ เพียง 99 บาทต่อปีเท่านั้น'
  }
})

UiStrings.setLanguage(LanguageCookie.getLanguage())
