'use client'
import * as createjs from 'createjs-module'
import { Point } from './point'

export class LayoutPadding extends createjs.DisplayObject {
  private _size: Point

  constructor(w: number, h: number) {
    super()
    this._size = Point.xy(w, h)
  }

  getBounds(): createjs.Rectangle {
    return new createjs.Rectangle(0, 0, this._size.w, this._size.h)
  }
}
