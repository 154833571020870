const s_playerTimerStorageKey = "playTimerSeconds";
const s_playerTimerCreateTimeStampStorageKey = "playTimerCreateTimeStamp";
let s_globalTimer: NodeJS.Timeout | null = null;
let s_globalRemainingTime: number = 0;

export function getGlobalTimer(): number {
    return s_globalRemainingTime;
}

function redirectToTimeoutPage() {
    // redirect to timeout page
    window.location.replace('/timeout')
}

function setupGlobalTimer(
    setSeconds: React.Dispatch<React.SetStateAction<number>> | undefined
) {
    s_globalTimer = setInterval(() => {
        if (s_globalRemainingTime <= 0) {
            redirectToTimeoutPage();
        }

        s_globalRemainingTime = s_globalRemainingTime - 1;

        if (setSeconds !== undefined) {
            setSeconds(s_globalRemainingTime);
        }

        localStorage.setItem(
            s_playerTimerStorageKey,
            s_globalRemainingTime.toString()
        );
    }, 1000);

    const currentTimeStamp = new Date().getTime();
    localStorage.setItem(s_playerTimerCreateTimeStampStorageKey, currentTimeStamp.toString());
}

function hasElaspedMoreThanNHours(
    n: number
) {
    const storedTimeStampStr = localStorage.getItem(s_playerTimerCreateTimeStampStorageKey);
    if (storedTimeStampStr) {
        const storedTimeStamp = parseInt(storedTimeStampStr, 10);
        const currentTime = new Date().getTime();
        const timeDifference = currentTime - storedTimeStamp;
        const nHoursInMillis = n * 60 * 60 * 1000; // 3 hours in milliseconds

        if (timeDifference >= nHoursInMillis) {
            return true;
        } else {
            return false;
        }
    } else {
        return true;
    }
}

export function setGlobalTimer(
    time: number,
    setSeconds: React.Dispatch<React.SetStateAction<number>>
) {
    if (s_globalTimer !== null) {
        clearInterval(s_globalTimer);
    }

    if (time <= 0) {
        return;
    }

    s_globalRemainingTime = time;

    setupGlobalTimer(setSeconds);
}

export function restoreGlobalTimer(
    setSeconds: React.Dispatch<React.SetStateAction<number>> | undefined
) {
    if (hasElaspedMoreThanNHours(3)) {
        clearGlobalTimer();
        return;
    }

    if (s_globalTimer === null) {
        const storedSeconds =
            parseInt(localStorage.getItem(s_playerTimerStorageKey) ?? "0", 10) || 0;
        s_globalRemainingTime = storedSeconds;

        if (s_globalRemainingTime <= 0) {
            redirectToTimeoutPage();
            return;
        }
    } else {
        clearInterval(s_globalTimer);
    }

    setupGlobalTimer(setSeconds);
}

export function clearGlobalTimer(
) {
    if (s_globalTimer !== null) {
        clearInterval(s_globalTimer)
    }
    s_globalRemainingTime = 0;

    localStorage.removeItem(
        s_playerTimerStorageKey,
    );
    localStorage.removeItem(
        s_playerTimerCreateTimeStampStorageKey,
    );
}