import { Language } from '../resource/language'

export interface ISoundFactory {
  // register all sounds for the current game
  register(language: Language): void
}

export class FakeSoundFactory implements ISoundFactory {
  register(_language: Language): void {}
}
