import { delay } from '../utils/delay'
import { GameBase, IGameConfig } from '../core/game-base'
import { GameSound } from '../resource/game-sound'
import { InkData } from './ink-data'
import { WritingGame } from './writing-game'
import { WritingSounds } from '../resource/writing-sounds'

export class WritingNumberGame extends WritingGame {
  constructor(config: IGameConfig) {
    super(config)

    GameSound.register(WritingSounds.NUBMER)
  }

  protected inkWidth() {
    return 80
  }

  protected inkFont() {
    return `600px Quicksand`
  }

  protected letter() {
    return ((this._pageIndex + 1) % (this.count() + 1)).toString()
  }

  protected resourceName() {
    return this.letter()
  }

  protected cartoonName() {
    return this.letter()
  }

  protected questionText() {
    return undefined
  }

  protected checkPoints() {
    return InkData.number[this.letter()]
  }

  async playIntroSound() {
    await delay(GameBase.INTROSOUNDDELAY)
    await this.playSound('can you write a number')
    await this.playSound(this.resourceName())
  }

  protected async playSummarySound() {
    await this.playSound(this.cartoonName())
  }
}
