import { BitmapLoader } from '../core/bitmap-loader'
import { ImageFactoryBase } from '../core/image-factory'

export class AbcImages extends ImageFactoryBase {
  public static ALPHABETS = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z'
  ]

  public static MAP: { [id: string]: string } = {
    A: 'ant',
    B: 'bird',
    C: 'cat',
    D: 'dog',
    E: 'elephant',
    F: 'fox',
    G: 'giraffe',
    H: 'horse',
    I: 'insect',
    J: 'jar',
    K: 'kangaroo',
    L: 'lion',
    M: 'monkey',
    N: 'nurse',
    O: 'ox',
    P: 'pig',
    Q: 'queen',
    R: 'rat',
    S: 'sheep',
    T: 'tiger',
    U: 'unicorn',
    V: 'violin',
    W: 'whale',
    X: 'x-ray',
    Y: 'yak',
    Z: 'zebra'
  }

  public static WORDS: string[] = AbcImages.ALPHABETS.map(
    (i) => AbcImages.MAP[i]
  )

  static s_images: { [id: string]: string } = {
    A: require('../../images/abc/A.png'),
    B: require('../../images/abc/B.png'),
    C: require('../../images/abc/C.png'),
    D: require('../../images/abc/D.png'),
    E: require('../../images/abc/E.png'),
    F: require('../../images/abc/F.png'),
    G: require('../../images/abc/G.png'),
    H: require('../../images/abc/H.png'),
    I: require('../../images/abc/I.png'),
    J: require('../../images/abc/J.png'),
    K: require('../../images/abc/K.png'),
    L: require('../../images/abc/L.png'),
    M: require('../../images/abc/M.png'),
    N: require('../../images/abc/N.png'),
    O: require('../../images/abc/O.png'),
    P: require('../../images/abc/P.png'),
    Q: require('../../images/abc/Q.png'),
    R: require('../../images/abc/R.png'),
    S: require('../../images/abc/S.png'),
    T: require('../../images/abc/T.png'),
    U: require('../../images/abc/U.png'),
    V: require('../../images/abc/V.png'),
    W: require('../../images/abc/W.png'),
    X: require('../../images/abc/X.png'),
    Y: require('../../images/abc/Y.png'),
    Z: require('../../images/abc/Z.png')
  }

  /* IMatchImageFactory */
  public getHead(name: string): string {
    const img = AbcImages.s_images[name]
    if (img !== undefined) {
      return img
    }

    const alphabet = Object.keys(AbcImages.MAP).find(
      (k) => AbcImages.MAP[k] === name
    )
    if (alphabet !== undefined) {
      return AbcImages.s_images[alphabet]
    }
    return ''
  }

  public getBody(name: string): string {
    return this.getHead(name)
  }

  /* IBasicImageFactory */
  public getNames() {
    return AbcImages.ALPHABETS
  }

  public async getImage(name: string) {
    const img = await BitmapLoader.loadAndCenter(this.getHead(name))
    return img
  }
}
