'use client'
import * as createjs from 'createjs-module'
import { Point } from './point'

export class ResizableRect extends createjs.Shape {
  private _size: Point
  private _color: string
  private _r: number
  private _borderWidth: number
  private _borderColor: string

  constructor(
    size: Point,
    color: string,
    r?: number,
    borderWidth?: number,
    borderColor?: string
  ) {
    super()
    this._size = size
    this._color = color
    this._r = r ?? 0
    this._borderWidth = borderWidth ?? 0
    this._borderColor = borderColor ?? color

    this.setBounds(0, 0, size.width(), size.height())
    this.regX = size.x / 2
    this.regY = size.y / 2
    this.layout()
  }

  get size() {
    return this._size
  }

  set size(s: Point) {
    this._size = s
    this.setBounds(this.x, this.y, s.width(), s.height())
    this.regX = s.x / 2
    this.regY = s.y / 2
  }

  set origin(p: Point) {
    this.x = p.x
    this.y = p.y
  }

  public layout(size?: Point) {
    if (size) {
      this.size = size
    }
    this.graphics.clear()
    this.layoutRect()
  }

  private layoutRect() {
    var w = this._size.x
    var h = this._size.y
    var x = 0
    var y = 0
    var r = this._r
    this.graphics
      .beginStroke(this._borderColor ?? this._color)
      .setStrokeStyle(this._borderWidth ?? 0)
      .beginFill(this._color)
      .drawRoundRect(x, y, w, h, r ?? 0)
  }
}
