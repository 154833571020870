import { endpoint, post } from './request'

export interface IHandtoyLinkAccount {
  ID: string
  Type: string
  Name: string
  Email: string
  RegisteredDate: number
  RenewDate: number
}

export class Member {
  public static async get(
    id: string,
    type: string
  ): Promise<IHandtoyLinkAccount | undefined> {
    // const json = {
    //   id,
    //   type
    // }
    // let r = await post<any, IHandtoyLinkAccount>(
    //   json,
    //   `${endpoint()}/member/get`
    // )
    // return r

    const account: IHandtoyLinkAccount = {
      ID: "12345",
      Type: "Basic",
      Name: "John Doe",
      Email: "john@example.com",
      RegisteredDate: 1678923456,
      RenewDate: 1678956789,
    };

    return account;
  }

  public static async checkCode(code: string): Promise<boolean> {
    if (!code || code.length !== 10) {
      return false
    }
    const json = {
      code: code
    }
    let r = await post<any, boolean>(json, `${endpoint()}/member/checkcode`)
    return r !== undefined && r
  }

  public static async redeemCode(
    code: string,
    id: string,
    name: string,
    email: string
  ): Promise<boolean> {
    if (!code || code.length !== 10) {
      return false
    }

    const json = {
      code: code,
      id: id,
      name: name,
      email: email
    }

    let r = await post<any, string>(json, `${endpoint()}/member/redeemcode`)
    return r !== undefined && r.length > 0
  }

  public static async checkoutSchoolMembership(
    token: string,
    id: string,
    name: string,
    email: string
  ): Promise<string | undefined> {
    if (!token || token.length === 0) {
      return 'error invalid token'
    }

    const json = {
      token: token,
      id: id,
      name: name,
      email: email
    }

    let r = await post<any, string>(
      json,
      `${endpoint()}/member/checkoutschoolmembership`
    )
    return r
  }

  public static async shareOnFacebook(
    id: string,
    name: string,
    email: string
  ): Promise<boolean> {
    const json = {
      id: id,
      name: name,
      email: email
    }
    let r = await post<any, string>(
      json,
      `${endpoint()}/member/shareonfacebook`
    )
    return r !== undefined && r.length > 0
  }
}
