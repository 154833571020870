'use client'
import * as createjs from 'createjs-module'
import { Point } from './point'

type DraggableTileCallback<T> = (t: DraggableTile) => T

export class DraggableTile extends createjs.Container {
  public onPlaced: DraggableTileCallback<Point | undefined> | undefined
  public onLifted: DraggableTileCallback<void> | undefined
  public canDrag: boolean = true
  public origin: Point
  protected size: Point

  protected isTouchedDown: boolean = false
  protected isDragging: boolean = false
  protected isSnapped: boolean = false

  constructor(pos: Point, origin: Point, size: Point) {
    super()
    this.size = size.copy()
    this.regX = size.x / 2
    this.regY = size.y / 2
    this.x = pos.x
    this.y = pos.y
    this.origin = new Point(origin.x, origin.y)

    this.on('mousedown', (evt: any) => {
      this.handleMouseDownEvent(evt)
    })

    this.on('pressmove', (evt: any) => {
      this.handleMouseMoveEvent(evt)
    })

    this.on('pressup', (evt: any) => {
      this.handleMouseUpEvent(evt)
    })
  }

  public isSnap() {
    return this.isSnapped
  }

  public setSnap() {
    this.isSnapped = true
  }

  public setPositionToOrigin() {
    this.isSnapped = false
    this.x = this.origin.x
    this.y = this.origin.y
  }

  protected onBeforeLifted() {}

  protected onPlacedWithoutDragging() {}

  protected onPlacedAndSnapped() {}

  protected onPlacedAndReturnToOrigin() {}

  private handleMouseDownEvent(_evt: any) {
    if (!this.canDrag) {
      return
    }
    // Bring it to front
    const parent = this.parent
    parent.removeChild(this)
    parent.addChild(this)
    parent.stage.update()

    this.onBeforeLifted()

    const onLifted = this.onLifted
    if (onLifted !== undefined) {
      onLifted(this)
    }

    this.isTouchedDown = true
    this.isSnapped = false
  }

  private handleMouseMoveEvent(evt: any) {
    if (!this.canDrag) {
      return
    }
    if (this.isTouchedDown) {
      this.isDragging = true
      const target = { x: evt.stageX, y: evt.stageY }
      this.x = target.x
      this.y = target.y

      this.stage?.update()
    }
  }

  private handleMouseUpEvent(_evt: any) {
    if (!this.canDrag) {
      return
    }
    if (!this.isDragging) {
      this.onPlacedWithoutDragging()
    }
    this.isTouchedDown = false
    this.isDragging = false

    var snapTo: Point | undefined
    const onPlaced = this.onPlaced
    if (onPlaced !== undefined) {
      snapTo = onPlaced(this)
    }

    if (snapTo === undefined) {
      this.isSnapped = false
      this.x = this.origin.x
      this.y = this.origin.y
      this.onPlacedAndReturnToOrigin()
    } else {
      this.x = snapTo.x
      this.y = snapTo.y
      this.isSnapped = true
      this.onPlacedAndSnapped()
    }

    this.stage?.update()
  }
}
