export function endpoint() {
  return 'https://handtoy-api.azurewebsites.net/api'
  // return 'http://localhost:7071/api'
}

export async function post<T, U>(json: T, url: string): Promise<U | undefined> {
  try {
    // console.info('post')
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(json)
    })
    var r: any = await response.json()
    // console.info(`post succeeded`)
    return r
  } catch (ex) {
    // console.info(`post failed`)
    return undefined
  }
}
