
import * as createjs from 'createjs-module'
import { Animator } from '../core/animator'
import { BitmapLoader } from '../core/bitmap-loader'
import { IMazeGameLevel, toWall } from './maze-level'
import { Point } from '../core/point'
import { BitmapTile } from '../core/bitmap-tile'
import { DraggableTile } from '../core/draggable-tile'

const PADDING = 0

export class MazeBoard extends createjs.Container {
    private _player: createjs.Bitmap | undefined

    private _level: IMazeGameLevel
    private _size: Point
    private _boardScale: number
    private _isTouchedDown: boolean = false
    public _onWinCallback: () => void = () => { }

    constructor(level: IMazeGameLevel, size: Point) {
        super()
        this._level = level
        this._size = size
        this._boardScale = 3 / level.size.x

        this.setBounds(0, 0, size.width(), size.height())
    }

    public get player() {
        return this._player
    }

    async load() {
        await this.loadBackground()
        await this.loadTarget(this._level.target)
        await this.loadPlayer(this._level.player)

        this.stage?.update()
    }

    async loadBackground() {
        const board = this._level.board

        if (board === undefined || board.length === 0) {
            return
        }

        const w = (this._size.width() - PADDING) / this._level.size.x
        const h = (this._size.height() - PADDING) / this._level.size.y

        const imgGlass = await BitmapLoader.load(
            require('../../images/coding/glass.png')
        )

        const s = this._boardScale
        imgGlass.scaleX = s
        imgGlass.scaleY = s

        // const imgWater = await BitmapLoader.load(
        //     require('../../images/coding/water.png')
        // )

        // imgWater.scaleX = s
        // imgWater.scaleY = s

        var green = new createjs.Shape();
        green.graphics.beginFill("green").drawRoundRect(4, 4, w - 8, h - 8, 6);

        var road = new createjs.Shape();
        road.graphics.beginFill("#c2b1ab").drawRoundRect(4, 4, w - 8, h - 8, 6);

        const container = new createjs.Container()

        for (let r = 0; r < this._level.size.y; r++) {
            for (let c = 0; c < this._level.size.x; c++) {
                // const tile = board[c][r]
                const tile = board[r][c]

                if (tile > 0) {
                    const i = imgGlass.clone()
                    i.x = c * w
                    i.y = r * h
                    container.addChild(i)
                } else {
                    const i = road.clone()
                    i.x = c * w
                    i.y = r * h
                    container.addChild(i)
                }
            }
        }

        container.cache(0, 0, this._size.width(), this._size.height())
        const combinedBitmap = new createjs.Bitmap(container.cacheCanvas)

        this.addChildAt(combinedBitmap, 0)
    }

    // async loadBackground() {
    //     const board = this._level.board

    //     if (board === undefined || board.length === 0) {
    //         return
    //     }

    //     const w = (this._size.width() - PADDING) / this._level.size.x
    //     const h = (this._size.height() - PADDING) / this._level.size.y

    //     // const imgGlass = await BitmapLoader.load(
    //     //     require('../../images/coding/glass.png')
    //     // )

    //     // const s = this._boardScale
    //     // imgGlass.scaleX = s
    //     // imgGlass.scaleY = s

    //     // const imgWater = await BitmapLoader.load(
    //     //     require('../../images/coding/water.png')
    //     // )

    //     // imgWater.scaleX = s
    //     // imgWater.scaleY = s

    //     const container = new createjs.Container()

    //     var graphics = new createjs.Graphics();
    //     graphics.setStrokeStyle(10); // Adjust thickness as needed
    //     graphics.beginStroke('#999999')

    //     for (let r = 0; r < this._level.size.y; r++) {
    //         for (let c = 0; c < this._level.size.x; c++) {
    //             const tile = board[r][c]

    //             const walls = toWall(tile)

    //             // Move to initial position
    //             const basex = (c * w) + (PADDING / 2.0)
    //             const basey = (r * h) + (PADDING / 2.0)
    //             graphics.moveTo(basex, basey);

    //             // Draw top border if present
    //             if (walls.top) {
    //                 graphics.lineTo(basex + w, basey + 0);
    //             } else {
    //                 graphics.moveTo(basex + w, basey + 0);
    //             }

    //             // Draw right border if present
    //             if (walls.right) {
    //                 graphics.lineTo(basex + w, basey + h);
    //             } else {
    //                 graphics.moveTo(basex + w, basey + h);
    //             }

    //             // Draw bottom border if present
    //             if (walls.bottom) {
    //                 graphics.lineTo(basex + 0, basey + h);
    //             } else {
    //                 graphics.moveTo(basex + 0, basey + h);
    //             }

    //             // Draw left border if present
    //             if (walls.left) {
    //                 graphics.lineTo(basex + 0, basey + 0);
    //             } else {
    //                 graphics.moveTo(basex + 0, basey + 0);
    //             }
    //         }
    //     }

    //     graphics.endStroke()
    //     var shape = new createjs.Shape(graphics);
    //     container.addChild(shape)

    //     container.cache(0, 0, this._size.width(), this._size.height())
    //     const combinedBitmap = new createjs.Bitmap(container.cacheCanvas)

    //     this.addChildAt(combinedBitmap, 0)
    // }

    async loadPlayer(p: Point) {
        const player = await BitmapLoader.loadAndCenter(
            require('../../images/maze/school_bus.png')
        )

        var pos = this.positionAt(p.r(), p.c())

        player.x = pos.x
        player.y = pos.y
        player.scaleX = this._boardScale * 1.2
        player.scaleY = this._boardScale * 1.2
        player.regX = player.image.width / 2
        player.regY = player.image.height / 2

        player.on('mousedown', (evt: any) => {
            this.handleMouseDownEvent(evt)
        })

        player.on('pressmove', (evt: any) => {
            this.handleMouseMoveEvent(evt)
        })

        player.on('pressup', (evt: any) => {
            this.handleMouseUpEvent(evt)
        })

        this.addChild(player)
        this._player = player
    }

    async loadTarget(p: Point) {
        const target = BitmapLoader.scale(
            await BitmapLoader.loadAndCenter(
                require('../../images/maze/flag.png')
            ),
            this._boardScale * 1.8
        )
        const pos = this.positionAt(p.r(), p.c())
        target.x = pos.x
        target.y = pos.y

        this.addChild(target)
    }

    private positionAt(r: number, c: number) {
        const w = (this._size.width() - PADDING) / this._level.size.x
        const h = (this._size.height() - PADDING) / this._level.size.y
        return Point.rc((r * h) + (PADDING / 2.0), (c * w) + (PADDING / 2.0)).plus(Point.xy(w / 2, h / 2))
    }

    private gridCellAt(target: Point) {
        const w = (this._size.width() - PADDING) / this._level.size.x
        const h = (this._size.height() - PADDING) / this._level.size.y

        const r = Math.floor((target.y - (PADDING / 2.0)) / h)
        const c = Math.floor((target.x - (PADDING / 2.0)) / w)

        return Point.rc(r, c)
    }


    private handleMouseDownEvent(_evt: any) {
        this._isTouchedDown = true
    }

    private handleMouseMoveEvent(evt: any) {
        if (this._isTouchedDown) {
            if (this._player) {
                const target = Point.xy(evt.stageX - this._player.image.width / 2, evt.stageY)

                if (!target.isRoughlyEqual(Point.xy(this._player.x, this._player.y), 40)) {
                    return
                }

                if (this.hitWall(target)) {
                    return
                }

                if (this.hitExit(target)) {
                    this._onWinCallback()
                    return
                }

                this._player.x = target.x
                this._player.y = target.y
            }

            this.stage?.update()
        }
    }

    private handleMouseUpEvent(_evt: any) {
        this._isTouchedDown = false
    }

    private hitWall(target: Point) {
        const rc = this.gridCellAt(target)
        if (rc.r() < 0 || rc.r() >= this._level.size.r() || rc.c() < 0 || rc.c() >= this._level.size.c()) {
            return true
        }

        if (this._level.board[rc.r()][rc.c()] > 0) {
            return true
        }

        return false
    }

    private hitExit(target: Point) {
        const rc = this.gridCellAt(target)
        if (rc.r() < 0 || rc.r() >= this._level.size.r() || rc.c() < 0 || rc.c() >= this._level.size.c()) {
            return false
        }

        if (this._level.target.r() === rc.r() && this._level.target.c() === rc.c()) {
            return true
        }

        return false
    }

    // private hitWall(target: Point) {
    //     // if (this._player === undefined) {
    //     //     return false
    //     // }

    //     // const p = Point.xy(this._player.x, this._player.y)

    //     // this.positionAt(target.x, target.y)

    //     const rc = this.gridCellAt(target)
    //     if (rc.r() < 0 || rc.r() >= this._level.size.r() || rc.c() < 0 || rc.c() >= this._level.size.c()) {
    //         return false
    //     }

    //     const center = this.positionAt(rc.r(), rc.c())
    //     const w = (this._size.width() - PADDING) / this._level.size.x
    //     const h = (this._size.height() - PADDING) / this._level.size.y
    //     const cell = this._level.board[rc.r()][rc.c()]
    //     const walls = toWall(cell)

    //     const hitTestFactor = 4.0

    //     const hits = {
    //         hitTop: walls.top && target.y < (center.y) - (h / hitTestFactor),
    //         hitRight: walls.right && target.x > (center.x) + (w / hitTestFactor),
    //         hitBottom: walls.bottom && target.y > (center.y) + (h / hitTestFactor),
    //         hitLeft: walls.left && target.x < (center.x) - (w / hitTestFactor),
    //     }

    //     console.log(JSON.stringify(hits))


    //     return hits.hitTop || hits.hitRight || hits.hitBottom || hits.hitLeft
    // }


}
