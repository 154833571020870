import { BitmapLoader } from '../core/bitmap-loader'
import { LayoutPin, Pin } from '../core/layout-pin'
import { SortingGame } from './sorting-game'

export class SortingAnimalGame extends SortingGame {
  private name1(index: number) {
    const names = this._imageFactory.getNames()
    return names[(index * 2) % names.length]
  }

  private name2(index: number) {
    const names = this._imageFactory.getNames()
    return names[(index * 2 + 1) % names.length]
  }

  public count() {
    return Math.round(this._imageFactory.getNames().length / 2)
  }

  async load() {
    const n1 = this.name1(this._pageIndex)
    this._bitmap1 = BitmapLoader.scale(
      await this._imageFactory.getImage(n1),
      0.3
    )
    this._bitmap1.alpha = 0
    const n2 = this.name2(this._pageIndex)
    this._bitmap2 = BitmapLoader.scale(
      await this._imageFactory.getImage(n2),
      0.3
    )
    this._bitmap2.alpha = 0

    await super.load()
  }

  public layout(landscape: boolean) {
    super.layout(landscape)

    LayoutPin.pin(this._bitmap1!, Pin.top, this._label1!, Pin.bottom, 20)
    LayoutPin.pin(this._bitmap2!, Pin.top, this._label2!, Pin.bottom, 20)
  }

  protected questions() {
    const c = this._imageFactory.getNames().length
    const q = []
    for (let i = 0; i < c; ++i) {
      q.push([this.name1(i), this.name2(i)])
    }
    return q
  }

  protected answers(index: number) {
    const setOne = []
    const setTwo = []
    const n1 = this.name1(this._pageIndex)
    const n2 = this.name2(this._pageIndex)

    for (let i = 0; i < 3; ++i) {
      setOne.push(n1)
    }

    for (let i = 0; i < 3; ++i) {
      setTwo.push(n2)
    }
    this._setOne = setOne
    this._setTwo = setTwo
    return [setOne, setTwo]
  }
}
