import InkContainer from './ink-container'
import { AbcImages } from '../resource/abc-images'
import { Animator } from '../core/animator'
import { Color } from '../core/color'
import { delay } from '../utils/delay'
import { GameBase, IGameConfig } from '../core/game-base'
import { GameSound } from '../resource/game-sound'
import { InkData } from './ink-data'
import { Point } from '../core/point'
import { TextLabelDeprecated } from '../core/text-label-deprecated'
import { WritingSounds } from '../resource/writing-sounds'

export class WritingGame extends GameBase {
  protected static INKCONTAINER_SIZE = Point.xy(800, 800)

  protected _color: string

  protected _win: boolean = false

  protected _inkContainer: InkContainer
  protected _bitmap: createjs.Bitmap | undefined
  protected _text: TextLabelDeprecated | undefined

  constructor(config: IGameConfig) {
    super(config)

    this._color = Color.randomStrongTextColor()

    this._inkContainer = new InkContainer(
      WritingGame.INKCONTAINER_SIZE,
      this._color,
      this.inkWidth(),
      this.letter(),
      this.inkFont(),
      this.checkPoints()
    )

    this._inkContainer.callback = this.onInkCallback
    this._inkContainer.alpha = 0
    this.addChild(this._inkContainer)

    GameSound.register(WritingSounds.INTRO)
  }

  protected inkWidth() {
    return 80
  }

  protected letter() {
    const letters = AbcImages.ALPHABETS
    const letter = letters[this._pageIndex % letters.length]
    return letter
  }

  protected inkFont() {
    return `600px Arial`
  }

  protected resourceName() {
    return this.letter()
  }

  protected cartoonName() {
    const names = AbcImages.WORDS
    const name = names[this._pageIndex % names.length]
    return name
  }

  protected questionText(): string | undefined {
    const cartoonName = this.letter() + this.cartoonName().slice(1)
    return `${this.letter()} for ${cartoonName}`
  }

  protected checkPoints() {
    return InkData.ABC[this.letter()]
  }

  async load() {
    await this.loadQuestion()

    this.layout(this._landscape)

    this._inkContainer.alpha = 1
    this._bitmap!.alpha = 1
    this._text!.alpha = this.questionText() === undefined ? 0 : 1

    this.stage?.update()

    await this.playIntroSound()
  }

  async loadQuestion() {
    const bitmap = await this._imageFactory.getImage(this.resourceName())
    this._bitmap = bitmap
    this._bitmap.alpha = 0
    this.addChild(bitmap)

    const text = new TextLabelDeprecated(
      Point.xy(300, 120),
      this.questionText()
    )
    text.setTextColor(this._color)
    text.shrinkToFitWidth()
    this._text = text
    this._text.alpha = 0
    this.addChild(text)

    bitmap.on('mousedown', () => {
      if (this._inkContainer.tracingMode) {
        this.copyToClipboard()
        this._callback()
        return
      }
      if (!this._win) {
        Animator.lose(this._bitmap!)
        this.playSound('error')
        this._inkContainer.resetInk()
      }
    })
  }

  public layout(landscape: boolean) {
    super.layout(landscape)

    const layoutSize = this.layoutSize(this._landscape)
    const center = layoutSize.divide(2)
    let p = center.minus(Point.xy(80, 0).flipIf(!this._landscape))
    this._inkContainer.x = p.x
    this._inkContainer.y = p.y

    const quarter = layoutSize.divide(4)
    p = center.plus(quarter).plus(Point.xy(100, 0).flipIf(!this._landscape))
    this._bitmap!.x = p.x
    this._bitmap!.y = p.y

    this._text!.x = p.x - 420
    this._text!.y = p.y + 40
  }

  async playIntroSound() {
    await delay(GameBase.INTROSOUNDDELAY)
    await this.playSound('can you write a letter')
    await this.playSound(this.resourceName())
  }

  onInkCallback = async () => {
    if (this._win) {
      return
    }

    this._win = true
    Animator.win(this._bitmap!)

    await this.playSound('success')

    await this.playSummarySound()

    this._callback()
  }

  protected async playSummarySound() {
    this.playSound(this.resourceName())
    await delay(800)
    this.playSound('for')
    await delay(500)
    await this.playSound(this.cartoonName())
  }

  // Tracing helpers
  handleClipboard = (e: ClipboardEvent) => {
    const text = `${this.letter()}: ${JSON.stringify(
      this._inkContainer.tracingPoints
    )},`
    e.clipboardData!.setData('text/plain', text)
    e.preventDefault()
    document.removeEventListener('copy', this.handleClipboard)
  }

  copyToClipboard = () => {
    document.addEventListener('copy', this.handleClipboard)
    document.execCommand('copy')
  }
}
