import * as React from 'react'

export default function Footer() {
  return (
    <footer className='footer mt-auto py-3'>
      <div className='container'>
        <p className='m-0 text-center text-primary'>
          &copy; 2018 <a href='https://www.handtoy.com'>handtoy.com</a>, All
          rights reserved
        </p>
        <p className='text-center text-primary text-small'>
          <a style={{ fontSize: 'small' }} href='./privacy'>
            Privacy Policy
          </a>{' '}
          |{' '}
          <a style={{ fontSize: 'small' }} href='./return-policy'>
            Return Policy
          </a>{' '}
          |{' '}
          <a style={{ fontSize: 'small' }} href='./credits'>
            Credits
          </a>{' '}
          |{' '}
          <a style={{ fontSize: 'small' }} href='./contact-us'>
            Contact us
          </a>
        </p>
      </div>
    </footer>
  )
}
