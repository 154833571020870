export default class Randomize {
  public static randomBool() {
    return Math.random() > 0.5
  }

  public static randomInt(min: number, max: number) {
    const range = max - min + 1
    const distance = Math.floor(Math.random() * range)
    return min + distance
  }

  public static randomFromArray<T>(arr: T[]) {
    const i = this.randomInt(0, arr.length - 1)
    return arr[i]
  }

  public static randomElementsFromArray<T>(
    arr: T[],
    count: number,
    initialArray: T[]
  ) {
    let tries = 100
    while (initialArray.length < count && tries > 0) {
      const i = Randomize.randomFromArray(arr)
      if (initialArray.findIndex((s) => s === i) === -1) {
        initialArray.push(i)
      }
      tries -= 1
    }
    this.shuffle(initialArray)
    return initialArray
  }

  public static shuffle<T>(arr: T[]) {
    var j, x, i
    for (i = arr.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1))
      x = arr[i]
      arr[i] = arr[j]
      arr[j] = x
    }
    return arr
  }
}
