import { MatchVehicleGame } from './match-vehicle-game'
import { Point } from '../core/point'

export class MatchActivityGame extends MatchVehicleGame {
  protected getTargetImpl(_n: number, p: Point) {
    const question = this._question
    if (question === undefined) {
      return Point.ZERO
    }
    return Point.from(question).plus(Point.xy(100, 8))
  }
}
