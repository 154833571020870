'use client'
import * as createjs from 'createjs-module'
import Randomize from '../utils/randomize'
import { Animator } from '../core/animator'
import { BitmapLoader } from '../core/bitmap-loader'
import { BitmapTile } from '../core/bitmap-tile'
import { delay } from '../utils/delay'
import { Direction } from '../core/layout-flex'
import { DraggableTile } from '../core/draggable-tile'
import { DropShadowFactory } from '../core/drop-shadow-factory'
import { GameBase, IGameConfig } from '../core/game-base'
import { GameSound } from '../resource/game-sound'
import { IMatchImageFactory } from '../core/image-factory'
import { LayoutFlex } from '../core/layout-flex'
import { Point } from '../core/point'
import { Rect } from '../core/rect'
import { SoundEffects } from '../resource/sound-effect'

export class MatchGame extends GameBase {
  protected _question: createjs.DisplayObject | undefined
  protected _questionImage: HTMLImageElement | undefined
  protected _questionName: string
  protected _imageFactory: IMatchImageFactory
  protected _answers: BitmapTile[] = []

  constructor(config: IGameConfig) {
    super(config)
    this._imageFactory = config.imageFactory as IMatchImageFactory
    this._questionName = this._names[this._pageIndex]

    GameSound.register(SoundEffects.MATCH)

    this.onLifted = this.onLifted.bind(this)
    this.onPlaced = this.onPlaced.bind(this)
  }

  public async load() {
    await this.loadQuestion()
    await this.loadAnswers()
    this.layout(this._landscape)
    this.stage?.update()

    this._answers.forEach((f) => {
      Animator.fadeIn(f)
    })
    Animator.fadeIn(this._question!)
    this.stage?.update()

    this.playIntroSound()
  }

  protected async loadQuestion() {
    const question = await BitmapLoader.load(
      this._imageFactory.getBody(this._questionName)
    )

    this._questionImage = question.image as HTMLImageElement
    this._question = question
    this._question.regX = this._questionImage.width / 2
    this._question.regY = this._questionImage.height / 2
    this._question.alpha = 0
    DropShadowFactory.create(this._question)
    this.addChild(this._question)
  }

  protected async loadAnswers() {
    const names = this.getAnswers(this._pageIndex)
    const answers = names.map((n) => this._imageFactory.getHead(n))

    for (var i = 0; i < answers.length; ++i) {
      const name = names[i]
      const answer = new BitmapTile(Point.ZERO, Point.ZERO, Point.ZERO)
      answer.name = name
      answer.alpha = 0
      answer.onLifted = this.onLifted
      answer.onPlaced = this.onPlaced
      DropShadowFactory.create(answer)
      this.addChild(answer)
      await answer.loadImage(answers[i])
      this._answers.push(answer)
    }
  }

  async playIntroSound() {
    await delay(GameBase.INTROSOUNDDELAY)
    this.playSound('can you match it')
  }

  public layoutSize(landscape: boolean) {
    super.layoutSize(landscape)
    if (landscape) {
      return new Point(1180, 800)
    } else {
      return new Point(800, 1000)
    }
  }

  public layout(landscape: boolean) {
    const layoutSize = this.layoutSize(landscape)

    const rect = new Rect(Point.ZERO, layoutSize).inset(-20, -20)

    new LayoutFlex(
      landscape ? Direction.horizontal : Direction.vertical,
      rect,
      [this._question!],
      this._answers
    ).layout()

    this.stage?.update()
  }

  protected onLifted(t: DraggableTile) {
    this.playSound(t.name)
  }

  protected onPlaced(t: DraggableTile) {
    const target = this.getTargetImpl(
      this._pageIndex,
      new Point(this._question!.x, this._question!.y)
    )
    const current = new Point(t.x, t.y)
    // console.log(`placed ${target.toString()}, ${current.toString()}`)
    if (target.isRoughlyEqual(current, 150)) {
      if (t.name === this._questionName) {
        this.win(t)
      } else {
        this.lose(t)
      }
      return target
    }
    return undefined
  }

  protected getTargetImpl(n: number, p: Point) {
    let offsetX = -60
    let offsetY = -60
    return new Point(p.x + offsetX, p.y + offsetY)
  }

  protected getAnswers(index: number) {
    var a = []
    a.push(this._names[index])

    const preloadedNames = this._names.filter((n) =>
      this._imageFactory.isPreloaded(n)
    )

    Randomize.randomElementsFromArray(preloadedNames, 3, a)
    return a
  }

  protected async win(t: DraggableTile) {
    this.playSound('success')

    this._answers.forEach((s) => {
      if (s.name !== this._questionName) {
        Animator.fadeOut(s)
      } else {
        s.canDrag = false
      }
    })

    await Animator.win(t)

    this.animateSummary()
  }

  protected async animateSummary() {
    this.playSound(this._questionName)

    this._answers.forEach((s) => {
      if (s.name === this._questionName) {
        const q = this._question
        if (q !== undefined) {
          const layoutSize = this.layoutSize(this._landscape)
          const distance = new Point(
            layoutSize.x / 2 - q.x,
            layoutSize.y / 2 - q.y
          )
          Animator.move(s, distance)
          Animator.move(this._question!, distance)
        }
      }
    })

    await delay(GameBase.WINDELAY)
    this._callback()
  }

  protected async lose(t: DraggableTile) {
    Animator.lose(t)
    this.playSound('error')
    await delay(500)
    t.setPositionToOrigin()
    t.stage?.update()
  }
}
