import { AbcSounds } from '../resource/abc-sounds'
import { Animator } from '../core/animator'
import { BitmapLoader } from '../core/bitmap-loader'
import { Color } from '../core/color'
import { delay } from '../utils/delay'
import { DraggableTile } from '../core/draggable-tile'
import { DropShadowFactory } from '../core/drop-shadow-factory'
import { MatchGame } from './match-game'
import { Point } from '../core/point'
import { TextLabelDeprecated } from '../core/text-label-deprecated'

export class MatchAlphabetGame extends MatchGame {
  protected _answerArea: createjs.Shape | undefined

  protected async loadQuestion() {
    const question = await BitmapLoader.load(
      this._imageFactory.getBody(this._questionName)
    )
    this._questionImage = question.image as HTMLImageElement

    const padding = 10
    const w = this._questionImage!.width + padding * 2
    const h = this._questionImage!.height + padding * 2

    const container = new createjs.Container()

    const s = Math.max(w, h)
    const text = new TextLabelDeprecated(
      new Point(s, s),
      this._questionName,
      false
    )
    text.setTextColor(Color.randomTextColor())
    text.regX = s / 2
    text.regY = s / 2
    container.addChild(text)

    this._answerArea = new createjs.Shape()
    this._answerArea.graphics
      .beginFill('white')
      .drawRoundRect(0, 0, s, s, s * 0.2)
    this._answerArea.regX = s / 2
    this._answerArea.regY = s / 2
    DropShadowFactory.create(this._answerArea)
    container.addChild(this._answerArea)

    this._question = container
    this._question.alpha = 0
    this.addChild(container)
  }

  public layout(landscape: boolean) {
    super.layout(landscape)

    const x = this._question!.x
    const y = this._question!.y

    if (landscape) {
      const distance = 200
      this._question!.x = x - distance
      this._question!.y = y

      this._answerArea!.x = distance * 2
      this._answerArea!.y = 0
    } else {
      const distance = 170
      this._question!.x = x
      this._question!.y = y - distance

      this._answerArea!.x = 0
      this._answerArea!.y = distance * 2
    }
  }

  protected onLifted(t: DraggableTile) {
    const sound = AbcSounds.map(t.name)
    if (sound.length > 0) {
      this.playSound(sound)
    }
  }

  protected getTargetImpl(_n: number, p: Point) {
    const answerArea = this._answerArea
    if (answerArea === undefined) {
      return Point.ZERO
    }
    return new Point(
      this._question!.x + answerArea.x,
      this._question!.y + answerArea.y
    )
  }

  protected async animateSummary() {
    const t = this._answers.find((s) => s.name === this._questionName)
    const q = this._question
    if (q !== undefined) {
      const layoutSize = this.layoutSize(this._landscape)
      const distance = this._landscape
        ? new Point(0, layoutSize.y / 2 - q.y)
        : new Point(layoutSize.x / 2 - q.x, 0)
      Animator.move(t!, distance)
      Animator.move(this._question!, distance)
      // Animator.move(this._answerArea!, distance)
    }

    const sound = AbcSounds.map(this._questionName)
    if (sound) {
      this.playSound(this._questionName)
      await delay(800)
      this.playSound('for')
      await delay(500)
      await this.playSound(sound)
    }

    await delay(2500)
    this._callback()
  }
}
