//  'use client'

import * as React from "react";
import Filter, { IFilterItem } from "../components/Filter";
import Footer from "../components/Footer";
import GameCard from "../components/GameCard";
import HeaderBar from "../components/HeaderBar";
import MainLoginSection from "../components/MainLoginSection";
import { Col, Container, Row } from "reactstrap";
import {
  getAgeFilter,
  getFilteredGameCards,
  getSubjectFilter,
  saveAgeFilter,
  saveSubjectFilter,
} from "../games/utils/game-filter";
import { getProdGames, IGameInfo } from "../games/utils/game-info";
import { IUserInfo, UserInfo } from "../games/utils/user-info";
import { UiStrings } from "./ui-strings";
import "./MainPage.css";

interface MainPageState {
  hasUserInfo: boolean;
  userInfo: IUserInfo | undefined;
  allGameCards: IGameInfo[];
  filteredGameCards: IGameInfo[];
  subjectFilter: IFilterItem[];
  ageFilter: IFilterItem[];
}

class MainPage extends React.Component<{}, MainPageState> {
  constructor(props: any) {
    super(props);

    const allGameCards = getProdGames();
    const subjectFilter = getSubjectFilter();
    const ageFilter = getAgeFilter();

    this.state = {
      hasUserInfo: false,
      userInfo: undefined,
      allGameCards,
      subjectFilter,
      ageFilter,
      filteredGameCards: getFilteredGameCards(
        allGameCards,
        ageFilter,
        subjectFilter
      ),
    };
  }

  async fetchUserInfo() {
    try {
      const userInfo = await UserInfo.get();
      // console.log(`fetchUserInfo ${userInfo}`)
      if (userInfo !== undefined) {
        const { allGameCards: gameCards } = this.state;
        gameCards.forEach((i) => (i.isLock = false));
        this.setState({
          hasUserInfo: true,
          userInfo,
          allGameCards: gameCards,
        });
      } else {
        this.setState({
          hasUserInfo: true,
        });
      }
    } catch (_e) {
      // console.error(_e)
    }
  }

  async componentDidMount() {
    await this.fetchUserInfo();
  }

  private renderGameCards() {
    const { filteredGameCards, hasUserInfo } = this.state;
    const cards = filteredGameCards;

    if (cards.length === 0) {
      return (
        <>
          <Row className="pt-3 pb-3"></Row>
          <Row className="pt-5 pb-3">
            <Col className="text-center text-danger">
              <h3>{UiStrings.noGames}</h3>
            </Col>
          </Row>
          <Row className="pt-3 pb-3">
            <Col className="text-center">
              <div>
                <img
                  className="img-fluid mb-3"
                  src={require("../images/dinosaurs/d14_h.png")}
                  alt="not found"
                />
              </div>
            </Col>
          </Row>
          <Row className="pt-3 pb-3"></Row>
        </>
      );
    }

    return (
      <Row className="pt-3">
        {cards.map((g, i) => (
          <GameCard key={i} gameInfo={g} hasUserInfo={hasUserInfo} />
        ))}
      </Row>
    );
  }

  public render() {
    const {
      allGameCards,
      filteredGameCards,
      ageFilter,
      subjectFilter,
      hasUserInfo,
      userInfo,
    } = this.state;
    const countOfGames = allGameCards.length;
    return (
      <>
        <HeaderBar />
        <Container>
          <Row className="mt-3 mb-3 text-center">
            <Col>
              <div className="display-4 ht-title">Handtoy School</div>
            </Col>
          </Row>
          <Row className="text-end">
            <Col>
              <Filter
                ages={ageFilter}
                subjects={subjectFilter}
                count={filteredGameCards.length}
                onChange={this.onFilterChange}
              />
            </Col>
          </Row>

          {this.renderGameCards()}
          <Footer />
        </Container>
      </>
    );
  }

  onFilterChange = (ages: IFilterItem[], subjects: IFilterItem[]) => {
    const { allGameCards } = this.state;

    const filteredGameCards = getFilteredGameCards(
      allGameCards,
      ages,
      subjects
    );

    this.setState({
      ageFilter: ages,
      subjectFilter: subjects,
      filteredGameCards,
    });
    saveAgeFilter(ages);
    saveSubjectFilter(subjects);
  };

  onLogin = async () => {
    await this.fetchUserInfo();
  };
}

export default MainPage;
