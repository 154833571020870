'use client'
import * as createjs from 'createjs-module'
import { Direction } from './layout-flex'
import { LayoutFlexStack } from './layout-flex-stack'
import { Point } from './point'
import { Rect } from './rect'

export class LayoutFlexGrid {
  private _container: Rect
  private _matrix: createjs.DisplayObject[][] = []
  private _itemPadding: number

  constructor(
    container: Rect,
    matrix: createjs.DisplayObject[][],
    itemPadding?: number
  ) {
    this._container = container
    this._matrix = matrix
    this._itemPadding = itemPadding ?? 0
  }

  public horizontal() {
    return true
  }

  // private getSize(p: { x: number; y: number }) {
  //   if (this.horizontal()) {
  //     return p.x
  //   } else {
  //     return p.y
  //   }
  // }

  // private getSizeFromDisplayObject(n: createjs.DisplayObject) {
  //   if (this.horizontal()) {
  //     return n.getBounds().width
  //   } else {
  //     return n.getBounds().height
  //   }
  // }

  // private totalSize() {
  //   return this._items
  //     .map((i) => this.getSizeFromDisplayObject(i))
  //     .reduce((s, i) => s + i)
  // }

  // private totalPadding() {
  //   return this._itemPadding * (this._matrix.length - 1)
  // }

  // private mapPositionToPoint(position: number) {
  //   if (this.horizontal()) {
  //     const y = this._container.origin.y + this._container.size.height() / 2
  //     return Point.xy(position, y)
  //   } else {
  //     const x = this._container.origin.x + this._container.size.width() / 2
  //     return Point.xy(x, position)
  //   }
  // }

  public layout() {
    const matrix = this._matrix
    const countOfRows = matrix.length

    if (matrix.length === 0 || matrix[0].length === 0) {
      return
    }

    for (let r = 0; r < countOfRows; ++r) {
      const row = matrix[r]
      const stackSize = this.boxSize(this._container, countOfRows, row)
      const stackOrigin = this.stackOrigin(this._container.origin, stackSize, r)
      const stackRect = new Rect(stackOrigin, stackSize)
      const stack = new LayoutFlexStack(
        Direction.horizontal,
        stackRect,
        row,
        this._itemPadding
      )
      stack.layout()
    }
  }

  private stackOrigin(origin: Point, stackSize: Point, currentRow: number) {
    if (this.horizontal()) {
      return origin.plus(Point.xy(0, stackSize.height() * currentRow))
    } else {
      return origin.plus(Point.xy(stackSize.width() * currentRow, 0))
    }
  }

  private boxSize(
    rect: Rect,
    passCount: number,
    objs: createjs.DisplayObject[]
  ) {
    if (this.horizontal()) {
      const maxHeight = objs
        .map((o) => o.getBounds().height)
        .reduce((p, i) => Math.max(p, i))
      const height = Math.min(rect.size.height() / passCount, maxHeight)
      return new Point(rect.size.width(), height)
    } else {
      return new Point(rect.size.width() / passCount, rect.size.height())
    }
  }
}
