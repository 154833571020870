import { BitmapLoader } from '../core/bitmap-loader'
import { ImageFactoryBase } from '../core/image-factory'

export class FoodImages extends ImageFactoryBase {
  static s_groups: { [id: string]: string } = {
    protein: require('../../images/food/protein.png'),
    vegetable: require('../../images/food/vegetable.png'),
    fruit: require('../../images/food/fruit.png'),
    sweet: require('../../images/food/sweet.png'),
    'fast food': require('../../images/food/fast food.png')
  }

  static s_images: { [id: string]: string } = {
    'bell pepper': require('../../images/food/bell pepper.png'),
    'bologna sausage': require('../../images/food/bologna sausage.png'),
    broccoli: require('../../images/food/broccoli.png'),
    cake: require('../../images/food/cake.png'),
    'chicken drumstick': require('../../images/food/chicken drumstick.png'),
    'chicken wing': require('../../images/food/chicken wing.png'),
    'chinese cabbage': require('../../images/food/chinese cabbage.png'),
    corn: require('../../images/food/corn.png'),
    doughnut: require('../../images/food/doughnut.png'),
    'dragon fruit': require('../../images/food/dragon fruit.png'),
    durian: require('../../images/food/durian.png'),
    'french fries': require('../../images/food/french fries.png'),
    // 'fried rice': require('../../images/food/fried rice.png'),
    hamburger: require('../../images/food/hamburger.png'),
    'hot dog': require('../../images/food/hot dog.png'),
    'ice cream': require('../../images/food/ice cream.png'),
    kiwi: require('../../images/food/kiwi.png'),
    melon: require('../../images/food/melon.png'),
    orange: require('../../images/food/orange.png'),
    pie: require('../../images/food/pie.png'),
    pizza: require('../../images/food/pizza.png'),
    pomelo: require('../../images/food/pomelo.png'),
    rambutan: require('../../images/food/rambutan.png'),
    sausage: require('../../images/food/sausage.png'),
    shrimp: require('../../images/food/shrimp.png'),
    // sushi: require('../../images/food/sushi.png'),
    'swiss roll': require('../../images/food/swiss roll.png')
  }

  public static MAP: { [id: string]: string } = {
    shrimp: 'protein',
    'bell pepper': 'vegetable',
    orange: 'fruit',
    cake: 'sweet',
    'french fries': 'fast food',

    'bologna sausage': 'protein',
    broccoli: 'vegetable',
    kiwi: 'fruit',
    doughnut: 'sweet',
    hamburger: 'fast food',

    sausage: 'protein',
    'chinese cabbage': 'vegetable',
    melon: 'fruit',
    'ice cream': 'sweet',
    'hot dog': 'fast food',

    'chicken drumstick': 'protein',
    corn: 'vegetable',
    'dragon fruit': 'fruit',
    'swiss roll': 'sweet',
    pizza: 'fast food',

    'chicken wing': 'protein',
    durian: 'fruit',
    pie: 'sweet',
    pomelo: 'fruit',
    rambutan: 'fruit'
  }

  /* IMatchImageFactory */
  public getHead(name: string): string {
    return FoodImages.s_images[name]
  }

  public getBody(name: string): string {
    return FoodImages.s_groups[FoodImages.MAP[name]]
  }

  /* IBasicImageFactory */
  public getNames() {
    return Object.keys(FoodImages.MAP)
  }

  public async getImage(name: string) {
    const img = await BitmapLoader.load(this.getHead(name))
    img.regX = img.image.width / 2
    img.regY = img.image.height / 2
    return img
  }
}
