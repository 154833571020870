'use client'
import * as createjs from 'createjs-module'
import { GameSongs } from './game-songs'

export class GameSound {
  private static s_registered = new Set<string>()

  public static play(name: string) {
    // On safari mobile, resume song on focus does not work well.
    // Let's resume the song when we play another sound.
    GameSongs.resume()

    const instance = createjs.Sound.play(name, createjs.Sound.INTERRUPT_LATE)

    return new Promise<void>((r) => {
      instance.on(
        'complete',
        () => {
          r()
        },
        instance
      )
    })
  }

  public static registerOne(key: string, path: string) {
    if (!GameSound.s_registered.has(key)) {
      try {
        createjs.Sound.registerSound(path, key)
        GameSound.s_registered.add(key)
      } catch (_e) {}
    }
  }

  public static register(audios: { [id: string]: string }) {
    createjs.Sound.alternateExtensions = ['ogg']
    Object.entries(audios).forEach(([key, path]) => {
      GameSound.registerOne(key, path)
    })
  }

  public static registerAll(audios: { [id: string]: string }[]) {
    audios.forEach((a) => {
      GameSound.register(a)
    })
  }

  public static clear(audios: { [id: string]: string }) {
    Object.entries(audios).forEach(([key, _path]) => {
      GameSound.s_registered.delete(key)
      createjs.Sound.removeSound(key, '')
    })
  }
}
