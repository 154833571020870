import { Browser, checkBrowser } from '../utils/browser'
import { DraggableTile } from './draggable-tile'
import { DropShadowFactory } from './drop-shadow-factory'
import { Point } from './point'
import { TextLabelDeprecated } from './text-label-deprecated'

// Use LetterTile
export class TextTileDeprecated extends DraggableTile {
  private static MARGIN: number = 20
  private static RADIUS: number = 0.2

  protected _text: string = ''
  protected _size: Point
  protected _includeBackground: boolean
  protected _textNode: createjs.Text
  protected _background: createjs.Shape

  constructor(
    size: Point,
    text: string = '',
    includeBackground: boolean = false,
    font: string = TextLabelDeprecated.DEFAULT_FONT
  ) {
    super(Point.ZERO, Point.ZERO, size)
    this._size = size.copy()
    this._text = text
    this._includeBackground = includeBackground

    this._background = new createjs.Shape()
    if (this._includeBackground) {
      this.addChild(this._background)
    }

    this._textNode = new createjs.Text()
    this._textNode.color = 'white'
    this._textNode.text = text.toString()

    var fontSize = Math.floor(this._size.y / 1.2)
    var w = 0
    do {
      this._textNode.font = TextLabelDeprecated.font(fontSize, font)
      w = this._textNode.getMeasuredWidth()
      fontSize -= 10
    } while (w > this._size.x)

    DropShadowFactory.create(this._textNode, 0.6)
    this.addChild(this._textNode)

    // text and background are drawn to center at 0,0
    this.regX = 0
    this.regY = 0

    this.measureTextSize()

    this.setBackground('blue')
  }

  protected measureTextSize() {
    const w = this._textNode.getMeasuredWidth()
    const h = this._textNode.getMeasuredHeight()

    this._size.x = w
    this._size.y = h

    // const rect = this._textNode.getBounds()

    // console.log(
    //   `measureText ${this._size.toString()}, rectx=${JSON.stringify(rect)}`
    // )

    this._textNode.x = -w / 2
    this._textNode.y = -h / 2

    const browser = checkBrowser()
    if (browser === Browser.Safari) {
      this._textNode.y -= this._size.y / 6
    } else if (browser === Browser.Chrome || browser === Browser.Edge) {
      this._textNode.y += this._size.y / 22
    }

    this._background.x = -w / 2
    this._background.y = -h / 2

    this.hitArea = this._background.clone()
    this.hitArea.alpha = 1
  }

  public setText(text: string) {
    this._text = text
    this._textNode.text = text
  }

  public setTextColor(color: string) {
    this._textNode.color = color
  }

  public setBackground(color: string) {
    var w = this._size.x + TextTileDeprecated.MARGIN * 2.0
    var h = this._size.y + TextTileDeprecated.MARGIN * 2.0
    var x = -TextTileDeprecated.MARGIN
    var y = -TextTileDeprecated.MARGIN
    var r = TextTileDeprecated.RADIUS * h

    this._background.graphics.clear()

    const borderColor = color
    this._background.graphics
      .setStrokeStyle(8)
      .beginStroke('#fff')
      .beginFill(borderColor)
      .drawRoundRect(x, y, w, h, r)

    this._background.alpha = this._includeBackground ? 1 : 0
  }
}
