import { GameBase, IGameConfig } from './game-base'

export interface IGameFactory {
  create(config: IGameConfig): GameBase
}

export class GameFactory<T extends GameBase> implements IGameFactory {
  private _instance: new (config: IGameConfig) => T

  constructor(instance: new (config: IGameConfig) => T) {
    this._instance = instance
  }

  public create(config: IGameConfig): GameBase {
    const game = new this._instance(config)
    return game
  }
}
