import * as React from 'react'
import LoginProfilePicture from './LoginProfilePicture'
import { Navbar, NavbarBrand } from 'reactstrap'

export default function HeaderBar() {
  return (
    <Navbar expand='md' style={{ background: '#A42D59' }}>
      <NavbarBrand href='/' className='mr-auto'>
        <img src={require('../images/logo.png')} height='40' alt='logo' />
      </NavbarBrand>
      <LoginProfilePicture />
    </Navbar>
  )
}
