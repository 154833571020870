import { GameSound } from './game-sound'
import { ISoundFactory } from '../core/sound-factory'
import { Language } from './language'
import { SoundEffects } from './sound-effect'

export class AnimalSounds implements ISoundFactory {
  private static FOLDER_EN = 'sounds/'
  private static FOLDER_TH = 'sounds/thai/'

  public static ANIMALS: { [id: string]: string } = {
    bear: require(`../../${AnimalSounds.FOLDER_EN}bear.mp3`),
    cat: require(`../../${AnimalSounds.FOLDER_EN}cat.mp3`),
    dog: require(`../../${AnimalSounds.FOLDER_EN}dog.mp3`),
    elephant: require(`../../${AnimalSounds.FOLDER_EN}elephant.mp3`),
    fox: require(`../../${AnimalSounds.FOLDER_EN}fox.mp3`),
    goat: require(`../../${AnimalSounds.FOLDER_EN}goat.mp3`),
    hippopotamus: require(`../../${AnimalSounds.FOLDER_EN}hippopotamus.mp3`),
    lion: require(`../../${AnimalSounds.FOLDER_EN}lion.mp3`),
    monkey: require(`../../${AnimalSounds.FOLDER_EN}monkey.mp3`),
    rabbit: require(`../../${AnimalSounds.FOLDER_EN}rabbit.mp3`)
  }

  /* ISoundFactory */
  public register(_language: Language) {
    GameSound.registerAll([AnimalSounds.ANIMALS, SoundEffects.EFFECTS])
  }
}
