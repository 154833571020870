'use client'
import * as createjs from 'createjs-module'
import { GameSound } from '../resource/game-sound'
import { IBasicImageFactory } from './image-factory'
import { ISoundFactory } from './sound-factory'
import { Language } from '../resource/language'
import { Point } from './point'

export type GameCallback = () => void

export interface IGameConfig {
  pageIndex: number
  imageFactory: IBasicImageFactory
  soundFactory: ISoundFactory
  language: Language
  landscape: boolean
}

export class GameBase extends createjs.Container {
  public static SHORTER = 800
  public static LONGER = 1000
  public static LONGEST = 1200

  public static WINDELAY = 2000
  public static INTROSOUNDDELAY = 1000

  protected _names: string[]
  protected _pageIndex: number
  protected _landscape: boolean
  protected _imageFactory: IBasicImageFactory
  protected _soundFactory: ISoundFactory
  protected _language: Language

  protected _callback: GameCallback

  private _willUnmount: boolean = false

  constructor(config: IGameConfig) {
    super()
    this._names = config.imageFactory.getNames()
    this._imageFactory = config.imageFactory
    this._soundFactory = config.soundFactory
    this._language = config.language
    this._pageIndex = config.pageIndex % this._names.length
    this._landscape = config.landscape
    config.soundFactory.register(config.language)
    this._callback = () => {}
  }

  public static layoutSize(landscape: boolean) {
    if (landscape) {
      return new Point(GameBase.LONGER, GameBase.SHORTER)
    } else {
      return new Point(GameBase.SHORTER, GameBase.LONGER)
    }
  }

  public setCallback(callback: GameCallback) {
    this._callback = callback
  }

  public async load() {}

  public layout(_landscape: boolean) {}

  public layoutSize(landscape: boolean) {
    this._landscape = landscape
    return GameBase.layoutSize(landscape)
  }

  public count() {
    return this._names.length
  }

  public willUnmount() {
    this._callback = () => {}
    this._willUnmount = true
  }

  protected canPlaySound() {
    return this.stage !== null && !this._willUnmount
  }

  protected async playSound(name: string) {
    if (this.canPlaySound()) {
      await GameSound.play(name)
    }
  }
}
