'use client'
import * as createjs from 'createjs-module'
import { BitmapLoader } from './bitmap-loader'
import { Color } from './color'
import { LayoutFlexGrid } from './layout-flex-grid'
import { Point } from './point'
import { Rect } from './rect'
import { LetterTile } from './letter-tile'

export class BitmapGrid extends createjs.Container {
  private _size: Point
  private _count: number

  private _bitmapSrc: createjs.Bitmap | undefined
  private _bitmapScaled: createjs.Bitmap | undefined

  private _label: LetterTile

  constructor(bitmap: createjs.Bitmap, size: Point, count: number) {
    super()
    this._bitmapSrc = bitmap
    this._size = size
    this._count = count

    this.setBounds(0, 0, size.width(), size.height())
    this.regX = size.width() / 2
    this.regY = size.height() / 2

    const labelSize = new Point(size.width(), 80)
    this._label = new LetterTile(labelSize, labelSize, count.toString(), false, false)
    this._label.setTextColor(Color.randomStrongTextColor())
    this._label.regX = labelSize.width() / 2
    this._label.regY = labelSize.height() / 2
    this.addChild(this._label)
  }

  public layout(landscape: boolean) {
    this.removeAllChildren()

    const rowColumn = this.calculateNumberOfRowColumn(landscape)

    const scaleFactor = this.calculateScaleFactor(rowColumn)
    this._bitmapScaled = BitmapLoader.scale(this._bitmapSrc!, scaleFactor)

    const matrix = this.calculateMatrix(rowColumn, this._count)

    const rect = new Rect(Point.ZERO, this._size)

    // console.log(`TileGrid layout ${matrix.length}, ${rect.toString()}`)
    new LayoutFlexGrid(rect, matrix, 10).layout()

    this.addChild(this._label)
    this._label.x = this._size.width() / 2
    this._label.y = this._size.height() + 60
  }

  private calculateNumberOfRowColumn(landscape: boolean) {
    const n = this._count
    var rows = 1
    var columns = 1

    if (n > 9) {
      rows = 3
      columns = 4
    } else if (n > 6) {
      rows = 3
      columns = 3
    } else if (n > 4) {
      rows = 2
      columns = 3
    } else if (n > 2) {
      rows = 2
      columns = 2
    } else if (n > 1) {
      rows = 1
      columns = 2
    } else if (n === 1) {
      rows = 1
      columns = 1
    }

    return landscape ? new Point(rows, columns) : new Point(columns, rows)
  }

  private calculateMatrix(rowColumn: Point, count: number) {
    const matrix: createjs.Bitmap[][] = []

    let i = 0
    for (let r = 0; r < rowColumn.r(); ++r) {
      const row: createjs.Bitmap[] = []
      for (let c = 0; c < rowColumn.c(); ++c) {
        if (i < count) {
          const clone = this._bitmapScaled!.clone()
          this.addChild(clone)
          row.push(clone)
        }
        i += 1
      }
      matrix.push(row)
    }

    return matrix
  }

  private calculateScaleFactor(rowColumn: Point) {
    const bitmapSrc = this._bitmapSrc
    if (!bitmapSrc) {
      return 1
    }

    return Math.min(
      this._size.width() / (bitmapSrc.image.width * rowColumn.c()),
      this._size.height() / (bitmapSrc.image.height * rowColumn.r()))
  }
}
