'use client'
import * as createjs from 'createjs-module'
import { BitmapLoader } from '../core/bitmap-loader'
import { Point } from '../core/point'
import { NumberImages } from './number-images'
import NumberCartoon from '../core/number-cartoon'

export class NumberWordImages extends NumberImages {
  static MAP: { [id: string]: string } = {
    one: '1',
    two: '2',
    three: '3',
    four: '4',
    five: '5',
    six: '6',
    seven: '7',
    eight: '8',
    nine: '9',
    ten: '10',
    eleven: '11',
    twelve: '12'
  }

  private mapNameToNumber(name: string) {
    return NumberWordImages.MAP[name]
  }

  /* IMatchImageFactory */
  public getHead(name: string): string {
    return NumberImages.IMAGES[this.mapNameToNumber(name)]
  }

  public getBody(name: string): string {
    return NumberImages.IMAGES[this.mapNameToNumber(name)]
  }

  /* IBasicImageFactory */
  public getNames() {
    return Object.keys(NumberWordImages.MAP)
  }

  public async getImage(name: string) {
    const img = await BitmapLoader.load(this.getHead(name))
    const padding = 10
    const gridSize = Point.xy(240, 240)
    const lableSize = Point.xy(200, 220)

    const grid = new NumberCartoon(parseInt(this.mapNameToNumber(name)), img, gridSize, lableSize)
    grid.layout(true)
    const bounds = grid.getBounds()
    grid.cache(bounds.x - padding, bounds.y - padding, bounds.width + padding * 2, bounds.height + padding * 2)

    const bitmap = new createjs.Bitmap(grid.cacheCanvas)
    bitmap.regX = bitmap.image.width / 2
    bitmap.regY = bitmap.image.height / 2
    bitmap.setBounds(0, 0, bitmap.image.width, bitmap.image.height)

    return bitmap
  }
}
