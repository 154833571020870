'use client'
import * as createjs from 'createjs-module'
import { BitmapGrid } from './bitmap-grid'
import { Point } from './point'
import { LetterTile } from './letter-tile'
import { LayoutFlexStack } from './layout-flex-stack'
import { Direction } from './layout-flex'
import { Rect } from './rect'
import { Color } from './color'

export default class NumberCartoon extends createjs.Container {

  private _bitmapGrid: BitmapGrid
  private _label: LetterTile

  private _gridSize: Point
  private _labelSize: Point

  constructor(count: number, bitmap: createjs.Bitmap, gridSize: Point, labelSize: Point) {
    super()

    this._gridSize = gridSize
    this._labelSize = labelSize

    this._bitmapGrid = new BitmapGrid(bitmap, gridSize, count)

    this._label = new LetterTile(labelSize, labelSize, count.toString(), false, false)
    this._label.setTextColor(Color.randomStrongTextColor())

    this.addChild(this._bitmapGrid)
    this.addChild(this._label)
  }

  public layout(landscape: boolean) {
    this._bitmapGrid.layout(landscape)

    const rectSize = Point.xy(this._gridSize.w + this._labelSize.w, Math.max(this._gridSize.h, this._labelSize.h))
    const rect = new Rect(rectSize.divide(-2), rectSize)
    new LayoutFlexStack(Direction.horizontal, rect, [this._label, this._bitmapGrid], 0).layout()

    this.regX = rect.size.x / 2
    this.regY = rect.size.y / 2
    this.setBounds(rect.origin.x, rect.origin.h, rect.size.w, rect.size.h)
  }
}