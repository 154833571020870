import AlertModal from "./AlertModal";
import LoginProfilePicture from "./LoginProfilePicture";
import TimeoutOption from "./TimeoutOption";
import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { GameNextLevel } from "../games/utils/game-next-level";
import "./GamePageOption.css";

interface IGamePageOptionProps {
  gameId: string;
  pageTitle: string;
  pageIndex: number;
  pagesCount: number;
  onBack: () => void;
  onNext: () => void;
}

interface IGamePageOptionState {}

class GamePageOption extends React.Component<
  IGamePageOptionProps,
  IGamePageOptionState
> {
  private alertIntro: React.RefObject<AlertModal>;

  constructor(props: any) {
    super(props);

    this.alertIntro = React.createRef<AlertModal>();
  }

  public async show() {
    try {
      const alertIntro = this.alertIntro.current;
      if (alertIntro) {
        await alertIntro.show();
      }
    } catch (e) {
      console.log(e);
    }
  }

  renderAlertBody() {
    const { pagesCount } = this.props;

    const disableBack = pagesCount === 0;
    const diableForward = pagesCount === 0;

    return (
      <Container fluid className="text-center text-small">
        <Row className="mt-2">
          <Col>
            <Button
              size="sm"
              color="primary"
              onClick={this.props.onBack}
              disabled={disableBack}
            >
              {"\u140a"}
            </Button>{" "}
            <Button size="sm" color="primary" onClick={this.onRestart}>
              Restart
            </Button>{" "}
            <Button
              size="sm"
              color="primary"
              onClick={this.props.onNext}
              disabled={diableForward}
            >
              {"\u1405"}
            </Button>{" "}
          </Col>
        </Row>

        <Row className="mt-2">
          <Col>
            <a className="btn btn-primary btn-sm" href="/">
              Go Home
            </a>
          </Col>
        </Row>

        <Row className="mt-4">
          <TimeoutOption />
        </Row>

        <Row className="mt-4">
          <Col>
            <div>
              <small>
                Designed and developed by handtoy.com, All rights reserved
              </small>
            </div>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <a href="https://www.handtoy.com">
              <img
                height="25px"
                alt="by handtoy"
                src={require("../images/logo.png")}
              />
            </a>
          </Col>
        </Row>
      </Container>
    );
  }

  render() {
    const { pageTitle, pageIndex, pagesCount } = this.props;

    const title =
      pagesCount === 0
        ? pageTitle
        : `${pageTitle} (${pageIndex + 1}/${pagesCount})`;

    return (
      <div>
        <div className="top-right">
          <Button size="sm" color="primary" onClick={this.onOptions}>
            <img
              src={require("../images/icons/gear.png")}
              alt="gear"
              height={25}
            ></img>
          </Button>
        </div>
        <AlertModal
          ref={this.alertIntro}
          header={title}
          body={this.renderAlertBody()}
          leftButton="Close"
        />
      </div>
    );
  }

  private onOptions = async () => {
    await this.show();
  };

  private onRestart = () => {
    const { gameId } = this.props;
    GameNextLevel.save(gameId, 0);

    window.location.reload();
  };
}

export default GamePageOption;
