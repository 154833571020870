export class InkData {
  public static ABC: { [id: string]: { x: number; y: number }[] } = {
    A: [
      { x: -164, y: 137 },
      { x: -114, y: 10 },
      { x: -23, y: -239 },
      { x: 15, y: -240 },
      { x: 103, y: 7 },
      { x: 157, y: 137 },
      { x: -61, y: 12 },
      { x: 51, y: 12 }
    ],
    B: [
      { x: -127, y: -236 },
      { x: -128, y: -92 },
      { x: -130, y: -25 },
      { x: -130, y: 146 },
      { x: -85, y: -239 },
      { x: 46, y: -235 },
      { x: 114, y: -155 },
      { x: 46, y: -63 },
      { x: -78, y: -58 },
      { x: 138, y: 53 },
      { x: 55, y: 138 },
      { x: -78, y: 141 }
    ],
    C: [
      { x: 146, y: -166 },
      { x: 7, y: -248 },
      { x: -151, y: -123 },
      { x: -149, y: 29 },
      { x: 10, y: 148 },
      { x: 10, y: 148 },
      { x: 157, y: 48 }
    ],
    D: [
      { x: -143, y: -236 },
      { x: -144, y: -48 },
      { x: -144, y: 140 },
      { x: -94, y: -243 },
      { x: 84, y: -218 },
      { x: 156, y: -40 },
      { x: 70, y: 124 },
      { x: -100, y: 142 }
    ],
    E: [
      { x: -125, y: -237 },
      { x: -125, y: -58 },
      { x: -125, y: 138 },
      { x: -92, y: -240 },
      { x: 136, y: -238 },
      { x: -74, y: -57 },
      { x: 113, y: -57 },
      { x: -73, y: 141 },
      { x: 134, y: 139 }
    ],
    F: [
      { x: -104, y: -237 },
      { x: -106, y: -57 },
      { x: -106, y: 140 },
      { x: -53, y: -238 },
      { x: 126, y: -238 },
      { x: -52, y: -56 },
      { x: 93, y: -55 }
    ],
    G: [
      { x: 158, y: -163 },
      { x: 7, y: -248 },
      { x: -160, y: -119 },
      { x: -138, y: 75 },
      { x: 20, y: 147 },
      { x: 168, y: 91 },
      { x: 167, y: 6 },
      { x: 165, y: -32 },
      { x: 44, y: -31 }
    ],
    H: [
      { x: -138, y: -235 },
      { x: -141, y: -65 },
      { x: -141, y: 139 },
      { x: 141, y: -242 },
      { x: 138, y: -63 },
      { x: 138, y: 139 },
      { x: -91, y: -66 },
      { x: 93, y: -65 }
    ],
    I: [
      { x: 0, y: -240 },
      { x: -1, y: -54 },
      { x: 0, y: 136 }
    ],
    J: [
      { x: 74, y: -235 },
      { x: 74, y: -82 },
      { x: 74, y: 61 },
      { x: -12, y: 144 },
      { x: -106, y: 62 }
    ],
    K: [
      { x: -129, y: -239 },
      { x: -130, y: -58 },
      { x: -130, y: 144 },
      { x: -92, y: -26 },
      { x: 20, y: -138 },
      { x: 127, y: -239 },
      { x: -9, y: -71 },
      { x: 58, y: 23 },
      { x: 133, y: 128 }
    ],
    L: [
      { x: -97, y: -237 },
      { x: -95, y: -72 },
      { x: -95, y: -72 },
      { x: -92, y: 135 },
      { x: -55, y: 138 },
      { x: 114, y: 138 }
    ],
    M: [
      { x: -180, y: 145 },
      { x: -180, y: 144 },
      { x: -179, y: -37 },
      { x: -178, y: -235 },
      { x: -137, y: -237 },
      { x: -74, y: -45 },
      { x: -7, y: 140 },
      { x: 7, y: 141 },
      { x: 76, y: -47 },
      { x: 147, y: -238 },
      { x: 179, y: -240 },
      { x: 178, y: -50 },
      { x: 178, y: -50 },
      { x: 176, y: 141 }
    ],
    N: [
      { x: -146, y: 140 },
      { x: -145, y: -52 },
      { x: -142, y: -231 },
      { x: -105, y: -203 },
      { x: 0, y: -49 },
      { x: 94, y: 87 },
      { x: 139, y: 136 },
      { x: 142, y: -56 },
      { x: 141, y: -235 }
    ],
    O: [
      { x: 0, y: -248 },
      { x: -141, y: -181 },
      { x: -177, y: -49 },
      { x: -125, y: 97 },
      { x: 9, y: 149 },
      { x: 143, y: 72 },
      { x: 176, y: -57 },
      { x: 115, y: -204 }
    ],
    P: [
      { x: -123, y: -235 },
      { x: -125, y: -69 },
      { x: -130, y: 136 },
      { x: -79, y: -238 },
      { x: 60, y: -237 },
      { x: 143, y: -141 },
      { x: 65, y: -41 },
      { x: -74, y: -37 }
    ],
    Q: [
      { x: -7, y: -246 },
      { x: -142, y: -181 },
      { x: -178, y: -43 },
      { x: -124, y: 98 },
      { x: 1, y: 147 },
      { x: 146, y: 61 },
      { x: 174, y: -61 },
      { x: 117, y: -198 },
      { x: 34, y: 73 },
      { x: 101, y: 118 },
      { x: 181, y: 167 }
    ],
    R: [
      { x: -141, y: -237 },
      { x: -141, y: -52 },
      { x: -143, y: 142 },
      { x: -93, y: -240 },
      { x: 78, y: -238 },
      { x: 144, y: -146 },
      { x: 84, y: -63 },
      { x: -88, y: -53 },
      { x: 69, y: 10 },
      { x: 154, y: 136 }
    ],
    S: [
      { x: 122, y: -170 },
      { x: -1, y: -249 },
      { x: -128, y: -155 },
      { x: -58, y: -77 },
      { x: 77, y: -36 },
      { x: 141, y: 54 },
      { x: 9, y: 148 },
      { x: -142, y: 50 }
    ],
    T: [
      { x: -143, y: -241 },
      { x: -1, y: -241 },
      { x: 150, y: -240 },
      { x: 1, y: -204 },
      { x: 0, y: -39 },
      { x: 1, y: 139 }
    ],
    U: [
      { x: -143, y: -232 },
      { x: -143, y: 17 },
      { x: -95, y: 122 },
      { x: 9, y: 146 },
      { x: 111, y: 112 },
      { x: 141, y: 9 },
      { x: 139, y: -224 }
    ],
    V: [
      { x: -157, y: -236 },
      { x: -90, y: -53 },
      { x: -20, y: 136 },
      { x: 13, y: 138 },
      { x: 87, y: -54 },
      { x: 154, y: -235 }
    ],
    W: [
      { x: -241, y: -238 },
      { x: -197, y: -60 },
      { x: -137, y: 138 },
      { x: -66, y: -62 },
      { x: -66, y: -62 },
      { x: -1, y: -247 },
      { x: 64, y: -66 },
      { x: 130, y: 140 },
      { x: 194, y: -64 },
      { x: 242, y: -243 }
    ],
    X: [
      { x: -124, y: -235 },
      { x: 3, y: -60 },
      { x: 141, y: 139 },
      { x: 136, y: -240 },
      { x: 27, y: -94 },
      { x: -30, y: -23 },
      { x: -147, y: 141 }
    ],
    Y: [
      { x: -148, y: -237 },
      { x: -83, y: -141 },
      { x: -4, y: -28 },
      { x: -7, y: 136 },
      { x: 144, y: -239 },
      { x: 71, y: -136 }
    ],
    Z: [
      { x: -120, y: -239 },
      { x: 127, y: -237 },
      { x: 4, y: -60 },
      { x: -140, y: 138 },
      { x: 131, y: 141 }
    ]
  }

  public static abc: { [id: string]: { x: number; y: number }[] } = {
    a: [
      { x: -104, y: -78 },
      { x: 4, y: -130 },
      { x: 94, y: -80 },
      { x: 98, y: 34 },
      { x: 103, y: 140 },
      { x: 12, y: 0 },
      { x: -120, y: 82 },
      { x: -23, y: 152 }
    ],
    b: [
      { x: -104, y: -226 },
      { x: -104, y: 0 },
      { x: -103, y: 135 },
      { x: -60, y: -98 },
      { x: 13, y: -130 },
      { x: 116, y: 17 },
      { x: 10, y: 150 },
      { x: -62, y: 124 }
    ],
    c: [
      { x: 105, y: -77 },
      { x: 12, y: -129 },
      { x: -94, y: -35 },
      { x: -91, y: 73 },
      { x: 10, y: 150 },
      { x: 110, y: 81 }
    ],
    d: [
      { x: 97, y: -238 },
      { x: 99, y: -6 },
      { x: 99, y: 144 },
      { x: 43, y: -112 },
      { x: -77, y: -111 },
      { x: -119, y: 13 },
      { x: -65, y: 134 },
      { x: 49, y: 129 }
    ],
    e: [
      { x: -67, y: 1 },
      { x: 18, y: 2 },
      { x: 113, y: 0 },
      { x: -1, y: -130 },
      { x: -112, y: 58 },
      { x: 1, y: 149 },
      { x: 102, y: 91 }
    ],
    f: [
      { x: 72, y: -247 },
      { x: 5, y: -228 },
      { x: -8, y: -127 },
      { x: -5, y: 133 },
      { x: -62, y: -128 },
      { x: 62, y: -128 }
    ],
    g: [
      { x: 100, y: -118 },
      { x: 103, y: 22 },
      { x: 98, y: 185 },
      { x: -14, y: 267 },
      { x: -111, y: 215 },
      { x: -13, y: -131 },
      { x: -121, y: 1 },
      { x: -4, y: 143 }
    ],
    h: [
      { x: -103, y: -236 },
      { x: -102, y: -33 },
      { x: -104, y: 138 },
      { x: -57, y: -105 },
      { x: 19, y: -129 },
      { x: 99, y: -50 },
      { x: 99, y: 134 }
    ],
    i: [
      { x: -1, y: -248 },
      { x: -1, y: -219 },
      { x: -2, y: -128 },
      { x: -1, y: 12 },
      { x: -2, y: 146 }
    ],
    j: [
      { x: -2, y: -116 },
      { x: -2, y: 58 },
      { x: -4, y: 210 },
      { x: -30, y: 262 },
      { x: -73, y: 265 },
      { x: -3, y: -249 },
      { x: -3, y: -217 }
    ],
    k: [
      { x: -86, y: -236 },
      { x: -87, y: -42 },
      { x: -88, y: 143 },
      { x: -54, y: 6 },
      { x: 26, y: -74 },
      { x: 83, y: -125 },
      { x: 1, y: -9 },
      { x: 96, y: 139 }
    ],
    l: [
      { x: -3, y: -243 },
      { x: -1, y: -110 },
      { x: -1, y: 18 },
      { x: -3, y: 139 }
    ],
    m: [
      { x: -187, y: -119 },
      { x: -187, y: 29 },
      { x: -184, y: 139 },
      { x: -144, y: -100 },
      { x: -67, y: -130 },
      { x: 0, y: -71 },
      { x: 1, y: 142 },
      { x: 49, y: -109 },
      { x: 125, y: -129 },
      { x: 185, y: -66 },
      { x: 186, y: 140 }
    ],
    n: [
      { x: -104, y: -121 },
      { x: -101, y: 31 },
      { x: -102, y: 140 },
      { x: -57, y: -100 },
      { x: 23, y: -129 },
      { x: 100, y: -62 },
      { x: 99, y: 142 }
    ],
    o: [
      { x: -5, y: -130 },
      { x: -109, y: -60 },
      { x: -106, y: 82 },
      { x: 2, y: 151 },
      { x: 111, y: 72 },
      { x: 97, y: -72 }
    ],
    p: [
      { x: -104, y: -120 },
      { x: -101, y: 79 },
      { x: -103, y: 262 },
      { x: -64, y: -94 },
      { x: 12, y: -133 },
      { x: 117, y: 7 },
      { x: 8, y: 149 },
      { x: -60, y: 124 }
    ],
    q: [
      { x: 99, y: -119 },
      { x: 100, y: 56 },
      { x: 98, y: 254 },
      { x: 55, y: -101 },
      { x: -57, y: -124 },
      { x: -119, y: 9 },
      { x: -57, y: 137 },
      { x: 56, y: 124 }
    ],
    r: [
      { x: -38, y: -124 },
      { x: -34, y: 25 },
      { x: -34, y: 25 },
      { x: -36, y: 140 },
      { x: 6, y: -100 },
      { x: 44, y: -124 },
      { x: 73, y: -121 }
    ],
    s: [
      { x: 80, y: -87 },
      { x: -11, y: -132 },
      { x: -94, y: -60 },
      { x: -3, y: 6 },
      { x: 97, y: 81 },
      { x: 0, y: 149 },
      { x: -99, y: 88 }
    ],
    t: [
      { x: -8, y: -205 },
      { x: -10, y: -10 },
      { x: -4, y: 124 },
      { x: 40, y: 145 },
      { x: -56, y: -127 },
      { x: 55, y: -127 }
    ],
    u: [
      { x: -104, y: -119 },
      { x: -100, y: 91 },
      { x: -57, y: 144 },
      { x: 34, y: 136 },
      { x: 97, y: -124 },
      { x: 99, y: 144 },
      { x: 98, y: -1 }
    ],
    v: [
      { x: -107, y: -121 },
      { x: -2, y: 142 },
      { x: 109, y: -127 }
    ],
    w: [
      { x: -181, y: -118 },
      { x: -95, y: 144 },
      { x: -2, y: -118 },
      { x: 88, y: 141 },
      { x: 179, y: -123 }
    ],
    x: [
      { x: -90, y: -124 },
      { x: -1, y: 2 },
      { x: 101, y: 145 },
      { x: 92, y: -124 },
      { x: -102, y: 143 }
    ],
    y: [
      { x: -104, y: -120 },
      { x: -15, y: 118 },
      { x: 109, y: -121 },
      { x: 9, y: 139 },
      { x: -35, y: 244 },
      { x: -101, y: 266 }
    ],
    z: [
      { x: -102, y: -127 },
      { x: 95, y: -124 },
      { x: -113, y: 140 },
      { x: 104, y: 143 }
    ]
  }

  public static number: { [id: string]: { x: number; y: number }[] } = {
    1: [
      { x: -77, y: -143 },
      { x: -61, y: -152 },
      { x: -45, y: -165 },
      { x: -26, y: -176 },
      { x: -13, y: -185 },
      { x: 5, y: -197 },
      { x: 25, y: -206 },
      { x: 28, y: -183 },
      { x: 28, y: -160 },
      { x: 28, y: -139 },
      { x: 28, y: -116 },
      { x: 28, y: -96 },
      { x: 30, y: -72 },
      { x: 27, y: -53 },
      { x: 24, y: -33 },
      { x: 24, y: -10 },
      { x: 22, y: 10 },
      { x: 22, y: 29 },
      { x: 22, y: 51 },
      { x: 22, y: 71 },
      { x: 23, y: 95 },
      { x: 23, y: 118 },
      { x: 23, y: 137 },
      { x: 23, y: 153 },
      { x: 23, y: 153 }
    ],
    2: [
      { x: -106, y: -154 },
      { x: -91, y: -173 },
      { x: -67, y: -191 },
      { x: -44, y: -204 },
      { x: -16, y: -214 },
      { x: 19, y: -212 },
      { x: 47, y: -199 },
      { x: 69, y: -180 },
      { x: 84, y: -159 },
      { x: 94, y: -129 },
      { x: 93, y: -98 },
      { x: 80, y: -69 },
      { x: 61, y: -37 },
      { x: 43, y: -10 },
      { x: 21, y: 16 },
      { x: -5, y: 49 },
      { x: -34, y: 81 },
      { x: -64, y: 118 },
      { x: -88, y: 146 },
      { x: -51, y: 146 },
      { x: -11, y: 145 },
      { x: 30, y: 145 },
      { x: 67, y: 144 },
      { x: 98, y: 145 }
    ],
    3: [
      { x: -104, y: -207 },
      { x: -64, y: -208 },
      { x: -15, y: -208 },
      { x: 27, y: -205 },
      { x: 26, y: -205 },
      { x: 67, y: -208 },
      { x: 52, y: -168 },
      { x: 24, y: -146 },
      { x: 2, y: -117 },
      { x: -22, y: -87 },
      { x: -46, y: -57 },
      { x: -9, y: -60 },
      { x: 33, y: -55 },
      { x: 62, y: -32 },
      { x: 83, y: -4 },
      { x: 89, y: 29 },
      { x: 90, y: 68 },
      { x: 76, y: 102 },
      { x: 53, y: 132 },
      { x: 15, y: 147 },
      { x: -28, y: 153 },
      { x: -63, y: 144 },
      { x: -93, y: 132 }
    ],
    4: [
      { x: 64, y: -198 },
      { x: 26, y: -157 },
      { x: -17, y: -109 },
      { x: -55, y: -64 },
      { x: -87, y: -21 },
      { x: -118, y: 26 },
      { x: 69, y: -161 },
      { x: 69, y: -125 },
      { x: 67, y: -85 },
      { x: 68, y: -43 },
      { x: 68, y: 2 },
      { x: 69, y: 43 },
      { x: 69, y: 83 },
      { x: 68, y: 137 },
      { x: -79, y: 29 },
      { x: -38, y: 29 },
      { x: -9, y: 29 },
      { x: 30, y: 28 },
      { x: 106, y: 29 },
      { x: 125, y: 29 }
    ],
    5: [
      { x: 86, y: -210 },
      { x: 51, y: -209 },
      { x: 4, y: -206 },
      { x: -44, y: -207 },
      { x: -79, y: -204 },
      { x: -79, y: -204 },
      { x: -94, y: -169 },
      { x: -97, y: -124 },
      { x: -102, y: -86 },
      { x: -103, y: -55 },
      { x: -64, y: -59 },
      { x: -26, y: -69 },
      { x: 16, y: -68 },
      { x: 16, y: -68 },
      { x: 54, y: -49 },
      { x: 84, y: -6 },
      { x: 93, y: 38 },
      { x: 81, y: 80 },
      { x: 56, y: 118 },
      { x: 16, y: 143 },
      { x: -36, y: 152 },
      { x: -96, y: 133 }
    ],
    6: [
      { x: 46, y: -206 },
      { x: 4, y: -188 },
      { x: -36, y: -154 },
      { x: -67, y: -118 },
      { x: -87, y: -83 },
      { x: -100, y: -30 },
      { x: -104, y: 9 },
      { x: -101, y: 54 },
      { x: -88, y: 98 },
      { x: -57, y: 133 },
      { x: -12, y: 148 },
      { x: 40, y: 146 },
      { x: 40, y: 146 },
      { x: 80, y: 115 },
      { x: 100, y: 77 },
      { x: 102, y: 22 },
      { x: 77, y: -23 },
      { x: 40, y: -49 },
      { x: -12, y: -55 },
      { x: -52, y: -38 }
    ],
    7: [
      { x: -96, y: -206 },
      { x: -60, y: -209 },
      { x: -18, y: -209 },
      { x: 25, y: -206 },
      { x: 65, y: -208 },
      { x: 102, y: -205 },
      { x: 87, y: -156 },
      { x: 69, y: -123 },
      { x: 58, y: -88 },
      { x: 39, y: -50 },
      { x: 22, y: -14 },
      { x: 4, y: 28 },
      { x: -16, y: 63 },
      { x: -27, y: 97 },
      { x: -27, y: 97 },
      { x: -47, y: 141 }
    ],
    8: [
      { x: -2, y: -206 },
      { x: -52, y: -194 },
      { x: -83, y: -159 },
      { x: -92, y: -118 },
      { x: -72, y: -76 },
      { x: -40, y: -44 },
      { x: -40, y: -44 },
      { x: 13, y: -35 },
      { x: 62, y: -14 },
      { x: 92, y: 17 },
      { x: 98, y: 71 },
      { x: 72, y: 116 },
      { x: 72, y: 117 },
      { x: 15, y: 143 },
      { x: 15, y: 143 },
      { x: -60, y: 131 },
      { x: -98, y: 88 },
      { x: -107, y: 47 },
      { x: -88, y: 5 },
      { x: -52, y: -25 },
      { x: 2, y: -41 },
      { x: 63, y: -61 },
      { x: 90, y: -93 },
      { x: 95, y: -142 },
      { x: 70, y: -177 },
      { x: 34, y: -203 }
    ],
    9: [
      { x: 100, y: -126 },
      { x: 80, y: -171 },
      { x: 44, y: -202 },
      { x: -15, y: -215 },
      { x: -69, y: -186 },
      { x: -101, y: -144 },
      { x: -101, y: -144 },
      { x: -101, y: -92 },
      { x: -76, y: -44 },
      { x: -38, y: -12 },
      { x: -38, y: -12 },
      { x: 8, y: -9 },
      { x: 62, y: -30 },
      { x: 97, y: -74 },
      { x: 97, y: -74 },
      { x: 101, y: -42 },
      { x: 86, y: 17 },
      { x: 64, y: 63 },
      { x: 30, y: 102 },
      { x: 3, y: 118 },
      { x: -45, y: 142 }
    ],
    10: [
      { x: -253, y: -152 },
      { x: -219, y: -169 },
      { x: -186, y: -188 },
      { x: -156, y: -203 },
      { x: -158, y: -125 },
      { x: -156, y: -55 },
      { x: -159, y: 4 },
      { x: -160, y: 63 },
      { x: -160, y: 63 },
      { x: -159, y: 117 },
      { x: 105, y: -211 },
      { x: 48, y: -192 },
      { x: 9, y: -146 },
      { x: -8, y: -82 },
      { x: -14, y: -19 },
      { x: 4, y: 53 },
      { x: 28, y: 110 },
      { x: 28, y: 110 },
      { x: 70, y: 136 },
      { x: 117, y: 149 },
      { x: 184, y: 123 },
      { x: 214, y: 78 },
      { x: 229, y: 18 },
      { x: 229, y: 18 },
      { x: 230, y: -49 },
      { x: 220, y: -108 },
      { x: 197, y: -160 },
      { x: 153, y: -201 }
    ],
    11: [
      { x: -185, y: -147 },
      { x: -185, y: -147 },
      { x: -151, y: -166 },
      { x: -117, y: -186 },
      { x: -84, y: -197 },
      { x: -85, y: -133 },
      { x: -86, y: -73 },
      { x: -87, y: -73 },
      { x: -86, y: -3 },
      { x: -87, y: 59 },
      { x: -87, y: 134 },
      { x: 40, y: -147 },
      { x: 72, y: -170 },
      { x: 121, y: -200 },
      { x: 135, y: -182 },
      { x: 135, y: -142 },
      { x: 137, y: -75 },
      { x: 137, y: -12 },
      { x: 136, y: 54 },
      { x: 134, y: 137 }
    ],
    12: [
      { x: -230, y: -150 },
      { x: -196, y: -167 },
      { x: -160, y: -187 },
      { x: -134, y: -196 },
      { x: -136, y: -121 },
      { x: -137, y: -57 },
      { x: -137, y: 10 },
      { x: -136, y: 63 },
      { x: -136, y: 107 },
      { x: -137, y: 140 },
      { x: 15, y: -165 },
      { x: 57, y: -195 },
      { x: 110, y: -208 },
      { x: 167, y: -194 },
      { x: 200, y: -152 },
      { x: 203, y: -99 },
      { x: 184, y: -53 },
      { x: 158, y: -13 },
      { x: 123, y: 25 },
      { x: 87, y: 67 },
      { x: 53, y: 102 },
      { x: 26, y: 142 },
      { x: 66, y: 146 },
      { x: 128, y: 146 },
      { x: 193, y: 146 }
    ]
  }
}
