import { BitmapLoader } from '../core/bitmap-loader'
import { ImageFactoryBase } from '../core/image-factory'

export class VehicleImages extends ImageFactoryBase {
  static s_backgrounds: { [id: string]: string } = {
    train: require('../../images/vehicles/bc01.png'),
    bulldozer: require('../../images/vehicles/bc02.png'),
    bus: require('../../images/vehicles/bc03.png'),
    // spaceship: require('../../images/vehicles/bc04.png'),
    // boat: require('../../images/vehicles/bc05.png'),

    truck: require('../../images/vehicles/bc06.png'),
    // 'ice cream truck': require('../../images/vehicles/bc07.png'),
    'school bus': require('../../images/vehicles/bc08.png'),
    'fire truck': require('../../images/vehicles/bc09.png'),
    ambulance: require('../../images/vehicles/bc10.png'),

    // 'cement mixer truck': require('../../images/vehicles/bc11.png'),
    // 'garbage truck': require('../../images/vehicles/bc14.png'),
    // submarine: require('../../images/vehicles/bc15.png'),
    plane: require('../../images/vehicles/bc16.png'),
    // 'mail truck': require('../../images/vehicles/bc18.png'),

    // skytrain: require('../../images/vehicles/bc19.png'),
    'police car': require('../../images/vehicles/bc20.png'),
    tank: require('../../images/vehicles/bc21.png')
    // 'warehouse car': require('../../images/vehicles/bc22.png')
  }

  static s_images: { [id: string]: string } = {
    train: require('../../images/vehicles/c01.png'),
    bulldozer: require('../../images/vehicles/c02.png'),
    bus: require('../../images/vehicles/c03.png'),
    // spaceship: require('../../images/vehicles/c04.png'),
    // boat: require('../../images/vehicles/c05.png'),

    truck: require('../../images/vehicles/c06.png'),
    // 'ice cream truck': require('../../images/vehicles/c07.png'),
    'school bus': require('../../images/vehicles/c08.png'),
    'fire truck': require('../../images/vehicles/c09.png'),
    ambulance: require('../../images/vehicles/c10.png'),

    // 'cement mixer truck': require('../../images/vehicles/c11.png'),
    // 'garbage truck': require('../../images/vehicles/c14.png'),
    // submarine: require('../../images/vehicles/c15.png'),
    plane: require('../../images/vehicles/c16.png'),
    // 'mail truck': require('../../images/vehicles/c18.png'),

    // skytrain: require('../../images/vehicles/c19.png'),
    'police car': require('../../images/vehicles/c20.png'),
    tank: require('../../images/vehicles/c21.png')
    // 'warehouse car': require('../../images/vehicles/c22.png')
  }

  /* IMatchImageFactory */
  public getHead(name: string): string {
    return VehicleImages.s_images[name]
  }

  public getBody(name: string): string {
    return VehicleImages.s_backgrounds[name]
  }

  /* IBasicImageFactory */
  public getNames() {
    return Object.keys(VehicleImages.s_images)
  }

  public async getImage(name: string) {
    const img = await BitmapLoader.load(this.getHead(name))
    img.regX = img.image.width / 2
    img.regY = img.image.height / 2
    return img
  }
}
