import { GameCookie } from './game-cookie'

export class GameNextLevel {
  private static key(name: string) {
    return `nextlevel ${name}`
  }

  public static save(name: string, n: number) {
    GameCookie.save(this.key(name), n)
  }

  public static get(name: string) {
    return GameCookie.get(this.key(name), 0)
  }
}
