import { GameCookie } from './game-cookie'
import { getProdGames, IGameInfo } from './game-info'
import { IFilterItem } from '../../components/Filter'

export function getDefaultSubjectFilter() {
  const games = getProdGames()
  const unique = new Set<string>()
  games
    .filter((g) => g.isProd)
    .forEach((g) =>
      g.category.forEach((c) => !c.endsWith('yrs') && unique.add(c))
    )
  return Array.from(unique.values())
    .sort()
    .map((c: string) => {
      return { text: c, active: true }
    })
}

export function getDefaultAgeFilter() {
  const games = getProdGames()
  const unique = new Set<string>()
  games
    .filter((g) => g.isProd)
    .forEach((g) =>
      g.category.forEach((c) => c.endsWith('yrs') && unique.add(c))
    )
  return Array.from(unique.values())
    .sort()
    .map((c: string) => {
      return { text: c, active: true }
    })
}

export function getSubjectFilter() {
  const defaults = getDefaultSubjectFilter()
  const saved = GameCookie.get<IFilterItem[]>('subject-filter', defaults)
  if (saved.length !== defaults.length) {
    return defaults
  }
  return saved
}

export function saveSubjectFilter(filter: IFilterItem[]) {
  GameCookie.save<IFilterItem[]>('subject-filter', filter)
}

export function getAgeFilter() {
  const defaults = getDefaultAgeFilter()
  const saved = GameCookie.get<IFilterItem[]>('age-filter', defaults)
  if (saved.length !== defaults.length) {
    return defaults
  }
  return saved
}

export function saveAgeFilter(filter: IFilterItem[]) {
  GameCookie.save<IFilterItem[]>('age-filter', filter)
}

export function getFilteredGameCards(
  gameCards: IGameInfo[],
  ages: IFilterItem[],
  subjects: IFilterItem[]
) {
  const activeSubjects = subjects.filter((f) => f.active).map((f) => f.text)
  const activeAges = ages.filter((f) => f.active).map((f) => f.text)

  if (activeSubjects.length === 0) {
    return gameCards.filter((c) => {
      return c.category.filter((v) => activeAges.indexOf(v) !== -1).length > 0
    })
  }

  if (activeAges.length === 0) {
    return gameCards.filter((c) => {
      return (
        c.category.filter((v) => activeSubjects.indexOf(v) !== -1).length > 0
      )
    })
  }

  return gameCards.filter((c) => {
    return (
      c.category.filter((v) => activeSubjects.indexOf(v) !== -1).length > 0 &&
      c.category.filter((v) => activeAges.indexOf(v) !== -1).length > 0
    )
  })
}
