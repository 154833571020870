'use client'
import * as createjs from 'createjs-module'
import { Direction } from './layout-flex'
import { Point } from './point'
import { Rect } from './rect'

export class LayoutFlexStack {
  private _direction: Direction
  private _container: Rect
  private _items: createjs.DisplayObject[] = []
  private _itemPadding: number

  constructor(
    direction: Direction,
    container: Rect,
    items: createjs.DisplayObject[],
    itemPadding?: number
  ) {
    this._direction = direction
    this._container = container
    this._items = items
    this._itemPadding = itemPadding ?? 0
  }

  public horizontal() {
    return this._direction === Direction.horizontal
  }

  private getSize(p: { x: number; y: number }) {
    if (this.horizontal()) {
      return p.x
    } else {
      return p.y
    }
  }

  private getSizeFromDisplayObject(n: createjs.DisplayObject) {
    if (this.horizontal()) {
      return n.getBounds().width
    } else {
      return n.getBounds().height
    }
  }

  private totalSize() {
    return this._items
      .map((i) => this.getSizeFromDisplayObject(i))
      .reduce((s, i) => s + i)
  }

  private totalPadding() {
    return this._itemPadding * (this._items.length - 1)
  }

  private mapPositionToPoint(position: number) {
    if (this.horizontal()) {
      const y = this._container.origin.y + this._container.size.height() / 2
      return Point.xy(position, y)
    } else {
      const x = this._container.origin.x + this._container.size.width() / 2
      return Point.xy(x, position)
    }
  }

  public layout() {
    const size = this.getSize(this._container.size)
    const padding = (size - this.totalSize() - this.totalPadding()) / 2

    const positions = []
    let previewItemSize = 0
    let position = this.getSize(this._container.origin)
    for (let i = 0; i < this._items.length; ++i) {
      if (i === 0) {
        position += padding
      } else {
        position += this._itemPadding
      }
      position += previewItemSize / 2
      const currentItemSize = this.getSizeFromDisplayObject(this._items[i])
      position += currentItemSize / 2
      previewItemSize = currentItemSize

      positions.push(this.mapPositionToPoint(position))
    }

    const rects = positions.map(
      (p, i) => new Rect(p, Point.fromWidthHeight(this._items[i].getBounds()))
    )
    this.apply(rects)
  }

  private apply(layout: Rect[]) {
    for (let i = 0; i < layout.length; ++i) {
      const obj = this._items[i]
      const rect = layout[i]
      obj.x = rect.origin.x
      obj.y = rect.origin.y
      // const bounds = obj.getBounds()
      // if (bounds !== null) {
      //   const currentSize = Point.fromWidthHeight(obj.getBounds())
      //   const scale = this.scaleToFit(currentSize, rect.size)
      //   obj.scaleX = scale
      //   obj.scaleY = scale
      //   console.log(
      //     `LayoutFlex apply scale=${scale}, currentSize=${currentSize.toString()}, frame=${rect.size.toString()}`
      //   )
      // }
      if (obj.hasOwnProperty('origin')) {
        Object.assign(obj, { origin: rect.origin })
      }
      // console.log(`LayoutFlex apply p=${pass}, i=${i}, r=${rect.toString()}`)
    }
  }

  private moveStackOrigin(origin: Point, stackSize: Point) {
    if (this.horizontal()) {
      return origin.plus(Point.xy(0, stackSize.height()))
    } else {
      return origin.plus(Point.xy(stackSize.width(), 0))
    }
  }

  private boxSize(rect: Rect, passCount: number) {
    if (this.horizontal()) {
      return new Point(rect.size.width() / passCount, rect.size.height())
    } else {
      return new Point(rect.size.width(), rect.size.height() / passCount)
    }
  }
}
