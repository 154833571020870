import { SortingGame } from './sorting-game'

export class SortingAnimalIIGame extends SortingGame {

    private static _data = [
        [['On Land', 'In Ocean'], ['bear', 'cat', 'dog'], ['clownfish', 'crab', 'dolphin']],
        [['Has Legs', 'No Leg'], ['crab', 'fox', 'dog'], ['whale', 'fish', 'dolphin']],
        [['Are Small', 'Are Large'], ['cat', 'dog', 'rabbit'], ['lion', 'elephant', 'hippopotamus']],
        [['Fish', 'Not Fish'], ['clownfish', 'shark', 'fish'], ['squid', 'seahorse', 'snail']],
        [['No Fin', 'Has Fins'], ['squid', 'crab', 'snail'], ['dolphin', 'fish', 'whale']],
        [['Are Pets', 'Are Wild'], ['dog', 'cat', 'rabbit'], ['bear', 'lion', 'hippopotamus']],
        [['On Land', 'In Ocean'], ['elephant', 'fox', 'goat'], ['fish', 'whale', 'seahorse']],
        [['Are Large', 'Are Small'], ['whale', 'shark', 'dolphin'], ['crab', 'snail', 'clownfish']],
        [['Has Legs', 'No Leg'], ['turtle', 'lion', 'rabbit'], ['clownfish', 'snail', 'shark']],
        [['Mammal', 'Fish'], ['bear', 'elephant', 'whale'], ['shark', 'clownfish', 'fish']],
        [['On Land', 'In Ocean'], ['hippopotamus', 'lion', 'monkey'], ['squid', 'shark', 'snail']],
        [['Herbivore', 'Carnivore'], ['goat', 'rabbit', 'elephant'], ['lion', 'bear', 'fox']],
    ]

    public count() {
        return this.questions().length
    }

    protected questions() {
        return SortingAnimalIIGame._data.map(d => d[0])
    }

    protected answers(index: number) {
        const ans = SortingAnimalIIGame._data[index]
        this._setOne = Array.from(ans[1])
        this._setTwo = Array.from(ans[2])
        return [this._setOne, this._setTwo]
    }
}
