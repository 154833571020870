'use client'
import * as createjs from 'createjs-module'
import { delay } from '../utils/delay'
import { Point } from './point'

export class Animator {
  private static s_animatingCount = 0

  public static animate(
    n: createjs.DisplayObject,
    run: (_: createjs.Tween) => void
  ) {
    Animator.s_animatingCount += 1

    return new Promise<void>((r) => {
      const tween = createjs.Tween.get(n)
      run(tween)
      tween.call(() => {
        r()
      })
      tween.call(Animator.onCallback)
    })
  }

  private static async onCallback(
    tweenObject: createjs.Tween,
    n: createjs.DisplayObject
  ) {
    await delay(50)
    Animator.s_animatingCount = Math.max(0, Animator.s_animatingCount - 1)
  }

  public static init(stage: createjs.Stage) {
    // createjs.Ticker.paused = true;
    // createjs.Ticker.interval = 1000
    // createjs.Ticker.framerate = 40
    // createjs.Ticker.addEventListener('tick', stage)
    Animator.s_animatingCount = 0
    createjs.Ticker.on('tick', () => {
      try {
        if (Animator.s_animatingCount > 0) {
          // console.log('update')
          stage?.update()
        }
      } catch (_e) {
        // console.error(e)
      }
    })
    createjs.Ticker.framerate = 40
  }

  public static shake(n: createjs.DisplayObject, magnitude: number, s: number) {
    return this.animate(n, (tween) => {
      const shake = magnitude

      const cycle = Math.floor((s - 100) / 100 / 2)

      tween.to({ rotation: shake }, 50, createjs.Ease.quadInOut)
      for (let i = 0; i < cycle; ++i) {
        tween.to({ rotation: -shake }, 100, createjs.Ease.quadInOut)
        tween.to({ rotation: shake }, 100, createjs.Ease.quadInOut)
      }
      tween.to({ rotation: 0 }, 50, createjs.Ease.quadInOut)
    })
  }

  public static lose(n: createjs.DisplayObject) {
    return this.animate(n, (tween) => {
      const shake = 25
      tween
        .to({ rotation: shake }, 50, createjs.Ease.quadInOut)
        .to({ rotation: -shake }, 100, createjs.Ease.quadInOut)
        .to({ rotation: shake }, 100, createjs.Ease.quadInOut)
        .to({ rotation: -shake }, 100, createjs.Ease.quadInOut)
        .to({ rotation: shake }, 100, createjs.Ease.quadInOut)
        .to({ rotation: -shake }, 100, createjs.Ease.quadInOut)
        .to({ rotation: 0 }, 50, createjs.Ease.quadInOut)
    })
  }

  public static win(n: createjs.DisplayObject) {
    return this.animate(n, (tween) => {
      tween.to({ rotation: -360 }, 500, createjs.Ease.quadInOut)
    })
  }

  public static scaleInOut(n: createjs.DisplayObject, s: number) {
    return this.animate(n, (tween) => {
      const sx = n.scaleX
      const sy = n.scaleY
      tween.to(
        { scaleX: sx * 1.4, scaleY: sy * 1.4 },
        s / 2,
        createjs.Ease.quadInOut
      )
      tween.to({ scaleX: sx, scaleY: sy }, s / 2, createjs.Ease.quadInOut)
    })
  }

  public static fadeIn(
    n: createjs.DisplayObject,
    a: number = 1
  ): Promise<void> {
    return this.animate(n, (tween) => {
      tween.to({ alpha: a }, 500)
    })
  }

  public static fadeOut(
    n: createjs.DisplayObject,
    a: number = 0
  ): Promise<void> {
    return this.animate(n, (tween) => {
      tween.to({ alpha: a }, 500)
    })
  }

  public static move(
    n: createjs.DisplayObject,
    distance: Point,
    s: number = 500
  ) {
    return this.animate(n, (tween) => {
      tween.to(
        { x: n.x + distance.x, y: n.y + distance.y },
        s,
        createjs.Ease.quadInOut
      )
    })
  }

  public static rotateTo(
    n: createjs.DisplayObject,
    r: number,
    s: number = 500
  ) {
    return this.animate(n, (tween) => {
      tween.to({ rotation: r }, s, createjs.Ease.quadInOut)
    })
  }

  public static rotate(n: createjs.DisplayObject, r: number, s: number = 500) {
    return this.animate(n, (tween) => {
      tween.to({ rotation: n.rotation + r }, s, createjs.Ease.quadInOut)
    })
  }

  public static flipHorizontal(n: createjs.DisplayObject, s: number = 500) {
    return this.animate(n, (tween) => {
      tween.to({ scaleX: n.scaleX * -1 }, s, createjs.Ease.quadInOut)
    })
  }

  public static flipVertical(n: createjs.DisplayObject, s: number = 500) {
    return this.animate(n, (tween) => {
      tween.to({ scaleY: n.scaleY * -1 }, s, createjs.Ease.quadInOut)
    })
  }

  public static moveTo(n: createjs.DisplayObject, p: Point, s: number = 500) {
    return this.animate(n, (tween) => {
      tween.to({ x: p.x, y: p.y }, s, createjs.Ease.quadInOut)
    })
  }

  public static shakeUpDown(n: createjs.DisplayObject, s: number = 500) {
    return this.animate(n, (tween) => {
      const shake = 20
      const origin = n.y
      const cycle = s / 6
      tween
        .to({ y: origin + shake }, cycle / 2, createjs.Ease.quadInOut)
        .to({ y: origin + shake }, cycle, createjs.Ease.quadInOut)
        .to({ y: origin - shake }, cycle, createjs.Ease.quadInOut)
        .to({ y: origin + shake }, cycle, createjs.Ease.quadInOut)
        .to({ y: origin - shake }, cycle, createjs.Ease.quadInOut)
        .to({ y: origin }, cycle / 2, createjs.Ease.quadInOut)
    })
  }

  public static scaleTo(
    n: createjs.DisplayObject,
    scale: number,
    s: number = 500
  ) {
    return this.animate(n, (tween) => {
      tween.to({ scaleX: scale, scaleY: scale }, s, createjs.Ease.quadInOut)
    })
  }
}
