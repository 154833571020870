export class Point {
  public static ZERO = new Point(0, 0)

  public x: number
  public y: number

  constructor(x: number, y: number) {
    this.x = x
    this.y = y
  }

  public static from(d: { x: number; y: number }) {
    return new Point(d.x, d.y)
  }

  public static fromWidthHeight(d: { width: number; height: number }) {
    return new Point(d.width, d.height)
  }

  public static xy(x: number, y: number) {
    return new Point(x, y)
  }

  public static rc(r: number, c: number) {
    return new Point(c, r)
  }

  public static size(x: number, y: number) {
    return new Point(x, y)
  }

  public static s(s: number) {
    return new Point(s, s)
  }

  public c() {
    return this.x
  }

  public r() {
    return this.y
  }

  public get w() {
    return this.x
  }

  public get h() {
    return this.y
  }

  public width() {
    return this.x
  }

  public height() {
    return this.y
  }

  public toString() {
    return JSON.stringify(this)
  }

  public isRoughlyEqual(b: Point, allow: number) {
    const diffX = Math.abs(this.x - b.x)
    const diffY = Math.abs(this.y - b.y)
    return diffX < allow && diffY < allow
  }

  public distanceXy(b: Point) {
    const diffX = Math.abs(this.x - b.x)
    const diffY = Math.abs(this.y - b.y)
    return diffX + diffY
  }

  public flipIf(flip: boolean) {
    if (!flip) {
      return Point.from(this)
    }
    return Point.xy(this.y, this.x)
  }

  public minus(b: Point) {
    return new Point(this.x - b.x, this.y - b.y)
  }

  public plus(b: Point) {
    return new Point(this.x + b.x, this.y + b.y)
  }

  public multiply(n: number) {
    return new Point(this.x * n, this.y * n)
  }

  public divide(n: number) {
    if (n === 0) {
      return this
    }
    return new Point(this.x / n, this.y / n)
  }

  public copy() {
    return new Point(this.x, this.y)
  }

  // public static sizeToFit()
}
