import { GameSound } from './game-sound'
import { ISoundFactory } from '../core/sound-factory'
import { Language } from './language'
import { SoundEffects } from './sound-effect'

export class VehicleSounds implements ISoundFactory {
  static SOUNDS: { [id: string]: string } = {
    train: require('../../sounds/vehicles/train.mp3'),
    bulldozer: require('../../sounds/vehicles/bulldozer.mp3'),
    bus: require('../../sounds/vehicles/bus.mp3'),
    truck: require('../../sounds/vehicles/truck.mp3'),
    'school bus': require('../../sounds/vehicles/school bus.mp3'),
    'fire truck': require('../../sounds/vehicles/fire truck.mp3'),
    ambulance: require('../../sounds/vehicles/ambulance.mp3'),
    plane: require('../../sounds/vehicles/plane.mp3'),
    'police car': require('../../sounds/vehicles/police car.mp3'),
    tank: require('../../sounds/vehicles/tank.mp3')
  }

  /* ISoundFactory */
  public register(_language: Language) {
    GameSound.registerAll([VehicleSounds.SOUNDS, SoundEffects.EFFECTS])
  }
}
