import { BitmapLoader } from '../core/bitmap-loader'
import { ImageFactoryBase } from '../core/image-factory'
import { Point } from '../core/point'

export class AnimalImages extends ImageFactoryBase {
  public static ANIMALS = [
    'bear',
    'cat',
    'dog',
    'elephant',
    'fox',
    'goat',
    'hippopotamus',
    'lion',
    'monkey',
    'rabbit'
  ]

  static s_heads: { [id: string]: string } = {
    bear: require('../../images/match-animals/h08.png'),
    cat: require('../../images/match-animals/h10.png'),
    dog: require('../../images/match-animals/h09.png'),
    elephant: require('../../images/match-animals/h06.png'),
    fox: require('../../images/match-animals/h07.png'),
    goat: require('../../images/match-animals/h04.png'),
    hippopotamus: require('../../images/match-animals/h05.png'),
    lion: require('../../images/match-animals/h01.png'),
    monkey: require('../../images/match-animals/h02.png'),
    rabbit: require('../../images/match-animals/h03.png')
  }

  static s_bodies: { [id: string]: string } = {
    bear: require('../../images/match-animals/h08-1.png'),
    cat: require('../../images/match-animals/h10-1.png'),
    dog: require('../../images/match-animals/h09-1.png'),
    elephant: require('../../images/match-animals/h06-1.png'),
    fox: require('../../images/match-animals/h07-1.png'),
    goat: require('../../images/match-animals/h04-1.png'),
    hippopotamus: require('../../images/match-animals/h05-1.png'),
    lion: require('../../images/match-animals/h01-1.png'),
    monkey: require('../../images/match-animals/h02-1.png'),
    rabbit: require('../../images/match-animals/h03-1.png')
  }

  /* IMatchImageFactory */
  public getHead(name: string): string {
    return AnimalImages.s_heads[name]
  }

  public getBody(name: string): string {
    return AnimalImages.s_bodies[name]
  }

  /* IBasicImageFactory */
  public getNames() {
    return AnimalImages.ANIMALS
  }

  public async getImage(name: string) {
    const img = await BitmapLoader.loadHeadBody(
      this.getHead(name),
      this.getBody(name),
      this.getTargetHeadPosition(name)
    )
    return img
  }

  private getTargetHeadPosition(name: string) {
    if (name === 'elephant' || name === 'fox' || name === 'goat') {
      return Point.xy(-50, -60)
    }
    return Point.xy(-50, -50)
  }
}
