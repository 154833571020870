import { BitmapLoader } from '../core/bitmap-loader'
import { ImageFactoryBase } from '../core/image-factory'
import { Point } from '../core/point'

export class DinosaurImages extends ImageFactoryBase {
  public static NAMES = [
    'tyrannosaurus',
    'camarasaurus', // Brachiosaurus
    'triceratops',
    'euoplocephalus',
    'stegosaurus',
    'pteranodon',
    'spinosaurus',
    'velociraptor',
    'ouranosaurus',
    'corythosaurus',
    'iguanodon',
    'parasaurolophus'
  ]

  static s_heads: { [id: string]: string } = {
    spinosaurus: require('../../images/dinosaurs/d01_h.png'),
    ouranosaurus: require('../../images/dinosaurs/d03_h.png'),
    camarasaurus: require('../../images/dinosaurs/d04_h.png'),
    corythosaurus: require('../../images/dinosaurs/d05_h.png'),
    iguanodon: require('../../images/dinosaurs/d06_h.png'),
    velociraptor: require('../../images/dinosaurs/d08_h.png'),
    euoplocephalus: require('../../images/dinosaurs/d10_h.png'),
    stegosaurus: require('../../images/dinosaurs/d12_h.png'),
    triceratops: require('../../images/dinosaurs/d14_h.png'),
    tyrannosaurus: require('../../images/dinosaurs/d15_h.png'),
    pteranodon: require('../../images/dinosaurs/d21_h.png'),
    parasaurolophus: require('../../images/dinosaurs/d25_h.png')
  }

  static s_bodies: { [id: string]: string } = {
    spinosaurus: require('../../images/dinosaurs/d01_b.png'),
    ouranosaurus: require('../../images/dinosaurs/d03_b.png'),
    camarasaurus: require('../../images/dinosaurs/d04_b.png'),
    corythosaurus: require('../../images/dinosaurs/d05_b.png'),
    iguanodon: require('../../images/dinosaurs/d06_b.png'),
    velociraptor: require('../../images/dinosaurs/d08_b.png'),
    euoplocephalus: require('../../images/dinosaurs/d10_b.png'),
    stegosaurus: require('../../images/dinosaurs/d12_b.png'),
    triceratops: require('../../images/dinosaurs/d14_b.png'),
    tyrannosaurus: require('../../images/dinosaurs/d15_b.png'),
    pteranodon: require('../../images/dinosaurs/d21_b.png'),
    parasaurolophus: require('../../images/dinosaurs/d25_b.png')
  }

  /* IMatchImageFactory */
  public getHead(name: string) {
    return DinosaurImages.s_heads[name]
  }

  public getBody(name: string) {
    return DinosaurImages.s_bodies[name]
  }

  /* IBasicImageFactory */
  public getNames() {
    return DinosaurImages.NAMES
  }

  public async getImage(name: string) {
    const img = await BitmapLoader.loadHeadBody(
      this.getHead(name),
      this.getBody(name),
      new Point(-50, -55)
    )
    return img
  }
}
