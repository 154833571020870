'use client'
import * as createjs from 'createjs-module'
import { BitmapLoader } from '../core/bitmap-loader'
import { Color } from '../core/color'
import { PaintPalette } from './paint-palette'
import { Point } from '../core/point'

export interface IPaintCanvas extends createjs.Container {
  setColor(color: string): void
  callback: () => void
}

class PaintCanvasLayer extends createjs.Container {
  public callback: () => void = () => {}
  private _bitmap: createjs.Bitmap | undefined
  private _color: string = PaintPalette.COLORS[0]

  constructor(src: string) {
    super()

    this.on('mousedown', (evt: any) => {
      this.handleMouseDownEvent(evt)
    })

    BitmapLoader.loadAndCenter(src).then((bitmap) => {
      bitmap.alpha = 0.01
      this.addChild(bitmap)
      this._bitmap = bitmap
      this.stage?.update()
    })
  }

  public setColor(color: string) {
    this._color = color
  }

  public isFilled() {
    return this._bitmap!.alpha > 0.9
  }

  private handleMouseDownEvent(evt: any) {
    const bitmap = this._bitmap!
    const colorComponent = Color.mapStringToRgb(this._color)
    if (colorComponent) {
      bitmap.filters = [
        new createjs.ColorFilter(
          0,
          0,
          0,
          1,
          colorComponent.r,
          colorComponent.g,
          colorComponent.b
        )
      ]
      bitmap.alpha = 1
      bitmap.cache(0, 0, bitmap.image.width, bitmap.image.height)
    }
    this.stage?.update()
    this.callback()
  }
}

export default class PaintCanvasWithLayers extends createjs.Container
  implements IPaintCanvas {
  public callback: () => void

  private _size: Point
  private _layer: PaintCanvasLayer[] = []

  constructor(size: Point, srcImage: string, layerImages: string[]) {
    super()
    this.callback = () => {}
    this._size = size

    this.setBounds(0, 0, this._size.width(), this._size.height())

    BitmapLoader.loadAndCenter(srcImage).then((bitmap) => {
      this.addChild(bitmap)
      this.stage?.update()

      this._layer = layerImages.map((i) => {
        const l = new PaintCanvasLayer(i)
        l.callback = this.onLayerFilled
        this.addChildAt(l, 0)
        return l
      })
    })
  }

  public setColor(color: string) {
    this._layer.forEach((l) => l.setColor(color))
  }

  private onLayerFilled = () => {
    const isAllFilled = this._layer
      .map((l) => l.isFilled())
      .reduce((p, c) => p && c)
    if (isAllFilled) {
      this.callback()
    }
  }
}
