import Randomize from '../utils/randomize'
import { BitmapLoader } from '../core/bitmap-loader'
import { DraggableTile } from '../core/draggable-tile'
import { MatchGame } from './match-game'
import { Point } from '../core/point'
import { ShadowBitmapLoader } from '../core/shadow-bitmap-loader'

export class MatchColorGame extends MatchGame {
  protected async loadQuestion() {
    let bitmap = await BitmapLoader.load(
      this._imageFactory.getBody(this._questionName)
    )
    bitmap = BitmapLoader.scale(bitmap, 0.9)
    const question = ShadowBitmapLoader.loadFromContainer(
      bitmap,
      this.getColor(this._questionName)
    )
    const bounds = question.getBounds()

    this._question = question
    this._question.regX = bounds.width / 2
    this._question.regY = bounds.height / 2
    this._question.alpha = 0
    this.addChild(this._question)
  }

  protected getAnswers(index: number) {
    var a = []
    a.push(this._questionName)
    const color = this.getColor(this._questionName)
    const possibleNames = this._names.filter(
      (n) => this._imageFactory.isPreloaded(n) && this.getColor(n) !== color
    )
    Randomize.randomElementsFromArray(possibleNames, 3, a)
    return a
  }

  protected getTargetImpl(_n: number, p: Point) {
    const question = this._question
    if (question === undefined) {
      return Point.ZERO
    }
    return Point.from(question)
  }

  protected onLifted(t: DraggableTile) {
    this.playSound(this.getColor(t.name))
  }

  protected async animateSummary() {
    this.playSound(this.getColor(this._questionName))
    await super.animateSummary()
  }

  private getColor(name: string) {
    const color = name.split('_')[0]
    return color
  }
}
