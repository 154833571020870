import Dexie from 'dexie'

export interface ISavedTile {
  x: number
  y: number
  name: string
}

export class GameStorage {
  public static DBNAME = 'handtoy-school'
  public static STICKERANIMALSTABLE = 'stickeranimals'
  public static USERINFOTABLE = 'userinfo'

  private static s_db: Dexie | undefined

  // https://dexie.org/docs/Tutorial/Design#database-versioning
  // https://dexie.org/docs/Dexie/Dexie.version()

  private static getDB(): Dexie {
    if (this.s_db === undefined) {
      const db = new Dexie(GameStorage.DBNAME)
      db.version(1).stores({
        userinfo: 'id,name,email,pictureUrl,loginDate',
        gamestats: 'pageName,pageCount,levelCount,maxLevel',
        stickeranimals: 'pagename,data'
      })
      db.version(2).stores({
        gamestats: null,
        gameplaystats: 'id,name,level,finishes,countLevel'
      })
      db.version(3).stores({
        stickerdata: 'key,data'
      })
      db.version(4).stores({
        userinfo: 'id,name,email,pictureUrl,loginDate,registerDate,renewDate'
      })
      this.s_db = db
    }
    return this.s_db
  }

  public static async reset() {
    const db = this.getDB()
    db.delete()
  }

  public static async save<T>(table: string, obj: T) {
    const db = this.getDB()
    const t = db.table(table)
    // console.log(`GameStroage save ${JSON.stringify(obj)}`)
    await t.put(obj)
  }

  public static async get<T>(table: string): Promise<T[]> {
    const db = this.getDB()
    const d = await db.table(table).toArray()
    return d as T[]
  }

  public static async getFirst<T>(table: string): Promise<T | undefined> {
    const a = await this.get<T>(table)
    if (a && a.length > 0) {
      return a[0]
    }
    return undefined
  }

  public static async getByField<T>(
    table: string,
    fieldName: string,
    fieldValue: string
  ): Promise<T[]> {
    const db = this.getDB()
    return await db.table(table).where(fieldName).equals(fieldValue).toArray()
  }

  public static async getByKey<T, Key>(
    table: string,
    key: string
  ): Promise<T | undefined> {
    const db = this.getDB()
    return await db.table(table).get(key)
  }

  public static async deleteByKey<T, Key>(
    table: string,
    key: string
  ): Promise<void> {
    const db = this.getDB()
    await db.table(table).delete(key)
  }

  /* ISavedTile */
  public static async saveTiles(pagename: string, tiles: ISavedTile[]) {
    const db = this.getDB()
    const table = db.table(GameStorage.STICKERANIMALSTABLE)
    await table.delete(pagename)
    await table.put({ pagename: pagename, data: JSON.stringify(tiles) })
  }

  public static async loadTiles(pagename: string) {
    const db = this.getDB()
    const data = await db.table(GameStorage.STICKERANIMALSTABLE).get(pagename)
    if (data && data.data) {
      const tiles = JSON.parse(data.data) as ISavedTile[]
      return tiles
    }
    return []
  }
}
