'use client'
import * as createjs from 'createjs-module'
import Randomize from '../utils/randomize'
import { Animator } from '../core/animator'
import { Color } from '../core/color'
import { delay } from '../utils/delay'
import { directionFrom, LayoutFlex } from '../core/layout-flex'
import { DraggableTile } from '../core/draggable-tile'
import { GameBase, IGameConfig } from '../core/game-base'
import { GameSound } from '../resource/game-sound'
import { IBasicImageFactory } from '../core/image-factory'
import { LayoutFlexStack } from '../core/layout-flex-stack'
import { LetterTile } from '../core/letter-tile'
import { Point } from '../core/point'
import { Rect } from '../core/rect'
import { SoundEffects } from '../resource/sound-effect'

export class SightwordGame extends GameBase {
  protected static _answerSize = new Point(300, 80)
  protected static _answerAreaSize = SightwordGame._answerSize.plus(
    Point.xy(40, 20)
  )

  protected _question: createjs.Container | undefined
  protected _questionImage: createjs.DisplayObject | undefined
  protected _questionName: string
  protected _averageNameSize: number
  protected _landscape: boolean
  protected _imageFactory: IBasicImageFactory
  protected _answers: LetterTile[] = []
  protected _answerArea: createjs.Shape | undefined

  constructor(config: IGameConfig) {
    super(config)
    this._averageNameSize =
      this._names.map((i) => i.length).reduce((p, c) => p + c) /
      this._names.length
    this._imageFactory = config.imageFactory
    this._landscape = config.landscape
    this._questionName = this._names[this._pageIndex]

    GameSound.register(SoundEffects.SIGHTWORD)

    this.onLifted = this.onLifted.bind(this)
    this.onPlaced = this.onPlaced.bind(this)
  }

  async load() {
    await this.loadQuestion()
    await this.loadAnswers()
    this.layout(this._landscape)
    this.stage.update()

    this._answers.forEach((f) => {
      Animator.fadeIn(f)
    })
    Animator.fadeIn(this._question!)

    await this.playIntroSounds()
  }

  protected async playIntroSounds() {
    await delay(GameBase.INTROSOUNDDELAY)
    this.playSound('find the word')
    await delay(1200)
    this.playSound(this._questionName)
  }

  protected async loadQuestion() {
    this._questionImage = await this._imageFactory.getImage(this._questionName)
    const size = SightwordGame._answerAreaSize.plus(new Point(40, 40))
    const totalHeight =
      this._questionImage.getBounds().height + size.height() + 50
    // console.log(`totalHeight ${totalHeight}`)

    this._questionImage.y =
      -(totalHeight / 2) + this._questionImage.getBounds().height / 2

    this._answerArea = new createjs.Shape()
    this._answerArea.graphics
      .beginFill('white')
      .drawRoundRect(0, 0, size.width(), size.height(), size.height() * 0.2)
    this._answerArea.regX = size.width() / 2
    this._answerArea.regY = size.height() / 2
    this._answerArea.y = totalHeight / 2 - size.height() / 2
    this._answerArea.setBounds(0, 0, size.width(), size.height())

    const container = new createjs.Container()
    container.addChild(this._questionImage)
    container.addChild(this._answerArea)
    container.alpha = 0

    this._question = container
    this.addChild(this._question)
  }

  protected async loadAnswers() {
    const names = this.getAnswers(this._pageIndex)
    for (var i = 0; i < names.length; ++i) {
      const name = names[i]
      const capitalizedText = name.charAt(0).toUpperCase() + name.slice(1)
      const answer = new LetterTile(
        SightwordGame._answerSize.copy(),
        SightwordGame._answerAreaSize.copy(),
        capitalizedText,
        true
      )
      answer.setBackground(Color.randomTextColor(), 8, 'white', true)
      answer.name = name
      answer.alpha = 0
      answer.onLifted = this.onLifted
      answer.onPlaced = this.onPlaced
      this.addChild(answer)
      this._answers.push(answer)
    }
  }

  public layout(landscape: boolean) {
    const layoutSize = this.layoutSize(landscape)

    const pos = LayoutFlex.questionPosition(landscape, layoutSize)
    this._question!.x = pos.x
    this._question!.y = pos.y

    const stackSize = landscape ? new Point(300, 500) : new Point(600, 300)

    new LayoutFlexStack(
      directionFrom(landscape, true),
      new Rect(
        LayoutFlex.answerPosition(landscape, layoutSize).minus(
          stackSize.divide(2)
        ),
        stackSize
      ),
      this._answers,
      landscape ? 30 : -150
    ).layout()

    this._answers.forEach((s, i) => {
      s.origin = Point.from(s)
    })

    if (!landscape) {
      // zigzag answers to create some spaces
      const zigzagOffset = 60
      const zigzag = 80
      this._answers.forEach((a, i) => {
        a.y += zigzagOffset + (i % 2 === 0 ? 1 : -1) * zigzag
        a.origin = Point.from(a)
      })
    }

    this.stage.update()
  }

  public count() {
    return this._names.length
  }

  protected onLifted(t: DraggableTile) {
    this.playSound(t.name)
  }

  protected onPlaced(t: DraggableTile) {
    const target = this.getTargetImpl()
    const current = new Point(t.x, t.y)
    // console.log(`placed ${target.toString()}, ${current.toString()}`)
    if (target.isRoughlyEqual(current, 150)) {
      if (t.name === this._questionName) {
        this.win(t)
      } else {
        this.lose(t)
      }
      return target
    }
    return undefined
  }

  protected getTargetImpl() {
    // const pt = this.localToGlobal(
    //   this._answerArea!.x,
    //   this._answerArea!.y,
    //   this._answerArea!
    // )
    // console.log(`pt x=${pt.x}, y=${pt.y}`)
    return new Point(
      this._answerArea!.x + this._question!.x,
      this._answerArea!.y + this._question!.y
    )
  }

  protected getAnswers(index: number) {
    var answers = []
    answers.push(this._names[index])
    const shortNames = this._names.filter(
      (i) => i.length < this._averageNameSize + 1
    )
    Randomize.randomElementsFromArray(shortNames, 3, answers)
    return answers
  }

  protected async win(t: DraggableTile) {
    this._answerArea!.alpha = 0
    this.playSound('success')

    this._answers.forEach((s) => {
      if (s.name !== this._questionName) {
        Animator.fadeOut(s)
      } else {
        s.canDrag = false
      }
    })

    await Animator.win(t)

    this.animateSummary()
  }

  protected async animateSummary() {
    this._answers.forEach((s) => {
      if (s.name === this._questionName) {
        const q = this._question
        if (q !== undefined) {
          const layoutSize = this.layoutSize(this._landscape)
          const distance = new Point(
            layoutSize.x / 2 - q.x,
            layoutSize.y / 2 - q.y
          )
          Animator.move(s, distance)
          Animator.move(this._question!, distance)
        }
      }
    })

    await delay(GameBase.WINDELAY)
    this._callback()
  }

  protected async lose(t: DraggableTile) {
    Animator.lose(t)
    this.playSound('error')
    await delay(500)
    t.setPositionToOrigin()
    t.stage.update()
  }
}
