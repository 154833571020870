import { AnimalImages } from '../resource/animal-images'
import { BitmapLoader } from '../core/bitmap-loader'
import { ImageFactoryBase } from '../core/image-factory'
import { SeaAnimalImages } from '../resource/sea-animal-images'

export class SortingAnimalImages extends ImageFactoryBase {
  public static ANIMALS = [
    'bear',
    'cat',
    'dog',
    'elephant',
    'fox',
    'goat',
    'hippopotamus',
    'lion',
    'monkey',
    'rabbit'
  ]

  public static SEAANIMALS = [
    'clownfish',
    'crab',
    'dolphin',
    'fish',
    'whale',
    'seahorse',
    'squid',
    'shark',
    'snail',
    'turtle'
  ]

  private _animalFactory = new AnimalImages()
  private _seaAnimalFactory = new SeaAnimalImages()

  /* IMatchImageFactory */
  public getHead(name: string): string {
    if (this.isSetOne(name)) {
      return this._animalFactory.getHead(name)
    } else {
      return this._seaAnimalFactory.getHead(name)
    }
  }

  public getBody(name: string): string {
    if (this.isSetOne(name)) {
      return this._animalFactory.getBody(name)
    } else {
      return this._seaAnimalFactory.getBody(name)
    }
  }

  /* IBasicImageFactory */
  public getNames() {
    return [...SortingAnimalImages.ANIMALS, ...SortingAnimalImages.SEAANIMALS]
  }

  public async getImage(name: string) {
    if (this.isSetOne(name)) {
      return this._animalFactory.getImage(name)
    } else {
      return this._seaAnimalFactory.getImage(name)
    }
  }

  public async preload(index: number) {
    const names = SortingAnimalImages.ANIMALS
    const names2 = SortingAnimalImages.SEAANIMALS
    const unique = new Set<string>()

    for (let i = index; i < index + 3; ++i) {
      const n = names[i % names.length]
      unique.add(this.getBody(n))
      unique.add(this.getHead(n))

      const n2 = names2[i % names2.length]
      unique.add(this.getBody(n2))
      unique.add(this.getHead(n2))
    }

    await BitmapLoader.preloadAll(Array.from(unique))
  }

  /* SortingAnimalImages */
  public getNamesFromSetOne() {
    return SortingAnimalImages.ANIMALS
  }

  public getNamesFromSetTwo() {
    return SortingAnimalImages.SEAANIMALS
  }

  public isSetOne(name: string) {
    return SortingAnimalImages.ANIMALS.findIndex((n) => n === name) !== -1
  }
}
