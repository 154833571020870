import { delay } from '../utils/delay'
import { DraggableTile } from '../core/draggable-tile'
import { LetterTile } from '../core/letter-tile'
import { SightwordGame } from './sightword-game'

export class SightwordColorGame extends SightwordGame {
  protected async playIntroSounds() {
    await delay(800)
    this.playSound('find the word')
    await delay(1200)
    this.playSound(this.getColor(this._questionName))
  }

  protected async loadQuestion() {
    await super.loadQuestion()
    this._answerArea!.graphics.clear()

    const color = this.getColor(this._questionName)
    const size = this._answerArea!.getBounds()
    this._answerArea!.graphics.beginFill(color).drawRoundRect(
      0,
      0,
      size.width,
      size.height,
      size.height * 0.2
    )
  }

  protected async loadAnswers() {
    const names = this.getAnswers(this._pageIndex)
    const colors = names.map((n) => this.getColor(n))
    for (var i = 0; i < names.length; ++i) {
      const name = names[i]
      const color = colors[i]
      const capitalizedText = color.charAt(0).toUpperCase() + color.slice(1)
      const answer = new LetterTile(
        SightwordGame._answerSize.copy(),
        SightwordGame._answerAreaSize.copy(),
        capitalizedText,
        true
      )
      if (['pink', 'yellow', 'white'].findIndex((c) => c === color) !== -1) {
        answer.setTextColor('grey')
      }
      answer.setBackground(color)
      answer.name = name
      answer.alpha = 0
      answer.onLifted = this.onLifted
      answer.onPlaced = this.onPlaced
      this.addChild(answer)
      this._answers.push(answer)
    }
  }

  protected onLifted(t: DraggableTile) {
    this.playSound(this.getColor(t.name))
  }

  protected async animateSummary() {
    this.playSound(this.getColor(this._questionName))
    await super.animateSummary()
  }

  private getColor(name: string) {
    const color = name.split('_')[0]
    return color
  }
}
