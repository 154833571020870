'use client'
import * as createjs from 'createjs-module'
import { Color } from '../core/color'
import { Direction } from '../core/layout-flex'
import { LayoutFlex } from '../core/layout-flex'
import { Point } from '../core/point'
import { Rect } from '../core/rect'

type PaintPaletteCallback = (color: string) => void

export class ColorCircle extends createjs.Container {
  private _selectArea: createjs.Shape

  public color: string
  public callback: PaintPaletteCallback = () => {}

  constructor(color: string, r: number) {
    super()
    this.color = color

    const c = new createjs.Shape()
    const colorHex = Color.mapStringToHex(color)
    c.graphics.beginFill(colorHex).drawCircle(0, 0, r)
    this.addChild(c)

    const selectArea = new createjs.Shape()
    selectArea.graphics.beginFill('#333').drawCircle(0, 0, r + 8)
    this._selectArea = selectArea
    this._selectArea.alpha = 0
    this.addChildAt(selectArea, 0)

    this.on('mousedown', this.handleMouseDownEvent)
  }

  public select() {
    this._selectArea.alpha = 1
  }

  public unselect() {
    this._selectArea.alpha = 0
  }

  private handleMouseDownEvent = (evt: any) => {
    this.callback(this.color)
  }
}

export class PaintPalette extends createjs.Container {
  private _colors: ColorCircle[] = []
  private _lastSelection: ColorCircle | undefined

  public static COLORS = Color.colorStrings()
  public callback: PaintPaletteCallback = () => {}

  constructor() {
    super()

    for (let i = 0; i < PaintPalette.COLORS.length; ++i) {
      const c = PaintPalette.COLORS[i]
      const r = 40
      const color = new ColorCircle(c, r)
      color.callback = this.onSelected
      color.setBounds(0, 0, r * 2, r * 2)
      this.addChild(color)
      this._colors.push(color)

      if (i === 0) {
        color.select()
        this._lastSelection = color
      }
    }
  }

  public layout(landscape: boolean) {
    const size = Point.xy(800, 200).flipIf(landscape)

    const l = this._colors.length
    const row1 = this._colors.slice(0, Math.ceil(l / 2))
    const row2 = this._colors.slice(row1.length, this._colors.length)

    new LayoutFlex(
      landscape ? Direction.vertical : Direction.horizontal,
      new Rect(Point.ZERO, size),
      row1,
      row2
    ).layout()

    this.setBounds(0, 0, size.width(), size.height())
  }

  onSelected = (color: string) => {
    this._lastSelection?.unselect()
    const newColor = this._colors.find((c) => c.color === color)
    newColor?.select()
    this._lastSelection = newColor
    this.stage?.update()
    this.callback(color)
  }
}
