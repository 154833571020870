import LocalizedStrings from 'react-localization'
import { GameCookie } from '../utils/game-cookie'

export class LanguageCookie {
  private static s_loc = new LocalizedStrings({ en: {}, th: {} })
  public static getLanguage() {
    return GameCookie.get('user-language', this.s_loc.getInterfaceLanguage())
  }

  public static setLanguage(lang: string) {
    GameCookie.save('user-language', lang)
  }
}
