import { ISoundFactory } from '../core/sound-factory'
import { Language } from './language'
import { GameSound } from './game-sound'
import { SoundEffects } from './sound-effect'

export class DinosaurSounds implements ISoundFactory {
  static NAMES: { [id: string]: string } = {
    spinosaurus: require('../../sounds/dinosaurs/spinosaurus.mp3'),
    ouranosaurus: require('../../sounds/dinosaurs/ouranosaurus.mp3'),
    camarasaurus: require('../../sounds/dinosaurs/camarasaurus.mp3'),
    corythosaurus: require('../../sounds/dinosaurs/corythosaurus.mp3'),
    iguanodon: require('../../sounds/dinosaurs/iguanodon.mp3'),
    velociraptor: require('../../sounds/dinosaurs/velociraptor.mp3'),
    euoplocephalus: require('../../sounds/dinosaurs/euoplocephalus.mp3'),
    stegosaurus: require('../../sounds/dinosaurs/stegosaurus.mp3'),
    triceratops: require('../../sounds/dinosaurs/triceratops.mp3'),
    tyrannosaurus: require('../../sounds/dinosaurs/tyrannosaurus.mp3'),
    pteranodon: require('../../sounds/dinosaurs/pteranodon.mp3'),
    parasaurolophus: require('../../sounds/dinosaurs/parasaurolophus.mp3')
  }

  /* ISoundFactory */
  public register(_language: Language) {
    GameSound.registerAll([DinosaurSounds.NAMES, SoundEffects.EFFECTS])
  }
}
