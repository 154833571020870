import { GameCookie } from './game-cookie'
import { GameStorage } from './game-storage'
import { IHandtoyLinkAccount, Member } from '../../pages/api/member'

export interface IUserInfo {
  id: string
  name?: string
  email?: string
  pictureUrl?: string
  loginDate: Date
  registerDate?: Date
  renewDate?: Date
}

type UserInfoCallback = (u: IUserInfo | undefined) => void

export class UserInfo {
  private static s_hasUserInfo: boolean = false
  private static s_userInfo: IUserInfo | undefined
  private static s_callbacks: Map<number, UserInfoCallback> = new Map()
  private static s_callbackLastKey: number = 0

  /* check locked games */
  public static checkValidUser() {
    if (window.location.href.startsWith('http://localhost:3000/')) {
      return true
    }
    return GameCookie.get('uid', '') !== ''
  }

  /* cookie - we also save valid user id in cookie */
  private static saveUserIdInCookie(u: IUserInfo | undefined) {
    GameCookie.save('uid', u?.id ?? '')
  }

  /* storage */
  public static async get() {
    if (!UserInfo.s_hasUserInfo) {
      UserInfo.s_userInfo = await GameStorage.getFirst(
        GameStorage.USERINFOTABLE
      )
      UserInfo.saveUserIdInCookie(UserInfo.s_userInfo)
      UserInfo.notifyOnChanged(UserInfo.s_userInfo)
      UserInfo.s_hasUserInfo = true
    }
    return UserInfo.s_userInfo
  }

  public static async delete(u: IUserInfo) {
    await GameStorage.deleteByKey(GameStorage.USERINFOTABLE, u.id)
    UserInfo.s_hasUserInfo = false
    UserInfo.saveUserIdInCookie(undefined)
    UserInfo.notifyOnChanged(undefined)
  }

  public static async save(u: IUserInfo) {
    await GameStorage.save(GameStorage.USERINFOTABLE, u)
    UserInfo.s_userInfo = u
    UserInfo.saveUserIdInCookie(u)
    UserInfo.notifyOnChanged(u)
  }

  /* notification */
  public static registerOnChanged(callback: UserInfoCallback) {
    const map = UserInfo.s_callbacks
    UserInfo.s_callbackLastKey += 1
    map.set(UserInfo.s_callbackLastKey, callback)
    return UserInfo.s_callbackLastKey
  }

  public static unregisterOnChanged(key: number) {
    const map = UserInfo.s_callbacks
    map.delete(key)
  }

  private static notifyOnChanged(u: IUserInfo | undefined) {
    const map = UserInfo.s_callbacks
    map.forEach((callback) => {
      callback(u)
    })
  }

  /* picture */
  public static async fetchPicture(imageUrl: string): Promise<string> {
    var res = await fetch(imageUrl)
    var blob = await res.blob()

    return new Promise<string>((resolve, reject) => {
      var reader = new FileReader()
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result as string)
        },
        false
      )

      reader.onerror = () => {
        return reject('error')
      }
      reader.readAsDataURL(blob)
    })
  }

  /* linkAccount */
  public static async getLinkAccount(
    id: string
  ): Promise<IHandtoyLinkAccount | undefined> {
    const linkAccount = await Member.get(id, 'facebook')

    if (linkAccount) {
      const userInfo = await UserInfo.get()
      if (userInfo) {
        userInfo.registerDate = new Date(linkAccount.RegisteredDate)
        userInfo.renewDate = new Date(linkAccount.RenewDate)
        await UserInfo.save(userInfo)
      }
    }
    return linkAccount
  }

  public static async saveAndRefreshExpiration(u: IUserInfo) {
    const linkAccount = await Member.get(u.id, 'facebook')
    if (linkAccount) {
      u.registerDate = new Date(linkAccount.RegisteredDate)
      u.renewDate = new Date(linkAccount.RenewDate)
    }
    UserInfo.save(u)
  }
}
