'use client'
import * as createjs from 'createjs-module'
import Randomize from '../utils/randomize'
import { AbcSounds } from '../resource/abc-sounds'
import { Animator } from '../core/animator'
import { Color } from '../core/color'
import { delay } from '../utils/delay'
import { Direction } from '../core/layout-flex'
import { DraggableTile } from '../core/draggable-tile'
import { GameBase, IGameConfig } from '../core/game-base'
import { GameSound } from '../resource/game-sound'
import { LayoutFlexStack } from '../core/layout-flex-stack'
import { LetterTile } from '../core/letter-tile'
import { Point } from '../core/point'
import { Rect } from '../core/rect'
import { ResizableShapeDeprecated } from '../core/resizable-shape'
import { SoundEffects } from '../resource/sound-effect'

export class SpellingGame extends GameBase {
  protected _question: createjs.Bitmap | undefined
  protected _questionName: string
  protected _answers: LetterTile[] = []
  protected _answerArea: createjs.Shape[] = []
  protected _answerLine: createjs.DisplayObject[] = []
  protected _answerColor: string = ''

  constructor(config: IGameConfig) {
    super(config)

    this._questionName = this.names()[this._pageIndex]

    GameSound.register(SoundEffects.SPELL)
    GameSound.register(AbcSounds.ALPHABETS)
  }

  public layoutSize(landscape: boolean) {
    super.layoutSize(landscape)
    if (landscape) {
      return new Point(GameBase.LONGEST, GameBase.SHORTER)
    } else {
      return new Point(GameBase.SHORTER, GameBase.LONGEST)
    }
  }

  async load() {
    await this.loadQuestion()
    await this.loadAnswers()
    this.layout(this._landscape)
    this.stage.update()

    this._answers.forEach((f) => {
      if (f.name !== ' ') {
        Animator.fadeIn(f)
      }
    })
    this._answerLine.forEach((f) => {
      if (f.name !== ' ') {
        Animator.fadeIn(f)
      }
    })
    Animator.fadeIn(this._question!)

    await this.playIntroSounds()
  }

  protected async playIntroSounds() {
    await delay(GameBase.INTROSOUNDDELAY)
    this.playSound('lets spell')
    await delay(1200)
    this.playSound(this._questionName)
  }

  protected async loadQuestion() {
    const img = await this._imageFactory.getImage(this._questionName)
    img.alpha = 0
    this._question = img
    this.addChild(this._question)
  }

  protected async loadAnswers() {
    const name = this.names()[this._pageIndex].toUpperCase()
    const w = this.letterSize()
    const size = Point.xy(w, w)
    const areaSize = size.plus(Point.xy(20, 20))
    const [color, textColor] = this.colors()
    this._answerColor = color

    const playableIndexes = new Set(
      Randomize.randomElementsFromArray(
        Array.from(Array(name.length).keys()).filter((i) => name[i] !== ' '),
        4,
        []
      )
    )

    let playableWord = ''

    for (let i = 0; i < name.length; ++i) {
      const letter = name[i]
      const playable = playableIndexes.has(i)

      const answer = new LetterTile(size.copy(), areaSize.copy(), letter, true)
      answer.setBackground(color)
      answer.setTextColor(textColor)
      answer.name = letter
      answer.alpha = 0
      answer.onLifted = this.onLifted
      answer.onPlaced = this.onPlaced

      if (playable) {
        playableWord = playableWord + letter
        const answerArea = new ResizableShapeDeprecated(areaSize, {
          color: 'white',
          r: 0.2 * areaSize.height()
        })
        if (letter === ' ') {
          answerArea.name = letter
        }
        answerArea.alpha = 0
        answerArea.regX = areaSize.width() / 2
        answerArea.regY = areaSize.height() / 2
        this._answerArea.push(answerArea)
        this.addChild(answerArea)

        this.addChild(answer)
        this._answerLine.push(answerArea)
        this._answers.push(answer)
      } else {
        answer.setBackground(color, 8, '#bbb')
        answer.canDrag = false
        answer.setSnap()
        this.addChild(answer)
        this._answerLine.push(answer)
      }
    }

    let word = playableWord
    do {
      Randomize.shuffle(this._answers)
      word = this._answers.map((a) => a.name).reduce((p, i) => p + i)
    } while (word === playableWord)
  }

  public layout(landscape: boolean) {
    const layoutSize = this.layoutSize(landscape)
    const letterSize = this.letterSize()

    const pos = Point.xy(layoutSize.x / 2, layoutSize.y / 3)
    this._question!.x = pos.x
    this._question!.y = pos.y

    const ansPos = Point.xy(layoutSize.x / 2, (layoutSize.y * 2) / 3).minus(
      Point.xy(0, 0)
    )
    const ansPos1 = Point.xy(layoutSize.x / 2, (layoutSize.y * 2) / 3).plus(
      Point.xy(0, letterSize + 40)
    )

    const stackSize = new Point(layoutSize.x, letterSize)

    new LayoutFlexStack(
      Direction.horizontal,
      new Rect(ansPos.minus(stackSize.divide(2)), stackSize),
      this._answerLine,
      10
    ).layout()

    new LayoutFlexStack(
      Direction.horizontal,
      new Rect(ansPos1.minus(stackSize.divide(2)), stackSize),
      this._answers,
      10
    ).layout()

    this.stage.update()
  }

  public count() {
    return this.names().length
  }

  protected names() {
    const names = this._names
      .filter((n) => n.length < 11)
      .sort((a, b) => a.length - b.length)
    return names
  }

  protected colors() {
    return [Color.randomTextColor(), 'white']
  }

  private letterSize() {
    const layoutSize = this.layoutSize(this._landscape)
    const name = this.names()[this._pageIndex]
    const size = Math.min(128, (layoutSize.width() - 40) / name.length - 30)
    return size
  }

  protected onLifted = (t: DraggableTile) => {
    this.playSound(t.name.toUpperCase())
  }

  protected onPlaced = (t: DraggableTile) => {
    const current = Point.from(t)

    let target = Point.ZERO

    this._answerArea.forEach((a) => {
      const local = Point.from(a.globalToLocal(current.x, current.y))
      if (a.hitTest(local.x, local.y)) {
        target = Point.from(a)
        a.name = t.name
      }
    })

    let isAllSnapped = true
    this._answers.forEach((a) => {
      if (a.isSnap()) {
        const local = Point.from(a.globalToLocal(target.x, target.y))
        if (a.hitTest(local.x, local.y)) {
          a.setPositionToOrigin()
          isAllSnapped = false
        }
      } else if (a !== t) {
        // t is not yet snapped
        isAllSnapped = false
      }
    })

    // console.log(`placed ${target.toString()}, ${current.toString()}`)
    if (!target.isRoughlyEqual(Point.ZERO, 1)) {
      if (isAllSnapped) {
        const word = this._answerLine.map((a) => a.name).reduce((p, i) => p + i)
        if (word.toUpperCase() === this._questionName.toUpperCase()) {
          this.win()
        } else {
          this.lose(t)
        }
      }
      return target
    }
    return undefined
  }

  protected async lose(t: DraggableTile) {
    await Promise.all(
      this._answers.map(async (s) => {
        Animator.lose(s)
      })
    )
    this.playSound('error')
    await delay(500)
    t.setPositionToOrigin()
    this.stage?.update()
  }

  protected async win() {
    this.playSound('success')

    await delay(200)

    this._answerArea.forEach((a) => {
      a.alpha = 0
    })

    const line = this._answerLine.map((s) => {
      if (s instanceof LetterTile) {
        const tile = s as LetterTile
        tile.setBackground(this._answerColor, 8, 'white')
        return tile
      } else {
        const tile = this._answers.find((a) => {
          return Point.from(a).isRoughlyEqual(Point.from(s), 20)
        })
        return tile!
      }
    })

    // await Promise.all([
    //   ...this._answerLine.map(async (s) => {
    //     if (s instanceof LetterTile) {
    //       const tile = s as LetterTile
    //       tile.setBackground(this._answerColor, 'white')
    //     }
    //     await Animator.win(s)
    //   }),
    //   ...this._answers.map(async (s) => {
    //     s.canDrag = false
    //     await Animator.win(s)
    //   })
    // ])

    await Promise.all([
      line.map(async (s) => {
        await Animator.win(s)
      })
    ])

    this.animateSummary(line)
  }

  protected async animateSummary(line: LetterTile[]) {
    await delay(800)

    const name = this.names()[this._pageIndex]

    for (let i = 0; i < name.length; ++i) {
      const letter = name[i]
      this.playSound(letter.toUpperCase())

      const tile = line[i]
      this.removeChild(tile)
      this.addChild(tile)
      await Animator.scaleInOut(tile, 800)
    }

    await this.playSound(this._questionName)

    await delay(GameBase.WINDELAY)
    this._callback()
  }
}
