import { GameSound } from './game-sound'
import { ISoundFactory } from '../core/sound-factory'
import { Language } from './language'
import { SoundEffects } from './sound-effect'

export class NumberWordSounds implements ISoundFactory {
  public static NUMBER: { [id: string]: string } = {
    one: require('../../sounds/numbers/number1.mp3'),
    two: require('../../sounds/numbers/number2.mp3'),
    three: require('../../sounds/numbers/number3.mp3'),
    four: require('../../sounds/numbers/number4.mp3'),
    five: require('../../sounds/numbers/number5.mp3'),
    six: require('../../sounds/numbers/number6.mp3'),
    seven: require('../../sounds/numbers/number7.mp3'),
    eight: require('../../sounds/numbers/number8.mp3'),
    nine: require('../../sounds/numbers/number9.mp3'),
    ten: require('../../sounds/numbers/number10.mp3'),
    eleven: require('../../sounds/numbers/number11.mp3'),
    twelve: require('../../sounds/numbers/number12.mp3'),
  }

  /* ISoundFactory */
  public register(language: Language) {
    GameSound.registerAll([NumberWordSounds.NUMBER, SoundEffects.EFFECTS])
  }
}
