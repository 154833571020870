export class SoundEffects {
  public static EFFECTS: { [id: string]: string } = {
    success: require('../../sounds/success.mp3'),
    error: require('../../sounds/error.mp3'),
    'can you match it': require('../../sounds/can you match it.mp3')
  }

  public static FOR: { [id: string]: string } = {
    for: require('../../sounds/for.mp3')
  }

  public static MATCH: { [id: string]: string } = {
    'can you match it': require('../../sounds/can you match it.mp3')
  }

  public static SHADOW: { [id: string]: string } = {
    'can you match my shadow': require('../../sounds/can you match my shadow.mp3')
  }

  public static SIGHTWORD: { [id: string]: string } = {
    'find the word': require('../../sounds/find the word.mp3')
  }

  public static SPELL: { [id: string]: string } = {
    'lets spell': require('../../sounds/lets spell.mp3')
  }
}
