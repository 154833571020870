'use client'
import * as createjs from 'createjs-module'
import { Point } from './point'
import { Rect } from './rect'

export enum Pin {
  top,
  left,
  bottom,
  right
}

export class LayoutPin {
  public static pin(
    target: createjs.DisplayObject,
    targetPin: Pin,
    guide: createjs.DisplayObject,
    guidePin: Pin,
    padding: number = 0
  ) {
    const guideBounds = Rect.fromBounds(guide.getBounds())
    guideBounds.origin.x = guide.x - guideBounds.size.w / 2
    guideBounds.origin.y = guide.y - guideBounds.size.h / 2
    let guidePosition = Point.ZERO

    switch (guidePin) {
      case Pin.top:
        guidePosition = guideBounds.top()
        break
      case Pin.left:
        guidePosition = guideBounds.left()
        break
      case Pin.bottom:
        guidePosition = guideBounds.bottom()
        break
      case Pin.right:
        guidePosition = guideBounds.right()
        break
    }

    const targetBounds = target.getBounds()
    let targetPosition = Point.from(guide)

    switch (targetPin) {
      case Pin.top:
        targetPosition.y = guidePosition.y + targetBounds.height / 2 + padding
        break
      case Pin.left:
        targetPosition.x = guidePosition.x + targetBounds.width / 2 + padding
        break
      case Pin.bottom:
        targetPosition.y = guidePosition.y - targetBounds.height / 2 - padding
        break
      case Pin.right:
        targetPosition.x = guidePosition.x - targetBounds.width / 2 - padding
        break
    }

    target.x = targetPosition.x
    target.y = targetPosition.y
  }
}
