import { Animator } from '../core/animator'
import { CodeAnswerBlock } from './code-answer-block'
import { CodeWalkCommand, CodingWalkBoard } from './coding-walk-board'
import { CommandBlock } from './command-block'
import { delay } from '../utils/delay'
import { Direction, LayoutFlex } from '../core/layout-flex'
import { GameBase, IGameConfig } from '../core/game-base'
import { getCodingWalkGameLevels, ICodingWalkGameLevel } from './coding-walk-level'
import { LayoutFlexStack } from '../core/layout-flex-stack'
import { Point } from '../core/point'
import { Rect } from '../core/rect'
import { ResizableShapeDeprecated } from '../core/resizable-shape'
// 'use client'
import * as createjs from 'createjs-module'

export class CodingWalkGame extends GameBase {
  public static WIDTH = 1080
  public static HEIGHT = 1380
  public static PADDING = 10

  private _background: ResizableShapeDeprecated
  private _level: ICodingWalkGameLevel
  private _board: CodingWalkBoard
  private _commandBlocks: CommandBlock[] = []
  private _answerBlock: CodeAnswerBlock

  constructor(config: IGameConfig) {
    super(config)

    const levels = getCodingWalkGameLevels()
    this._level = levels[config.pageIndex % levels.length]

    this._background = new ResizableShapeDeprecated(
      this.layoutSize(this._landscape),
      {
        color: 'white',
        r: 0,
        borderWidth: 0,
        borderColor: 'white'
      }
    )
    this.addChild(this._background)

    this._board = new CodingWalkBoard(this._level, Point.xy(800, 800))
    this.addChild(this._board)

    this._commandBlocks = this._level.possibleCommands.map(
      (c) => new CommandBlock(c)
    )
    this._commandBlocks.forEach((b) => {
      b.alpha = 0
      this.addChild(b)
      b.on('mousedown', (e) => {
        this.onMouseDown(e as MouseEvent)
      })
    })

    this._answerBlock = new CodeAnswerBlock(Point.xy(300, 600))
    this._answerBlock.onRun = async (commands: CodeWalkCommand[]) => {
      const pass = await this._board.execute(commands)
      if (pass) {
        this.win()
      } else {
        this.lose()
      }
    }
    this._answerBlock.onReset = () => {
      this._board.reset()
      this.stage?.update()
    }
    this.addChild(this._answerBlock)
  }

  public count() {
    return getCodingWalkGameLevels().length
  }

  public layoutSize(landscape: boolean) {
    super.layoutSize(landscape)
    if (landscape) {
      return Point.xy(CodingWalkGame.HEIGHT, CodingWalkGame.WIDTH)
    } else {
      return Point.xy(CodingWalkGame.WIDTH, CodingWalkGame.HEIGHT)
    }
  }

  async load() {
    await this._board.load()
    await this._answerBlock.load()
    this.layout(this._landscape)

    this._commandBlocks.forEach((b) => (b.alpha = 1))
    this._answerBlock.alpha = 1
    this.stage?.update()
  }

  public layout(landscape: boolean) {
    const layoutSize = this.layoutSize(landscape)

    this._background.layout(layoutSize)

    const bounds = this._board.getBounds()
    this._answerBlock.layout(
      Rect.from(
        bounds.width,
        0,
        layoutSize.width() - bounds.width,
        bounds.height
      ),
      landscape
    )

    const yOffset = layoutSize.height() - this._board.getBounds().height
    const commandsRect = new Rect(
      Point.xy(0, layoutSize.y - yOffset),
      Point.xy(layoutSize.x, yOffset)
    )
    if (this._commandBlocks.length === 3) {
      new LayoutFlexStack(
        Direction.horizontal,
        commandsRect,
        this._commandBlocks,
        50
      ).layout()
    } else {
      const l = this._commandBlocks.length
      const row1 = this._commandBlocks.slice(0, Math.ceil(l / 2))
      const row2 = this._commandBlocks.slice(
        row1.length,
        this._commandBlocks.length
      )
      new LayoutFlex(Direction.horizontal, commandsRect, row1, row2).layout()
    }
  }

  private onMouseDown = (evt: MouseEvent) => {
    const block = evt.currentTarget as CommandBlock
    const c = block.command
    this._answerBlock.addCommand(c)
    this.stage?.update()
  }

  private async win() {
    await delay(500)
    this.playSound('success')
    const player = this._board.player
    if (player) {
      if (player.scaleY < 0) {
        Animator.flipVertical(player, 100)
      }
      await Animator.rotateTo(player, -720, 500)
    }
    this._callback()
  }

  private async lose() {
    await delay(500)
    this.playSound('error')
    const player = this._board.player
    if (player) {
      if (player.scaleY < 0) {
        Animator.flipVertical(player, 100)
      }
      await Animator.rotateTo(player, 0, 100)
      await delay(100)
      await Animator.lose(player)
    }
    this._board.reset()
  }
}
