import { CodeWalkCommand } from './coding-walk-board'
import { Point } from '../core/point'
import { TextLabelDeprecated } from '../core/text-label-deprecated'
// 'use client'
import * as createjs from 'createjs-module'

export class CommandBlock extends TextLabelDeprecated {
  private static SIZE = Point.xy(240, 60)
  private _command: CodeWalkCommand

  constructor(command: CodeWalkCommand) {
    super(CommandBlock.SIZE, CommandBlock.textFromCommand(command), true)
    this._command = command
    this.setBounds(0, 0, CommandBlock.SIZE.x, CommandBlock.SIZE.y)
    this.regX = CommandBlock.SIZE.x / 2
    this.regY = CommandBlock.SIZE.y / 2
  }

  public get command() {
    return this._command
  }

  public static textFromCommand(command: CodeWalkCommand) {
    switch (command) {
      case CodeWalkCommand.walk:
        return 'walk'
      case CodeWalkCommand.turnLeft:
        return 'turn left'
      case CodeWalkCommand.turnRight:
        return 'turn right'
      case CodeWalkCommand.jump:
        return 'jump'
      case CodeWalkCommand.dig:
        return 'dig'
    }
  }
}
