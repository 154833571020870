import LocalizedStrings from 'react-localization'
import { LanguageCookie } from './language-cookie'

class GameStrings {
  constructor() {
    const lang = LanguageCookie.getLanguage()
    this.setLanguage(lang)
  }

  public getTitleForGameId(gameId: string) {
    return this.title.getString(Strings.getKey(gameId))
  }

  public getTitleForLink(link: string) {
    return this.title.getString(Strings.getKey(link))
  }

  public getDescriptionForLink(link: string) {
    return this.description.getString(Strings.getKey(link))
  }

  public setLanguage(lang: string) {
    this.title.setLanguage(lang)
    this.description.setLanguage(lang)
  }

  private getKey(link: string) {
    return link.replace('./', '').replace(/-/g, '_')
  }

  private title = new LocalizedStrings({
    en: {
      match_abc: 'Match ABC',
      match_animal: 'Match Animal',
      match_dinosaur: 'Match Dinosaur',
      match_sea_animal: 'Match Sea Animal',
      match_color: 'Match Color',
      match_vehicle: 'Match Vehicle',
      match_food: 'Match Food',
      match_activity: 'Match Activity',
      write_uppercase_letters: 'Write Uppercase Letters',
      write_lowercase_letters: 'Write lowercase letters',
      write_number: 'Write numbers',
      painting_sea_animal: 'Paint Sea Animal',
      painting_animal: 'Paint Animal',
      painting_vehicle: 'Paint Vehicle',
      sightword_animal: 'Sight Words Animal',
      sightword_sea_animal: 'Sight Words Sea Animal',
      sightword_abc: 'Sight Words ABC',
      sightword_dinosaur: 'Sight Words Dinosaur',
      sightword_color: 'Sight Words Color',
      sightword_vehicle: 'Sight Words Vehicle',
      sightword_food: 'Sight Words Food',
      sightword_activity: 'Sight Words Activity',
      sightword_number: 'Sight Words Number',
      spelling_animal: 'Animal Spelling',
      spelling_sea_animal: 'Sea Animal Spelling',
      spelling_color: 'Color Spelling',
      spelling_vehicle: 'Vehicle Spelling',
      spelling_food: 'Food Spelling',
      spelling_activity: 'Activity Spelling',
      spelling_number: 'Number Spelling',
      shadow_animal: "Animals' Shadow",
      shadow_sea_animal: "Sea Animals' Shadow",
      shadow_dinosaur: "Dinosaurs' Shadow",
      shadow_vehicle: "Vehicles' Shadow",
      shadow_food: "Food's Shadow",
      counting_dinosaur: 'Counting Dinosaurs',
      counting_animal: 'Counting Animals',
      pattern_animal: 'Pattern',
      addition: 'Addition',
      addition_ii: 'Addition II',
      subtraction: 'Subtraction',
      subtraction_ii: 'Subtraction II',
      skip_counting: 'Skip Counting',
      sorting_animal: 'Sorting Animal',
      sorting_animal_ii: 'Sorting Animal II',
      sorting: 'Sorting',
      comparing: 'Comparing',
      animal_sticker: 'Sticker Book',
      animation: 'Animation',
      coding_rabbit: 'Coding Rabbit',
      sticker: 'Sticker Book',
      maze_game: 'Maze',
    },
    th: {
      match_abc: 'จับคู่ ABC',
      match_animal: 'จับคู่หัวกับตัวสัตว์',
      match_dinosaur: 'จับคู่หัวกับตัวไดโนเสาร์',
      match_sea_animal: 'จับคู่สัตว์น้ำ',
      match_color: 'จับคู่สี',
      match_vehicle: 'จับคู่ยานพาหนะ',
      match_food: 'จับคู่อาหาร',
      match_activity: 'จับคู่กิจกรรมต่างๆ',
      write_uppercase_letters: 'ฝึกเขียน ABC',
      write_lowercase_letters: 'ฝึกเขียน abc',
      write_number: 'ฝึกเขียนตัวเลข',
      painting_sea_animal: 'ระบายสีสัตว์น้ำ',
      painting_animal: 'ระบายสีสัตว์',
      painting_vehicle: 'ระบายสีรถ',
      sightword_animal: 'จับคู่ชื่อกับตัวสัตว์',
      sightword_sea_animal: 'จับคู่ชื่อกับตัวสัตว์น้ำ',
      sightword_abc: 'จับคู่ชื่อกับรูป ABC',
      sightword_dinosaur: 'จับคู่ชื่อกับตัวไดโนเสาร์',
      sightword_color: 'จับคู่ชื่อสี',
      sightword_vehicle: 'จับคู่ชื่อยานพาหนะ',
      sightword_food: 'จับคู่ชื่อของอาหาร',
      sightword_activity: 'จับคู่ชื่อกิจกรรม',
      sightword_number: 'จับคู่ชื่อตัวเลข',
      spelling_animal: 'สะกดชื่อสัตว์',
      spelling_sea_animal: 'สะกดชื่อสัตว์น้ำ',
      spelling_color: 'สะกดชื่อสี',
      spelling_vehicle: 'สะกดชื่อยานพาหนะ',
      spelling_food: 'สะกดชื่ออาหาร',
      spelling_activity: 'สะกดชื่อกิจกรรม',
      spelling_number: 'สะกดชื่อตัวเลข 1-12',
      shadow_animal: 'จับคู่ตัวสัตว์กับเงา',
      shadow_sea_animal: 'จับคู่ตัวสัตว์น้ำกับเงา',
      shadow_dinosaur: 'จับคู่ไดโนเสาร์กับเงา',
      shadow_vehicle: 'จับคู่ยานพาหนะกับเงา',
      shadow_food: 'จับคู่อาหารกับเงา',
      counting_dinosaur: 'นับเลข',
      counting_animal: 'นับเลข',
      pattern_animal: 'หาตัวสัตว์ที่หายไป',
      addition: 'บวกเลข',
      addition_ii: 'บวกเลข 2',
      subtraction: 'ลบเลข',
      subtraction_ii: 'ลบเลข 2',
      skip_counting: 'Skip Counting',
      sorting_animal: 'จับกลุ่มสัตว์',
      sorting_animal_ii: 'จับกลุ่มสัตว์ 2',
      sorting: 'Sorting',
      comparing: 'เปรียบเทียบตัวเลข',
      animal_sticker: 'หนังสือสติกเกอร์',
      animation: 'Animation',
      coding_rabbit: 'Coding กับเจ้ากระต่าย',
      sticker: 'หนังสือสติกเกอร์',
      maze_game: 'Maze',
    }
  })

  private description = new LocalizedStrings({
    en: {
      match_abc: 'Match a character to a letter',
      match_animal: "Match an animal's head to its body",
      match_dinosaur: "Match a dinosaur's head to its body",
      match_sea_animal: 'Match a sea animal with its friend',
      match_color: 'Match an animal that has a similar color',
      match_vehicle: "Match a vehicle to a background and learn vehicle's name",
      match_food: 'Match food to its group',
      match_activity: 'Match an activity to a matching background',
      write_uppercase_letters: 'Write Uppercase Letters from A to Z',
      write_lowercase_letters: 'Write lowercase letters from a to z',
      write_number: 'Write numbers from 1 to 12',
      painting_sea_animal:
        'Use your imagination and paint a cute character with colors',
      painting_animal:
        'Use your imagination and paint a cute character with colors',
      painting_vehicle:
        'Use your imagination and paint a cute vehicle with beautiful colors',
      sightword_animal: 'Animals Sight words games',
      sightword_sea_animal: 'Sea Animals Sight words games',
      sightword_abc: 'ABC Sight words games',
      sightword_dinosaur: 'Dinosaurs Sight words games',
      sightword_color: 'Colors Sight words games',
      sightword_vehicle: 'Vehicles Sight words games',
      sightword_food: 'Food Sight words games',
      sightword_activity: 'Activity Sight words games',
      sightword_number: 'Spot the word of a number',
      spelling_animal: "Learn how to spell many animal's names",
      spelling_sea_animal: "Learn how to spell many sea animal's names",
      spelling_color: "Learn how to spell color's names",
      spelling_vehicle: "Learn how to spell vehicle's names",
      spelling_food: "Learn how to spell food's names",
      spelling_activity: "Learn how to spell activity's names",
      spelling_number: 'Learn how to spell number one to tweleve',
      shadow_animal: 'Match an animal with its shadow that has the same shape',
      shadow_sea_animal:
        'Match a sea animal with its shadow that has the same shape',
      shadow_dinosaur:
        'Match a dinosaur with its shadow that has the same shape',
      shadow_vehicle: 'Match a vehicle with its shadow that has the same shape',
      shadow_food: 'Match food with its shadow that has the same shape',
      counting_dinosaur: 'Count dinosaurs and learn how to count 1-12 in Thai',
      counting_animal: 'Count animals and learn how to count 1-12 in English',
      pattern_animal: 'Learn how to build a pattern with cute animals',
      addition: 'Learn addition using two numbers',
      addition_ii: 'Learn double digits addition',
      subtraction: 'Learn subtraction using two numbers',
      subtraction_ii: 'Learn double digits subtraction',
      skip_counting:
        'Learn how to skip counting and improve addition and multiplication skills',
      sorting_animal: 'Sort the same type of animal into its group',
      sorting_animal_ii:
        'Classify animals into two groups based on characteristic that they are shared',
      sorting: 'Sorting',
      comparing: 'Learn how to compare two numbers',
      animal_sticker: 'Put cute stickers on a beautiful background',
      animation: '',
      coding_rabbit:
        'Learn how to code basic sequences with a cute rabbit and its yummy carrots',
      sticker: 'Update your stickers that you have collected as reward',
      maze_game: 'Find your exit through Maze',
    },
    th: {
      match_abc:
        'ทาง handtoy ขอเชิญชวนคุณหนูๆมาลองเล่นเกมจับคู่รูปสัตว์ต่างๆ กับตัวพยัญชนะภาษาอังกฤษ พบกับตัวละครต่างๆน่ารักมากมาย พร้อมทั้งเรียนรู้พยัญชนะภาษาอังกฤษ',
      match_animal:
        'คุณหนูๆมาลองเล่นเกมจับคู่หัวกับตัวสัตว์กันดูนะค่ะ พบกับสัตว์ต่างๆน่ารักมากมาย พร้อมทั้งเรียนรู้ชื่อสัตว์ภาษาอังกฤษ',
      match_dinosaur:
        'เกมจับคู่หัวกับตัวสำหรับเจ้าไดโนเสาร์น่ารักๆ เสริมทักษะกล้ามเนื้อมือ พร้อมทั้งฝึกเชาวน์ปัญญา และเรียนรู้ชื่อไดโนเสาร์ภาษาอังกฤษ',
      match_sea_animal:
        'เกมจับคู่สัตว์น้ำต่างๆแสนน่ารัก เสริมทักษะกล้ามเนื้อมือ และเรียนรู้ชื่อสัตว์น้ำในภาษาอังกฤษ',
      match_color:
        'คุณหนูๆมาลองจับคู่สี ลองมาฝึกเชาวน์ปัญญา พร้อมทั้งเสริมทักษะกล้ามเนื้อมือ',
      match_vehicle:
        'คุณหนูๆมาลองจับคู่ยานพาหนะ กับสิ่งแวดลอมของยานพาหนะกันนะคะ เรียนรู้ชื่อยานพาหนะในภาษาอังกฤษ',
      match_food:
        'คุณหนูๆมาลองจับคู่อาหาร กับหมวดหมู่ของอาหารกันนะคะ เรียนรู้ชื่ออาหารในภาษาอังกฤษ',
      match_activity:
        'คุณหนูๆมาลองจับคู่กิจกรรมต่างๆกับสถานที่ของมันกันคะ เรียนรู้ชื่อกิจกรรมต่างๆในภาษาอังกฤษ พร้อมพบตัวละครน่ารักๆ',
      write_uppercase_letters:
        'ฝึกเขียนตัวอักษร A-Z ตัวใหญ่ พร้อมเรียนรู้คำที่เริ่มต้นด้วยตัวอักษรนั้นๆ',
      write_lowercase_letters:
        'ฝึกเขียนตัวอักษร a-z ตัวเล็ก พร้อมเรียนรู้คำที่เริ่มต้นด้วยตัวอักษรนั้นๆ',
      write_number: 'ฝึกเขียนตัวเลข 1-12',
      painting_sea_animal:
        'เชิญชวนคุณหนูๆมาฝึกระบายสีตัวสัตว์น้ำน่ารักๆกันนะค่ะ เสริมสร้างความคิดสร้างสรรค์',
      painting_animal:
        'เชิญชวนคุณหนูๆมาฝึกระบายสีตัวสัตว์น่ารักๆกันนะค่ะ เสริมสร้างความคิดสร้างสรรค์',
      painting_vehicle:
        'เชิญชวนคุณหนูๆมาฝึกระบายสียานพาหนะน่ารักๆกันนะค่ะ เสริมสร้างความคิดสร้างสรรค์',
      sightword_animal:
        'คุณหนูๆมาลองจับคู่ตัวสัตว์กับชื่อของเจ้าตัวสัตว์กันนะคะ เรียนรู้ชื่อสัตว์ในภาษาอังกฤษ พร้อมทั้งเสริมทักษะกล้ามเนื้อมือ',
      sightword_sea_animal:
        'คุณหนูๆมาลองหาชื่อของเจ้าตัวสัตว์น้ำน่ารักๆกันนะคะ เรียนรู้ชื่อสัตว์น้ำในภาษาอังกฤษ พร้อมทั้งเสริมทักษะกล้ามเนื้อมือ',
      sightword_abc: '',
      sightword_dinosaur:
        'คุณหนูๆมาลองจับคู่ตัวไดโนเสาร์กับชื่อของเจ้าตัวไดโนเสาร์กันนะคะ เรียนรู้ชื่อของไดโนเสาร์ในภาษาอังกฤษ พร้อมทั้งเสริมทักษะกล้ามเนื้อมือ',
      sightword_color: 'คุณหนูๆมาลองเรียนรู้ชื่อของสีในในภาษาอังกฤษกันนะคะ',
      sightword_vehicle:
        'คุณหนูๆมาลองจับคู่ยานพาหนะกับชื่อของมันในภาษาอังกฤษกันนะค่ะ',
      sightword_food:
        'คุณหนูๆมาลองจับคู่อาหารกับชื่อของมันในภาษาอังกฤษกันนะค่ะ',
      sightword_activity:
        'คุณหนูๆมาลองจับคู่กิจกรรมกับชื่อของมันในภาษาอังกฤษกันนะค่ะ',
      sightword_number:
        'คุณหนูๆมาลองจับคู่ตัวเลขกับชื่อของมันในภาษาอังกฤษกันนะค่ะ',
      spelling_animal: 'คุณหนูๆมาลองฝึกสะกดชื่อสัตว์ในภาษาอังกฤษกันนะค่ะ',
      spelling_sea_animal:
        'คุณหนูๆมาลองฝึกสะกดชื่อสัตว์น้ำในภาษาอังกฤษกันนะค่ะ',
      spelling_color: 'คุณหนูๆมาลองฝึกสะกดชื่อสีในภาษาอังกฤษกันนะค่ะ',
      spelling_vehicle: 'คุณหนูๆมาลองฝึกสะกดชื่อยานพาหนะในภาษาอังกฤษกันนะค่ะ',
      spelling_food: 'คุณหนูๆมาลองฝึกสะกดชื่ออาหารในภาษาอังกฤษกันนะค่ะ',
      spelling_activity:
        'คุณหนูๆมาลองฝึกสะกดชื่อกิจกรรมต่างๆ ในภาษาอังกฤษกันนะค่ะ',
      spelling_number:
        'คุณหนูๆมาลองฝึกสะกดชื่อตัวเลข 1-12 ในภาษาอังกฤษกันนะค่ะ',
      shadow_animal:
        'คุณหนูๆมาลองจับคู่ตัวสัตว์กับเจ้าเงาของมันกันนะคะ ลองมาฝึกเชาวน์ปัญญา พร้อมทั้งเสริมทักษะกล้ามเนื้อมือ',
      shadow_sea_animal:
        'คุณหนูๆมาลองจับคู่ตัวสัตว์น้ำกับเจ้าเงาของมันกันนะคะ ลองมาฝึกเชาวน์ปัญญา พร้อมทั้งเสริมทักษะกล้ามเนื้อมือ',
      shadow_dinosaur:
        'คุณหนูๆมาลองจับคู่ตัวไดโนเสาร์กับเจ้าเงาของมันกันนะคะ ลองมาฝึกเชาวน์ปัญญา พร้อมทั้งเสริมทักษะกล้ามเนื้อมือ',
      shadow_vehicle:
        'คุณหนูๆมาลองจับคู่ยานพาหนะกับเจ้าเงาของมันกันนะคะ ลองมาฝึกเชาวน์ปัญญา พร้อมทั้งเสริมทักษะกล้ามเนื้อมือ',
      shadow_food:
        'คุณหนูๆมาลองจับคู่อาหารกับเจ้าเงาของมันกันนะคะ ลองมาฝึกเชาวน์ปัญญา พร้อมทั้งเสริมทักษะกล้ามเนื้อมือ',
      counting_dinosaur:
        'มาลองฝึกนับเลข 1 ถึง 12 กับตัวไดโนเสาร์น่ารักๆ นับเลขในภาษาไทยกับนะคะ',
      counting_animal: 'มาลองฝึกนับเลข 1 ถึง 12 ในภาษาอังกฤษกับนะคะ',
      pattern_animal:
        'ลองเล่นเกมหาตัวสัตว์ที่หายไปกันนะคะ ให้คุณหนูๆฝึกเชาวน์ปัญญา ค้นหา pattern ที่ซ่อนอยู่',
      addition: 'เกมฝึกบวกเลขสำหรับคุณหนูๆ บวกเลขหลักเดียวค่ะ',
      addition_ii: 'เกมฝึกบวกเลขสำหรับคุณหนูๆ บวกเลขสองหลักค่ะ',
      subtraction: 'เกมฝึกลบเลขสำหรับคุณหนูๆ เริ่มจากเลขหลักเดียวนะคะ',
      subtraction_ii: 'เกมฝึกลบเลขสำหรับคุณหนูๆ มาลองฝึกลบเลขสองหลักกันนะคะ',
      skip_counting:
        'เกมฝึกการนับเพิ่มที่ละ 2, 3, หรือเลขอื่นๆ เสริมสร้างทักษะบวกและคูณเลข',
      sorting_animal:
        'คุณหนูๆมาลองจับกลุ่มสัตว์แต่ละประเภทให้เข้าพวกของมันกันนะคะ',
      sorting_animal_ii:
        'คุณหนูๆมาลองจับกลุ่มประเภทสัตว์ต่างๆ ตามลักษณะของมันกันนะคะ',
      sorting: 'Sorting',
      comparing:
        'เกมฝึกให้คุณหนูๆ รู้จักการเปรียบเทียบเลขว่ามันมากกว่า หรือ น้อยกว่ากันค่ะ',
      animal_sticker:
        'ทาง handtoy ขอเชิญชวนคุณหนูๆ มาลองเล่นหนังสือ sticker กับทาง handtoy ฟรี',
      animation: '',
      coding_rabbit:
        'เรียนรู้การเขียนโปรแกรมแบบเริ่มต้น ด้วยการสร้างโปรแกรมคำสั่งพา เจ้ากระต่ายน้อยไปหาแครอทของมัน',
      sticker: 'หนังสือสติกเกอร์ให้คุณหนูปรับเปลี่ยนสติกเกอร์ที่สะสมมาได้ตามใจ',
      maze_game: 'สนุกสนานกับการหาทางออก',
    }
  })
}

export const Strings = new GameStrings()
