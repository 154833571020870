import { Point } from './point'

export class Rect {
  public static EMPTY = new Rect(Point.ZERO, Point.ZERO)

  public origin: Point
  public size: Point

  constructor(origin: Point, size: Point) {
    this.origin = origin.copy()
    this.size = size.copy()
  }

  public static from(x: number, y: number, w: number, h: number) {
    return new Rect(Point.xy(x, y), Point.xy(w, h))
  }

  public static fromBounds(bounds: {
    x: number
    y: number
    width: number
    height: number
  }) {
    return new Rect(
      Point.xy(bounds.x, bounds.y),
      Point.xy(bounds.width, bounds.height)
    )
  }

  public inset(dx: number, dy: number) {
    const p = Point.xy(dx, dy)
    const origin = this.origin.minus(p)
    const size = this.size.plus(p.multiply(2))
    return new Rect(origin, size)
  }

  public center() {
    return Point.xy(
      this.origin.x + this.size.x / 2,
      this.origin.y + this.size.y / 2
    )
  }

  public top() {
    return Point.xy(this.origin.x + this.size.x / 2, this.origin.y)
  }

  public left() {
    return Point.xy(this.origin.x, this.origin.y + this.size.y / 2)
  }

  public bottom() {
    return Point.xy(
      this.origin.x + this.size.x / 2,
      this.origin.y + this.size.y
    )
  }

  public right() {
    return Point.xy(
      this.origin.x + this.size.x,
      this.origin.y + this.size.y / 2
    )
  }

  public isPointIn(p: Point) {
    return (
      this.origin.x < p.x &&
      p.x < this.origin.x + this.size.x &&
      this.origin.y < p.y &&
      p.y < this.origin.y + this.size.y
    )
  }

  public toString() {
    return `origin=${this.origin.toString()},size=${this.size.toString()},center=${this.center().toString()}`
  }
}
