import Randomize from '../utils/randomize'

export class BackgroundPattern {
  private static s_preloaded = false

  private static list() {
    return ['ball', 'heart', 'star', 'boat', 'dot', 'flower', 'sun']
  }

  public static preload() {
    if (BackgroundPattern.s_preloaded) {
      return
    }
    BackgroundPattern.list()
      .map((l) => {
        return require(`../../images/background-icons/${l}.svg`)
      })
      .forEach((path) => {
        new Image().src = path
      })
    BackgroundPattern.s_preloaded = true
  }

  public static addPattern(ele: HTMLElement) {
    const list = BackgroundPattern.list()
    const pattern = Randomize.randomFromArray(list)
    const pattern2 = Randomize.randomFromArray(list)

    ele.style.backgroundImage = `url(${require(`../../images/background-icons/${pattern}.svg`)}), url(${require(`../../images/background-icons/${pattern2}.svg`)})`
    ele.style.backgroundColor = '#f9e9ef'
    ele.style.backgroundSize = '200px 200px'
    ele.style.backgroundPosition = '0 0, 100px 100px'
  }

  public static clearPattern(ele: HTMLElement) {
    ele.style.backgroundImage = ''
  }
}
