import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import {
  getGlobalTimer,
  setGlobalTimer,
  restoreGlobalTimer,
} from "../games/core/play-timout";

function formatTime(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const formattedSeconds =
    remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;

  return `${formattedMinutes}:${formattedSeconds}`;
}

const TimeoutOption: React.FC = () => {
  const [seconds, setSeconds] = useState<number>(getGlobalTimer());

  const setTimer = (time: number) => {
    setGlobalTimer(time * 60, setSeconds);
  };

  useEffect(() => {
    restoreGlobalTimer(setSeconds);
  }, []);

  const renderTimeoutButton = (n: number, disabled: boolean | undefined) => {
    return (
      <>
        <Button
          className="my-1 mx-1"
          onClick={() => {
            setTimer(n);
          }}
          color="primary"
          size="sm"
          disabled={disabled}
        >
          {n} mins
        </Button>
      </>
    );
  };

  const hasSetTimer = seconds > 0;
  const disableButton = hasSetTimer;
  const formattedTime = formatTime(seconds);

  const timers = [1, 5, 10, 15, 20, 30];

  return (
    <Container fluid className="text-center text-small">
      <Row className="text-center">
        <Col>
          <small>
            Set Timeout session: {hasSetTimer ? formattedTime : "no timeout"}
          </small>
        </Col>
      </Row>
      <Row>
        <Col>
          {timers.map((item) => renderTimeoutButton(item, disableButton))}
        </Col>
      </Row>
    </Container>
  );
};

export default TimeoutOption;
