import { BitmapLoader } from '../core/bitmap-loader'
import { IBasicImageFactory } from '../core/image-factory'
import { IPaintingImageFactory } from '../painting/painting-game'

export abstract class PaintingBaseImages
  implements IBasicImageFactory, IPaintingImageFactory {
  protected abstract pictures(): { [id: string]: string }
  protected abstract allLayers(): { [id: string]: string[] }

  protected static zeroToN(n: number, name: string, folder: string) {
    const a = []
    for (let i = 0; i <= n; ++i) {
      a.push(require(`../../images/${folder}/layers/${name}_${i}.png`))
    }
    return a
  }

  /* IBasicImageFactory */
  public getNames() {
    return Object.keys(this.pictures())
  }

  public async getImage(name: string) {
    return await BitmapLoader.loadAndCenter(this.pictures()[name])
  }

  public async preload(index: number) {
    const names = this.getNames()
    const name = names[index % names.length]
    await BitmapLoader.preloadAll([this.pictures()[name]])
  }

  public isPreloaded(name: string) {
    return BitmapLoader.isPreloaded(this.pictures()[name])
  }

  /* IPaintingImageFactor */
  getOutline(name: string): string {
    return this.pictures()[name]
  }
  getLayers(name: string): string[] {
    return this.allLayers()[name]
  }
}
