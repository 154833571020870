import { BitmapLoader } from '../core/bitmap-loader'
import { ImageFactoryBase } from '../core/image-factory'
import { Point } from '../core/point'

export class ActivityImages extends ImageFactoryBase {
  static IMAGES: { [id: string]: string } = {
    cooking: require('../../images/activities/cooking.png'),
    painting: require('../../images/activities/painting.png'),
    reading: require('../../images/activities/reading.png'),
    dressing: require('../../images/activities/dressing.png'),
    dining: require('../../images/activities/dining.png'),
    camping: require('../../images/activities/camping.png'),
    fishing: require('../../images/activities/fishing.png'),
    gardening: require('../../images/activities/gardening.png'),
    shopping: require('../../images/activities/shopping.png'),
    swimming: require('../../images/activities/swimming.png'),
    cleaning: require('../../images/activities/cleaning.png'),
    'going to bed': require('../../images/activities/going to bed.png'),
    'taking a bath': require('../../images/activities/taking a bath.png'),
    'taking a photo': require('../../images/activities/taking a photo.png')
  }

  static BACKGROUNDS: { [id: string]: string } = {
    cooking: require('../../images/activities/cooking_b.png'),
    painting: require('../../images/activities/painting_b.png'),
    reading: require('../../images/activities/reading_b.png'),
    dressing: require('../../images/activities/dressing_b.png'),
    dining: require('../../images/activities/dining_b.png'),
    camping: require('../../images/activities/camping_b.png'),
    fishing: require('../../images/activities/fishing_b.png'),
    gardening: require('../../images/activities/gardening_b.png'),
    shopping: require('../../images/activities/shopping_b.png'),
    swimming: require('../../images/activities/swimming_b.png'),
    cleaning: require('../../images/activities/cleaning_b.png'),
    'going to bed': require('../../images/activities/going to bed_b.png'),
    'taking a bath': require('../../images/activities/taking a bath_b.png'),
    'taking a photo': require('../../images/activities/taking a photo_b.png')
  }

  /* IMatchImageFactory */
  public getHead(name: string): string {
    return ActivityImages.IMAGES[name]
  }

  public getBody(name: string): string {
    return ActivityImages.BACKGROUNDS[name]
  }

  /* IBasicImageFactory */
  public getNames() {
    return Object.keys(ActivityImages.IMAGES)
  }

  public async getImage(name: string) {
    const img = await BitmapLoader.loadSubjectBackground(
      this.getHead(name),
      this.getBody(name),
      Point.xy(200, 14)
    )
    return img
  }
}
