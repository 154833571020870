"use client";
import * as createjs from "createjs-module";
import FullScreenCanvas from "./FullScreenCanvas";
import GamePageOption from "./GamePageOption";
import React from "react";
import ShareToFacebookModal from "./ShareToFacebookModal";
import { Animator } from "../games/core/animator";
import { BackgroundPattern } from "../games/resource/background-pattern";
import { GameBase } from "../games/core/game-base";
import { GameNextLevel } from "../games/utils/game-next-level";
import { GameSongs } from "../games/resource/game-songs";
import { GameStats } from "../games/utils/game-stats";
import { IBasicImageFactory } from "../games/core/image-factory";
import { IGameFactory } from "../games/core/game-factory";
import { IntroAnimation } from "../games/intro-animation/intro-animation";
import { isLandscape } from "../games/utils/orientation";
import { ISoundFactory } from "../games/core/sound-factory";
import { Language } from "../games/resource/language";
import { Point } from "../games/core/point";
import { StickerGame } from "../games/sticker/sticker-game";
import { StickerImages } from "../games/resource/sticker-images";
import { StickerSounds } from "../games/resource/sticker-sounds";
import { StickerStorage } from "../games/sticker/sticker-storage";
import { Strings } from "../games/utils/game-strings";
import { UserInfo } from "../games/utils/user-info";
import { restoreGlobalTimer } from "../games/core/play-timout";
import "./GamePage.css";

interface IGamePageProps {
  language: Language;
  gameFactory: IGameFactory;
  imageFactory: IBasicImageFactory;
  soundFactory: ISoundFactory;
  skipIntro?: boolean;
  excludeStats?: boolean;
  skipSticker?: boolean;
  gameId?: string;
}

interface IGamePageState {
  gameId: string;
  pageTitle: string;
  pageIndex: number;
  pagesCount: number;
  renderSize: Point;
  hasShownIntro: boolean;
  unlockedStickers: number;
  isAuth: boolean;
}

class GamePage extends React.Component<IGamePageProps, IGamePageState> {
  private stage: createjs.Stage | undefined;
  private game: GameBase | undefined;
  private shareModalRef = React.createRef<ShareToFacebookModal>();

  constructor(props: IGamePageProps) {
    super(props);

    const gameId = props.gameId ?? window.location.pathname.replace("/", "");
    this.state = {
      gameId,
      pageTitle: Strings.getTitleForLink(gameId),
      pageIndex: GameNextLevel.get(gameId),
      pagesCount: 0,
      renderSize: GameBase.layoutSize(isLandscape()),
      hasShownIntro: props.skipIntro ?? false,
      unlockedStickers: 0,
      isAuth: UserInfo.checkValidUser(),
    };
  }

  async componentDidMount() {
    const { pageTitle } = this.state;
    document.title = `${pageTitle} by handtoy`;
    BackgroundPattern.addPattern(document.body);

    this.stage = new createjs.Stage("game-canvas");
    createjs.Touch.enable(this.stage);
    this.componentDidMountInternal(this.stage);

    GameSongs.register();

    const unlockedStickers = await StickerStorage.computeUnlockStickers();
    this.setState({ unlockedStickers });

    window.addEventListener("blur", this.onBlur);
    window.addEventListener("focus", this.onFocus);

    restoreGlobalTimer(undefined);
  }

  componentWillUnmount() {
    window.removeEventListener("blur", this.onBlur);
    window.removeEventListener("focus", this.onFocus);

    this.game?.willUnmount();
    this.stage = undefined;
    GameSongs.stop();
    BackgroundPattern.clearPattern(document.body);
  }

  onBlur = () => {
    GameSongs.pause();
  };

  onFocus = () => {
    GameSongs.resume();
  };

  render() {
    const { renderSize, gameId, pageTitle, pageIndex, pagesCount, isAuth } =
      this.state;
    return (
      <div>
        <GamePageOption
          gameId={gameId}
          pageTitle={pageTitle}
          pageIndex={pageIndex}
          pagesCount={pagesCount}
          onBack={this.onBack}
          onNext={this.onNext}
        />
        <a href="/">
          <img
            className="top-left"
            src={require("../images/logo.png")}
            alt="logo"
            height="32px"
          />
        </a>
        <FullScreenCanvas canvasId="game-canvas" renderSize={renderSize} />
      </div>
    );
  }

  private async newLevel(pageIndex: number) {
    try {
      this.stage?.removeAllChildren();

      if (await this.showStickerIfNeeded()) {
        return;
      }

      const stage = this.stage;
      if (stage === undefined) {
        return;
      }

      this.game = this.props.gameFactory.create({
        pageIndex,
        imageFactory: this.props.imageFactory,
        soundFactory: this.props.soundFactory,
        language: this.props.language,
        landscape: isLandscape(),
      });
      this.game.setCallback(this.onGameCallback);
      stage.addChild(this.game);

      this.setState({
        pageIndex: pageIndex % this.game!.count(),
        pagesCount: this.game!.count(),
        renderSize: this.game!.layoutSize(isLandscape()),
      });

      await this.game.load();

      // preload image for next level
      this.props.imageFactory.preload(pageIndex + 1);
    } catch (_e) {
      console.error(_e);
    }
  }

  private async showIntro(stage: createjs.Stage) {
    try {
      stage.removeAllChildren();
      const intro = new IntroAnimation(
        this.state.pageTitle,
        this.state.renderSize,
        isLandscape()
      );
      stage.addChild(intro);
      await intro.load();

      this.props.soundFactory.register(this.props.language);

      await Promise.all([
        this.props.imageFactory.preload(this.state.pageIndex),
        intro.play(),
      ]);

      await intro.animateOut();

      this.setState({
        hasShownIntro: true,
      });

      const { pageIndex } = this.state;
      this.newLevel(pageIndex);
    } catch (_e) {
      console.error(_e);
    }
  }

  private componentDidMountInternal = (stage: createjs.Stage) => {
    try {
      const { pageIndex, hasShownIntro } = this.state;

      Animator.init(stage);

      if (!hasShownIntro) {
        this.showIntro(stage);
      } else {
        this.newLevel(pageIndex);
      }

      window.onorientationchange = () => {
        const game = this.game;
        if (game === undefined) {
          this.setState({
            renderSize: GameBase.layoutSize(isLandscape()),
          });
          return;
        }
        this.setState({
          renderSize: game.layoutSize(isLandscape()),
        });
        game.layout(isLandscape());
        game.stage?.update();
      };
    } catch (_e) {
      console.error(_e);
    }
  };

  private onGameCallback = async () => {
    this.game?.setCallback(() => {});
    const { gameId, pageIndex, pagesCount } = this.state;
    const { excludeStats } = this.props;
    if (excludeStats !== true) {
      try {
        await GameStats.increment(gameId, pageIndex, pagesCount);
      } catch (_e) {}
    }
    this.onNext();
  };

  private onBack = () => {
    const { gameId, pageIndex, pagesCount } = this.state;
    const newIndex = pageIndex === 0 ? pagesCount - 1 : pageIndex - 1;
    GameNextLevel.save(gameId, newIndex);
    this.newLevel(newIndex);
  };

  private onNext = () => {
    const { gameId, pageIndex, pagesCount } = this.state;
    const newIndex = (pageIndex + 1) % pagesCount;
    GameNextLevel.save(gameId, newIndex);
    this.newLevel(newIndex);
  };

  private async showStickerIfNeeded() {
    // For now, we will skip sticker

    return false;

    // const { skipSticker } = this.props
    // if (skipSticker) {
    //   return false
    // }

    // const { pageIndex, pagesCount } = this.state
    // if (pageIndex !== pagesCount - 1) {
    //   return false
    // }

    // const stage = this.stage
    // if (stage === undefined) {
    //   return false
    // }

    // const { unlockedStickers } = this.state
    // const count = await StickerStorage.computeUnlockStickers()

    // if (count > unlockedStickers) {
    //   this.game = new StickerGame({
    //     pageIndex: Math.floor((count - 1) / 6),
    //     imageFactory: new StickerImages(),
    //     soundFactory: new StickerSounds(),
    //     language: this.props.language,
    //     landscape: isLandscape()
    //   })
    //   this.game.setCallback(this.onNext)
    //   stage.addChild(this.game)
    //   stage.update()

    //   this.setState({
    //     unlockedStickers: count,
    //     renderSize: this.game.layoutSize(isLandscape())
    //   })

    //   await this.game.load()

    //   if (!this.state.isAuth) {
    //     this.shareModalRef.current?.show()
    //   }

    //   return true
    // }

    // return false
  }
}

export default GamePage;
