'use client'
import * as createjs from 'createjs-module'
import Randomize from '../utils/randomize'
import { Animator } from '../core/animator'
import { BitmapLoader } from '../core/bitmap-loader'
import { BitmapTile } from '../core/bitmap-tile'
import { Color } from '../core/color'
import { delay } from '../utils/delay'
import { Direction } from '../core/layout-flex'
import { DraggableTile } from '../core/draggable-tile'
import { GameBase, IGameConfig } from '../core/game-base'
import { IBasicImageFactory } from '../core/image-factory'
import { LayoutFlex } from '../core/layout-flex'
import { Point } from '../core/point'
import { Rect } from '../core/rect'

export class PatternGame extends GameBase {
  public static SHORTER = 800
  public static LONGER = 1300

  protected _imageFactory: IBasicImageFactory
  protected _questions: createjs.DisplayObject[] = []
  protected _answers: BitmapTile[] = []

  private _pattern: string[]
  private _scale: number
  private _missingIndex: number
  private _win: boolean = false

  constructor(config: IGameConfig) {
    super(config)
    this._imageFactory = config.imageFactory
    this._pattern = this.generatePattern()
    this._missingIndex = Randomize.randomInt(0, this._pattern.length - 1)
    this._scale = this._pattern.length <= 6 ? 0.55 : 0.4

    this.onLifted = this.onLifted.bind(this)
    this.onPlaced = this.onPlaced.bind(this)
  }

  public layoutSize(landscape: boolean) {
    super.layoutSize(landscape)
    if (landscape) {
      return new Point(PatternGame.LONGER, PatternGame.SHORTER)
    } else {
      return new Point(PatternGame.SHORTER, PatternGame.LONGER)
    }
  }

  public count() {
    return this.patterns().length
  }

  private patterns() {
    const patterns = [
      [0, 1, 0, 1, 0, 1],
      [0, 0, 1, 1, 0, 0],
      [0, 0, 1, 0, 0, 1],
      [1, 0, 0, 1, 0, 0],
      [1, 0, 1, 1, 0, 1],
      [0, 1, 2, 0, 1, 2],

      [0, 0, 0, 1, 0, 0, 0, 1],
      [0, 0, 1, 1, 0, 0, 1, 1],
      [1, 0, 0, 1, 1, 0, 0, 1],
      [1, 1, 0, 0, 1, 1, 0, 0],
      [0, 0, 1, 2, 0, 0, 1, 2],
      [0, 1, 2, 2, 0, 1, 2, 2],
      [0, 1, 2, 1, 0, 1, 2, 1],
      [0, 1, 2, 0, 0, 1, 2, 0]
    ]
    return patterns
  }

  private generatePattern() {
    const patterns = this.patterns()
    const pattern = patterns[this._pageIndex % patterns.length]
    const names = Randomize.shuffle(this._names)
    return pattern.map((i) => names[i])
  }

  async load() {
    await this.loadQuestion()
    await this.loadAnswers()
    this.layout(this._landscape)
    this.stage?.update()

    this._answers.forEach((f) => {
      Animator.fadeIn(f)
    })
    this._questions.forEach((f) => {
      Animator.fadeIn(f)
    })
    await delay(GameBase.INTROSOUNDDELAY)
    this.playSound('build a pattern')
  }

  private async createBitmaps(names: string[]) {
    const bitmaps = (
      await Promise.all(names.map((n) => this._imageFactory.getImage(n)))
    ).map((n) => BitmapLoader.scale(n, this._scale))
    return bitmaps
  }

  protected async loadQuestion() {
    const bitmaps = await this.createBitmaps(this._pattern)

    for (let i = 0; i < bitmaps.length; ++i) {
      if (i === this._missingIndex) {
        const padding = 4
        const w = bitmaps[i].image.width + padding * 2
        const h = bitmaps[i].image.height + padding * 2
        const shape = new createjs.Shape()
        shape.graphics
          .beginStroke(Color.randomTextColor())
          .setStrokeStyle(6)
          .drawRoundRect(0, 0, w, h, 10)
        shape.regX = w / 2
        shape.regY = h / 2
        shape.alpha = 0
        this.addChild(shape)
        this._questions.push(shape)
      } else {
        bitmaps[i].alpha = 0
        this.addChild(bitmaps[i])
        this._questions.push(bitmaps[i])
      }
    }
  }

  protected async loadAnswers() {
    const unique = Array.from(new Set(this._pattern))
    const bitmaps = await this.createBitmaps(unique)

    for (let i = 0; i < bitmaps.length; ++i) {
      const tile = new BitmapTile(Point.ZERO, Point.ZERO, Point.ZERO)
      tile.alpha = 0
      tile.name = unique[i]
      tile.onLifted = this.onLifted
      tile.onPlaced = this.onPlaced
      this.addChild(tile)
      tile.loadFromBitmap(bitmaps[i])
      this._answers.push(tile)
    }
  }

  public layout(landscape: boolean) {
    const layoutSize = this.layoutSize(landscape)

    const rect = new Rect(Point.ZERO, layoutSize).inset(-20, -100)

    new LayoutFlex(
      landscape ? Direction.horizontal : Direction.vertical,
      rect,
      this._questions,
      this._answers
    ).layout()

    this.stage?.update()
  }

  protected onLifted(t: DraggableTile) {
    // this.playSound(t.name)
  }

  protected onPlaced(t: DraggableTile) {
    const target = Point.from(this._questions[this._missingIndex])
    const current = new Point(t.x, t.y)
    // console.log(`placed ${target.toString()}, ${current.toString()}`)
    if (target.isRoughlyEqual(current, 150)) {
      if (t.name === this._pattern[this._missingIndex]) {
        this.win(t)
      } else {
        this.lose(t)
      }
      return target
    }
    return undefined
  }

  protected async win(t: DraggableTile) {
    this._win = true
    this.playSound('success')
    Animator.fadeOut(this._questions[this._missingIndex])
    this._answers.forEach((a) => {
      if (a.name !== this._pattern[this._missingIndex]) {
        Animator.fadeOut(a)
      }
    })
    await Animator.win(t)
    this.animateSummary()
  }

  protected async lose(t: DraggableTile) {
    Animator.lose(t)
    this.playSound('error')
    await delay(500)
    t.setPositionToOrigin()
    this.stage?.update()
  }

  protected async animateSummary() {
    // const q = this._question
    // if (q !== undefined) {
    //   const distance = new Point(this._size.x / 2 - q.x, this._size.y / 2 - q.y)
    //   Animator.move(this._question!, distance)
    // }
    // await delay(1500)
    // this.playSound(this._currentCount.toString())
    await delay(GameBase.WINDELAY)
    this._callback()
  }
}
