'use client'
import * as createjs from 'createjs-module'
import { Point } from './point'

export class BitmapLoader {
  private static s_queue = new createjs.LoadQueue()

  private static preload(src: string) {
    if (!BitmapLoader.isPreloaded(src)) {
      BitmapLoader.s_queue.loadManifest([{ id: src, src: src }])
      return true
    }
    return false
  }

  public static isPreloaded(src: string) {
    const img = BitmapLoader.s_queue.getResult(src)
    return img !== undefined && img !== null
  }

  public static preloadAll(sources: string[]): Promise<void> {
    return new Promise((resolve) => {
      BitmapLoader.s_queue.on('complete', () => {
        resolve()
      })

      let finished = false
      for (let i = 0; i < sources.length; ++i) {
        const f = BitmapLoader.preload(sources[i])
        finished = finished && f
      }

      BitmapLoader.s_queue.load()

      if (finished) {
        resolve()
      }
    })
  }

  public static loadRawImage(src: string): Promise<HTMLImageElement> {
    return new Promise<HTMLImageElement>((resolve) => {
      // Check cache in the load queue first
      const img = BitmapLoader.s_queue.getResult(src)
      if (img !== undefined && img !== null) {
        resolve(img as HTMLImageElement)
        return
      }

      // Missed from the load queue
      var image = new Image()
      image.src = src
      image.onload = (event) => {
        const image = event.target as HTMLImageElement
        resolve(image)
      }
    })
  }

  public static async load(src: string): Promise<createjs.Bitmap> {
    const image = await BitmapLoader.loadRawImage(src)
    const bitmap = new createjs.Bitmap(image)
    return bitmap
  }

  public static async loadAndCenter(src: string) {
    const bitmap = await BitmapLoader.load(src)
    bitmap.regX = bitmap.image.width / 2
    bitmap.regY = bitmap.image.height / 2
    return bitmap
  }

  public static async loadHeadBody(
    srcHead: string,
    srcBody: string,
    distance: Point
  ) {
    const body = await this.load(srcBody)
    const head = await this.load(srcHead)

    const container = new createjs.Container()
    container.addChild(body)
    container.addChild(head)

    const px = 0
    const py = 0
    const pw = body.image.width / 2 + head.image.width / 2 - distance.x * 2
    const ph = body.image.height / 2 + head.image.height / 2 - distance.y * 2

    head.x = pw / 2 - head.image.width / 2 + distance.x / 2
    head.y = ph / 2 - head.image.height / 2 + distance.y / 2
    body.x = pw / 2 - body.image.width / 2 - distance.x / 2
    body.y = ph / 2 - body.image.height / 2 - distance.y / 2

    // console.log(`bounds ${px}, ${py}, ${pw}, ${ph}`)
    container.cache(px, py, pw, ph)
    container.setBounds(px, py, pw, ph)

    const bitmap = new createjs.Bitmap(container.cacheCanvas)
    bitmap.regX = pw / 2
    bitmap.regY = ph / 2
    return bitmap
  }

  public static async loadSubjectBackground(
    srcSubject: string,
    srcBackground: string,
    location: Point
  ) {
    const subject = await this.load(srcSubject)
    const background = await this.load(srcBackground)

    const container = new createjs.Container()
    container.addChild(background)
    container.addChild(subject)

    subject.x = location.x
    subject.y = location.y

    const px = 0
    const py = 0
    const pw = background.image.width
    const ph = background.image.height

    // console.log(`bounds ${px}, ${py}, ${pw}, ${ph}`)
    container.cache(px, py, pw, ph)
    container.setBounds(px, py, pw, ph)

    const bitmap = new createjs.Bitmap(container.cacheCanvas)
    bitmap.regX = pw / 2
    bitmap.regY = ph / 2
    return bitmap
  }

  public static scale(src: createjs.Bitmap, scale: number): createjs.Bitmap {
    if (scale < 0.5) {
      const s1 = scale * 2
      return this.scale(this.scale(src, s1), 0.5)
    } else {
      src.cache(0, 0, src.image.width, src.image.height, scale)
      const bitmap = new createjs.Bitmap(src.cacheCanvas)
      bitmap.regX = bitmap.image.width / 2
      bitmap.regY = bitmap.image.height / 2
      bitmap.setBounds(0, 0, bitmap.image.width, bitmap.image.height)
      return bitmap
    }
  }

  public static scaleToFit(src: createjs.Bitmap, size: Point): createjs.Bitmap {
    const scale = Math.min(size.w / src.image.width, size.h / src.image.height)
    return BitmapLoader.scale(src, scale)
  }

  // public static scale(src: createjs.Bitmap, scale: number): createjs.Bitmap {
  //   const canvas = document.createElement('canvas')
  //   const ctx = canvas.getContext('2d')

  //   if (ctx === null) {
  //     return new createjs.Bitmap(src.image)
  //   }

  //   const w = src.image.width
  //   const h = src.image.height

  //   // ctx.save()
  //   // ctx.clip()
  //   ctx.drawImage(src.image, 0, 0, w, h, 0, 0, w * scale, h * scale)
  //   // ctx.restore()

  //   const bitmap = new createjs.Bitmap(canvas)
  //   bitmap.regX = bitmap.image.width / 2
  //   bitmap.regY = bitmap.image.height / 2
  //   return bitmap
  // }
}
