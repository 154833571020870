'use client'
import * as createjs from 'createjs-module'
import { Animator } from '../core/animator'
import { BitmapGrid } from '../core/bitmap-grid'
import { Color } from '../core/color'
import { delay } from '../utils/delay'
import { Direction, LayoutFlex } from '../core/layout-flex'
import { DraggableTile } from '../core/draggable-tile'
import { GameBase, IGameConfig } from '../core/game-base'
import { GameSound } from '../resource/game-sound'
import { IBasicImageFactory } from '../core/image-factory'
import { LayoutFlexStack } from '../core/layout-flex-stack'
import { NumberSounds } from '../resource/number-sounds'
import { Point } from '../core/point'
import { Rect } from '../core/rect'
import { ResizableShapeDeprecated } from '../core/resizable-shape'
import { TextTileDeprecated } from '../core/text-tile-deprecated'

export class ComparingGame extends GameBase {
  protected _imageFactory: IBasicImageFactory

  protected _questions: createjs.DisplayObject[] = []
  protected _answers: createjs.DisplayObject[] = []
  protected _background: ResizableShapeDeprecated | undefined
  protected _number1: number
  protected _number2: number

  protected _distanceToCenter: Point = Point.ZERO
  protected _win: boolean = false

  private _grid1: BitmapGrid | undefined
  private _grid2: BitmapGrid | undefined

  constructor(config: IGameConfig) {
    super(config)
    this._pageIndex = config.pageIndex % this.count()
    this._imageFactory = config.imageFactory

    const [n1, n2] = this.questions()[this._pageIndex % this.count()]
    this._number1 = n1
    this._number2 = n2

    this.onLifted = this.onLifted.bind(this)
    this.onPlaced = this.onPlaced.bind(this)

    GameSound.register(NumberSounds.LETSCOMAPRE)
  }

  questions() {
    return [
      [1, 2],
      [2, 1],
      [2, 2],
      [4, 3],
      [4, 1],
      [4, 4],
      [2, 5],
      [6, 3],
      [7, 5],
      [6, 7],
      [1, 8],
      [9, 4],
      [8, 9]
    ]
  }

  count() {
    return this.questions().length
  }

  protected tileSize() {
    return Point.xy(250, 250)
  }

  public layoutSize(landscape: boolean) {
    super.layoutSize(landscape)
    return Point.xy(840, 800)
  }

  async load() {
    this._background = new ResizableShapeDeprecated(Point.ZERO, {
      color: 'rgba(255, 255, 255, 0.7)',
      r: 20,
      borderColor: '',
      borderWidth: 0
    })
    this.addChild(this._background)

    await this.loadQuestion()
    await this.loadAnswers()
    this.layout(this._landscape)
    this.stage?.update()

    this._questions.forEach((f) => {
      Animator.fadeIn(f)
    })

    await this.playIntroSound()

    this._answers.forEach((f) => {
      Animator.fadeIn(f)
    })
  }

  public layout(landscape: boolean) {
    landscape = true

    const layoutSize = this.layoutSize(landscape)

    let rect = new Rect(Point.ZERO, layoutSize)

    if (!landscape) {
      // rect = new Rect(rect.origin.minus(Point.xy(40, 0)), rect.size)
      rect = rect.inset(-40, 0)
    } else {
      rect = rect.inset(-20, -80)
    }

    this._background!.origin = rect.origin
    this._background!.size = rect.size
    this._background!.layout()

    const layoutFlex = new LayoutFlex(
      landscape ? Direction.horizontal : Direction.vertical,
      rect,
      this._questions,
      this._answers
    )
    layoutFlex.layout()

    new LayoutFlexStack(
      landscape ? Direction.horizontal : Direction.vertical,
      layoutFlex.layoutSizeForQuestion(),
      this._questions,
      50
    ).layout()

    this._distanceToCenter = layoutFlex.distanceFromCenter(
      layoutFlex.layoutSizeForQuestion()
    )

    this.stage?.update()

    this._grid1?.layout(true)
    this._grid2?.layout(true)

    this.stage?.update()
  }

  protected async loadQuestion() {
    const size = Point.xy(220, 220)
    const textColor = Color.randomTextColor()

    const src = await this.getImage1()
    const grid = new BitmapGrid(src, size, this._number1)
    this._grid1 = grid
    this._grid1.alpha = 0
    this.addChild(grid)
    this._questions.push(grid)

    const shapeRect = size.minus(Point.xy(40, 40))
    const shape = new ResizableShapeDeprecated(shapeRect, {
      color: 'white',
      r: 30,
      borderColor: textColor,
      borderWidth: 5
    })
    shape.regX = shapeRect.width() / 2
    shape.regY = shapeRect.height() / 2
    shape.alpha = 0
    this.addChild(shape)
    this._questions.push(shape)

    const src2 = await this.getImage2()
    const grid2 = new BitmapGrid(src2, size, this._number2)
    this._grid2 = grid2
    this._grid2.alpha = 0
    this.addChild(grid2)
    this._questions.push(grid2)
  }

  protected async loadAnswers() {
    const size = this.tileSize()
    const answers = ['>', '<', '=']
    for (let i = 0; i < answers.length; ++i) {
      const t = new TextTileDeprecated(size, answers[i].toString())
      t.alpha = 0
      t.name = answers[i]
      t.onPlaced = this.onPlaced
      t.onLifted = this.onLifted
      t.setTextColor(Color.randomTextColor())
      this.addChild(t)
      this._answers.push(t)
    }
  }

  protected async playIntroSound() {
    await delay(GameBase.INTROSOUNDDELAY)
    await this.playSound('lets compare')
    this.playSound(this._number1.toString())
    await delay(1000)
    this.playSound('and')
    await delay(300)
    this.playSound(this._number2.toString())
  }

  protected async onLifted(t: DraggableTile) {
    await this.playTileSound(t)
  }

  protected onPlaced(t: DraggableTile) {
    const target = Point.from(this.placedTarget())
    const current = new Point(t.x, t.y)
    if (target.isRoughlyEqual(current, 150)) {
      if (this.checkAnswer(t)) {
        this.win(t)
        t.canDrag = false
      } else {
        this.lose(t)
      }
      return target
    }
    return undefined
  }

  protected placedTarget() {
    return this._questions[1]
  }

  protected checkAnswer(t: DraggableTile) {
    let sign = '='
    if (this._number1 === this._number2) {
      sign = '='
    } else if (this._number1 > this._number2) {
      sign = '>'
    } else {
      sign = '<'
    }
    return t.name === sign
  }

  protected async win(t: DraggableTile) {
    if (this._win) {
      return
    }

    Animator.fadeOut(this.placedTarget())
    this._win = true
    this.playSound('success')

    this._answers.forEach((a) => {
      if (a !== t) {
        Animator.fadeOut(a)
      }
    })
    if (t.name !== '=') {
      t.regY += 30
    }
    await Animator.win(t)

    this.animateSummary(t)
  }

  protected async lose(t: DraggableTile) {
    Animator.lose(t)
    this.playSound('error')
    await delay(500)
    t.setPositionToOrigin()
    t.stage?.update()
  }

  protected async animateSummary(t: DraggableTile) {
    // console.log(`animateSummary ${this._distanceToCenter.toString()}`)
    const objs = [...this._questions, t]
    objs.forEach((o) => {
      Animator.move(o, this._distanceToCenter)
    })

    await this.soundSummary(t)
    await delay(GameBase.WINDELAY)
    this._callback()
  }

  protected async soundSummary(t: DraggableTile) {
    this.playSound(this._number1.toString())
    await delay(1000)

    if (t.name !== '=') {
      this.playSound('is')
      await delay(100)
    }
    await this.playTileSound(t)
    await this.playSound(this._number2.toString())
  }

  private async playTileSound(t: DraggableTile) {
    if (t.name === '>') {
      this.playSound('greater')
      await delay(700)
      this.playSound('than')
      await delay(700)
    } else if (t.name === '<') {
      this.playSound('less')
      await delay(500)
      this.playSound('than')
      await delay(700)
    } else {
      this.playSound('equals')
      await delay(1000)
    }
  }

  private async getImage1() {
    const names = this._imageFactory.getNames()
    const name = names[this._pageIndex % names.length]
    return await this._imageFactory.getImage(name)
  }

  private async getImage2() {
    const names = this._imageFactory.getNames()
    const name = names[(this._pageIndex + 1) % names.length]
    return await this._imageFactory.getImage(name)
  }
}
