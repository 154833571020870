import { PaintingBaseSounds } from './painting-base-sounds'

export class PaintingSeaAnimalSounds extends PaintingBaseSounds {
  public static ANIMALS: { [id: string]: string } = {
    whale: require('../../sounds/whale.mp3'),
    jellyfish: require('../../sounds/jellyfish.mp3'),
    frog: require('../../sounds/frog.mp3'),

    crab: require('../../sounds/sea-animals/crab.mp3'),
    dolphin: require('../../sounds/sea-animals/dolphin.mp3'),
    'puffer fish': require('../../sounds/sea-animals/puffer fish.mp3'),
    shrimp: require('../../sounds/sea-animals/shrimp.mp3'),
    squid: require('../../sounds/sea-animals/squid.mp3'),
    turtle: require('../../sounds/sea-animals/turtle.mp3')
  }

  protected allSounds() {
    return PaintingSeaAnimalSounds.ANIMALS
  }
}
