'use client'
import * as createjs from 'createjs-module'
import { Direction } from './layout-flex'
import { Point } from './point'
import { Rect } from './rect'

export class LayoutStack {
  private _direction: Direction
  private _container: Rect
  private _count: number
  private _boxSize: Point

  constructor(
    direction: Direction,
    container: Rect,
    count: number,
    boxSize: Point
  ) {
    this._direction = direction
    this._container = container
    this._count = count
    this._boxSize = boxSize
  }

  public horizontal() {
    return this._direction === Direction.horizontal
  }

  public stack() {
    if (this.horizontal()) {
      const w = this._container.size.width()
      const totalWidth = this._boxSize.width() * this._count
      const padding = (w - totalWidth) / this._count
      const distance = padding + this._boxSize.width()

      const arrayOfX = []
      var currentX = this._container.origin.x
      for (let i = 0; i < this._count; ++i) {
        currentX += i === 0 ? distance / 2 : distance
        arrayOfX.push(currentX)
      }

      const y = this._container.origin.y + this._container.size.height() / 2
      return arrayOfX.map((x) => new Point(x, y))
    } else {
      const h = this._container.size.height()
      const total = this._boxSize.height() * this._count
      const padding = Math.max(h - total, 0) / this._count
      const distance = padding + this._boxSize.height()

      const arrayOfY = []
      var currentY = this._container.origin.y
      for (let i = 0; i < this._count; ++i) {
        currentY += i === 0 ? distance / 2 : distance
        arrayOfY.push(currentY)
      }

      const x = this._container.origin.x + this._container.size.width() / 2
      return arrayOfY.map((y) => new Point(x, y))
    }
  }

  public apply(objs: createjs.DisplayObject[]) {
    const stack = this.stack()
    for (let i = 0; i < objs.length; ++i) {
      const obj = objs[i]
      const pt = stack[i]
      obj.x = pt.x
      obj.y = pt.y
      // const bounds = obj.getBounds()
      // if (bounds !== null) {
      //   const currentSize = Point.fromWidthHeight(obj.getBounds())
      //   const scale = this.scaleToFit(currentSize, rect.size)
      //   obj.scaleX = scale
      //   obj.scaleY = scale
      //   console.log(
      //     `LayoutStack apply scale=${scale}, currentSize=${currentSize.toString()}, frame=${rect.size.toString()}`
      //   )
      // }
      if (obj.hasOwnProperty('origin')) {
        Object.assign(obj, { origin: pt })
      }
      // console.log(`LayoutStack apply p=${pass}, i=${i}, r=${rect.toString()}`)
    }
  }
}
