import Randomize from '../utils/randomize'
import { AdditionGame } from './addition-game'
import { Point } from '../core/point'

export default class AdditionIIGame extends AdditionGame {
  // questions() {
  //   return [
  //     [8, 4],
  //     [9, 8],
  //     [20, 10],
  //     [50, 40],
  //     [11, 12],
  //     [15, 13],
  //     [23, 12],
  //     [17, 21],
  //     [22, 42],
  //     [53, 9],
  //     [44, 17],
  //     [21, 59]
  //   ]
  // }

  question() {
    const n1 = Randomize.randomInt(10, 99)
    let n2 = n1
    do {
      n2 = Randomize.randomInt(10, 99)
    } while (n2 === n1)
    return n1 > n2 ? [n1 - n2, n2] : [n1, n2 - n1]
  }

  protected tileSize() {
    return Point.xy(160, 160)
  }
}
