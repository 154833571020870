import { CodeWalkCommand } from './coding-walk-board'
import { Point } from '../core/point'

export interface ICodingWalkGameLevel {
  dimension: Point
  possibleCommands: CodeWalkCommand[]
  player: Point
  target: Point[]
  water?: Point[]
  hole?: Point[]
  verticalFence?: Point[]
  horizontalFence?: Point[]
}

function p(x: number, y: number) {
  return Point.xy(x, y)
}

export function getCodingWalkGameLevels(): ICodingWalkGameLevel[] {
  const three = p(3, 3)
  const four = p(4, 4)
  // const five = p(5, 5)
  // const six = p(6, 6)

  const threeCommands = [
    CodeWalkCommand.walk,
    CodeWalkCommand.turnLeft,
    CodeWalkCommand.turnRight
  ]

  const fourCommands = [
    CodeWalkCommand.walk,
    CodeWalkCommand.turnLeft,
    CodeWalkCommand.turnRight,
    CodeWalkCommand.jump
  ]

  const fiveCommands = [
    CodeWalkCommand.walk,
    CodeWalkCommand.turnLeft,
    CodeWalkCommand.turnRight,
    CodeWalkCommand.jump,
    CodeWalkCommand.dig
  ]

  return [
    {
      // walk 1
      dimension: three,
      possibleCommands: threeCommands,
      player: p(2, 1),
      target: [p(0, 1)]
    },
    {
      // walk 2
      dimension: three,
      possibleCommands: threeCommands,
      player: p(2, 1),
      target: [p(0, 0)]
    },
    {
      // walk 3
      dimension: three,
      possibleCommands: threeCommands,
      player: p(2, 0),
      target: [p(0, 2)]
    },
    {
      // water 1
      dimension: three,
      possibleCommands: threeCommands,
      player: p(2, 1),
      target: [p(0, 1)],
      water: [p(1, 1)]
    },
    {
      // water 2
      dimension: three,
      possibleCommands: fourCommands,
      player: p(2, 1),
      target: [p(0, 1)],
      water: [p(1, 0), p(1, 1), p(1, 2)]
    },
    {
      // water 3
      dimension: three,
      possibleCommands: fourCommands,
      player: p(2, 2),
      target: [p(0, 0)],
      water: [p(1, 0), p(1, 1), p(1, 2), p(0, 1), p(2, 1)]
    },
    {
      // targets 1
      dimension: three,
      possibleCommands: fourCommands,
      player: p(2, 1),
      target: [p(0, 1), p(1, 1)]
    },
    {
      // targets 2
      dimension: three,
      possibleCommands: fourCommands,
      player: p(2, 0),
      target: [p(0, 0), p(0, 2)],
      water: [p(0, 1), p(1, 1), p(2, 1)]
    },
    {
      // fence 1
      dimension: three,
      possibleCommands: fourCommands,
      player: p(2, 0),
      target: [p(0, 1)],
      verticalFence: [p(1, 0), p(1, 2)]
    },
    {
      // fence 2
      dimension: three,
      possibleCommands: fourCommands,
      player: p(2, 2),
      target: [p(0, 0), p(0, 2)],
      horizontalFence: [p(0, 1), p(1, 1)]
    },
    {
      // 8 - dig 1
      dimension: three,
      possibleCommands: fiveCommands,
      player: p(2, 0),
      target: [p(0, 2)],
      horizontalFence: [p(0, 1), p(1, 1), p(2, 1)],
      hole: [p(0, 0), p(2, 2)]
    },
    {
      // 9 - dig 2
      dimension: four,
      possibleCommands: fiveCommands,
      player: p(3, 0),
      target: [p(0, 2)],
      water: [p(2, 1), p(2, 2), p(3, 2)],
      verticalFence: [p(1, 0), p(1, 1), p(1, 2), p(1, 3)],
      hole: [p(0, 0), p(3, 3)]
    },
    {
      // 10
      dimension: four,
      possibleCommands: fiveCommands,
      player: p(3, 0),
      target: [p(3, 3), p(0, 3)],
      water: [
        p(1, 0),
        p(2, 0),
        p(0, 1),
        p(1, 1),
        p(2, 1),
        p(3, 1),
        p(0, 2),
        p(1, 2),
        p(2, 2),
        p(3, 2),
        p(1, 3),
        p(2, 3)
      ],
      hole: [p(3, 0), p(0, 0), p(3, 3), p(0, 3)]
    },
    {
      // 11
      dimension: four,
      possibleCommands: fiveCommands,
      player: p(3, 3),
      target: [p(1, 2), p(0, 0)],
      water: [p(2, 2), p(2, 3)],
      horizontalFence: [p(0, 1), p(1, 1), p(2, 1)],
      hole: [p(0, 2), p(2, 0)]
    },
    {
      // 12
      dimension: four,
      possibleCommands: fiveCommands,
      player: p(2, 3),
      target: [p(0, 3), p(3, 0)],
      water: [
        p(2, 0),
        p(0, 1),
        p(1, 1),
        p(2, 1),
        p(3, 1),
        p(0, 2),
        p(1, 2),
        p(2, 2),
        p(3, 2),
        p(1, 3)
      ],
      hole: [p(0, 0), p(3, 3)]
    }
    // {
    //   dimension: five,
    //   possibleCommands: fiveCommands,
    //   player: p(1, 1),
    //   target: [p(2, 3)],
    //   water: [p(2, 2)],
    //   hole: [p(3, 3), p(0, 0)],
    //   verticalFence: [p(3, 4)],
    //   horizontalFence: [p(1, 2)]
    // }
  ]
}
