'use client'
import * as createjs from 'createjs-module'
import Randomize from '../utils/randomize'

export class GameSongs {
  public static SONGS: { [id: string]: string } = {
    peace_song: require('../../sounds/songs/peace-mike-vekris.mp3')
  }

  private static s_registered = new Set<string>()
  private static s_current: createjs.AbstractSoundInstance | undefined

  public static play(name: string) {
    GameSongs.s_current?.stop()
    GameSongs.s_current = createjs.Sound.play(
      name,
      createjs.Sound.INTERRUPT_NONE,
      0,
      0,
      -1,
      name === 'piano_song' ? 0.1 : 0.2 /*volume*/
    )
  }

  public static resume() {
    // GameSongs.s_current?.play(createjs.Sound.INTERRUPT_NONE)
    const c = GameSongs.s_current
    if (c) {
      c.paused = false
    }
  }

  public static pause() {
    const c = GameSongs.s_current
    if (c) {
      c.paused = true
    }
  }

  public static stop() {
    GameSongs.s_current?.stop()
  }

  private static registerOne(key: string, path: string) {
    if (!GameSongs.s_registered.has(key)) {
      try {
        createjs.Sound.on('fileload', (e: any) => {
          if (e.id === key) {
            GameSongs.play(key)
          }
        })
        createjs.Sound.registerSound(path, key)
        GameSongs.s_registered.add(key)
      } catch (_e) {}
    } else {
      GameSongs.play(key)
    }
  }

  public static register() {
    const key = Randomize.randomFromArray(Object.keys(GameSongs.SONGS))
    GameSongs.registerOne(key, GameSongs.SONGS[key])
  }
}
