import { PaintingBaseSounds } from './painting-base-sounds'

export class PaintingAnimalSounds extends PaintingBaseSounds {
  public static ANIMALS: { [id: string]: string } = {
    chicken: require('../../sounds/chicken.mp3'),
    bear: require('../../sounds/bear.mp3'),
    deer: require('../../sounds/deer.mp3'),

    koala: require('../../sounds/koala.mp3'),
    monkey: require('../../sounds/monkey.mp3'),
    bird: require('../../sounds/bird.mp3'),
    fox: require('../../sounds/fox.mp3'),
    rabbit: require('../../sounds/rabbit.mp3'),

    parrot: require('../../sounds/parrot.mp3'),
    lion: require('../../sounds/lion.mp3')
  }

  protected allSounds() {
    return PaintingAnimalSounds.ANIMALS
  }
}
