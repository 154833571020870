import Randomize from '../utils/randomize'

export interface IColorComponent {
  r: number
  g: number
  b: number
}

export class Color {
  // https://www.w3schools.com/colors/colors_names.asp
  private static s_colors = Randomize.shuffle([
    'crimson',
    'darkred',
    'magenta',
    'indigo',
    // 'hotpink',
    'limegreen',
    'darkolivegreen',
    'orangered',
    'brown',
    // 'chocolate',
    'darkgoldenrod',
    // 'orchid',
    // 'darkcyan',
    'blue',
    // 'coral',
    'darkslateblue'
  ])

  private static s_colorIndex: number = 0
  public static randomTextColor() {
    const c = this.s_colors[this.s_colorIndex]
    this.s_colorIndex = (this.s_colorIndex + 1) % this.s_colors.length
    return c
  }

  private static s_strongColors = Randomize.shuffle([
    'red',
    'green',
    'blue',
    'purple'
  ])

  private static s_strongColorIndex: number = 0
  public static randomStrongTextColor() {
    const c = this.s_strongColors[this.s_strongColorIndex]
    this.s_strongColorIndex =
      (this.s_strongColorIndex + 1) % this.s_strongColors.length
    return c
  }

  private static s_map: { [id: string]: IColorComponent } = {
    blue: { r: 26, g: 76, b: 205 },
    'light blue': { r: 166, g: 212, b: 238 },
    green: { r: 0, g: 128, b: 0 },
    'light green': { r: 142, g: 211, b: 36 },
    yellow: { r: 255, g: 255, b: 0 },
    beige: { r: 249, g: 220, b: 162 },
    brown: { r: 164, g: 42, b: 42 },
    orange: { r: 255, g: 164, b: 0 },
    purple: { r: 128, g: 0, b: 128 },
    pink: { r: 244, g: 184, b: 194 },
    red: { r: 255, g: 0, b: 0 },
    gray: { r: 128, g: 128, b: 128 },
    black: { r: 40, g: 40, b: 40 },
    white: { r: 245, g: 245, b: 245 }
  }
  public static mapStringToRgb(color: string): IColorComponent | undefined {
    return Color.s_map[color]
  }

  public static colorStrings(): string[] {
    return Object.keys(Color.s_map)
  }

  private static componentToHex(c: number) {
    var hex = c.toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }

  public static mapStringToHex(color: string): string {
    const c = Color.mapStringToRgb(color)
    if (c === undefined) {
      return '#000'
    }
    return (
      '#' +
      this.componentToHex(c.r) +
      this.componentToHex(c.g) +
      this.componentToHex(c.b)
    )
  }
}
