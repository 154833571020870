'use client'
import * as createjs from 'createjs-module'
import { LayoutFlexStack } from './layout-flex-stack'
import { LayoutStack } from './layout-stack'
import { Point } from './point'
import { Rect } from './rect'

export enum Direction {
  horizontal,
  vertical
}

export function directionFrom(landscape: boolean, flip: boolean = false) {
  if (flip) {
    return landscape ? Direction.vertical : Direction.horizontal
  }
  return landscape ? Direction.horizontal : Direction.vertical
}

export class LayoutFlex {
  // inputs
  private _direction: Direction
  private _container: Rect
  private _questions: createjs.DisplayObject[] = []
  private _answers: createjs.DisplayObject[] = []
  private _padding: number

  // outputs
  private _layoutPassSize: Rect[] = []

  constructor(
    direction: Direction,
    container: Rect,
    questions: createjs.DisplayObject[],
    answers: createjs.DisplayObject[],
    padding: number = 0
  ) {
    this._direction = direction
    this._container = container
    this._questions = questions
    this._answers = answers
    this._padding = padding
  }

  public static questionPosition(landscape: boolean, size: Point) {
    if (!landscape) {
      const y = size.height() * (1 / 3) - 35
      const x = size.width() / 2
      return new Point(x, y)
    } else {
      const x = size.width() * (2 / 3) + 35
      const y = size.height() / 2
      return new Point(x, y)
    }
  }

  public static answerPosition(landscape: boolean, size: Point) {
    if (!landscape) {
      const y = size.height() * (2 / 3) + 35
      const x = size.width() / 2
      return new Point(x, y)
    } else {
      const x = size.width() * (1 / 3) - 35
      const y = size.height() / 2
      return new Point(x, y)
    }
  }

  public horizontal() {
    return this._direction === Direction.horizontal
  }

  public layoutSizeForQuestion() {
    if (this.horizontal()) {
      return this._layoutPassSize[0]
    } else {
      return this._layoutPassSize[1]
    }
  }

  public layoutSizeForAnswer() {
    if (this.horizontal()) {
      return this._layoutPassSize[1]
    } else {
      return this._layoutPassSize[0]
    }
  }

  public distanceFromCenter(size: Rect) {
    return this._container.center().minus(size.center())
  }

  public layout() {
    const layouts: Rect[][] = []
    const stacks = this.getStacks()
    const stackSize = this.stackSize(stacks.length)
    var stackOrigin = this._container.origin
    for (let i = 0; i < stacks.length; ++i) {
      this._layoutPassSize.push(new Rect(stackOrigin.copy(), stackSize.copy()))
      const rects = this.layoutPass(stacks[i], new Rect(stackOrigin, stackSize))
      stackOrigin = this.moveStackOrigin(stackOrigin, stackSize)
      layouts.push(rects)
    }
    this.apply(layouts)
  }

  private layoutPass(objs: createjs.DisplayObject[], rect: Rect) {
    const stackLength = objs.length
    const boxSize = this.boxSize(rect, stackLength)
    const stack = new LayoutStack(this._direction, rect, stackLength, boxSize)
    return stack.stack().map((p) => new Rect(p, boxSize))
  }

  public layoutFlex() {
    const stacks = this.getStacks()
    const stackPadding = this.stackFlexPaddingSize(stacks)
    let stackOrigin = this._container.origin
    stackOrigin = this.moveStackOrigin(stackOrigin, stackPadding)
    for (let i = 0; i < stacks.length; ++i) {
      const stackSize = this.stackFlexSize(stacks[i])
      this._layoutPassSize.push(new Rect(stackOrigin.copy(), stackSize.copy()))
      this.layoutPassFlex(
        stacks[i],
        new Rect(stackOrigin.copy(), stackSize.copy())
      )
      stackOrigin = this.moveStackOrigin(stackOrigin, stackSize)
      stackOrigin = this.moveStackOrigin(stackOrigin, stackPadding)
    }
  }

  private layoutPassFlex(objs: createjs.DisplayObject[], rect: Rect) {
    const layout = new LayoutFlexStack(
      this._direction,
      rect,
      objs,
      this._padding
    )
    layout.layout()
    return layout
  }

  private apply(layouts: Rect[][]) {
    const stacks = this.getStacks()
    for (let pass = 0; pass < layouts.length; ++pass) {
      const layout = layouts[pass]
      for (let i = 0; i < layout.length; ++i) {
        const obj = stacks[pass][i]
        const rect = layout[i]
        obj.x = rect.origin.x
        obj.y = rect.origin.y
        // const bounds = obj.getBounds()
        // if (bounds !== null) {
        //   const currentSize = Point.fromWidthHeight(obj.getBounds())
        //   const scale = this.scaleToFit(currentSize, rect.size)
        //   obj.scaleX = scale
        //   obj.scaleY = scale
        //   console.log(
        //     `LayoutFlex apply scale=${scale}, currentSize=${currentSize.toString()}, frame=${rect.size.toString()}`
        //   )
        // }
        if (obj.hasOwnProperty('origin')) {
          Object.assign(obj, { origin: rect.origin })
        }
        // console.log(`LayoutFlex apply p=${pass}, i=${i}, r=${rect.toString()}`)
      }
    }
  }

  private getStacks() {
    if (this.horizontal()) {
      return [this._questions, this._answers]
    } else {
      return [this._answers, this._questions]
    }
  }

  private moveStackOrigin(origin: Point, stackSize: Point) {
    if (this.horizontal()) {
      return origin.plus(Point.xy(0, stackSize.height()))
    } else {
      return origin.plus(Point.xy(stackSize.width(), 0))
    }
  }

  private stackSize(stackCount: number) {
    if (this.horizontal()) {
      return new Point(
        this._container.size.width(),
        this._container.size.height() / stackCount
      )
    } else {
      return new Point(
        this._container.size.width() / stackCount,
        this._container.size.height()
      )
    }
  }

  private stackFlexSize(stack: createjs.DisplayObject[]) {
    if (this.horizontal()) {
      return new Point(
        this._container.size.w,
        stack.map((i) => i.getBounds().height).reduce((p, i) => Math.max(p, i))
      )
    } else {
      return new Point(
        stack.map((i) => i.getBounds().width).reduce((p, i) => Math.max(p, i)),
        this._container.size.h
      )
    }
  }

  private stackFlexPaddingSize(stacks: createjs.DisplayObject[][]) {
    if (this.horizontal()) {
      let size = 0
      stacks.forEach((stack) => {
        size += stack
          .map((i) => i.getBounds().height)
          .reduce((p, i) => Math.max(p, i))
      })
      return Point.xy(0, Math.max(0, (this._container.size.h - size) / 3))
    } else {
      let size = 0
      stacks.forEach((stack) => {
        size += stack
          .map((i) => i.getBounds().width)
          .reduce((p, i) => Math.max(p, i))
      })
      return Point.xy(Math.max(0, (this._container.size.w - size) / 3), 0)
    }
  }

  private boxSize(rect: Rect, passCount: number) {
    if (this.horizontal()) {
      return new Point(rect.size.width() / passCount, rect.size.height())
    } else {
      return new Point(rect.size.width(), rect.size.height() / passCount)
    }
  }
}
