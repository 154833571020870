import React from "react";
import "./App.css";

import MainPage from "./pages/MainPage";
import TimeoutPage from "./pages/TimeoutPage";
import RouteIdToGamePage from "./components/RouteIdToGamePage";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={MainPage} />

        <Route path="/games/:id" Component={RouteIdToGamePage} />

        <Route path="/timeout" Component={TimeoutPage} />

        <Route path="*" Component={MainPage} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
