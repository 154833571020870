import React, { FC } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { clearGlobalTimer } from "../games/core/play-timout";

interface TitleProps {}

const TimeoutPage: FC<TitleProps> = ({}) => {
  const onResetClick = () => {
    const n = Math.floor(Math.random() * 9);
    const answer = prompt(`Enter 4 * ${n} = ?`);

    if (answer === (4 * n).toString()) {
      clearGlobalTimer();
      window.location.replace("/");
    }
  };

  return (
    <Container fluid className="text-center text-small">
      <Row className="mt-4">
        <Col>
          <img src={require("../images/match-animals/h02.png")} />{" "}
          <img src={require("../images/match-animals/h01.png")} />{" "}
          <img src={require("../images/match-animals/h09.png")} />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <h1>⏱️⏱️⏱️ Timeout ⏱️⏱️⏱️</h1>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <h2>🪥🪥 Sorry, the playtime is over for this session! 🪥🪥</h2>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Button onClick={onResetClick} color="primary">
            For Mom and Dad only, Reset Here
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default TimeoutPage;
