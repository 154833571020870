import * as React from "react";
import {
  Card,
  CardBody,
  CardImg,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
} from "reactstrap";
import { IGameInfo } from "../games/utils/game-info";
import { Link } from "react-router-dom";
import { UiStrings } from "../pages/ui-strings";

export interface IGameCardProps {
  hasUserInfo: boolean;
  gameInfo: IGameInfo;
}

export default function GameCard(props: IGameCardProps) {
  const { hasUserInfo, gameInfo } = props;
  const btnClasses = `btn btn-primary w-75 ${!hasUserInfo ? "disabled" : ""}`;
  return (
    <Col
      lg={{ size: 4, offset: 0 }}
      md={{ size: 6, offset: 0 }}
      xs={{ size: 10, offset: 1 }}
      className="mb-5"
    >
      <Card className="text-center h-100 border-white">
        <a href={`./games/${gameInfo.link}`}>
          <CardImg top width="100%" src={gameInfo.img} alt={gameInfo.title} />
        </a>
        <CardBody>
          <CardTitle>
            <h3 className="ht-title">{gameInfo.title}</h3>
          </CardTitle>
          <CardText className="text-left">{gameInfo.lead}</CardText>
          <CardSubtitle className="text-left pb-3">
            {gameInfo.category.map((c, i) => (
              <span
                key={`category${i}`}
                className="badge badge-pill badge-info mr-1"
              >
                {c}
              </span>
            ))}
          </CardSubtitle>
          <Link className={btnClasses} to={`./games/${gameInfo.link}`}>
            {UiStrings.playButton}
          </Link>
        </CardBody>
      </Card>
    </Col>
  );
}
