import { Point } from '../core/point'
import Randomize from '../utils/randomize';
import { IMazeGameLevel } from './maze-level'

export function randomEndPoint(size: Point, start: Point): Point {
    let distance = 0
    let r = 0
    let c = 0
    do {
        r = Randomize.randomInt(size.r() / 2, size.r() - 1)
        c = Randomize.randomInt(size.c() / 2, size.c() - 1)
        distance = Math.sqrt(Math.pow(r - start.r(), 2) + Math.pow(c - start.c(), 2))
    } while (distance < (size.r() / 1.5))

    return Point.rc(r, c)
}

export function generateMaze(size: Point): IMazeGameLevel {
    let maze: number[][] = Array.from({ length: size.r() }, () => Array(size.c()).fill(0));
    let resultPaths: Point[][] = [];

    function isValidMove(x: number, y: number, maze: number[][], path: Point[]): boolean {
        const n = maze.length;

        const isInBound = x >= 0 && x < n && y >= 0 && y < n && maze[x][y] === 0;
        if (!isInBound) {
            return false
        }

        // If this move going to create 2x2 square of zeros in one the direction
        // it will be invalid since 2x2 sqaure create ambiguity path
        let invalidCombinations = [
            [[-1, 0], [0, -1], [-1, -1]],
            [[-1, 0], [0, 1], [-1, 1]],
            [[1, 0], [0, -1], [1, -1]],
            [[1, 0], [0, 1], [1, 1]],
        ];

        for (const c of invalidCombinations) {
            let countOfZero = 0
            for (const [dx, dy] of c) {
                const p = Point.xy(x + dx, y + dy)
                if (path.filter(q => q.x === p.x && q.y === p.y).length > 0) {
                    countOfZero++
                }
                // for (const resultPath of resultPaths) {
                //     if (resultPath.filter(q => q.x === p.x && q.y === p.y).length > 0) {
                //         countOfZero++
                //     }
                // }
            }
            if (countOfZero >= 3) {
                return false
            }
        }

        return true
    }

    function backtrack(x: number, y: number, maze: number[][], path: Point[], end: Point): boolean {
        if (!isValidMove(x, y, maze, path)) {
            return false;
        }

        if (path.length > maze.length * 3.4) {
            return false;
        }

        // console.log('backtrack', x, y, path)

        if (x === end.x && y === end.y) {
            // Reached the destination
            path.push(new Point(x, y));
            resultPaths.push([...path])
            // console.log('path', path)
            return true;
        }

        path.push(new Point(x, y));

        let directions = [
            [0, -1], // Left
            [0, 1],  // Right
            [-1, 0], // Up
            [1, 0],  // Down
        ];
        directions = Randomize.shuffle(directions);

        for (let [dx, dy] of directions) {
            const newX = x + dx;
            const newY = y + dy;

            if (path.filter(p => p.x === newX && p.y === newY).length > 0) {
                continue
            }

            if (backtrack(newX, newY, maze, path, end)) {
                return true;
            }
        }

        // Backtrack if no valid move found
        path.pop();

        return false;
    }

    let endPoint = Point.ZERO
    for (let i = 0; i < 1; ++i) {
        endPoint = resultPaths.length == 0 ? Point.xy(size.x - 1, size.y - 1) : randomEndPoint(size, Point.rc(0, 0));
        // end = randomEndPoint(size, Point.rc(0, 0));
        // end = Point.xy(size.x - 1, size.y - 1)
        // console.log(end)
        const path: Point[] = [];
        backtrack(0, 0, maze, path, endPoint);
    }

    maze = Array.from({ length: size.r() }, () => Array(size.c()).fill(1));
    for (const resultPath of resultPaths) {
        for (const p of resultPath) {
            maze[p.x][p.y] = 0
        }
    }

    return {
        board: maze,
        size: size,
        player: Point.ZERO,
        target: Point.xy(size.x - 1, size.y - 1)
    }
}

