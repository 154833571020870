import PaintCanvasLayer, { IPaintCanvas } from './paint-canvas-layer'
import PaintCanvasPixel from './paint-canvas-pixel'
import { delay } from '../utils/delay'
import { GameBase, IGameConfig } from '../core/game-base'
import { GameSound } from '../resource/game-sound'
import { PaintPalette } from './paint-palette'
import { Point } from '../core/point'
import { ResizableShapeDeprecated } from '../core/resizable-shape'
import { WritingSounds } from '../resource/writing-sounds'

export interface IPaintingImageFactory {
  getOutline(name: string): string
  getLayers(name: string): string[]
}

export class PaintingGame extends GameBase {
  protected static CANVAS_SIZE = Point.xy(800, 800)

  protected _win: boolean = false
  protected _name: string

  protected _background: ResizableShapeDeprecated
  protected _canvas: IPaintCanvas
  protected _palette: PaintPalette

  private _generateLayerMode: boolean = false

  constructor(config: IGameConfig) {
    super(config)

    this._name = this._names[this._pageIndex]

    GameSound.register(WritingSounds.INTRO)

    const layoutSize = this.layoutSize(this._landscape)
    this._background = new ResizableShapeDeprecated(layoutSize, {
      color: 'white',
      r: 0,
      borderWidth: 0,
      borderColor: ''
    })
    this.addChild(this._background)

    if (!this._generateLayerMode) {
      this._canvas = new PaintCanvasLayer(
        PaintingGame.CANVAS_SIZE,
        this.resourceName(),
        this.layers()
      )
    } else {
      this._canvas = new PaintCanvasPixel(
        PaintingGame.CANVAS_SIZE,
        this.resourceName(),
        this._name
      )
    }

    this._canvas.callback = this.onCanvasCallback
    this.addChild(this._canvas)

    this._palette = new PaintPalette()
    this._palette.callback = this.onColorSelected
    this.addChild(this._palette)
  }

  public layoutSize(landscape: boolean) {
    return PaintingGame.CANVAS_SIZE.plus(Point.xy(400, 0).flipIf(!landscape))
  }

  public layout(landscape: boolean) {
    super.layout(landscape)
    const layoutSize = this.layoutSize(landscape)

    this._background.size = layoutSize
    this._background.layout()

    const center = layoutSize.divide(2)
    this._canvas.x = center.x
    this._canvas.y = center.y

    this._palette.layout(landscape)
    if (landscape) {
      this._palette.x = layoutSize.width() - this._palette.getBounds().width
      this._palette.y = 0
    } else {
      this._palette.x = 0
      this._palette.y = layoutSize.height() - this._palette.getBounds().height
    }
    this.stage?.update()
  }

  protected resourceName() {
    const factory = (this._imageFactory as unknown) as IPaintingImageFactory
    return factory.getOutline(this._name)
  }

  protected layers() {
    const factory = (this._imageFactory as unknown) as IPaintingImageFactory
    return factory.getLayers(this._name)
  }

  async load() {
    this.layout(this._landscape)
    this.stage?.update()

    await this.playIntroSound()
  }

  async playIntroSound() {
    await delay(GameBase.INTROSOUNDDELAY)
    await this.playSound('lets paint')
    await this.playSound(this._name)
  }

  onCanvasCallback = async () => {
    if (this._win) {
      return
    }
    this._win = true

    await this.playSound('success')

    this.playSound(this._name)

    await delay(GameBase.WINDELAY)
    this._callback()
  }

  onColorSelected = (color: string) => {
    if (this._generateLayerMode) {
      this._callback()
      return
    }
    this.playSound(color)
    this._canvas.setColor(color)
  }
}
