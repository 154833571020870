export class GameCookie {
  private static setCookie(name: string, val: string) {
    const date = new Date()
    const value = val

    // Set it expire in 7 days
    date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000)

    // Set it
    document.cookie =
      name + '=' + value + '; expires=' + date.toUTCString() + '; path=/'
  }

  private static getCookie(name: string) {
    // const value =  '; ' + document.cookie
    // const parts = value.split('; ' + name + '=')

    // if (parts.length === 2) {
    //   const p = parts.pop()
    //   if (p !== undefined) {
    //     return p.split(';').shift()
    //   }
    // }

    return undefined
  }

  private static deleteCookie(name: string) {
    const date = new Date()

    // Set it expire in -1 days
    date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000)

    // Set it
    document.cookie = name + '=; expires=' + date.toUTCString() + '; path=/'
  }

  public static save<T>(key: string, t: T) {
    this.setCookie(key, JSON.stringify(t))
  }

  public static get<T>(key: string, d: T) {
    const str = this.getCookie(key)
    if (str === undefined) {
      return d
    }
    const obj = JSON.parse(str) as T
    return obj
  }

  public static delete(key: string) {
    this.deleteCookie(key)
  }
}
