import { BitmapGrid } from '../core/bitmap-grid'
import { BitmapLoader } from '../core/bitmap-loader'
import { ImageFactoryBase } from '../core/image-factory'
import { Point } from '../core/point'

export class NumberImages extends ImageFactoryBase {
  static IMAGES: { [id: string]: string } = {
    1: require('../../images/sea-animals/whale.png'),
    2: require('../../images/sea-animals/crab.png'),
    3: require('../../images/sea-animals/dolphin.png'),
    4: require('../../images/sea-animals/eel.png'),
    5: require('../../images/sea-animals/clownfish.png'),
    6: require('../../images/sea-animals/lionfish.png'),
    7: require('../../images/sea-animals/squid.png'),
    8: require('../../images/sea-animals/seahorse.png'),
    9: require('../../images/sea-animals/stingray.png'),
    10: require('../../images/sea-animals/snail.png'),
    11: require('../../images/sea-animals/fish.png'),
    12: require('../../images/sea-animals/starfish.png')
  }

  /* IMatchImageFactory */
  public getHead(name: string): string {
    return NumberImages.IMAGES[name]
  }

  public getBody(name: string): string {
    return NumberImages.IMAGES[name]
  }

  /* IBasicImageFactory */
  public getNames() {
    return Object.keys(NumberImages.IMAGES)
  }

  public async getImage(name: string) {
    const img = await BitmapLoader.load(this.getHead(name))
    const size = Point.xy(280, 280)
    const padding = 10

    const grid = new BitmapGrid(img, size, parseInt(name))
    grid.layout(true)
    grid.cache(-padding, -padding, size.w + padding * 2, size.h + padding * 2)
    const bitmap = new createjs.Bitmap(grid.cacheCanvas)
    bitmap.regX = bitmap.image.width / 2
    bitmap.regY = bitmap.image.height / 2
    bitmap.setBounds(0, 0, bitmap.image.width, bitmap.image.height)

    return bitmap
  }
}
