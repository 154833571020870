import * as React from 'react'
import { IUserInfo, UserInfo } from '../games/utils/user-info'
import './LoginProfilePicture.css'


interface ILoginProfilePictureState {
  userInfoPictureUrl: string
}

export default class LoginProfilePicture extends React.Component<
  {},
  ILoginProfilePictureState
> {
  private onChangeRegistrationKey: number | undefined

  constructor(props: any) {
    super(props)

    this.state = {
      userInfoPictureUrl: ''
    }
  }

  async componentDidMount() {
    try {
      const userInfo = await UserInfo.get()
      if (userInfo && userInfo.pictureUrl) {
        this.setState({
          userInfoPictureUrl: userInfo.pictureUrl
        })
      }
      this.onChangeRegistrationKey = UserInfo.registerOnChanged(
        this.onUserInfoChanged
      )
    } catch (_e) {
      console.log(_e)
    }
  }

  componentWillUnmount() {
    const key = this.onChangeRegistrationKey
    if (key !== undefined) {
      UserInfo.unregisterOnChanged(key)
    }
  }

  render() {
    const { userInfoPictureUrl } = this.state
    if (userInfoPictureUrl.length === 0) {
      return <></>
    }
    return (
      <></>
    )
  }

  onUserInfoChanged = (u: IUserInfo | undefined) => {
    this.setState({
      userInfoPictureUrl: u?.pictureUrl ?? ''
    })
  }
}
