'use client'
import * as createjs from 'createjs-module'
import { Animator } from '../core/animator'
import { BitmapLoader } from '../core/bitmap-loader'
import { Color } from '../core/color'
import { delay } from '../utils/delay'
import { Direction } from '../core/layout-flex'
import { DraggableTile } from '../core/draggable-tile'
import { GameBase } from '../core/game-base'
import { LayoutFlex } from '../core/layout-flex'
import { LayoutFlexStack } from '../core/layout-flex-stack'
import { MatchGame } from './match-game'
import { Point } from '../core/point'
import { Rect } from '../core/rect'
import { ResizableShapeDeprecated } from '../core/resizable-shape'
import { TextLabelDeprecated } from '../core/text-label-deprecated'

export class MatchSeaAnimalGame extends MatchGame {
  private _questions: createjs.DisplayObject[] = []
  private _distanceToCenter: Point = Point.ZERO

  async load() {
    await super.load()

    this._questions.forEach((f) => {
      Animator.fadeIn(f)
    })
  }

  protected async loadQuestion() {
    const question = await BitmapLoader.load(
      this._imageFactory.getBody(this._questionName)
    )

    question.scaleX = -1
    question.regX = question.image.width / 2
    question.regY = question.image.height / 2

    const capitalizedText =
      this._questionName.charAt(0).toUpperCase() + this._questionName.slice(1)
    const label = new TextLabelDeprecated(Point.xy(0, 70), capitalizedText)
    label.setTextColor(Color.randomStrongTextColor())
    label.y += question.image.height / 2

    const container = new createjs.Container()
    container.alpha = 0
    container.addChild(question)
    container.addChild(label)
    this.addChild(container)

    this._question = container
    this._questions.push(container)

    const size = Point.xy(360, 360)
    const answerArea = new ResizableShapeDeprecated(size, {
      color: 'white',
      r: 30,
      borderColor: 'white',
      borderWidth: 0
    })
    answerArea.regX = size.width() / 2
    answerArea.regY = size.height() / 2
    answerArea.alpha = 0
    this.addChild(answerArea)
    this._questions.push(answerArea)
  }

  public layout(landscape: boolean) {
    const layoutSize = this.layoutSize(landscape)

    const rect = new Rect(Point.ZERO, layoutSize)

    const layoutFlex = new LayoutFlex(
      landscape ? Direction.horizontal : Direction.vertical,
      rect,
      this._questions,
      this._answers
    )
    layoutFlex.layout()

    this._distanceToCenter = layoutFlex.distanceFromCenter(
      layoutFlex.layoutSizeForQuestion()
    )

    new LayoutFlexStack(
      landscape ? Direction.horizontal : Direction.vertical,
      layoutFlex.layoutSizeForQuestion(),
      this._questions,
      50
    ).layout()

    this.stage?.update()
  }

  protected getTargetImpl(_n: number, p: Point) {
    const question = this._questions[1]
    if (question === undefined) {
      return Point.ZERO
    }
    return Point.from(question)
  }

  protected onLifted(t: DraggableTile) {
    this.playSound(t.name)
  }

  protected async animateSummary() {
    const a = this._answers.find((i) => i.name === this._questionName)
    const objs = [...this._questions, a]
    objs.forEach((o) => {
      if (o !== undefined) {
        Animator.move(o, this._distanceToCenter)
      }
    })

    await delay(1500)
    this.playSound(this._questionName)
    await delay(GameBase.WINDELAY)
    this._callback()
  }
}
