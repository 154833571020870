import { PaintingBaseSounds } from './painting-base-sounds'

export class PaintingVehicleSounds extends PaintingBaseSounds {
  public static VEHICLES: { [id: string]: string } = {
    car: require('../../sounds/vehicles/car.mp3'),
    truck: require('../../sounds/vehicles/truck.mp3'),
    helicopter: require('../../sounds/vehicles/helicopter.mp3'),
    'school bus': require('../../sounds/vehicles/school bus.mp3'),
    train: require('../../sounds/vehicles/train.mp3'),

    van: require('../../sounds/vehicles/van.mp3'),
    boat: require('../../sounds/vehicles/boat.mp3'),
    plane: require('../../sounds/vehicles/plane.mp3'),
    submarine: require('../../sounds/vehicles/submarine.mp3'),
    ambulance: require('../../sounds/vehicles/ambulance.mp3')
  }

  protected allSounds() {
    return PaintingVehicleSounds.VEHICLES
  }
}
