import { SpellingGame } from './spelling-game'

export class SpellingColorGame extends SpellingGame {
  protected names() {
    const names = this._names.map((n) => this.getColor(n))
    return names
  }

  protected colors() {
    const color = this._questionName
    let textColor = 'white'
    if (['pink', 'yellow', 'white'].findIndex((c) => c === color) !== -1) {
      textColor = 'grey'
    }
    return [color, textColor]
  }

  protected async loadQuestion() {
    const imgName = this._names[this._pageIndex]
    const img = await this._imageFactory.getImage(imgName)

    this._question = img
    this.addChild(this._question)
  }

  private getColor(name: string) {
    const color = name.split('_')[0]
    return color
  }
}
