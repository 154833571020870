import { Direction } from '../core/layout-flex'
import { LayoutFlex } from '../core/layout-flex'
import { MatchGame } from './match-game'
import { Point } from '../core/point'
import { Rect } from '../core/rect'

export class MatchVehicleGame extends MatchGame {
  public layoutSize(landscape: boolean) {
    super.layoutSize(landscape)
    if (landscape) {
      return super.layoutSize(landscape)
    } else {
      return Point.xy(860, 1000)
    }
  }

  public layout(horizontal: boolean) {
    const layoutSize = this.layoutSize(horizontal)

    const rect = new Rect(Point.ZERO, layoutSize).inset(-20, -20)

    new LayoutFlex(
      Direction.horizontal,
      rect,
      [this._question!],
      this._answers
    ).layout()

    if (!horizontal) {
      // zigzag answers to create some spaces
      const zigzag = 120
      this._answers.forEach((a, i) => {
        a.y += (i % 2 === 0 ? 1 : -1) * zigzag
        a.origin = Point.from(a)
      })
    }

    this.stage?.update()
  }

  protected getTargetImpl(_n: number, p: Point) {
    const question = this._question
    if (question === undefined) {
      return Point.ZERO
    }
    return Point.from(question)
  }
}
