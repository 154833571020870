import React from 'react'
import { isPortrait } from '../games/utils/orientation'
import { Point } from '../games/core/point'

interface IFullScreenCanvasProps {
  canvasId: string
  renderSize: Point
}

interface IFullScreenCanvasState {
  windowSize: Point
  portrait: boolean
}

export default class FullScreenCanvas extends React.Component<
  IFullScreenCanvasProps,
  IFullScreenCanvasState
> {
  private containerRef: React.RefObject<HTMLDivElement>
  private canvasRef: React.RefObject<HTMLCanvasElement>

  constructor(props: IFullScreenCanvasProps) {
    super(props)

    this.containerRef = React.createRef<HTMLDivElement>()
    this.canvasRef = React.createRef<HTMLCanvasElement>()

    this.state = {
      windowSize: new Point(window.innerWidth, window.innerHeight),
      portrait: isPortrait()
    }
  }

  componentDidMount() {
    window.onresize = () => {
      const point = new Point(window.innerWidth, window.innerHeight)
      if (this.state.windowSize.isRoughlyEqual(point, 1)) {
        return // same width, don't resize canvas
      }
      this.setState({
        windowSize: point,
        portrait: isPortrait()
      })
    }
  }

  render() {
    const { canvasId, renderSize } = this.props
    const { windowSize } = this.state

    const containerStyle = {
      width: `${Math.round(windowSize.width())}px`,
      height: `${Math.round(windowSize.height())}px`
    }

    return (
      <div ref={this.containerRef} style={containerStyle}>
        <div className='d-flex h-100 justify-content-center'>
          <canvas
            id={canvasId}
            ref={this.canvasRef}
            className='align-self-center'
            width={renderSize.width()}
            height={renderSize.height()}
            style={this.getStyleFittedInWindow()}
          ></canvas>
        </div>
      </div>
    )
  }

  // private getStyle() {
  //   const { windowSize, portrait } = this.state
  //   const { renderSize } = this.props
  //   var width = 0
  //   var height = 0

  //   const useFixedWidth = portrait
  //   if (useFixedWidth) {
  //     width = windowSize.width()
  //     height = (width * renderSize.height()) / renderSize.width()
  //   } else {
  //     height = windowSize.height()
  //     width = (height * renderSize.width()) / renderSize.height()
  //   }
  //   // console.log(`getStyle p=${portrait}, w=${width}, h=${height}`)

  //   const style = {
  //     width: `${Math.round(width)}px`,
  //     height: `${Math.round(height)}px`
  //   }

  //   return style
  // }

  private getStyleFittedInWindow() {
    const { windowSize } = this.state
    const { renderSize } = this.props
    var width = 0
    var height = 0

    const scaleWidth = windowSize.width() / renderSize.width()
    const scaleHeight = windowSize.height() / renderSize.height()
    const scale = Math.min(scaleWidth, scaleHeight)

    width = renderSize.width() * scale
    height = renderSize.height() * scale
    // console.log(`getStyle p=${portrait}, w=${width}, h=${height}`)

    const style = {
      width: `${Math.round(width)}px`,
      height: `${Math.round(height)}px`
    }

    return style
  }
}
