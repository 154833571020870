'use client'
import * as createjs from 'createjs-module'
import { BitmapLoader } from './bitmap-loader'

export interface IBasicImageFactory {
  // get all available names
  getNames(): string[]

  // get an image for name
  getImage(name: string): Promise<createjs.Bitmap>

  // preload
  preload(index: number): Promise<void>
  isPreloaded(name: string): boolean
}

export interface IMatchImageFactory extends IBasicImageFactory {
  getHead(name: string): string
  getBody(name: string): string
}

export interface IStickerImageFactory extends IBasicImageFactory {
  getStickerNames(name: string): string[]
  getSticker(name: string): string
  getLandscapeBackground(name: string): string
  getPortraitBackground(name: string): string
}

export abstract class ImageFactoryBase implements ImageFactoryBase {
  abstract getHead(name: string): string
  abstract getBody(name: string): string
  abstract getNames(): string[]
  abstract getImage(name: string): Promise<createjs.Bitmap>

  public async preload(index: number) {
    const names = this.getNames()
    const unique = new Set<string>()

    for (let i = index; i < index + 5; ++i) {
      const n = names[i % names.length]
      unique.add(this.getBody(n))
      unique.add(this.getHead(n))
    }

    await BitmapLoader.preloadAll(Array.from(unique))
  }

  public isPreloaded(name: string) {
    const src = this.getBody(name)
    return BitmapLoader.isPreloaded(src)
  }
}

export class FakeImageFactory extends ImageFactoryBase {
  public getHead(_name: string): string {
    return ''
  }
  public getBody(_name: string): string {
    return ''
  }
  public getNames(): string[] {
    return []
  }
  public async getImage(_name: string): Promise<createjs.Bitmap> {
    return new createjs.Bitmap('')
  }

  public async preload(_index: number) {}

  public isPreloaded(_name: string) {
    return true
  }
}
