import { ColorSounds } from './color-sounds'
import { GameSound } from './game-sound'
import { ISoundFactory } from '../core/sound-factory'
import { Language } from './language'
import { SoundEffects } from './sound-effect'

export abstract class PaintingBaseSounds implements ISoundFactory {
  protected abstract allSounds(): { [id: string]: string }

  public static PAINT: { [id: string]: string } = {
    'lets paint': require('../../sounds/colors/lets paint.mp3')
  }

  /* ISoundFactory */
  public register(_language: Language) {
    GameSound.registerAll([
      this.allSounds(),
      PaintingBaseSounds.PAINT,
      ColorSounds.COLORS,
      SoundEffects.EFFECTS
    ])
  }
}
