'use client'
import * as createjs from 'createjs-module'
import { Animator } from '../core/animator'
import { BitmapLoader } from '../core/bitmap-loader'
import { Color } from '../core/color'
import { delay } from '../utils/delay'
import { GameBase, IGameConfig } from '../core/game-base'
import { IBasicImageFactory } from '../core/image-factory'
import { Language } from '../resource/language'
import { LayoutFlex } from '../core/layout-flex'
import { LayoutFlexGrid } from '../core/layout-flex-grid'
import { Point } from '../core/point'
import { Rect } from '../core/rect'
import { TextLabelDeprecated } from '../core/text-label-deprecated'

export class CountingGame extends GameBase {
  protected _question: TextLabelDeprecated | undefined
  protected _imageFactory: IBasicImageFactory
  protected _answers: createjs.Bitmap[] = []
  protected _label: TextLabelDeprecated | undefined

  private _currentCount: number = 0
  private _win: boolean = false

  constructor(config: IGameConfig) {
    super(config)
    this._pageIndex = config.pageIndex % this.count()
    this._imageFactory = config.imageFactory
  }

  public count() {
    return 12 // count to 12
  }

  async load() {
    await this.loadAnswers()
    await this.loadQuestion()
    this.layout(this._landscape)
    this.stage.update()

    this._answers.forEach((f) => {
      Animator.fadeIn(f)
    })
    Animator.fadeIn(this._question!)
    await delay(GameBase.INTROSOUNDDELAY)
    this.playSound('lets count')
  }

  protected async loadQuestion() {
    const color = Color.randomTextColor()
    const questionSize = new Point(350, 350)
    const question = new TextLabelDeprecated(
      questionSize,
      this._currentCount.toString()
    )
    question.setTextColor(color)
    this._question = question
    this._question.regX = questionSize.width() / 2
    this._question.regY = questionSize.height() / 2
    this._question.alpha = 0
    this.addChild(this._question)

    const countTo = `${(this._pageIndex + 1).toString()}`
    const text =
      this._language === Language.en ? `Count to ${countTo}` : `นับ ${countTo}`
    const lableSize = Point.xy(220, this._language === Language.en ? 50 : 70)
    const label = new TextLabelDeprecated(lableSize, text, true)
    label.setBackground(color)
    label.regX = lableSize.x / 2
    this._label = label
    this.addChild(label)
  }

  protected async loadAnswers() {
    const bitmap = await this._imageFactory.getImage(
      this._names[this._pageIndex % this._names.length]
    )
    const scale =
      this.answerSize().width() /
      Math.max(bitmap.image.height, bitmap.image.width)
    const scaledBitmap = BitmapLoader.scale(bitmap, scale)

    for (var i = 0; i <= this._pageIndex; ++i) {
      const clone = scaledBitmap.clone()
      clone.alpha = 0
      clone.on('pressup', this.handleMouse)
      this.addChild(clone)
      this._answers.push(clone)
    }
  }

  public layout(landscape: boolean) {
    const layoutSize = this.layoutSize(landscape)

    const pos = LayoutFlex.questionPosition(landscape, layoutSize).plus(
      Point.xy(landscape ? 100 : 0, 0)
    )
    this._question!.x = pos.x
    this._question!.y = pos.y

    this._label!.x = pos.x
    this._label!.y = pos.y - 250

    const gridRowColumn = this.gridRowColumn(landscape)
    const stackSize = new Point(
      this.answerSize().width() * gridRowColumn.c(),
      this.answerSize().height() * gridRowColumn.r()
    )

    const matrix = []
    let i = 0
    for (let r = 0; r < gridRowColumn.r(); r++) {
      const row = []
      for (let c = 0; c < gridRowColumn.c(); c++) {
        if (i < this._answers.length) {
          row.push(this._answers[i])
          i += 1
        }
      }
      matrix.push(row)
    }

    new LayoutFlexGrid(
      new Rect(
        LayoutFlex.answerPosition(landscape, layoutSize).minus(
          stackSize.divide(2)
        ),
        stackSize
      ),
      matrix,
      10
    ).layout()

    this.stage.update()
  }

  private gridRowColumn(landscape: boolean) {
    const n = this._answers.length
    var rows = 1
    var columns = 1

    if (n > 9) {
      rows = 3
      columns = 4
    } else if (n > 6) {
      rows = 3
      columns = 3
    } else if (n > 4) {
      rows = 2
      columns = 3
    } else if (n > 2) {
      rows = 2
      columns = 2
    } else if (n > 1) {
      rows = 1
      columns = 2
    } else if (n === 1) {
      rows = 1
      columns = 1
    }

    return landscape ? new Point(rows, columns) : new Point(columns, rows)
  }

  private answerSize() {
    const n = this._pageIndex + 1
    if (n > 8) {
      return new Point(180, 180)
    } else if (n > 6) {
      return new Point(200, 200)
    } else if (n > 4) {
      return new Point(220, 220)
    } else if (n > 2) {
      return new Point(260, 260)
    }
    return new Point(300, 300)
  }

  private handleMouse = async (evt: any) => {
    if (this._win) {
      return
    }
    const answer = evt.target as createjs.DisplayObject
    answer.removeAllEventListeners('pressup')
    this._currentCount += 1
    this._question!.setText(this._currentCount.toString())

    Animator.win(answer)
    Animator.scaleTo(answer, 0.3)
    Animator.moveTo(answer, Point.from(this._question!)).then(
      () => (answer.alpha = 0)
    )

    if (this._currentCount === this._pageIndex + 1) {
      await this.playSound(this._currentCount.toString())
      this.win()
    } else {
      this.playSound(this._currentCount.toString())
    }
  }

  protected async win() {
    this._win = true
    this.playSound('success')
    this.animateSummary()
  }

  protected async animateSummary() {
    Animator.fadeOut(this._label!)
    const layoutSize = this.layoutSize(this._landscape)
    const q = this._question
    if (q !== undefined) {
      const distance = new Point(layoutSize.x / 2 - q.x, layoutSize.y / 2 - q.y)
      Animator.move(this._question!, distance)
    }
    await delay(1500)
    this.playSound(this._currentCount.toString())
    await delay(GameBase.WINDELAY)
    this._callback()
  }
}
