'use client'
import * as createjs from 'createjs-module'
import { BitmapLoader } from '../core/bitmap-loader'
import { IPaintCanvas } from './paint-canvas-layer'
import { Point } from '../core/point'

export default class PaintCanvasPixel extends createjs.Container
  implements IPaintCanvas {
  public callback: () => void

  private _size: Point
  private _name: string

  private _background: createjs.Shape
  private _canvas: createjs.Shape
  private _countOfFills: number = 0

  constructor(size: Point, srcImage: string, name: string) {
    super()
    this.callback = () => {}
    this._size = size
    this._name = name

    const w = this._size.width()
    const h = this._size.height()
    this.setBounds(0, 0, w, h)

    this._background = new createjs.Shape()
    this._background.graphics.beginFill('white').drawRect(-w / 2, -h / 2, w, h)
    this.addChild(this._background)

    this._canvas = new createjs.Shape()
    this.addChild(this._canvas)

    this.on('mousedown', (evt: any) => {
      this.handleMouseDownEvent(evt)
    })

    BitmapLoader.loadAndCenter(srcImage).then((bitmap) => {
      this.addChildAt(bitmap, 1)
      this.stage?.update()
    })
  }

  public setColor(color: string) {}

  public resetInk() {
    this._canvas.graphics.clear()
    this.stage?.update()
  }

  private addPaint(p: Point) {
    const ink = this._canvas
    if (ink === undefined) {
      return
    }

    const local = Point.from(this.globalToLocal(p.x, p.y))
    this.fill(local)
    this.stage?.update()
  }

  private handleMouseDownEvent(evt: any) {
    const p = Point.xy(evt.stageX, evt.stageY)
    this.addPaint(p)
  }

  private fill(p: Point) {
    const layoutSize = this._size
    const w = layoutSize.width()
    const h = layoutSize.height()
    this.cache(-w / 2, -h / 2, w, h)
    const canvas = this.cacheCanvas as HTMLCanvasElement
    this.uncache()

    const ctx = canvas.getContext('2d')
    if (ctx === null) {
      return
    }

    console.log('start fill')

    this._canvas.graphics.clear()

    const pixelSize = 1
    PaintCanvasPixel.boundaryFill8(
      p,
      'rgba(255, 0, 0, 1)',
      pixelSize,
      (p) => {
        return ctx.getImageData(p.x + w / 2, p.y + h / 2, pixelSize, pixelSize)
          .data
      },
      (p, c) => {
        const imgData = ctx.createImageData(pixelSize, pixelSize)
        imgData.data[0] = 255
        imgData.data[3] = 255
        ctx.putImageData(imgData, p.x + w / 2, p.y + h / 2)
        this._canvas.graphics
          .beginFill(c)
          .drawRect(p.x, p.y, pixelSize, pixelSize)
      }
    )

    console.log(`fill done`)

    this._canvas.cache(-w / 2, -h / 2, w, h)
    const savedCanvas = this._canvas.cacheCanvas as HTMLCanvasElement

    const savedBitmap = new createjs.Bitmap(savedCanvas)
    savedBitmap.x = -w / 2
    savedBitmap.y = -h / 2
    this.addChild(savedBitmap)

    const countOfFills = this._countOfFills
    savedCanvas.toBlob((b) => {
      var downloadLink = document.createElement('a')
      downloadLink.download = `${this._name}_${countOfFills}.png`
      downloadLink.innerHTML = 'Download File'
      if ('webkitURL' in window) {
        // Chrome allows the link to be clicked without actually adding it to the DOM.
        // downloadLink.href = window.webkitURL.createObjectURL(b)
      } else {
        // Firefox requires the link to be added to the DOM before it can be clicked.
        // downloadLink.href = window.URL.createObjectURL(b)
        downloadLink.style.display = 'none'
        document.body.appendChild(downloadLink)
      }

      downloadLink.click()
    })

    this._countOfFills += 1
    this._canvas.uncache()
  }

  public static boundaryFill8(
    d: Point,
    c: string,
    pixelSize: number,
    getpixel: (p: Point) => Uint8ClampedArray,
    putpixel: (p: Point, c: string) => void
  ) {
    const visited = new Set<Point>()

    const queue = new Array<Point>()
    queue.push(d)

    while (queue.length > 0) {
      const p = queue.pop()
      if (p === undefined) {
        break
      }
      if (visited.has(p)) {
        continue
      }
      visited.add(p)
      const pixel = getpixel(p)
      if (pixel[3] < 125) {
        continue
      }
      putpixel(p, c)

      if (pixel[0] >= 125 && pixel[1] >= 125 && pixel[2] >= 125) {
        queue.push(Point.xy(p.x + pixelSize, p.y))
        queue.push(Point.xy(p.x, p.y + pixelSize))
        queue.push(Point.xy(p.x - pixelSize, p.y))
        queue.push(Point.xy(p.x, p.y - pixelSize))
        queue.push(Point.xy(p.x - pixelSize, p.y - pixelSize))
        queue.push(Point.xy(p.x - pixelSize, p.y + pixelSize))
        queue.push(Point.xy(p.x + pixelSize, p.y - pixelSize))
        queue.push(Point.xy(p.x + pixelSize, p.y + pixelSize))
      }
    }
  }
}
