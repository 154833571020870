import { GameSound } from './game-sound'
import { ISoundFactory } from '../core/sound-factory'
import { Language } from './language'
import { SoundEffects } from './sound-effect'

export class ColorSounds implements ISoundFactory {
  public static COLORS: { [id: string]: string } = {
    beige: require('../../sounds/colors/beige.mp3'),
    black: require('../../sounds/colors/black.mp3'),
    blue: require('../../sounds/colors/blue.mp3'),
    brown: require('../../sounds/colors/brown.mp3'),
    cyan: require('../../sounds/colors/cyan.mp3'),
    gray: require('../../sounds/colors/gray.mp3'),
    green: require('../../sounds/colors/green.mp3'),
    orange: require('../../sounds/colors/orange.mp3'),
    pink: require('../../sounds/colors/pink.mp3'),
    purple: require('../../sounds/colors/purple.mp3'),
    red: require('../../sounds/colors/red.mp3'),
    teal: require('../../sounds/colors/teal.mp3'),
    white: require('../../sounds/colors/white.mp3'),
    yellow: require('../../sounds/colors/yellow.mp3'),
    'light green': require('../../sounds/colors/light green.mp3'),
    'light blue': require('../../sounds/colors/light blue.mp3')
  }

  /* ISoundFactory */
  public register(_language: Language) {
    GameSound.registerAll([ColorSounds.COLORS, SoundEffects.EFFECTS])
  }
}
