'use client'
import * as createjs from 'createjs-module'
import { Color } from '../core/color'
import { Direction } from '../core/layout-flex'
import { LayoutFlexStack } from '../core/layout-flex-stack'
import { LetterTile } from '../core/letter-tile'
import { Point } from '../core/point'
import { Rect } from '../core/rect'
import { ResizableShapeDeprecated } from '../core/resizable-shape'

export class MathQuestion extends createjs.Container {
  private _number1: number
  private _number2: number
  private _operator: string
  private _tileSize: Point

  private _textColor: string

  private _label1: createjs.Text
  private _label2: createjs.Text
  private _labelOp: createjs.Text
  private _lableEqual: createjs.Text
  private _answerArea: ResizableShapeDeprecated

  constructor(n1: number, n2: number, op: string, size: Point) {
    super()
    this._number1 = n1
    this._number2 = n2
    this._operator = op
    this._tileSize = size

    this._textColor = Color.randomTextColor()

    this._label1 = this.createLabel(n1.toString(), size)
    this._label2 = this.createLabel(n2.toString(), size)
    this._labelOp = this.createLabel(op, size)
    this._lableEqual = this.createLabel('=', size)

    const opScale = op === '+' ? 0.7 : 0.9
    this._labelOp.scaleX = opScale
    this._labelOp.scaleY = opScale

    const shapeSize = size.multiply(1.2)
    const shape = new ResizableShapeDeprecated(shapeSize, {
      color: 'white',
      r: 30,
      borderColor: this._textColor,
      borderWidth: shapeSize.w / 8
    })
    shape.regX = shapeSize.w / 2
    shape.regY = shapeSize.h / 2
    this._answerArea = shape
  }

  public layout(landscape: boolean, layoutSize: Rect) {
    this.removeAllChildren()

    const line = landscape
      ? [
          this._label1,
          this._labelOp,
          this._label2,
          this._lableEqual,
          this._answerArea
        ]
      : [this._label1, this._label2, this._answerArea]

    line.forEach((l) => {
      this.addChild(l)
    })

    new LayoutFlexStack(
      landscape ? Direction.horizontal : Direction.vertical,
      layoutSize,
      line,
      landscape ? 25 : 20
    ).layout()

    if (!landscape) {
      this._labelOp.x = this._label2.x - 150
      this._labelOp.y = this._label2.y
      this.addChild(this._labelOp)
    }

    this.setBounds(0, 0, layoutSize.size.w, layoutSize.size.h)
    this.regX = layoutSize.size.w / 2
    this.regY = layoutSize.size.h / 2
  }

  public get answerArea() {
    return this._answerArea
  }

  private createLabel(s: string, size: Point) {
    const label = LetterTile.createLabel()
    label.text = s
    label.font = LetterTile.createFont(Math.floor(size.y / 1.2))
    label.color = this._textColor
    return label
  }
}
