'use client'
import * as createjs from 'createjs-module'
import Randomize from '../utils/randomize'
import { Animator } from '../core/animator'
import { BitmapLoader } from '../core/bitmap-loader'
import { Color } from '../core/color'
import { delay } from '../utils/delay'
import { Direction, LayoutFlex } from '../core/layout-flex'
import { DraggableTile } from '../core/draggable-tile'
import { GameBase, IGameConfig } from '../core/game-base'
import { IBasicImageFactory } from '../core/image-factory'
import { MathQuestion } from './math-question'
import { NumberSounds } from '../resource/number-sounds'
import { Point } from '../core/point'
import { Rect } from '../core/rect'
import { ResizableShapeDeprecated } from '../core/resizable-shape'
import { TextTileDeprecated } from '../core/text-tile-deprecated'

export class AdditionGame extends GameBase {
  protected _imageFactory: IBasicImageFactory

  protected _question: MathQuestion | undefined
  protected _questions: createjs.DisplayObject[] = []
  protected _answers: createjs.DisplayObject[] = []
  protected _character: createjs.Bitmap | undefined
  protected _background: ResizableShapeDeprecated | undefined
  protected _number1: number
  protected _number2: number

  protected _win: boolean = false

  constructor(config: IGameConfig) {
    super(config)

    this._pageIndex = config.pageIndex % this.count()
    this._imageFactory = config.imageFactory

    const [n1, n2] = this.question()
    this._number1 = n1
    this._number2 = n2

    this.onLifted = this.onLifted.bind(this)
    this.onPlaced = this.onPlaced.bind(this)
  }

  public count() {
    return 12
  }

  question() {
    const m = Math.max(5, this._pageIndex % this.count())
    const n1 = Randomize.randomInt(1, m)
    let n2 = n1
    do {
      n2 = Randomize.randomInt(1, 12)
    } while (n2 === n1)
    return n1 > n2 ? [n1 - n2, n2] : [n1, n2 - n1]
  }

  answer() {
    return this._number1 + this._number2
  }

  answerRange() {
    const a = this.answer()
    if (a <= 12) {
      // const min = Math.max(a - 5, 0)
      // const max = a + 5
      return [0, 12]
    } else {
      const min = Math.max(a - 25, 0)
      const max = Math.min(a + 25, 99)
      return [min, max]
    }
  }

  randomAnswers() {
    const a = this.answer()
    let answers = [a]

    const [min, max] = this.answerRange()
    while (answers.length < 3) {
      let x = Randomize.randomInt(min, max)
      if (answers.findIndex((i) => i === x) === -1) {
        answers.push(x)
      }
    }
    Randomize.shuffle(answers);
    return answers
  }

  async load() {
    this._background = new ResizableShapeDeprecated(Point.ZERO, {
      color: 'rgba(255, 255, 255, 0.7)',
      r: 20,
      borderColor: '',
      borderWidth: 0
    })
    this.addChild(this._background)

    await this.loadQuestion()
    await this.loadAnswers()
    this.layout(this._landscape)
    this.stage?.update()

    this._questions.forEach((f) => {
      Animator.fadeIn(f)
    })

    Animator.fadeIn(this._question!)

    try {
      await delay(GameBase.INTROSOUNDDELAY)
      await this.playIntroSound()
    } catch (_ex) {
      console.log(_ex)
    }

    this._answers.forEach((f) => {
      Animator.fadeIn(f)
    })
    Animator.fadeIn(this._character!)
  }

  protected async loadQuestion() {
    const size = this.tileSize()

    this._question = new MathQuestion(this._number1, this._number2, '+', size)
    this._question.alpha = 0
    this.addChild(this._question)
  }

  protected async loadAnswers() {
    const size = this.tileSize()
    const answers = this.randomAnswers()
    for (let i = 0; i < answers.length; ++i) {
      const t = new TextTileDeprecated(size, answers[i].toString())
      t.alpha = 0
      t.name = answers[i].toString()
      t.onPlaced = this.onPlaced
      t.onLifted = this.onLifted
      t.setTextColor(Color.randomTextColor())
      this.addChild(t)
      this._answers.push(t)
    }

    let bitmap = await this._imageFactory.getImage(
      this._names[this._pageIndex % this._names.length]
    )
    const scale = Math.min(
      size.w / bitmap.image.width,
      size.h / bitmap.image.height
    )
    bitmap = BitmapLoader.scale(bitmap, scale)
    bitmap.alpha = 0
    this.addChild(bitmap)
    this._character = bitmap
  }

  public layout(landscape: boolean) {
    const layoutSize = this.layoutSize(landscape)

    let rect = new Rect(Point.ZERO, layoutSize)

    if (!landscape) {
      rect = rect.inset(-40, 0)
    } else {
      rect = rect.inset(-20, -80)
    }

    this._background!.origin = rect.origin
    this._background!.size = rect.size
    this._background!.layout()

    if (!landscape) {
      rect.origin.y += 70
    }

    const layoutFlex = new LayoutFlex(
      Direction.horizontal,
      rect,
      [this._question!],
      [...this._answers, this._character!]
    )
    layoutFlex.layout()

    this._question!.layout(landscape, layoutFlex.layoutSizeForQuestion())

    this.stage?.update()
  }

  protected async playIntroSound() {
    await this.playNumberSound(this._number1)
    this.playSound('plus')
    await delay(1000)
    await this.playNumberSound(this._number2)
  }

  protected async playNumberSound(number: number) {
    if (this.canPlaySound()) {
      await NumberSounds.play(number)
    }
  }

  protected tileSize() {
    return Point.xy(200, 200)
  }

  protected placedTarget(): createjs.DisplayObject {
    return this._question!.answerArea
  }

  protected checkAnswer(t: DraggableTile) {
    return parseInt(t.name) === this.answer()
  }

  protected onLifted(t: DraggableTile) {
    this.playNumberSound(parseInt(t.name))
  }

  protected onPlaced(t: DraggableTile) {
    const target = this.placedTarget()
    const global = Point.from(this._question!.localToGlobal(target.x, target.y))
    const current = new Point(t.x, t.y)
    if (global.isRoughlyEqual(current, 150)) {
      if (this.checkAnswer(t)) {
        this.win(t)
        t.canDrag = false
      } else {
        this.lose(t)
      }
      return global
    }
    return undefined
  }

  protected async win(t: DraggableTile) {
    if (this._win) {
      return
    }

    this._win = true
    this.playSound('success')

    this._answers.forEach((a) => {
      if (a !== t) {
        Animator.fadeOut(a)
      }
    })
    Animator.fadeOut(this._character!)
    await Animator.win(t)

    this.animateSummary(t)
  }

  protected async lose(t: DraggableTile) {
    Animator.lose(t)
    this.playSound('error')
    await delay(500)
    t.setPositionToOrigin()
    t.stage?.update()
  }

  protected async animateSummary(t: DraggableTile) {
    await this.soundSummary(t)
    await delay(GameBase.WINDELAY)
    this._callback()
  }

  protected async soundSummary(t: DraggableTile) {
    await this.playIntroSound()

    this.playSound('equals')
    await delay(1000)

    await this.playNumberSound(this.answer())
  }
}
