import { BitmapLoader } from '../core/bitmap-loader'
import { ImageFactoryBase } from '../core/image-factory'

export class ColorImages extends ImageFactoryBase {
  static s_background: { [id: string]: string } = {
    background: require('../../images/colors/colorpaintbg.png')
  }

  static s_images: { [id: string]: string } = {
    blue_whale: require('../../images/colors/blue_whale.png'),
    brown_bear: require('../../images/colors/brown_bear.png'),
    black_bat: require('../../images/colors/black_bat.png'),
    green_frog: require('../../images/colors/green_frog.png'),
    pink_rabbit: require('../../images/colors/pink_rabbit.png'),
    purple_walrus: require('../../images/colors/purple_walrus.png'),
    red_fox: require('../../images/colors/red_fox.png'),
    yellow_duck: require('../../images/colors/yellow_duck.png'),
    gray_koala: require('../../images/colors/gray_koala.png'),
    orange_fish: require('../../images/colors/orange_fish.png'),
    white_seal: require('../../images/colors/white_seal.png')
    // blue_jellyfish: require('../../images/colors/blue_jellyfish.png'),
    // brown_lion: require('../../images/colors/brown_lion.png'),
    // pink_octopus: require('../../images/colors/pink_octopus.png'),
    // red_deer: require('../../images/colors/red_deer.png'),
    // brown_camel: require('../../images/colors/yellow_camel.png'),
    // blue_bird: require('../../images/colors/blue_bird.png'),
    // brown_monkey: require('../../images/colors/brown_monkey.png'),
    // green_parrot: require('../../images/colors/green_parrot.png'),
    // yellow_seahorse: require('../../images/colors/yellow_seahorse.png')
  }

  /* IMatchImageFactory */
  public getHead(name: string): string {
    return ColorImages.s_images[name]
  }

  public getBody(_name: string): string {
    return ColorImages.s_background.background
  }

  /* IBasicImageFactory */
  public getNames() {
    return Object.keys(ColorImages.s_images)
  }

  public async getImage(name: string) {
    const img = await BitmapLoader.load(this.getHead(name))
    img.regX = img.image.width / 2
    img.regY = img.image.height / 2
    return img
  }
}
