'use client'
import * as createjs from 'createjs-module'
import Randomize from '../utils/randomize'
import { Animator } from '../core/animator'
import { BitmapLoader } from '../core/bitmap-loader'
import { Color } from '../core/color'
import { delay } from '../utils/delay'
import { Direction } from '../core/layout-flex'
import { DraggableTile } from '../core/draggable-tile'
import { GameBase, IGameConfig } from '../core/game-base'
import { GameSound } from '../resource/game-sound'
import { IBasicImageFactory } from '../core/image-factory'
import { LayoutFlex } from '../core/layout-flex'
import { LetterTile } from '../core/letter-tile'
import { NumberSounds } from '../resource/number-sounds'
import { Point } from '../core/point'
import { Rect } from '../core/rect'
import { ResizableShapeDeprecated } from '../core/resizable-shape'

export class SkipCountingGame extends GameBase {
  public static SHORTER = 800
  public static LONGER = 1300
  protected static COUNT_SKIPS = 7

  protected _imageFactory: IBasicImageFactory

  protected _questionLabel: LetterTile | undefined
  protected _questions: LetterTile[] = []
  protected _answers: LetterTile[] = []
  protected _character: createjs.Container | undefined
  protected _background: ResizableShapeDeprecated | undefined
  protected _skipBy: number
  protected _skipByStarter: number
  protected _skipIndex: number = 0
  protected _questionColor: string
  protected _distanceToCenter: Point = Point.ZERO
  protected _win: boolean = false

  constructor(config: IGameConfig) {
    super(config)

    this._pageIndex = config.pageIndex % this.count()
    this._imageFactory = config.imageFactory

    this._skipBy = this.skipBys()[this._pageIndex]
    this._skipByStarter = this.skipByStarters()[this._pageIndex]

    this._questionColor = Color.randomTextColor()

    GameSound.register(NumberSounds.SKIPCOUNTING)
  }

  public layoutSize(landscape: boolean) {
    super.layoutSize(landscape)
    if (landscape) {
      return new Point(SkipCountingGame.LONGER, SkipCountingGame.SHORTER)
    } else {
      return new Point(SkipCountingGame.SHORTER, SkipCountingGame.LONGER)
    }
  }

  public count() {
    return this.skipBys().length
  }

  public skipByStarters() {
    return [2, 4, 3, 5, 0, 4, 10, 9, 12, 25, 30, 31]
  }

  public skipBys() {
    return [2, 4, 3, 5, 10, 10, 2, 3, 4, 5, 2, 3]
  }

  protected randomAnswers() {
    let answers = []
    for (let i = 2; i < SkipCountingGame.COUNT_SKIPS; ++i) {
      answers.push(this._skipByStarter + this._skipBy * i)
    }
    const min = this._skipByStarter + (this._skipBy * 2 + 1)
    const max =
      this._skipByStarter + this._skipBy * SkipCountingGame.COUNT_SKIPS

    const totalCount = SkipCountingGame.COUNT_SKIPS + 1
    while (answers.length < totalCount) {
      let x = Randomize.randomInt(min, max)
      if (answers.findIndex((i) => i === x) === -1) {
        answers.push(x)
      }
    }

    Randomize.shuffle(answers)
    return answers
  }

  async load() {
    this._background = new ResizableShapeDeprecated(Point.ZERO, {
      color: 'rgba(255, 255, 255, 0.7)',
      r: 20,
      borderColor: '',
      borderWidth: 0
    })
    this.addChild(this._background)

    await this.loadQuestion()
    this.loadAnswers()
    await this.loadCharacter()
    this.layout(this._landscape)
    this.stage?.update()

    Animator.fadeIn(this._character!)
    this._questions.forEach((f, i) => {
      Animator.fadeIn(f, i <= this._skipIndex ? 1 : 0.4)
    })

    try {
      await this.playIntroSound()
    } catch (_ex) {
      console.log(_ex)
    }

    this._answers.forEach((f) => {
      Animator.fadeIn(f)
    })
  }

  protected async loadQuestion() {
    const size = this.tileSize()
    this._skipIndex = 2
    const c = this._questionColor

    for (let i = 0; i < SkipCountingGame.COUNT_SKIPS; ++i) {
      const s = this._skipByStarter + this._skipBy * i
      const l = new LetterTile(this.textSize(), size, s.toString(), true, false)
      l.alpha = 0
      l.name = s.toString()
      if (i >= this._skipIndex) {
        l.setText('')
      }
      l.setBackground(c, 0)
      this.addChild(l)
      this._questions.push(l)
    }
  }

  protected async loadAnswers() {
    const size = this.tileSize()
    const answers = this.randomAnswers()
    this._answers = []
    for (let i = 0; i < answers.length; ++i) {
      const t = new LetterTile(
        this.textSize(),
        size,
        answers[i].toString(),
        true
      )
      const c = Color.randomTextColor()
      t.alpha = 0
      t.name = answers[i].toString()
      t.onPlaced = this.onPlaced
      t.onLifted = this.onLifted
      t.setBackground(c, 0)
      this.addChild(t)
      this._answers.push(t)
    }
  }

  protected async loadCharacter() {
    this._character = new createjs.Container()
    this._character.alpha = 0
    this.addChild(this._character)

    const size = Point.xy(240, 240)
    let bitmap = await this._imageFactory.getImage(
      this._names[this._pageIndex % this._names.length]
    )
    bitmap = BitmapLoader.scaleToFit(bitmap, size)
    bitmap.scaleX = -1
    bitmap.y = -20
    this._character.addChild(bitmap)

    // question label
    const labelSize = Point.xy(size.x, 60)
    const skipCounting = `Count by ${this._skipBy}'s`
    const label = new LetterTile(
      labelSize,
      labelSize.plus(Point.xy(20, 0)),
      skipCounting,
      true,
      false
    )
    label.setBackground(Color.randomTextColor())
    label.y = size.y / 2 - 20
    this._questionLabel = label
    this._character.addChildAt(label, 0)

    this._character.setBounds(0, 0, 240, 240)
  }

  public layout(landscape: boolean) {
    const layoutSize = this.layoutSize(landscape)

    let rect = new Rect(Point.ZERO, layoutSize)
    rect = rect.inset(-20, -20)

    this._background!.origin = rect.origin
    this._background!.size = rect.size
    this._background!.layout()

    rect = rect.inset(-20, -20)
    const layoutFlex = new LayoutFlex(
      this._landscape ? Direction.horizontal : Direction.vertical,
      rect,
      [this._character!, ...this._questions],
      this._answers,
      20
    )
    layoutFlex.layoutFlex()

    const questionPosition = layoutFlex.layoutSizeForQuestion()
    this._distanceToCenter = layoutFlex.distanceFromCenter(questionPosition)

    this.stage?.update()
  }

  protected async playIntroSound() {
    await delay(1000)
    this.playSound('skip counting')
    await delay(1200)
    this.playSound('by')
    await delay(500)
    await this.playNumberSound(this._skipBy)
  }

  protected async playNumberSound(number: number) {
    if (this.canPlaySound()) {
      await NumberSounds.play(number)
    }
  }

  protected textSize() {
    return Point.xy(100, 100)
  }

  protected tileSize() {
    return this.textSize().plus(Point.xy(20, 20))
  }

  protected highlightPlacedTarget() {
    const t = this.placedTarget()
    t.alpha = 1
  }

  protected placedTarget(): LetterTile {
    return this._questions[this._skipIndex]
  }

  protected checkAnswer(t: DraggableTile) {
    return t.name === this.placedTarget().name
  }

  protected onLifted = (t: DraggableTile) => {
    this.playNumberSound(parseInt(t.name))
  }

  protected onPlaced = (t: DraggableTile) => {
    const placedTarget = this.placedTarget()
    const target = Point.from(placedTarget)
    const current = new Point(t.x, t.y)
    if (target.isRoughlyEqual(current, 150)) {
      if (this.checkAnswer(t)) {
        t.canDrag = false
        t.alpha = 0
        placedTarget.setText(placedTarget.name)
        this._skipIndex += 1

        if (this._skipIndex === SkipCountingGame.COUNT_SKIPS) {
          placedTarget.setBackground(this._questionColor, 0)
          this.win()
        } else {
          this.highlightPlacedTarget()
          this.playSound('success')
        }
      } else {
        this.lose(t)
      }
      return target
    }
    return undefined
  }

  protected async win() {
    if (this._win) {
      return
    }

    this._win = true
    this.playSound('success')

    this._answers.forEach((a) => {
      Animator.fadeOut(a)
    })

    this._questions.forEach((a) => {
      Animator.win(a)
    })
    await Animator.win(this._character!)

    this.animateSummary()
  }

  protected async lose(t: DraggableTile) {
    Animator.lose(t)
    this.playSound('error')
    await delay(500)
    t.setPositionToOrigin()
    t.stage?.update()
  }

  protected async animateSummary() {
    const objs = [...this._questions, this._character!]
    await Promise.all(
      objs.map(async (o) => {
        if (o !== undefined) {
          await Animator.move(o, this._distanceToCenter)
        }
      })
    )

    await this.playIntroSound()

    for (let i = 0; i < this._questions.length; ++i) {
      const q = this._questions[i]
      this.removeChild(q)
      this.addChild(q)
      Animator.scaleInOut(q, 800)
      await this.playNumberSound(parseInt(q.name))
    }

    await delay(GameBase.WINDELAY)
    this._callback()
  }
}
