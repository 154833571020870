import { delay } from '../utils/delay'
import { GameSound } from './game-sound'
import { ISoundFactory } from '../core/sound-factory'
import { Language } from './language'
import { SoundEffects } from './sound-effect'

export class NumberSounds implements ISoundFactory {
  private static s_previusLanguage: Language = Language.en

  public static LETSCOUNT: { [id: string]: string } = {
    'lets count': require('../../sounds/numbers/lets count.mp3')
  }

  public static LETSCOMAPRE: { [id: string]: string } = {
    'lets compare': require('../../sounds/numbers/lets compare.mp3'),
    and: require('../../sounds/and.mp3')
  }

  public static LETSCOUNTTHAI: { [id: string]: string } = {
    'lets count': require('../../sounds/numbers/lets count thai.mp3')
  }

  public static SKIPCOUNTING: { [id: string]: string } = {
    'skip counting': require('../../sounds/numbers/skip counting.mp3'),
    by: require('../../sounds/numbers/by.mp3')
  }

  public static SIGN: { [id: string]: string } = {
    plus: require('../../sounds/numbers/plus.mp3'),
    minus: require('../../sounds/numbers/minus.mp3'),
    equals: require('../../sounds/numbers/equals.mp3'),
    greater: require('../../sounds/numbers/greater.mp3'),
    less: require('../../sounds/numbers/less.mp3'),
    than: require('../../sounds/numbers/than.mp3'),
    is: require('../../sounds/is.mp3')
  }

  public static NUMBER: { [id: string]: string } = {
    0: require('../../sounds/numbers/number0.mp3'),
    1: require('../../sounds/numbers/number1.mp3'),
    2: require('../../sounds/numbers/number2.mp3'),
    3: require('../../sounds/numbers/number3.mp3'),
    4: require('../../sounds/numbers/number4.mp3'),
    5: require('../../sounds/numbers/number5.mp3'),
    6: require('../../sounds/numbers/number6.mp3'),
    7: require('../../sounds/numbers/number7.mp3'),
    8: require('../../sounds/numbers/number8.mp3'),
    9: require('../../sounds/numbers/number9.mp3'),
    10: require('../../sounds/numbers/number10.mp3'),
    11: require('../../sounds/numbers/number11.mp3'),
    12: require('../../sounds/numbers/number12.mp3'),
    13: require('../../sounds/numbers/number13.mp3'),
    14: require('../../sounds/numbers/number14.mp3'),
    15: require('../../sounds/numbers/number15.mp3'),
    16: require('../../sounds/numbers/number16.mp3'),
    17: require('../../sounds/numbers/number17.mp3'),
    18: require('../../sounds/numbers/number18.mp3'),
    19: require('../../sounds/numbers/number19.mp3'),
    20: require('../../sounds/numbers/number20.mp3'),
    30: require('../../sounds/numbers/number30.mp3'),
    40: require('../../sounds/numbers/number40.mp3'),
    50: require('../../sounds/numbers/number50.mp3'),
    60: require('../../sounds/numbers/number60.mp3'),
    70: require('../../sounds/numbers/number70.mp3'),
    80: require('../../sounds/numbers/number80.mp3'),
    90: require('../../sounds/numbers/number90.mp3')
  }

  public static NUMBERTHAI: { [id: string]: string } = {
    1: require('../../sounds/numbers/number1thai.mp3'),
    2: require('../../sounds/numbers/number2thai.mp3'),
    3: require('../../sounds/numbers/number3thai.mp3'),
    4: require('../../sounds/numbers/number4thai.mp3'),
    5: require('../../sounds/numbers/number5thai.mp3'),
    6: require('../../sounds/numbers/number6thai.mp3'),
    7: require('../../sounds/numbers/number7thai.mp3'),
    8: require('../../sounds/numbers/number8thai.mp3'),
    9: require('../../sounds/numbers/number9thai.mp3'),
    10: require('../../sounds/numbers/number10thai.mp3'),
    11: require('../../sounds/numbers/number11thai.mp3'),
    12: require('../../sounds/numbers/number12thai.mp3')
  }

  /* Play sound from 0 - 99 */
  public static async play(n: number) {
    if (n < 0 || n > 99) {
      return
    }

    if (n <= 20) {
      await GameSound.play(n.toString())
    } else {
      const n0 = n % 10
      const n1 = Math.floor(n / 10)

      if (n0 === 0) {
        await GameSound.play(n.toString())
      } else {
        GameSound.play((n1 * 10).toString())
        await delay(600)
        await GameSound.play(n0.toString())
      }
    }
  }

  /* ISoundFactory */
  public register(language: Language) {
    // console.log(`register ${language}`)

    if (language === NumberSounds.s_previusLanguage) {
      GameSound.clear(NumberSounds.LETSCOUNT)
    }
    NumberSounds.s_previusLanguage = language

    GameSound.register(
      language === Language.en
        ? NumberSounds.LETSCOUNT
        : NumberSounds.LETSCOUNTTHAI
    )
    GameSound.register(SoundEffects.EFFECTS)
    GameSound.register(
      language === Language.en ? NumberSounds.NUMBER : NumberSounds.NUMBERTHAI
    )
    GameSound.register(NumberSounds.SIGN)
  }
}
