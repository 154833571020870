import { GameBase } from '../core/game-base'
import { Point } from '../core/point'
import { SightwordGame } from './sightword-game'

export class SightwordActivityGame extends SightwordGame {
  public layoutSize(landscape: boolean) {
    this._landscape = landscape
    if (landscape) {
      return new Point(GameBase.LONGEST, GameBase.SHORTER)
    } else {
      return new Point(GameBase.SHORTER, GameBase.LONGEST)
    }
  }

  // protected async loadQuestion() {
  //   await super.loadQuestion()
  //   const matchImageFactor = this._imageFactory as IMatchImageFactory
  //   const charactorSrc = matchImageFactor.getHead(this._questionName)
  //   const backgroundSrc = matchImageFactor.getBody(this._questionName)
  //   if (this._questionImage) {
  //     this._question!.removeChild(this._questionImage)
  //   }
  //   this._questionImage = await BitmapLoader.loadSubjectBackground(
  //     charactorSrc,
  //     backgroundSrc,
  //     Point.xy(200, 14)
  //   )
  //   this._questionImage.y = this._questionImage.getBounds().height / 2
  //   this._question!.addChild(this._questionImage)
  // }
}
