import { GameSound } from './game-sound'
import { ISoundFactory } from '../core/sound-factory'
import { Language } from './language'
import { SoundEffects } from './sound-effect'

export class ActivitySounds implements ISoundFactory {
  public static SOUNDS: { [id: string]: string } = {
    cooking: require('../../sounds/activities/cooking.mp3'),
    painting: require('../../sounds/activities/painting.mp3'),
    reading: require('../../sounds/activities/reading.mp3'),
    dressing: require('../../sounds/activities/dressing.mp3'),
    dining: require('../../sounds/activities/dining.mp3'),
    camping: require('../../sounds/activities/camping.mp3'),
    fishing: require('../../sounds/activities/fishing.mp3'),
    gardening: require('../../sounds/activities/gardening.mp3'),
    shopping: require('../../sounds/activities/shopping.mp3'),
    swimming: require('../../sounds/activities/swimming.mp3'),
    cleaning: require('../../sounds/activities/cleaning.mp3'),
    'going to bed': require('../../sounds/activities/going to bed.mp3'),
    'taking a bath': require('../../sounds/activities/taking a bath.mp3'),
    'taking a photo': require('../../sounds/activities/taking a photo.mp3')
  }

  /* ISoundFactory */
  public register(_language: Language) {
    GameSound.registerAll([ActivitySounds.SOUNDS, SoundEffects.EFFECTS])
  }
}
