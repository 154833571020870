import { Animator } from './animator'
import { Color } from './color'
import { delay } from '../utils/delay'
import { Point } from './point'
import { TextLabelDeprecated } from './text-label-deprecated'

export class LoadingLabel extends TextLabelDeprecated {
  private static SIZE = Point.xy(200, 40)

  private _finishAnimation = false
  private _loadingText: string

  constructor(position: Point, text: string = 'Loading') {
    super(LoadingLabel.SIZE, text)
    this._loadingText = text
    this.setTextColor(Color.randomStrongTextColor())
    this.x = position.x
    this.y = position.y
    this.regX = LoadingLabel.SIZE.x / 2
    this.regY = LoadingLabel.SIZE.y / 2
    this.alpha = 0
  }

  public animate() {
    Animator.fadeIn(this)
    this.animateLoadingText()
  }

  public stop() {
    this._finishAnimation = true
  }

  private animateLoadingText = () => {
    if (this._finishAnimation) {
      return
    }
    delay(1000).then(() => {
      if (this.stage === null) {
        return
      }

      let text = this.getText()
      if (text.length > 26) {
        text = this._loadingText
      } else {
        text = `.${text}.`
      }
      this.setText(text)
      this.stage?.update()

      // Recursively call the next animation
      this.animateLoadingText()
    })
  }
}
