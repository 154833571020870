import { AbcImages } from '../resource/abc-images'
import { InkData } from './ink-data'
import { WritingGame } from './writing-game'

export class WritingSmallAbcGame extends WritingGame {
  protected inkWidth() {
    return 60
  }

  protected letter() {
    const letters = AbcImages.ALPHABETS
    const letter = letters[this._pageIndex % letters.length]
    return letter.toLowerCase()
  }

  protected resourceName() {
    const letters = AbcImages.ALPHABETS
    const letter = letters[this._pageIndex % letters.length]
    return letter
  }

  protected cartoonName() {
    const names = AbcImages.WORDS
    const name = names[this._pageIndex % names.length]
    return name
  }

  protected checkPoints() {
    return InkData.abc[this.letter()]
  }
}
