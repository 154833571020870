import { BitmapLoader } from '../core/bitmap-loader'
import { ImageFactoryBase } from '../core/image-factory'

export class SeaAnimalImages extends ImageFactoryBase {
  static IMAGES: { [id: string]: string } = {
    clownfish: require('../../images/sea-animals/clownfish.png'),
    crab: require('../../images/sea-animals/crab.png'),
    dolphin: require('../../images/sea-animals/dolphin.png'),
    eel: require('../../images/sea-animals/eel.png'),
    fish: require('../../images/sea-animals/fish.png'),
    lionfish: require('../../images/sea-animals/lionfish.png'),
    octopus: require('../../images/sea-animals/octopus.png'),
    seahorse: require('../../images/sea-animals/seahorse.png'),
    shark: require('../../images/sea-animals/shark.png'),
    snail: require('../../images/sea-animals/snail.png'),
    squid: require('../../images/sea-animals/squid.png'),
    starfish: require('../../images/sea-animals/starfish.png'),
    stingray: require('../../images/sea-animals/stingray.png'),
    turtle: require('../../images/sea-animals/turtle.png'),
    whale: require('../../images/sea-animals/whale.png')
  }

  /* IMatchImageFactory */
  public getHead(name: string): string {
    return SeaAnimalImages.IMAGES[name]
  }

  public getBody(name: string): string {
    return SeaAnimalImages.IMAGES[name]
  }

  /* IBasicImageFactory */
  public getNames() {
    return Object.keys(SeaAnimalImages.IMAGES)
  }

  public async getImage(name: string) {
    const img = await BitmapLoader.load(this.getHead(name))
    img.regX = img.image.width / 2
    img.regY = img.image.height / 2
    return img
  }
}
