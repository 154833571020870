import { ISoundFactory } from '../core/sound-factory'
import { Language } from './language'
import { GameSound } from './game-sound'
import { SoundEffects } from './sound-effect'

export class FoodSounds implements ISoundFactory {
  static SOUNDS: { [id: string]: string } = {
    'bell pepper': require('../../sounds/food/bell pepper.mp3'),
    'bologna sausage': require('../../sounds/food/bologna sausage.mp3'),
    broccoli: require('../../sounds/food/broccoli.mp3'),
    cake: require('../../sounds/food/cake.mp3'),
    'chicken drumstick': require('../../sounds/food/chicken drumstick.mp3'),
    'chicken wing': require('../../sounds/food/chicken wing.mp3'),
    'chinese cabbage': require('../../sounds/food/chinese cabbage.mp3'),
    corn: require('../../sounds/food/corn.mp3'),
    doughnut: require('../../sounds/food/doughnut.mp3'),
    'dragon fruit': require('../../sounds/food/dragon fruit.mp3'),
    durian: require('../../sounds/food/durian.mp3'),
    'french fries': require('../../sounds/food/french fries.mp3'),
    hamburger: require('../../sounds/food/hamburger.mp3'),
    'hot dog': require('../../sounds/food/hot dog.mp3'),
    'ice cream': require('../../sounds/food/ice cream.mp3'),
    kiwi: require('../../sounds/food/kiwi.mp3'),
    melon: require('../../sounds/food/melon.mp3'),
    orange: require('../../sounds/food/orange.mp3'),
    pie: require('../../sounds/food/pie.mp3'),
    pizza: require('../../sounds/food/pizza.mp3'),
    pomelo: require('../../sounds/food/pomelo.mp3'),
    rambutan: require('../../sounds/food/rambutan.mp3'),
    sausage: require('../../sounds/food/sausage.mp3'),
    shrimp: require('../../sounds/food/shrimp.mp3'),
    sushi: require('../../sounds/food/sushi.mp3'),
    'swiss roll': require('../../sounds/food/swiss roll.mp3')
  }

  /* ISoundFactory */
  public register(_language: Language) {
    GameSound.registerAll([FoodSounds.SOUNDS, SoundEffects.EFFECTS])
  }
}
