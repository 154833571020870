'use client'
import * as createjs from 'createjs-module'
import { DropShadowFactory } from './drop-shadow-factory'
import { Point } from './point'

// Use LetterTile
export class TextLabelDeprecated extends createjs.Container {
  public static DEFAULT_FONT = 'Quicksand'
  private static MARGIN: number = 20
  private static RADIUS: number = 0.2

  protected _text: string = ''
  protected _font: string
  protected _size: Point
  protected _includeBackground: boolean
  protected _textNode: createjs.Text
  // protected _outlineTextNode: createjs.Text | undefined
  protected _background: createjs.Shape

  constructor(
    size: Point,
    text: string = '',
    includeBackground: boolean = false,
    font: string = TextLabelDeprecated.DEFAULT_FONT
  ) {
    super()
    this._size = size.copy()
    this._text = text
    this._font = font
    this._includeBackground = includeBackground

    this._background = new createjs.Shape()
    if (this._includeBackground) {
      this.addChild(this._background)
    }

    this._textNode = TextLabelDeprecated.createTextNode(
      Math.floor(size.y / 1.2),
      this._font
    )
    this._textNode.text = text.toString()
    this._textNode.x = this._size.x / 2
    this._textNode.y = this._size.y / 2
    DropShadowFactory.create(this._textNode, 0.6)
    this.addChild(this._textNode)

    // if (this._includeOutline) {
    //   this._outlineTextNode = this._textNode.clone()
    //   this._outlineTextNode.color = '#333'
    //   this._outlineTextNode.outline = 5
    //   this.addChild(this._outlineTextNode)
    // }

    if (this._includeBackground) {
      this.setBackground('blue')
    }

    this.setBounds(0, 0, this._size.x, this._size.y)
  }

  public static font(
    size: number,
    fontName: string = TextLabelDeprecated.DEFAULT_FONT
  ) {
    // return `${size}px Arial Rounded MT Bold`

    // https://fonts.googleapis.com/css2?family=Quicksand:wght@700
    // `normal normal bold ${size}px ${fontName}`
    return `${size}px ${fontName}`
  }

  public static createTextNode(
    size: number,
    fontName: string = TextLabelDeprecated.DEFAULT_FONT
  ) {
    const textNode = new createjs.Text()
    textNode.color = 'white'
    const fontSize = size
    textNode.font = this.font(fontSize, fontName)
    textNode.textAlign = 'center'
    textNode.textBaseline = 'middle'
    DropShadowFactory.create(textNode, 0.6)
    return textNode
  }

  public measureText() {
    if (!this._includeBackground) {
      const w = this._textNode.getMeasuredWidth()
      const h = this._textNode.getMeasuredHeight()
      this._size.x = w
      this._size.y = h

      this.regX = this._size.x / 2
      this.regY = this._size.y / 2

      this.setBounds(0, 0, w, h)
    } else {
      this.regX = this._size.x / 2
      this.regY = this._size.y / 2

      this.setBounds(0, 0, this._size.x, this._size.y)
    }
  }

  public shrinkToFitWidth() {
    const padding = 0 // Math.floor(this._size.y / 2.5)
    var fontSize = Math.floor(this._size.y / 1.2)
    var w = 0
    do {
      this._textNode.font = TextLabelDeprecated.font(fontSize, this._font)
      w = this._textNode.getMeasuredWidth()
      fontSize -= 10
    } while (w > this._size.x - padding * 2)
  }

  public setText(text: string) {
    this._text = text
    this._textNode.text = text

    // const outlineTextNode = this._outlineTextNode
    // if (outlineTextNode !== undefined) {
    //   outlineTextNode.text = text
    // }
  }

  public getText() {
    return this._text
  }

  public setTextColor(color: string) {
    this._textNode.color = color
  }

  public setBackground(color: string) {
    var w = this._size.x + TextLabelDeprecated.MARGIN * 2.0
    var h = this._size.y + TextLabelDeprecated.MARGIN * 2.0
    var x = w / 2 - TextLabelDeprecated.MARGIN
    var y = h / 2 - TextLabelDeprecated.MARGIN
    var r = TextLabelDeprecated.RADIUS * h

    this._background.regX = w / 2
    this._background.regY = h / 2

    this._background.graphics.clear()

    const borderColor = color
    this._background.graphics
      .beginFill(borderColor)
      .drawRoundRect(x, y, w, h, r)
    // const inset = Math.floor(this._size.x / 30)
    // x = x + inset
    // y = y + inset
    // w = w - inset * 2
    // h = h - inset * 2
    // this._background.graphics
    //   .beginFill(color)
    //   .drawRoundRect(x, y, w, h, r - inset / 2)
  }
}
